<template>
  <v-container
    fluid
    fill-height
    @keydown.enter="setPasswords"
  >
    <v-layout
      align-center
      justify-center
      style="margin-top: 8vh;"
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('main.recovery.title_second') }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="pass1"
                prepend-icon="lock"
                name="password"
                :label="$t('common.fields.new_password')"
                type="password"
              />
              <v-text-field
                v-model="pass2"
                prepend-icon="lock"
                name="password"
                :label="$t('common.fields.new_password_confirm')"
                type="password"
              />
            </v-form>
            <template v-if="passPolicies.length > 0">
              {{ $t('main.recovery.pass_rule') }}:<br>
              <ul>
                <span v-html="passPolicies" />
              </ul>
            </template>
          </v-card-text>
          <v-alert
            v-if="error.length !== 0"
            class="mx-2"
            :dense="true"
            type="error"
            v-html="error"
          />
          <v-alert
            v-if="success.length != 0"
            class="mx-2"
            :dense="true"
            type="success"
          >
            {{ success }}
          </v-alert>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="disabledSaveButton"
              @click="setPasswords"
            >
              {{ $t('common.actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import helpers from '../helpers';
import AuthService from '../services/AuthService';
export default {
  name: 'PassRecoverySecond',
  data() {
    return {
      pass1: '',
      pass2: '',
      error: '',
      success: '',

      passPolicies: '',
      disabledSaveButton: false,
    };
  },
  mounted() {
    const that = this;
    AuthService.simulateRedirect(this.$route.params.uid);
    AuthService.getPasswordPolicies().then((resp) => {
      that.passPolicies = resp.data.data
        .map((el) => '<li>' + that.$sanitize(el) + '</li>')
        .join('');
    });
  },
  methods: {
    validation() {
      if (this.pass1.length === 0 || this.pass2.length === 0) {
        this.error = 'All fields are required';
        return false;
      }

      if (this.pass1 !== this.pass2) {
        this.error = 'Password values are different';
        return false;
      }

      return true;
    },
    setPasswords() {
      this.error = '';

      if (this.validation() === false) {
        this.disabledSaveButton = false;
        return;
      }

      this.disabledSaveButton = true;

      AuthService.setNewUserPassword(
        this.$route.params.uid,
        this.pass1,
        this.pass2,
      )
        .then((resp) => {
          this.success = resp.data.message;
          setTimeout((_) => {
            location.replace('/login');
          }, 2500);
        })
        .catch((e) => {
          this.error = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
