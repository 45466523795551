<template>
  <div id="forPermits">
    <div v-if="cardView === 0">
      <h3>{{ $t('foreigners.view.permits_in_process') }}</h3>
      <v-data-table
        id="processPermits"
        :headers="processTableHeaders"
        :items="this.$store.getters['permits/data'].filter(el => el.permit_status !== 30)"
        :items-per-page.sync="processrowsOnPage"
        class="tableSmallFont"
        :page.sync="processtablePage"
        hide-default-header
        :footer-props="{
          'disable-pagination': true,
          'items-per-page-all-text': '',
          'show-current-page': false,
          firstIcon: '',
          lastIcon: '',
          prevIcon: '',
          nextIcon: '',
        }"
        @click:row="openPermitAsCard"
      >
        <template
          slot="header"
        >
          <tr>
            <template v-for="(h, index) in processTableHeaders">
              <template>
                <th
                  :key="index"
                  :class="'header_sortable_'+h.sortable"
                  :style="'width:'+h.width"
                  @click="sorting(h.value, h.sortable)"
                >
                  {{ $t(h.text) }}
                </th>
              </template>
            </template>
          </tr>
        </template>
        <template
          slot="item.foreigner_avatar_url"
          slot-scope="props"
        >
          <v-img
            v-if="Object.keys(props.item.foreigner_avatar_url).length === 0 &&
              props.item.foreigner_avatar_url.constructor === Object"
            src="../../../assets/avatar.png"
            style="width: 64px; height: 64px"
          />
          <img
            v-else
            :src="BASE_HOST+props.item.foreigner_avatar_url['64']"
          >
        </template>
        <template
          slot="item.foreigner_fullname"
          slot-scope="props"
        >
          <span>{{ props.item.foreigner_fullname }}</span>
        </template>
        <template
          slot="item.permit_type_name"
          slot-scope="props"
        >
          {{ props.item.permit_type_name }} ({{ props.item.reference_number }})
        </template>
        <template
          slot="item.permit_document_missing_count"
          slot-scope="props"
        >
          <div @click.stop="openPermitAsCard(props.item, 1)">
            <template v-if="props.item.permit_document_missing_count === 0">
              <v-img
                src="../../../assets/icons_numbers/file-outline-0.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 1">
              <v-img
                src="../../../assets/icons_numbers/file-outline-1.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 2">
              <v-img
                src="../../../assets/icons_numbers/file-outline-2.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 3">
              <v-img
                src="../../../assets/icons_numbers/file-outline-3.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 4">
              <v-img
                src="../../../assets/icons_numbers/file-outline-4.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 5">
              <v-img
                src="../../../assets/icons_numbers/file-outline-5.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 6">
              <v-img
                src="../../../assets/icons_numbers/file-outline-6.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 7">
              <v-img
                src="../../../assets/icons_numbers/file-outline-7.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 8">
              <v-img
                src="../../../assets/icons_numbers/file-outline-8.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 9">
              <v-img
                src="../../../assets/icons_numbers/file-outline-9.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 10">
              <v-img
                src="../../../assets/icons_numbers/file-outline-10.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 11">
              <v-img
                src="../../../assets/icons_numbers/file-outline-11.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 12">
              <v-img
                src="../../../assets/icons_numbers/file-outline-12.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 13">
              <v-img
                src="../../../assets/icons_numbers/file-outline-13.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 14">
              <v-img
                src="../../../assets/icons_numbers/file-outline-14.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 15">
              <v-img
                src="../../../assets/icons_numbers/file-outline-15.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 16">
              <v-img
                src="../../../assets/icons_numbers/file-outline-16.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 17">
              <v-img
                src="../../../assets/icons_numbers/file-outline-17.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 18">
              <v-img
                src="../../../assets/icons_numbers/file-outline-18.png"
                width="24"
              />
            </template>
            <template v-else-if="props.item.permit_document_missing_count === 19">
              <v-img
                src="../../../assets/icons_numbers/file-outline-19.png"
                width="24"
              />
            </template>
            <template v-else>
              <v-img
                src="../../../assets/icons_numbers/file-outline-inf.png"
                width="24"
              />
            </template>
          </div>
        </template>
        <template
          slot="item.permit_status_index"
          slot-scope="props"
        >
          <template v-if="props.item.permit_status_index.length === 0">
            {{ $t('common.values.no_data') }}
          </template>
          <v-stepper
            v-else
            id="stepperStatuses"
            grow
          >
            <v-stepper-header style="padding-right: 10px">
              <template v-for="index in parseInt(props.item.permit_status_index.split('/')[1])">
                <v-stepper-step
                  :key="index"
                  :title="props.item.permit_status_list[index-1]"
                  :value="index"
                  complete="true"
                  :complete-icon="String(index)"
                  :step="index"
                  :color="countColor(index, parseInt(props.item.permit_status_index.split('/')[0]))"
                  @click="openPermitAsCard(props.item, 0)"
                />

                <v-divider
                  v-if="index !== parseInt(props.item.permit_status_index.split('/')[1])"
                  :key="`divider-${index}`"
                />
              </template>
            </v-stepper-header>
          </v-stepper>
        </template>
      </v-data-table>

      <br>
      <br>
      <h3>{{ $t('foreigners.view.permits_obtained') }}</h3>
      <v-data-table
        :headers="obtainedTableHeaders"
        :items="this.$store.getters['permits/data'].filter(el => el.permit_status === 30)"
        :items-per-page.sync="obtainedrowsOnPage"
        class="tableSmallFont"
        :page.sync="obtainedtablePage"
        hide-default-header
        :footer-props="{

          'disable-pagination': true,
          'items-per-page-all-text': '',
          'show-current-page': false,
          firstIcon: '',
          lastIcon: '',
          prevIcon: '',
          nextIcon: '',
        }"
      >
        <template
          slot="header"
        >
          <tr>
            <th
              v-for="(h, index) in obtainedTableHeaders"
              :key="index"
              :class="'header_sortable_'+h.sortable "
              :style="'width:'+h.width"
              @click="sorting(h.value, h.sortable)"
            >
              {{ $t(h.text) }}
            </th>
          </tr>
        </template>
        <template
          slot="item.foreigner_avatar_url"
          slot-scope="props"
        >
          <v-img
            v-if="Object.keys(props.item.foreigner_avatar_url).length === 0 && props.item.foreigner_avatar_url.constructor === Object"
            src="../../../assets/avatar.png"
            style="width: 64px; height: 64px"
          />
          <img
            v-else
            :src="BASE_HOST+props.item.foreigner_avatar_url['64']"
          >
        </template>
        <template
          slot="item.foreigner_fullname"
          slot-scope="props"
        >
          <span>{{ props.item.foreigner_fullname }}</span>
        </template>
        <template
          slot="item.icon"
          slot-scope="props"
        >
          <template v-if="props.item.final_document_url !== null">
            <v-icon
              class="pointerHover"
              :color="$appColours.status.others.active"
              @click="downloadFileByUrl(props.item.final_document_url)"
            >
              mdi-file-document
            </v-icon>
          </template>
          <template v-else>
            <v-icon :color="$appColours.status.others.notactive">
              mdi-file-document
            </v-icon>
          </template>
        </template>
        <template
          slot="item.permit_type_name"
          slot-scope="props"
        >
          {{ props.item.permit_type_name }} ({{ props.item.reference_number }})
        </template>
        <template
          slot="item.issue_date"
          slot-scope="props"
        >
          {{ props.item.issue_date }}
        </template>
        <template
          slot="item.renewal_date"
          slot-scope="props"
        >
          {{ props.item.renewal_date }}
        </template>
        <template
          slot="item.expire_date"
          slot-scope="props"
        >
          {{ props.item.expire_date }}
        </template>
      </v-data-table>
    </div>

    <!--		loading-->
    <div v-else-if="cardView === 1">
      <v-container
        bg
        fill-height
        grid-list-md
        text-xs-center
      >
        <v-layout
          row
          wrap
          justify-center
          fill-height
        >
          <Loading
            style="margin-top: 12vh"
          />
        </v-layout>
      </v-container>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>

    <div v-else-if="cardView === 2">
      <v-btn @click="goBack">
        &lt; {{ $t('foreigners.view.back') }}
      </v-btn>
      <br>
      <br>
      <v-expansion-panels :value="0">
        <v-expansion-panel readonly>
          <v-expansion-panel-header
            style=" background-color: #f7f7f7;"
            :hide-actions="true"
            :disable-icon-rotate="true"
          >
            <h3>
              {{ permitForCardView.foreigner_fullname }} -
              {{ permitForCardView.permit_type_name }}
              ({{ permitForCardView.reference_number }})
            </h3>
            <v-flex xs4>
              <v-tabs
                id="permitTabs"
                v-model="tab"
                grow
                style="background-color: transparent"
              >
                <v-tab @click="openStatusTab">
                  {{ $t('foreigners.view.status_dates') }}
                </v-tab>
                <v-tab
                  id="documents"
                  @click="openDocumentTab"
                >
                  {{ $t('foreigners.view.documents') }}
                </v-tab>
              </v-tabs>
            </v-flex>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <br>
            <v-tabs-items v-model="tab">
              <v-tab-item :value="0">
                <v-stepper
                  v-model="statusTab"
                  non-linear
                  :value="this.$store.getters['permits/currentPermitData']['statuses'].findIndex(el => el.is_current === true)+1"
                >
                  <v-stepper-header
                    :value="this.$store.getters['permits/currentPermitData']['statuses'].findIndex(el => el.is_current === true)+1"
                    style="padding: 3px; height: 100%; min-height: 80px;"
                  >
                    <template v-for="(s, index) in this.$store.getters['permits/currentPermitData']['statuses']">
                      <template>
                        <v-stepper-step
                          :key="index"
                          :complete="ifStatusPassed(s, $store.getters['permits/currentPermitData']['statuses'])"
                          :editable="enabledNavigation"
                          :step="s.human_order"
                          :class="(s.is_current === true) ? 'currentStatus' : ''"
                          :style="`width: ${100 / $store.getters['permits/currentPermitData']['statuses'].length}%; word-break: break-word;`"
                        >
                          {{ s.status_type_name }}
                          <small v-if="s.is_current === true">
                            {{ $t('foreigners.permits.current') }}
                          </small>
                        </v-stepper-step>
                      </template>
                    </template>
                  </v-stepper-header>

                  <v-stepper-content
                    v-for="(s,i) in $store.getters['permits/currentPermitData']['statuses']"
                    :key="s.id"
                    :step="i+1"
                  >
                    <v-container
                      v-if="s.action_dates.length !== 0"
                      style="margin-left: 5px; margin-right: 5px; max-width: inherit"
                    >
                      <v-layout row>
                        <v-flex xs3>
                          <div>
                            <b>{{ $t('foreigners.permits.action') }} </b>
                          </div>
                        </v-flex>
                        <v-flex xs2>
                          <div>
                            <b>{{ $t('foreigners.permits.date') }} </b>
                          </div>
                        </v-flex>
                        <v-flex xs3>
                          <div>
                            <b>{{ $t('foreigners.permits.note') }} </b>
                          </div>
                        </v-flex>
                        <v-flex xs2>
                          <div>
                            <b>{{ $t('foreigners.permits.place') }} </b>
                          </div>
                        </v-flex>
                        <v-flex xs2>
                          <div>
                            <b>{{ $t('foreigners.permits.assistance') }} </b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div
                      v-for="a in s.action_dates"
                      :key="a.id"
                    >
                      <v-container style="margin-left: 5px; margin-right: 5px; max-width: inherit">
                        <v-layout row>
                          <v-flex xs3>
                            <div>
                              {{ a.action_date_type_name }}

                              <v-tooltip
                                v-if="a.action_date_type_description.length > 0"
                                v-model="a.showToolTip"
                                right
                                max-width="400"
                                :color="'grey darken-4'"
                                style="word-wrap:break-word;"
                              >
                                <template v-slot:activator="on">
                                  <v-btn
                                    icon
                                    v-on="on"
                                    @click="showNeededTooltip(a, s.action_dates)"
                                  >
                                    <v-icon color="blue">
                                      mdi-information-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <div v-html="prepareTooltipHtml(a.action_date_type_description)" />
                              </v-tooltip>

                            </div>
                          </v-flex>


                          <v-flex xs2>
                            <div>
                              <template v-if="a.action_date && a.action_time">
                                {{ a.action_date + " " + a.action_time }}
                              </template>
                              <template v-else-if="a.action_date">
                                {{ a.action_date }}
                              </template>
                              <template v-else-if="a.action_time">
                                {{ a.action_time }}
                              </template>
                              <template v-else>
                                <i>{{ $t('foreigners.permits.no_date') }}</i>
                              </template>
                            </div>
                          </v-flex>
                          <v-flex xs3>
                            <div>
                              {{ (a.note) ? a.note : "-" }}
                            </div>
                          </v-flex>
                          <v-flex xs2>
                            <template v-if="a.place_maps_url !== ''">
                              <div style="margin-top: 5px">
                                <a
                                  :href="a.place_maps_url"
                                  target="_blank"
                                >
                                  {{ a.place_short_name }}
                                </a>
                              </div>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </v-flex>
                          <v-flex xs2>
                            <template v-if="a.assistance_id === -2">
                              {{ $t('foreigners.permits.assistance.without') }}
                            </template>
                            <template v-else-if="a.assistance_id !== null">
                              <v-layout row>
                                <v-flex
                                  xs2
                                  style="margin-bottom: 5px"
                                >
                                  <div v-if="Object.entries(a.assistance_avatar_url).length === 0">
                                    <v-img
                                      :title="a.assistance_fullname"
                                      src="../../../assets/avatar.png"
                                      style="width: 32px; height: 32px"
                                    />
                                  </div>
                                  <div v-else>
                                    <v-img
                                      :src="BASE_HOST + a.assistance_avatar_url[32]"
                                      :title="a.assistance_fullname"
                                      style="width: 32px; height: 32px"
                                    />
                                  </div>
                                </v-flex>
                                <v-flex
                                  v-if="a.assistance_phone.length !== 0"
                                  xs10
                                >
                                  <a href="tel:a.assistance_phone">{{ a.assistance_phone }}</a>
                                </v-flex>
                              </v-layout>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </div>
                    <div
                      v-if="s.action_dates.length === 0"
                      class="text-center"
                    >
                      {{ $t('foreigners.permits.no_action_dates') }}
                    </div>
                  </v-stepper-content>
                </v-stepper>
              </v-tab-item>

              <v-tab-item :value="1">
                <div id="documentsDiv">
                  <v-flex
                    xs12
                    row
                    mt-3
                  >
                    <v-flex xs4>
                      <b>{{ $t('foreigners.permits.document_type') }}</b>
                    </v-flex>
                    <v-flex xs3>
                      <b>{{ $t('foreigners.permits.file_name') }}</b>
                    </v-flex>
                    <v-flex xs3>
                      <b>{{ $t('foreigners.permits.note') }} </b>
                    </v-flex>
                    <v-flex xs2>
                      <b>{{ $t('common.filters.status') }}</b>
                    </v-flex>
                  </v-flex>
                  <v-flex
                    v-for="document in this.$store.getters['permits/currentPermitData']['documents']"
                    :id="document.id"
                    :key="document.id"
                    xs12
                    row
                    mt-3
                  >
                    <v-flex xs4>
                      {{ document.document_type_name }}
                      <v-tooltip
                        v-if="document.document_type_description.length > 0"
                        v-model="document.showToolTip"
                        right
                        max-width="400"
                        :color="'grey darken-4'"
                        style="word-wrap:break-word;"
                      >
                        <template v-slot:activator="on">
                          <v-btn
                            icon
                            v-on="on"
                            @click="showNeededTooltip(document, $store.getters['permits/currentPermitData']['documents'])"
                          >
                            <v-icon color="blue">
                              mdi-information-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <div v-html="prepareTooltipHtml(document.document_type_description)" />
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs3>
                      <v-file-input
                        :disabled="(!document.editable_by_foreigner)"
                        class="fileInput"
                        :label="$t('foreigners.permits.file_input')"
                        style="display: inline"
                        :error-messages="getDocumentValidationErrorMessage({
                          field: 'document',
                          id: document.id,
                        })"
                        multiple
                        @change="function(file){selectedDocument = document; onFileSelect(file, document.id); }"
                      />
                      <template v-if="document.document_name !== null">
                        <a
                          style="top: -5px"
                          :title="document.document_name"
                          @click="downloadFile(5, document.id)"
                        >
                          {{
                            ((document.document_name.length > 20) ?
                              document.document_name.slice(0, 20) + '...'
                              :
                              document.document_name)
                          }}
                        </a>
                      </template>
                      <template v-else>
                        <span
                          style="top: -5px"
                          :title="document.document_name"
                        >{{
                          $t('foreigners.permits.no_file')
                        }}</span>
                      </template>
                      <div
                        v-if="getDocumentValidationErrorMessage({
                          field: 'document',
                          id: document.id,
                        })"
                        class="v-messages theme--light error--text"
                      >
                        <span class="v-messages__message">
                          {{ getDocumentValidationErrorMessage({
                            field: 'document',
                            id: document.id,
                          }) }}
                        </span>
                      </div>
                    </v-flex>
                    <v-flex xs3>
                      {{ (document.note) ? document.note : "-" }}
                    </v-flex>
                    <v-flex xs2>
                      <div>
                        <v-icon :color="$store.getters['coreData/foreignerPermitDocumentStatusById'](document.status)['color']">
                          mdi-circle
                        </v-icon>
                        {{ $t($store.getters['coreData/foreignerPermitDocumentStatusById'](document.status)['key']) }}
                      </div>
                    </v-flex>
                  </v-flex>
                </div>

                {{ permitForCardView.documents }}

                <v-flex xs12>
                  <br>
                  <v-alert
                    v-if="errorHtml.length > 0"
                    type="error"
                    v-html="errorHtml"
                  />
                </v-flex>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermitsService from '../../../services/foreignerApi/PermitsService';
import baseURL from '../../../services/APIConfig';
import axios from 'axios';
import helpers from '@/helpers';

import Loading from '@/assets/loading.svg';

export default {
  name: 'Permits',
  components: {
    Loading,
  },
  props: {
    pid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cardView: 0, // 0 for table, 1 for loading, 2 for card view

      errorHtml: '',

      BASE_HOST: process.env.VUE_APP_HOSTURL,
      processTableHeaders: [
        {
          text: this.$t('common.fields.foreigner'),
          value: 'foreigner_avatar_url',
          sortable: false,
          width: '10%',
        },
        {
          text: '',
          value: 'foreigner_fullname',
          sortable: false,
          width: '15%',
        },
        {
          text: this.$t(
            'foreigners.permits.new_permit_dialog.permit_type_name',
          ),
          value: 'permit_type_name',
          sortable: false,
          width: '25%',
        },
        {
          text: this.$t('common.filters.status'),
          value: 'permit_status_index',
          sortable: false,
          width: '35%',
        },
        {
          text: this.$t('common.fields.documents'),
          value: 'permit_document_missing_count',
          sortable: false,
          width: '15%',
        },
      ],
      processtablePage: 0,
      processrowsOnPage: 10,

      obtainedTableHeaders: [
        {
          text: this.$t('common.fields.foreigner'),
          value: 'foreigner_avatar_url',
          sortable: false,
          width: '10%',
        },
        {
          text: '',
          value: 'foreigner_fullname',
          sortable: false,
          width: '15%',
        },

        {
          text: this.$t(
            'foreigners.permits.new_permit_dialog.permit_type_name',
          ),
          value: 'permit_type_name',
          sortable: false,
          width: '25%',
        },
        {
          text: this.$t('foreigners.permits.permit_obtain_dialog.issue_date'),
          value: 'issue_date',
          sortable: false,
          width: '12%',
        },
        {
          text: this.$t('foreigners.filters.renewal_date'),
          value: 'renewal_date',
          sortable: false,
          width: '12%',
        },
        {
          text: this.$t('foreigners.filters.expiry_date'),
          value: 'expire_date',
          sortable: false,
          width: '11%',
        },
        {
          text: this.$t(
            'foreigners.permits.permit_obtain_dialog.permit_document',
          ),
          value: 'icon',
          sortable: false,
          width: '15%',
        },
      ],
      obtainedtablePage: 0,
      obtainedrowsOnPage: 10,

      permitForCardView: null,
      tab: 0,
      enabledNavigation: true,
      statusTabs: [],

      selectedDocument: 0,

      documentValidationErrors: {},
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      foreignersQuestionnaires: 'foreignerQuestionnaire/foreignersQuestionnaires',
      permits: 'foreigners/permits',
    }),
    statusTab: function() {
      if (
        this.$store.getters['permits/currentPermitData']['statuses'].length ===
        0
      )
        return 0;
      return (
        this.$store.getters['permits/currentPermitData']['statuses'].findIndex(
          (el) => el.is_current === true,
        ) + 1
      );
    },
  },
  created() {},
  mounted() {
    const that = this;
    this.$store.dispatch('permits/retrievePermits').then((_) => {
      const forwardPermit = localStorage.getItem('itrack_for_forward_to_permit');
      const forwardPermitDocuments = localStorage.getItem(
        'itrack_for_forward_to_permit_document',
      );

      if (forwardPermit != null) {
        localStorage.removeItem('itrack_for_forward_to_permit');
        let p = null;
        for (const pp of that.$store.getters['permits/data']) {
          if (pp.id === parseInt(forwardPermit)) {
            p = pp;
            break;
          }
        }
        that.openPermitAsCard(p);
      } else if (forwardPermitDocuments != null) {
        localStorage.removeItem('itrack_for_forward_to_permit_document');
        let p = null;
        for (const pp of that.$store.getters['permits/data']) {
          if (pp.id === parseInt(forwardPermitDocuments)) {
            p = pp;
            break;
          }
        }
        that.openPermitAsCard(p, 1);
      }
    });
  },
  methods: {
    debug: function() {},
    downloadFile: function(pid, did) {
      const url =
        process.env.VUE_APP_HOSTURL +
        'backend/api_foreigner/permit/' +
        this.permitForCardView.id +
        '/document/' +
        did +
        '/';

      axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if (response.headers['content-disposition'])
          fileLink.setAttribute(
            'download',
            decodeURI(
              response.headers['content-disposition']
                .split('filename')[2]
                .slice(9),
            ),
          );
        else fileLink.setAttribute('download', 'obtained_document.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getDocumentValidationErrorMessage({ field, id }) {
      if (this.documentValidationErrors[id]) {
        const message = this.documentValidationErrors[id][field];
        return Array.isArray(message) ? message.join(', ') : message;
      }
      return false;
    },
    downloadFileByUrl: function(urll) {
      const url = baseURL + '/' + urll;

      axios({
        url: urll,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if (response.headers['content-disposition'])
          fileLink.setAttribute(
            'download',
            decodeURI(
              response.headers['content-disposition']
                .split('filename')[2]
                .slice(9),
            ),
          );
        else fileLink.setAttribute('download', 'obtained_document.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    showNeededTooltip: function(doc, documents) {
      doc.showToolTip = !doc.showToolTip;
      for (const d of documents) {
        if (d.id !== doc.id) {
          d.showToolTip = false;
        }
      }
    },
    closeAllTooltips: function() {
      for (const d of this.$store.getters['permits/currentPermitData']['documents'])
        d.showToolTip = false;

      for (const s of this.$store.getters['permits/currentPermitData']['statuses'])
        for (const a of s.action_dates)
          a.showToolTip = false;

    },
    openStatusTab: function() {
      const p = window.location.pathname.split('/').slice(0, -1).join('/');
      window.history.pushState('page2', 'Title', p);
    },
    openDocumentTab: function() {
      const p = window.location.pathname;
      window.history.pushState('page2', 'Title', p + '/documents');
    },
    goBack: function() {
      this.cardView = 0;
      window.history.pushState('objevfdvdfng', 'vdfvtle', '/');
    },
    countComplete: function(index, currentIndex) {
      return index <= currentIndex;
    },
    countColor: function(index, currentIndex) {
      const statuses = this.$store.getters['permits/data'].filter(
        (el) => el.permit_status !== 30,
      );
      if (index < currentIndex) return 'grey lighten-1';
      if (index === currentIndex) return 'blue lighten-3';
      if (index > currentIndex) return '#f0f0f0';

      return 'blue';
    },
    prepareTooltipHtml: function(text) {
      return (
        '<div style=\'word-wrap:break-word;\'>' +
        text.replace(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm,
          '<a target="_blank" href=\'$1\'>$1</a>',
        ) +
        '</div>'
      );
    },
    ifStatusPassed: function(status, allStatuses) {
      const indexOfCurrent = allStatuses.findIndex(
        (el) => el.is_current === true,
      );
      const indexOfNeeded = allStatuses.findIndex((el) => el.id === status.id);
      return indexOfNeeded < indexOfCurrent + 1;
    },
    getIndexOfCurrentStatus: function(allStatuses) {},
    openPermitAsCard: function(permit, tab = 0) {
      this.permitForCardView = permit;
      this.cardView = 1;

      const that = this;
      Promise.all([
        this.$store.dispatch('permits/retrievePermitStatus', {
          pid: permit.id,
        }),
        this.$store.dispatch('permits/retrievePermitDocuments', {
          pid: permit.id,
        }),
      ]).then((_) => {
        that.tab = tab;
        that.cardView = 2;
        window.history.pushState(
          'object or string',
          'Page Title',
          `/permits/${permit.id}${tab === 1 ? '/documents' : ''}`,
        );
        setTimeout(function() {
          document.querySelector('.currentStatus').click();
          that.enabledNavigation = false;
        }, 100);
      });
    },
    onFileSelect: function(file, documentId) {
      const that = this;
      that.errorHtml = '';

      PermitsService.uploadDocument(
        file,
        this.permitForCardView.id,
        this.selectedDocument.id,
      )
        .then((_) => {
          that.$store.dispatch('permits/retrievePermitDocuments', {
            pid: that.permitForCardView.id,
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data && typeof err.response.data.data === 'object') {
              this.documentValidationErrors[documentId] = err.response.data.data;
              this.$forceUpdate();
            } else if (
              typeof err.response.data == 'string' &&
              err.response.data.includes('413 Request Entity Too Large')
            ) {
              that.errorHtml = this.$t(
                'foreigners.permits.documents.file_too_big',
              );
            } else {
              that.errorHtml = helpers.createErrorHtml(err.response.data);
            }
          } else {
            that.errorHtml = this.$t(
              'foreigners.permits.documents.file_too_big',
            );
          }
        });
    },
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: auto;
}

#stepperStatuses {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
}

#permitTabs div,
#permitTabs .v-tabs-bar {
  background-color: rgb(247, 247, 247);
}

#documentsDiv .v-text-field .v-input__control {
  /*background-color: red;*/
  display: none !important;
}

#processPermits tr:hover {
  cursor: pointer;
}

.panel-content .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}

.document_icon-1 {
  background-image: url("../../../assets/icons_numbers/file-outline-1.png");
}

.v-stepper__header {
  height: 82px;
}

.pastColor span {
  background-color: green;
}

#forPermits .v-icon.material-icons {
  font-family: "Roboto", sans-serif;
  color: #464646 !important;
  font-size: 100% !important;
}
</style>
