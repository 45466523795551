import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getPermitTypes(page, rows, order_by) {
    // //console.log("qqqq", page, rows, order_by);
    return axios.get(`${baseURL}/permit/?page=${page}&page_size=${rows}&order_by=${order_by}`);
  },
  getPermitTypesDetails(id) {
    return axios.get(`${baseURL}/permit/${id}/`);
  },
  createPermitType(obj) {
    return axios.post(`${baseURL}/permit/`, obj);
  },
  editPermitType(obj, id) {
    return axios.patch(`${baseURL}/permit/${id}/`, obj);
  },
  deletePermitType(id) {
    return axios.delete(`${baseURL}/permit/${id}/`);
  },
};
