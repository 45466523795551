import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setPasswords.apply(null, arguments)}}},[_c(VLayout,{staticStyle:{"margin-top":"8vh"},attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('main.recovery.title_second')))]),_c(VSpacer)],1),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"prepend-icon":"lock","name":"password","label":_vm.$t('common.fields.new_password'),"type":"password"},model:{value:(_vm.pass1),callback:function ($$v) {_vm.pass1=$$v},expression:"pass1"}}),_c(VTextField,{attrs:{"prepend-icon":"lock","name":"password","label":_vm.$t('common.fields.new_password_confirm'),"type":"password"},model:{value:(_vm.pass2),callback:function ($$v) {_vm.pass2=$$v},expression:"pass2"}})],1),(_vm.passPolicies.length > 0)?[_vm._v(" "+_vm._s(_vm.$t('main.recovery.pass_rule'))+":"),_c('br'),_c('ul',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.passPolicies)}})])]:_vm._e()],2),(_vm.error.length !== 0)?_c(VAlert,{staticClass:"mx-2",attrs:{"dense":true,"type":"error"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e(),(_vm.success.length != 0)?_c(VAlert,{staticClass:"mx-2",attrs:{"dense":true,"type":"success"}},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","loading":_vm.disabledSaveButton},on:{"click":_vm.setPasswords}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.save'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }