<template>
  <div>
    <v-flex md12>
      <v-toolbar
        class="elevation-1"
        color="white accent-4"
      >
        <v-flex
          row
          xs12
        >
          <!-- Edit button -->
          <v-btn
            v-if="me.role !== $roles.AGENCY"
            :color="$appColours.button.edit"
            dark
            outlined
            @click="$emit('edit-foreigner')"
          >
            {{ $t('foreigners.basic_info.edit_button') }}
          </v-btn>
          <!-- Edit family button -->
          <v-btn
            v-if="me.role !== $roles.AGENCY && me.role !== $roles.HR"
            class="ml-4"
            :color="$appColours.button.edit"
            dark
            outlined
            @click="$emit('open-family-dialog')"
          >
            {{ $t('foreigners.basic_info.edit_family_button') }}
          </v-btn>
          <!-- Send activation email button -->
          <v-btn
            v-if="me.role !== $roles.AGENCY && me.role !== $roles.HR && foreigner.registered === false"
            class="ml-4"
            dark
            outlined
            :color="$appColours.button.edit"
            @click="$emit('send-activation-mail')"
          >
            {{ $t('foreigners.basic_info.activate_button') }}
          </v-btn>
          <!-- Send reset email button -->
          <v-btn
            v-if="me.role !== $roles.AGENCY && me.role !== $roles.HR && foreigner.registered === true"
            class="ml-4 "
            :color="$appColours.button.edit"
            dark
            outlined
            @click="$emit('open-send-reset-mail-dialog')"
          >
            {{ $t('foreigners.basic_info.reset_button') }}
          </v-btn>
          <!-- Edit notifications button -->
          <v-btn
            class="ml-4 "
            :color="$appColours.button.edit"
            outlined
            @click="$emit('open-foreigner-notifications-dialog')"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
          <v-spacer />
          <!-- Delete button -->
          <template v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT">
            <v-btn
              :color="$appColours.button.delete"
              outlined
              @click="$emit('open-foreigner-delete-dialog')"
            >
              {{ $t('foreigners.basic_info.delete_button') }}
            </v-btn>
          </template>
        </v-flex>
      </v-toolbar>
    </v-flex>
    <v-container>
      <v-layout row>
        <v-flex
          sm6
          xs12
        >
          <div>
            <strong>{{ $t('common.fields.first_name') }}: </strong> {{ foreigner.first_name }}
          </div>
          <div class="mt-3">
            <strong>{{ $t('common.fields.last_name') }}: </strong> {{ foreigner.last_name }}
          </div>
          <div
            v-if="foreigner.profile.assigned_to"
            class="mt-3"
          >
            <strong>{{ $t('foreigners.filters.assigned_to') }}: </strong>
            <span>
              {{ foreigner.profile.assigned_to }}
            </span>
          </div>
          <div class="mt-3">
            <strong>{{ $t('common.fields.citizenship') }}: </strong> {{ this.$store.getters['coreData/countryById'](foreigner.profile.citizenship_id).name }}
          </div>
          <div class="mt-3">
            <strong>{{ $t('foreigners.basic_info.fields.hire_origin') }}: </strong> {{ foreigner.profile.hire_origin_name }}
          </div>
          <div class="mt-3">
            <strong>{{ $t('foreigners.basic_info.family') }}: </strong>
            <template v-if="foreigner.family.length === 0">
              <i style="color: grey">
                -
              </i>
            </template>
            <template v-else>
              <div
                v-for="(f, index) in foreigner.family"
                :key="index"
                class="ml-6"
              >
                <router-link :to="`/foreigners/${f.relation_with_id}`">
                  {{ f.relation_with_fullname }} - {{ f.relation_type_name }}
                </router-link>
              </div>
            </template>
            <br>
            <template v-if="foreigner.family.length > 0">
              <strong>{{ $t('foreigners.basic_info.family.head') }}: </strong> {{ (foreigner.profile.master) ? $t('common.values.yes') : $t('common.values.no') }}
            </template>
          </div>
        </v-flex>

        <v-flex
          sm6
          xs12
        >
          <div v-if="foreigner.reference_number">
            <strong>{{ $capitalize($lowercase($t('foreigners.basic_info.fields.ref_number'))) }}: </strong>
            <span>
              {{ foreigner.reference_number }}
            </span>
          </div>
          <div
            v-if="foreigner.profile.client_reference_number"
            class="mt-3"
          >
            <strong>{{ $capitalize($lowercase($t('foreigners.basic_info.fields.cli_ref_number'))) }}: </strong>
            <span>
              {{ foreigner.profile.client_reference_number }}
            </span>
          </div>
          <div
            v-if="foreigner.profile.official_job_code"
            class="mt-3"
          >
            <strong>{{ $t('foreigners.basic_info.fields.off_job_code') }}: </strong>
            <span>
              {{ foreigner.profile.official_job_code }}
            </span>
          </div>
          <div
            v-if="foreigner.phone_primary"
            class="mt-3"
          >
            <strong>{{ $t('common.fields.phone') }}: </strong>
            <span v-if="foreigner.phone_primary !== ''">
              <a :href="`tel:${foreigner.phone_primary}`">{{ foreigner.phone_primary }}</a>
            </span>
            <span v-else>
              <i>{{ $t('common.values.no_data') }}</i>
            </span>
          </div>
          <div
            v-if="foreigner.contact_email"
            class="mt-3"
          >
            <strong>{{ $t('common.fields.email') }}: </strong>
            <span>
              <a :href="`mailto:${foreigner.contact_email}`">{{ foreigner.contact_email }}</a>
            </span>
          </div>
          <div class="mt-3">
            &nbsp;
          </div>
          <div class="mt-3">
            <div class="mt-3">
              <strong>{{ $t('common.fields.company') }}: </strong>
              <span>
                <span v-if="foreigner.profile.client_company.length === 0">
                  <i>{{ $t('common.values.no_data') }}</i>
                </span>
                <span v-else>
                  {{ foreigner.profile.client_company }}
                </span>
              </span>

              <div
                v-if="foreigner.profile.assigned_to_hr_id"
                class="mt-3"
              >
                <strong>{{ $t('foreigners.basic_info.assigned_hr') }}: </strong>
                <span>
                  {{ foreigner.profile.assigned_to_hr }}
                </span>
              </div>
              <div
                v-if="foreigner.profile.supervisor"
                class="mt-3"
              >
                <strong>{{ $t('foreigners.basic_info.fields.supervisor') }}: </strong>
                <span>{{ foreigner.profile.supervisor }}</span>
              </div>

              <div class="mt-3">
                <strong>{{ $t('main.me.interface_language') }}: </strong>
                <span>
                  {{ $capitalize(interfaceLanguagesOptions.find(el => el.id === foreigner.interface_language).name) }}
                </span>
              </div>
              <div
                class="mt-3"
                style="color: #959595"
              >
                <strong>{{ $t('foreigners.basic_info.created_by') }}: </strong>
                <span>{{ foreigner.created_by }}</span>
              </div>
              <div
                class="mt-3"
                style="color: #959595"
              >
                <strong>{{ $t('foreigners.basic_info.last_modified_by') }}: </strong>
                <span>{{ foreigner.modified_by }} on {{ foreigner.date_modified }}</span>
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ForeignersBasicInfoView',

  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      me: 'coreData/me',
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
    }),
  },
};
</script>
