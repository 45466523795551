<template>
  <div
    class="mt-2"
    :class="isSender ? 'text-left' : 'text-right'"
    :style="isSender ? 'margin-right: 35%' : 'margin-left: 35%'"
  >
    <div
      class="message"
      :class="isSender ? 'left' : 'right'"
      :style="messageBackgroundColor ? `background-color: ${messageBackgroundColor}` : ''"
      v-html="prepareMessage($sanitize(message.body))"
    />
    <br>
    <table
      v-if="!isSender"
      :title="message.user_from_fullname"
      style="margin-left: auto"
    >
      <tr>
        <td>
          <span style="margin-top: 5px">
            {{ message.date_created }}
          </span>
        </td>
        <td/>
        <td v-if="message.user_from_avatar_url['32']">
          <v-img
            :src="BASE_HOST + '/' + message.user_from_avatar_url['32']"
            class="Avatar"
          />
        </td>
        <td v-else>
          <v-img
            src="@/assets/avatar.png"
            class="Avatar"
          />
        </td>
      </tr>
    </table>
    <table
      v-else
      :title="message.user_from_fullname"
    >
      <tr>
        <td v-if="message.user_from_avatar_url['32']">
          <v-img
            :src="BASE_HOST + '/' + message.user_from_avatar_url['32']"
            class="Avatar"
          />
        </td>
        <td v-else>
          <v-img
            src="@/assets/avatar.png"
            class="Avatar"
          />
        </td>
        <td/>
        <td>
          <span style="margin-top: 5px">
            {{ message.date_created }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    fid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      BASE_HOST: process.env.VUE_APP_HOSTURL,
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      permits: 'foreigners/permits',
    }),
    isSender() {
      return String(this.me.role)[0] !== String(this.message.user_from_role)[0];
    },
    messageBackgroundColor() {
      let permits = this.$store.getters['foreigners/permits'];

      if (this.fid) {
        permits = this.$store.getters['permits/dataWithFid'][this.fid];
      }

      if ((permits || []).find((el) => el.id === this.message.permit_id)) {
        const color = this.isSender ? permits.find((el) => el.id === this.message.permit_id).color + 'd' : permits.find((el) => el.id === this.message.permit_id).color;
        return this.$msgColours[color];
      }

      return false;
    },
  },

  methods: {
    prepareMessage(message) {
      message = message.replace(/\n/g, '<br>');

      if (this.filterValues.bodySearch.length > 0) {
        const re = new RegExp('(' + this.filterValues.bodySearch + ')', 'gi');
        message = message.replace(re, '<b>$1</b>');
      }

      return message;
    },
  },
};
</script>

<style scoped>
.message {
  border: 1px solid #9d9d9d;
  color: #1c1c1c;
  border-radius: 8px;
  display: inline-block;
  padding: 7px;
}

.right.message {
  text-align: left !important;
}

.Avatar {
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
}
</style>
