<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    persistent
    @keydown.ctrl.enter="sendResetPass"
    @keydown.ctrl.s="sendResetPass"
    @keydown.enter="sendResetPass"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('foreigners.basic_info.reset_dialog.title') }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          {{ $t('foreigners.basic_info.reset_dialog.confirm_text') }}
          <v-flex
            v-if="error.length > 0"
            xs12
          >
            <v-alert
              v-if="error.length > 0"
              type="error"
              v-html="error"
            />
          </v-flex>
          <v-flex
            v-if="success.length > 0"
            xs12
          >
            <v-alert
              v-if="success.length > 0"
              type="success"
              v-html="success"
            />
          </v-flex>
        </v-container>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="yellow darken-1"
          outlined
          @click="$emit('close')"
        >
          {{ $t('common.actions.close') }}
        </v-btn>
        <template v-if="showBtn">
          <v-btn
            :loading="isLoading"
            color="green darken-1"
            outlined
            @click="sendResetPass"
          >
            {{ $t('common.actions.send') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from '@/helpers';
import AuthService from '@/services/AuthService';

export default {
  name: 'ForeignerBasicInfoSendResetMailDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showBtn: true,
      error: '',
      success: true,
    };
  },

  watch: {
    isOpen() {
      this.showBtn = true;
      this.isLoading = false;
      this.error = '';
      this.success = '';
    },
  },


  methods: {
    sendResetPass() {
      this.success = '';
      this.error = '';
      AuthService.resetForeignerPassword(this.foreigner.id)
        .then((data) => {
          this.success = this.$capitalize(data.data.message);
          this.isLoading = false;
          this.showBtn = false;
          setTimeout(() => this.$emit('close'), 2500);
        })
        .catch((error) => {
          this.error = helpers.createErrorHtml(error.response.data);
          this.isLoading = false;
          this.showBtn = true;
        });
    },
  },
};
</script>
