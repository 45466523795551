import Vue from 'vue';
import Vuex from 'vuex';

import QuestionnairesService from '../../services/foreignerApi/QuestionnairesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    foreignersQuestionnaires: [],
    currentQuestionnaire: [],
    exportApplicants: [{
      'type_id': 10,
      'label': 'EU questionnaire',
      'applicants': [{
        'id': 1,
        'fullname': 'Ivan Ivanov',
      },
      {
        'id': 2,
        'fullname': 'Ivan Rivanov',
      },
      ],
    },
    {
      'type_id': 20,
      'label': 'Non-EU questionnaire',
      'applicants': [{
        'id': 2,
        'fullname': 'Ivan Sivanov',
      },
      {
        'id': 3,
        'fullname': 'Ivan Livanov',
      },
      {
        'id': 4,
        'fullname': 'Ivan Pivanov',
      },
      ],
    },
    ],
  },
  mutations: {
    setQuestionnaires(state, data) {
      state.foreignersQuestionnaires = data.data;
    },
    setQuestionnaire(state, data) {
      state.currentQuestionnaire = data.data;
    },
    setExportApplicants(state, data) {
      state.exportApplicants = data.data;
    },
  },
  actions: {
    retrieveForeignersQuestionnaires(context) {
      return new Promise((resolve, reject) => {
        QuestionnairesService.getQuestionnaires().then((data) => {
          context.commit('setQuestionnaires', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveExportApplicants(context) {
      return new Promise((resolve, reject) => {
        QuestionnairesService.getQuestionnaires().then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveForeignersSelectedQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        QuestionnairesService.getQuestionnaire(params.qid)
          .then((data) => {
            context.commit('setQuestionnaire', data.data);
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
    addDataQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        QuestionnairesService.sendDataForQuestionnaire(params.qid, params.survey_json, params.completed)
          .then((data) => {
            context.commit('setQuestionnaire', data.data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
      });
    },

  },
  getters: {
    foreignersQuestionnaires: (state) => {
      return state.foreignersQuestionnaires;
    },
    currentQuestionnaire: (state) => {
      return state.currentQuestionnaire;
    },
    exportApplicants: (state) => {
      return state.exportApplicants;
    },
  },
});
