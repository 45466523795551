'use strict';

import Vue from 'vue';
import axios from 'axios';
import UAParser from 'ua-parser-js';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function setCustomHeaders() {
  const parser = new UAParser();

  // Parse the user agent string
  const userAgentInfo = parser.setUA(navigator.userAgent).getResult();

  const customOSNames = {
    'iOS': 'ios',
    'Android': 'android',
    'Windows': 'windows',
    'Linux': 'linux',
    'Mac OS': 'macos',
  };

  // Extract operating system information
  const operatingSystem = customOSNames[userAgentInfo.os.name] || 'Unknown';
  const osVersion = userAgentInfo.os.version;

  axios.defaults.headers.common['X-IT-APP-TYPE'] = 'web';
  axios.defaults.headers.common['X-IT-OS'] = operatingSystem;
  axios.defaults.headers.common['X-IT-OS-VERSION'] = osVersion;
}

setCustomHeaders();

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

// //console.log("SETTING AXIOS CONFIG")

const _axios = axios.create(config);

_axios.interceptors.request.use(
  /*function (config) {
  	// Do something before request is sent
  	return config;
  },
  function (error) {
  	// Do something with request error
  	return Promise.reject(error);
  }*/
  function(config) {
    config.headers['User-TZ'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const token = localStorage.getItem('token');
    if (token !== null)
      config.headers.Authorization = `JWT ${token}`;
    return config;
  },
  function(error) {
    // //console.log("HERE ERROR ON REQUEST", error, error.response, error.response.status);
    return Promise.reject(error);
  },
);

// Add a response interceptor
/*_axios.interceptors.response.use(

	/!*function (response) {
		return response;
	},
	function (error) {
		// //console.log("HEREEE! ERROR IN plugin/axios", error.response )
		if (error.response.data.error.statusCode === 401) {
			//this.$store.dispatch("auth/logout");
		}
		return Promise.reject(error);
	}*!/

	response => {
		// //console.log("RESPONSE GOOD1")
		return response
	},
	error => {
		// //console.log("some error on response in axios: ", error.response);
		// //console.log("HERE ERROR ON RESPONSE", error, error.response, error.response.status);
		if (  (error.response.status === 401 && localStorage.getItem("token") !== null) ) {
			// //console.log("401 yes, here is unauthorized error in axios");
			return that.$store.dispatch("auth/refreshToken").then(async ({data}) => {
				localStorage.setItem("token", data.token);
				axios.defaults.headers.common["Authorization"] = `JWT ${data.token}`;
				return axios.request(error.config);
			}).catch(error => {
				that.$store.dispatch("auth/logout")
			})

			// this.dispatch("auth/logout");
		}
		return Promise.reject(error);
	}

);*/

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
