import axios from 'axios';
import baseURL from '@/services/APIConfig.js';


export default {
  getFaqList(page, rows, order_by) {
    const afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    return axios.get(`${baseURL}/faq/${afterUrl}`);
  },
  getFaqDetail(id) {
    return axios.get(`${baseURL}/faq/${id}/`);
  },
  createFaq(obj) {
    return axios.post(`${baseURL}/faq/`, obj);
  },
  editFaq(obj, id) {
    return axios.patch(`${baseURL}/faq/${id}/`, obj);
  },
  deleteFaq(id) {
    return axios.delete(`${baseURL}/faq/${id}/`);
  },
};
