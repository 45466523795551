import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getAuditLog(page, rows, order_by, action_user, action_name, severity, action_date_from, action_date_to) {
    let afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    if (action_user != undefined && action_user.length !== 0)
      afterUrl += `&action_user=${action_user}`;
    if (action_name != undefined && action_name.length !== 0)
      afterUrl += `&action_name=${action_name}`;
    if (severity != undefined && severity.length !== 0)
      afterUrl += `&severity=${severity}`;
    if (action_date_from != undefined && action_date_from.length !== 0)
      afterUrl += `&action_date_from=${action_date_from}`;
    if (action_date_to != undefined && action_date_to.length !== 0)
      afterUrl += `&action_date_to=${action_date_to}`;

    return axios.get(`${baseURL}/audit/log/administration/${afterUrl}`);
  },
};
