import Vue from 'vue';
import Vuex from 'vuex';

import InternalPlacesService from '../../services/administration/InternalPlacesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    places: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setPlacesAndParams(state, data) {
      state.places = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
  },
  actions: {
    retrievePlaces(context, params) {
      InternalPlacesService.getPlaces(params.page, params.rows, params.order_by)
        .then((data) => {
          context.commit('setPlacesAndParams', data.data);
        });
    },
    createPlace(context, obj) {
      return InternalPlacesService.createPlace(obj);
    },
    getPlaceDetail(context, id) {
      return InternalPlacesService.getPlaceDetail(id);
    },
    editPlace(context, obj) {
      return InternalPlacesService.editPlace(obj.place, obj.id);
    },
    deletePlace(context, id) {
      return InternalPlacesService.deletePlace(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.places) {
        data.push({
          id: u.id,
          short_name: u.short_name,
          address_city: u.address_city,
          address_street: u.address_street,
          address_zip: u.address_zip,
          address_house_number: u.address_house_number,
          address_country: u.address_country,
          address_country_id: u.address_country_id,
          full_address: u.full_address,
          google_calendar_id: u.google_calendar_id,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.places.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.places) {
        arr.push({
          id: u.id,
          short_name: u.short_name,
          full_address: u.full_address,
        });
      }
      return arr;
    },
  },
});
