import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import coreData from './core_data';
import internalUsers from './administration/internal_users';
import internalPlaces from './administration/internal_places';
import internalFaq from './administration/internal_faq';
import clientsUsers from './administration/clients_users';
import clientsCompanies from './administration/clients_companies';
import permitDocuments from './administration/permit_documents';
import permitStatuses from './administration/permit_statuses';
import permitActionDates from './administration/permit_action_dates';
import permitTypes from './administration/permit_types';
import agencyUsers from './administration/agency_users';
import agencyCompanies from './administration/agency_companies';
import auditLog from './administration/audit_log';
import foreigners from './foreigners/foreigners';
import foreignerAuditLog from './foreigners/ForeignersAuditLog';
import foreignerMessaging from './foreigners/foreignersMessaging';
import foreignerQuestionnaire from './foreigners/foreignerQuestionnaire';
import notifications from './notifications/notifications';
import permits from './foreigner_api/permits';
import messaging from './foreigner_api/messaging';
import questionnaires from './foreigner_api/questionnaires';
import faq from './faq/faq';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    auth,
    coreData,
    internalUsers,
    internalPlaces,
    internalFaq,
    clientsUsers,
    clientsCompanies,
    permitDocuments,
    permitStatuses,
    permitActionDates,
    permitTypes,
    agencyUsers,
    agencyCompanies,
    auditLog,
    foreigners,
    foreignerAuditLog,
    foreignerMessaging,
    foreignerQuestionnaire,
    notifications,
    faq,
    permits,
    messaging,
    questionnaires,
  },
});
