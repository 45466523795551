import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NotificationsWrapper',[_c('NotificationsToolbar',{staticClass:"mb-6",attrs:{"unique-icons":_vm.uniqueIcons,"per-page-value":_vm.perPageValue,"per-page-options":_vm.perPageOptions},on:{"change-type-filter":_vm.changeTypeFilter,"per-page-changed":(value) => {
      _vm.perPageValue = value;
      _vm.setFiltersToLocalStorage();
      _vm.totalRefreshNotifications();
    }}}),_c('h2',[_vm._v(_vm._s(_vm.$t('notifications.title_new')))]),(_vm.isLoading)?_c(VFlex,{attrs:{"xs12":"","text-center":""}}):(_vm.unseenNotifications.length === 0)?_c(VFlex,{attrs:{"xs12":"","text-center":""}},[_vm._v(" "+_vm._s(_vm.$t('notifications.no_notifications'))+" ")]):_vm._e(),_vm._l((_vm.unseenNotifications),function(notification,index){return _c('NotificationsNotification',{key:index,attrs:{"notification":notification,"notification-icons":_vm.notificationIcons,"is-seen":false},on:{"see-notification":_vm.seeNotification,"unsee-notification":_vm.unseeNotification,"link-unseen":(event, id) => _vm.linkUnseen(event, id)}})}),(_vm.unseenData.next !== null)?_c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,{staticClass:"py-1"},[_c(VFlex,{attrs:{"row":"","px-4":""}},[_c(VFlex,{staticClass:"pointerHover",attrs:{"xs12":"","text-center":""},on:{"click":_vm.loadUnseenNext}},[_vm._v(" "+_vm._s(_vm.$t('notifications.load_next'))+" ")])],1)],1)],1):_vm._e(),_c('br'),_c('h2',[_vm._v(_vm._s(_vm.$t('notifications.title_seen')))]),(_vm.isLoading)?_c(VFlex,{attrs:{"xs12":"","text-center":""}}):(_vm.seenNotifications.length === 0)?_c(VFlex,{attrs:{"xs12":"","text-center":""}},[_vm._v(" "+_vm._s(_vm.$t('notifications.no_notifications'))+" ")]):_vm._e(),_vm._l((_vm.seenNotifications),function(notification,index){return _c('NotificationsNotification',{key:index,attrs:{"notification":notification,"notification-icons":_vm.notificationIcons,"is-seen":true},on:{"see-notification":_vm.seeNotification,"unsee-notification":_vm.unseeNotification,"link-unseen":(event, id) => _vm.linkUnseen(event, id)}})}),(_vm.seenData.next !== null)?_c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,{staticClass:"py-1"},[_c(VFlex,{attrs:{"row":"","px-4":""}},[_c(VFlex,{staticClass:"pointerHover",attrs:{"xs12":"","text-center":""},on:{"click":_vm.loadSeenNext}},[_vm._v(" "+_vm._s(_vm.$t('notifications.load_next'))+" ")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }