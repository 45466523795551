import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getUserMe() {
    return axios.get(`${baseURL}/user/me/`);
  },

  updateUser(data) {
    return axios.patch(`${baseURL}/user/me/`, data);
  },

  updateAvatar(file) {
    const fd = new FormData();
    fd.append('avatar', file);

    return axios({
      method: 'patch',
      url: `${baseURL}/user/me/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },
};
