import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFlex,{attrs:{"md12":""}},[_c(VToolbar,{attrs:{"color":"white accent-4"}},[_c(VFlex,{attrs:{"xs7":"","row":""}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.uniqueIcons),function(icon,index){return _c(VFlex,{key:index,attrs:{"justify-center":"","align-center":""}},[_c('span',{staticClass:"pointerHover d-flex mx-4",attrs:{"title":icon.titles.map((title) => _vm.$capitalize(title)).join('. ')},on:{"click":function($event){return _vm.$emit('change-type-filter', icon)}}},[_c(icon.icon,{tag:"component",class:icon.selected ? 'blue--text lighten-1' : 'grey--text text--darken-1'})],1)])}),1)]),_c(VFlex,{attrs:{"offset-xs1":"","xs4":""}},[_c(VSpacer),_c(VFlex,{staticClass:"mt-4 offset-xs7 xs3"},[_c(VAutocomplete,{attrs:{"value":_vm.perPageValue,"title":_vm.$t('common.rows_per_page'),"items":_vm.perPageOptions,"prepend-icon":"none","dense":""},on:{"change":(value) => _vm.$emit('per-page-changed', value)}})],1),_c(VBtn,{staticClass:"mr-2",attrs:{"fixed":"","small":"","depressed":"","color":_vm.$appColours.button.close,"outlined":"","dark":"","top":"","right":""},on:{"click":function($event){return _vm.$router.push('/me')}}},[_c(VIcon,[_vm._v("mdi-settings")])],1),_c(VFabTransition)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }