<template>
  <v-flex
    v-if="me.role !== $roles.FOREIGNER"
    xs12
    px-3
  >
    <slot />
  </v-flex>
  <v-card
    v-else
    width="80%"
    class="mt-2 px-3 py-3"
    style="margin-left: 10%; "
  >
    <slot />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationsWrapper',

  computed: {
    ...mapGetters({
      me: 'coreData/me',
    }),
  },
};
</script>
