<template>
  <v-dialog
    :value="isOpen"
    max-width="800px"
    persistent
    @keydown.ctrl.enter="createNewForeigner"
    @keydown.ctrl.s="createNewForeigner"
    @keydown.enter="createNewForeigner"
    @keydown.esc="$emit('close')"
    @keydown.meta.enter="createNewForeigner"
    @keydown.meta.s="createNewForeigner"
  >
    <v-card>
      <v-card-title>
        <span
          class="headline"
        >
          {{ $t('foreigners.foreigner_dialog.title_new') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <!-- First name -->
            <v-flex
              md6
              sm6
              xs12
            >
              <v-text-field
                v-model="newForeigner.first_name"
                :autocomplete="$randomString()"
                :label="$t('common.fields.first_name') + ' *'"
                required
              />
            </v-flex>
            <!-- Last name -->
            <v-flex
              md6
              sm6
              xs12
            >
              <v-text-field
                v-model="newForeigner.last_name"
                :autocomplete="$randomString()"
                :label="$t('common.fields.last_name') + ' *'"
                required
              />
            </v-flex>
            <!-- Email -->
            <v-flex xs12>
              <v-text-field
                v-model="newForeigner.email"
                :autocomplete="$randomString()"
                :label="$t('common.fields.email') + ' *'"
                required
              />
            </v-flex>
            <v-flex
              md6
              sm6
              xs12
            >
              <v-autocomplete
                v-model="newForeigner.gender"
                :autocomplete="$randomString()"
                :items="userGenders"
                :label="$t('common.fields.gender') + ' *'"
                :menu-props="{ maxHeight: '400' }"
                item-text="name"
                item-value="id"
              >
                <template
                  slot="item"
                  slot-scope="props"
                >
                  {{ $t(props.item.key) }}
                </template>
              </v-autocomplete>
            </v-flex>
            <!-- Client company -->
            <v-flex
              v-if="me.role !== $roles.HR"
              md6
              sm6
              xs12
            >
              <v-autocomplete
                v-model="newForeigner.profile.client_company_id"
                :autocomplete="$randomString()"
                :items="companiesOptions"
                :label="$t('common.fields.company') + ' *'"
                :menu-props="{ maxHeight: '400' }"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <!-- Phone primary -->
            <v-flex
              v-if="me.role !== $roles.HR"
              md6
              sm6
              xs12
            >
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="newForeigner.phone_primary"
                    :autocomplete="$randomString()"
                    :label="$t('common.fields.phone') + ' 1'"
                    required
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- Phone secondary -->
            <v-flex
              v-if="me.role !== $roles.HR"
              md6
              sm6
              xs12
            >
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="newForeigner.phone_secondary"
                    :autocomplete="$randomString()"
                    :label="$t('common.fields.phone') + ' 2'"
                    required
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- Citizenship -->
            <v-flex
              md6
              sm6
              xs12
            >
              <v-autocomplete
                v-model="newForeigner.profile.citizenship_id"
                :autocomplete="$randomString()"
                :items="citizenshipOptions"
                :label="$t('common.fields.citizenship') + ' *'"
                :menu-props="{ maxHeight: '400' }"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <!-- Interface language -->
            <v-flex
              v-if="me.role !== $roles.HR"
              xs12
              sm6
              md6
            >
              <v-autocomplete
                v-model="newForeigner.interface_language"
                name="lang"
                :items="interfaceLanguagesOptions"
                :label="$t('main.me.interface_language')"
                item-text="name"
                item-value="id"
                :autocomplete="$randomString()"
              />
            </v-flex>
            <!-- Assigned to -->
            <v-flex
              v-if="me.role !== $roles.HR"
              xs12
            >
              <v-autocomplete
                v-model="newForeigner.profile.assigned_to_id"
                :autocomplete="$randomString()"
                :items="internalUserOptions"
                :label="$t('foreigners.filters.assigned_to') + ' *'"
                :menu-props="{ maxHeight: '400' }"
                item-text="fullname"
                item-value="id"
              />
            </v-flex>
            <v-flex xs12>
              <v-alert
                v-if="newForeignerAlertText.length > 0"
                type="error"
                v-html="newForeignerAlertText"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          text
          @click="$emit('close')"
        >
          {{ $t('common.actions.close') }}
        </v-btn>
        <v-btn
          :loading="isSubmitting"
          color="success"
          outlined
          text
          @click="createNewForeigner"
        >
          {{ $t('common.actions.add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

const NEW_FOREIGNER = {
  first_name: '',
  last_name: '',
  email: '',
  profile: {
    client_company_id: '',
    assigned_to_id: '',
    citizenship_id: '',
  },
  gender: '',
  password: '',
  password_confirm: '',
  phone_primary_code: '',
  phone_primary: '',
  phone_secondary_code: '',
  phone_secondary: '',
};

import helpers from '@/helpers';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSubmitting: false,
      newForeignerAlertText: '',
      newForeigner: _cloneDeep(NEW_FOREIGNER),
    };
  },

  computed: {
    ...mapGetters({
      me: 'coreData/me',
      userGenders: 'coreData/userGenders',
      companiesOptions: 'clientsCompanies/selectOptions',
      citizenshipOptions: 'coreData/countries',
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
      internalUserOptions: 'internalUsers/users',
    }),
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.clearNewForeignerForm();
      }
    },
  },

  methods: {
    validateNewForeignerForm() {
      // First name
      if (this.newForeigner.first_name.length < 2) {
        this.newForeignerAlertText = 'First name is too short';
        return false;
      }
      // Last name
      if (this.newForeigner.last_name.length < 2) {
        this.newForeignerAlertText = 'Last name is too short';
        return false;
      }
      // Citizenship
      if (this.newForeigner.profile.citizenship_id.length < 1) {
        this.newForeignerAlertText = 'Citizenship is empty';
        return false;
      }

      // Ignore for user role "Clint HR"
      if (this.me.role !== this.$roles.HR) {
        // Password
        if (this.newForeigner.password.length > 0 && this.newForeigner.password !== this.newForeigner.password_confirm) {
          this.newForeignerAlertText = 'Passwords are not the same';
          return false;
        }
        // Email
        this.newForeigner.email = this.newForeigner.email.toLowerCase();
        if (this.$isEmailValid(this.newForeigner.email) === false) {
          this.newForeignerAlertText = 'Email is not valid';
          return false;
        }
        // Client company
        if (this.newForeigner.profile.client_company_id === '') {
          this.newForeignerAlertText = 'Company field can not by empty';
          return false;
        }
        // Assigned to
        if (this.newForeigner.profile.assigned_to_id < 1) {
          this.newForeignerAlertText = 'Assigned to is empty';
          return false;
        }
      }

      return true;
    },
    clearNewForeignerForm() {
      this.newForeigner = _cloneDeep(NEW_FOREIGNER);
      this.newForeignerAlertText = '';
    },
    createNewForeigner() {
      this.isSubmitting = true;
      this.newForeignerAlertText = '';

      if (this.validateNewForeignerForm() === false) {
        this.isSubmitting = false;
        return;
      }

      const newForeigner = _cloneDeep(this.newForeigner);

      if (newForeigner.password.length === 0) {
        delete newForeigner.password;
        delete newForeigner.password_confirm;
      }

      if (newForeigner.profile.assigned_to_id === '') {
        newForeigner.profile.assigned_to_id = null;
      }

      newForeigner.role = 30;

      this.$store
        .dispatch('foreigners/createForeigner', newForeigner)
        .then((data) => {
          this.$emit('set-snackbar', data.data.message);
          this.$emit('refresh');
          this.$emit('close');
          this.clearNewForeignerForm();
          this.isSubmitting = false;
        })
        .catch((e) => {
          this.newForeignerAlertText = helpers.createErrorHtml(e.response.data);
          this.isSubmitting = false;
        });
    },
  },
};
</script>
