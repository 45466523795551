import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getMessages(params) {
    let url = `${baseURL}/foreigner/${params.fid}/message/?page=${params.page}&page_size=${params.rows}`;
    if (params.user_from != undefined && params.user_from.length !== 0)
      url += `&user_from=${params.user_from}`;
    if (params.permit != undefined && params.permit.length !== 0)
      url += `&permit=${params.permit}`;
    if (params.date_create_from != undefined && params.date_create_from.length !== 0)
      url += `&date_create_from=${params.date_create_from}`;
    if (params.date_create_to != undefined && params.date_create_to.length !== 0)
      url += `&date_create_to=${params.date_create_to}`;

    return axios.get(url);
  },

  sendMessage(fid, text, permit_id, recipients) {
    const url = `${baseURL}/foreigner/${fid}/message/`;
    const obj = {
      body: text,
      recipients: recipients,
    };
    if (permit_id) {
      obj.permit_id = permit_id;
    }
    return axios.post(url, obj);
  },

  getPossibleMentiones(fid) {
    const url = `${baseURL}/foreigner/${fid}/message/user/`;
    return axios.get(url);
  },

  getFamily(foreignerId) {
    return axios.get(`${baseURL}/foreigner/${foreignerId}/message/family/`);
  },
};
