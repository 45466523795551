<template>
  <div>
    <v-flex
      v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT"
      md12
    >
      <v-toolbar
        class="elevation-1"
        color="white accent-4"
      >
        <v-flex
          v-if="me.role !== $roles.HR && me.role !== $roles.AGENCY"
          row
          xs12
        >
          <v-btn
            :color="$appColours.button.ok"
            dark
            outlined
            @click="newPermitDialog = true"
          >
            {{ $t('foreigners.permits.add') }}
          </v-btn>
        </v-flex>
      </v-toolbar>
    </v-flex>
    <v-container
      fluid="true"
      class="pl-3 pr-3"
    >
      <v-expansion-panels
        :value="expanded"
        active-class="activePanel"
        @change="value => expanded = value"
      >
        <v-layout row>
          <v-flex
            v-for="(permit, index) in foreignerPermits"
            :key="index"
            class="permitPanel"
            mt-2
            xs12
          >
            <v-card>
              <v-expansion-panel @change="debug">
                <v-expansion-panel-header style="padding: 0; background-color: #f7f7f7;">
                  <v-list-item style="background-color: #f7f7f7;padding: 0">
                    <v-list-item-content style="padding-bottom: 5px">
                      <v-list-item-subtitle style="margin-left: 1%; padding-left: 1%">
                        <v-layout row>
                          <v-flex xs9>
                            <div
                              class="headline"
                              style="color: #1f1f1f; margin-left: 5px; margin-top: -5px"
                            >
                              {{ permit.permit_type_name }} ({{
                                permit.reference_number
                              }})
                              <template v-if="permit.permit_status === 30">
                                <template v-if="permit.final_document_url !== null">
                                  <v-icon
                                    :color="$appColours.status.others.active"
                                    @click.stop="downloadFileByUrl(permit.final_document_url)"
                                  >
                                    mdi-account-card-details-outline
                                  </v-icon>
                                </template>
                                <template v-else>
                                  <v-icon :color="$appColours.status.others.notactive">
                                    mdi-account-card-details-outline
                                  </v-icon>
                                </template>
                                <span class="body-2 ml-2">{{
                                  permit.issue_date
                                }} - {{ permit.expire_date }}</span>
                              </template>
                            </div>
                            <span v-if="permit.is_main === true">
                              <v-icon :color="$appColours.button.action">
                                star
                              </v-icon>
                              {{ $t('foreigners.permits.main_permit') }}
                            </span>
                          </v-flex>
                          <v-flex />
                        </v-layout>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="panel-content">
                  <v-tabs
                    v-show="expanded === index"
                    :value="tab"
                    :height="35"
                    background-color="#f7f7f7"
                    grow
                    mt-5
                    right
                    slider-color="blue darken-2"
                    @change="value =>{ debug(ev); return tab = value; }"
                  >
                    <v-tab :enabled="enabledStatuses">
                      {{ $t('foreigners.permits.statuses') }}
                    </v-tab>
                    <v-tab :enabled="enabledDocuments">
                      {{ $t('foreigners.permits.documents') }}
                    </v-tab>
                  </v-tabs>
                  <!--			Status tab						-->
                  <v-card-text
                    v-if="tab === 0"
                    style="color: rgba(0, 0, 0, 0.87);"
                  >
                    <div
                      v-if="!permit.statuses"
                      class="text-center"
                    >
                      <v-container
                        bg
                        fill-height
                        grid-list-md
                        text-xs-center
                      >
                        <v-layout
                          fill-height
                          justify-center
                          row
                          wrap
                        >
                          <Loading
                            style="margin-top: 10vh"
                          />
                        </v-layout>
                      </v-container>
                    </div>
                    <div
                      v-if="permit.statuses && permit.statuses.length === 0"
                      class="text-center"
                    >
                      {{ $t('foreigners.permits.no_statuses') }}
                    </div>
                    <v-stepper
                      v-else-if="permit.statuses && permit.statuses.length !== 0"
                      :value="statusTab"
                      non-linear
                      @change="value => statusTab = value"
                    >
                      <v-stepper-header
                        disabled
                        style="padding: 3px; height: 100%; min-height: 80px;"
                      >
                        <template v-for="(s, i) in permit.statuses">
                          <template v-if="statusTabs[index] === i">
                            <v-stepper-step
                              :key="i"
                              :complete="true"
                              :editable="enabledNavigation"
                              :step="s.human_order"
                              :style="`width: ${100 / permit.statuses.length}% !important; word-break: break-word;`"
                              class="activeStep"
                              @click="function(event) {
                                ttt(event)
                              }"
                            >
                              {{ s.status_type_name }}
                              <small v-if="s.is_current === true">
                                {{ $t('foreigners.permits.current') }}
                              </small>
                            </v-stepper-step>
                          </template>
                          <template v-else>
                            <v-stepper-step
                              :key="i"
                              :class="(me.role > $roles.CONSULTANT) ? 'notactiveStep' : ''"
                              :complete="false"
                              :editable="enabledNavigation"
                              :step="s.human_order"
                              :style="`width: ${100 / permit.statuses.length}% !important`"
                              @click="function(event) {
                                ttt(event)
                              }"
                            >
                              {{ s.status_type_name }}
                              <small v-if="s.is_current === true">{{
                                $t('foreigners.permits.current')
                              }}</small>
                            </v-stepper-step>
                          </template>
                        </template>
                      </v-stepper-header>
                      <v-stepper-items>
                        <div
                          v-for="s in permit.statuses"
                          :key="s.id"
                        >
                          <!--   View mode   -->
                          <v-stepper-content
                            v-if="editing === 0"
                            :step="s.human_order"
                          >
                            <v-container
                              v-if="s.action_dates.length !== 0"
                              fluid
                            >
                              <v-layout row>
                                <v-flex xs3>
                                  <div>
                                    <b>{{ $t('foreigners.permits.action') }} </b>
                                  </div>
                                </v-flex>
                                <v-flex xs2>
                                  <div>
                                    <b>{{ $t('foreigners.permits.date') }} </b>
                                  </div>
                                </v-flex>
                                <v-flex xs2>
                                  <div>
                                    <b>{{ $t('foreigners.permits.note') }} </b>
                                  </div>
                                </v-flex>
                                <v-flex xs3>
                                  <div>
                                    <b>{{ $t('foreigners.permits.place') }} </b>
                                  </div>
                                </v-flex>
                                <v-flex xs1>
                                  <div>
                                    <b>{{ $t('foreigners.permits.assistance') }} </b>
                                  </div>
                                </v-flex>
                                <v-flex xs1>
                                  <div>
                                    <b>{{ $t('foreigners.permits.shared') }}</b>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-container>
                            <div
                              v-for="a in s.action_dates"
                              :key="a.id"
                            >
                              <v-container fluid>
                                <v-layout row>
                                  <v-flex xs3>
                                    <div>
                                      {{ a.action_date_type_name }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs2>
                                    <div>
                                      <template v-if="a.action_date && a.action_time">
                                        {{ a.action_date + ' ' + a.action_time }}
                                      </template>
                                      <template v-else-if="a.action_date">
                                        {{ a.action_date }}
                                      </template>
                                      <template v-else-if="a.action_time">
                                        {{ a.action_time }}
                                      </template>
                                      <template v-else>
                                        <i>{{ $t('foreigners.permits.no_date') }}</i>
                                      </template>
                                    </div>
                                  </v-flex>
                                  <v-flex xs2>
                                    <div>
                                      {{ (a.note) ? a.note : '-' }}
                                      <v-icon>mdi-icon-text</v-icon>
                                    </div>
                                  </v-flex>
                                  <v-flex xs3>
                                    <template v-if="a.place_maps_url !== ''">
                                      <div style="margin-top: 5px">
                                        <a
                                          :href="a.place_maps_url"
                                          target="_blank"
                                        >
                                          {{ a.place_short_name }}
                                        </a>
                                      </div>
                                    </template>
                                    <template v-else>
                                      -
                                    </template>
                                  </v-flex>
                                  <v-flex xs1>
                                    <template v-if="a.assistance_id === -2">
                                      {{ $t('foreigners.permits.assistance.without') }}
                                    </template>
                                    <template v-else-if="a.assistance_id !== null">
                                      <div v-if="Object.entries(a.assistance_avatar_url).length === 0">
                                        <v-img
                                          :title="a.assistance_fullname"
                                          src="../../../assets/avatar.png"
                                          style="width: 32px; height: 32px"
                                        />
                                      </div>
                                      <div v-else>
                                        <v-img
                                          :src="BASE_HOST + a.assistance_avatar_url[32]"
                                          :title="a.assistance_fullname"
                                          style="width: 32px; height: 32px"
                                        />
                                      </div>
                                    </template>
                                    <template v-else>
                                      -
                                    </template>
                                  </v-flex>
                                  <v-flex xs1>
                                    <div>
                                      <v-icon
                                        :color="(a.share_with_foreigner === true) ? $appColours.button.ok : $appColours.status.others.notactive"
                                        :title="(a.share_with_foreigner === true) ? $t('foreigners.permits.shared_foreigner') : $t('foreigners.permits.not_shared_foreigner')"
                                      >
                                        mdi-alpha-f-box-outline
                                      </v-icon>
                                      <v-icon
                                        :color="(a.share_with_foreigner === true) ? $appColours.button.ok : $appColours.status.others.notactive"
                                        :title="(a.share_with_foreigner === true) ? $t('foreigners.permits.shared_client') : $t('foreigners.permits.not_shared_client')"
                                      >
                                        mdi-alpha-c-box-outline
                                      </v-icon>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </div>
                            <div
                              v-if="s.action_dates.length === 0"
                              class="text-center"
                            >
                              {{ $t('foreigners.permits.no_action_dates') }}
                            </div>
                          </v-stepper-content>
                          <!--   Edit mode   -->
                          <v-stepper-content
                            v-else-if="editing === 1"
                            :step="s.human_order"
                          >
                            <div
                              v-for="(a,j) in editedStatus.action_dates"
                              :key="a.id"
                            >
                              <v-container fluid>
                                <v-container fluid>
                                  <v-layout row>
                                    <!-- name -->
                                    <v-flex xs4>
                                      <v-text-field
                                        :label="$t('common.fields.name')"
                                        :value="a.action_date_type_name"
                                        class="blackInputText"
                                        readonly
                                      />
                                    </v-flex>
                                    <!-- date -->
                                    <v-flex
                                      ml-2
                                      xs1
                                    >
                                      <v-menu
                                        :ref="'menu'+j"
                                        :close-on-content-click="true"
                                        :v-model="'a.showPicker'+j"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            v-model.lazy="a.action_date"
                                            :error-messages="getStepperValidationErrorMessage({
                                              field: 'action_date',
                                              index: j,
                                            })"
                                            :label="$t('foreigners.permits.date')"
                                            v-on="on"
                                          />
                                        </template>
                                        <v-date-picker
                                          v-model.lazy="a.action_date_iso"
                                          :first-day-of-week="1"
                                          no-title
                                          scrollable
                                          @input="() => a.action_date = parseDate(a.action_date_iso)"
                                          @change="value => a.action_date_iso = value"
                                        />
                                      </v-menu>
                                    </v-flex>
                                    <!-- time -->
                                    <v-flex
                                      ml-1
                                      xs1
                                    >
                                      <v-menu
                                        :ref="'menu'+j"
                                        :close-on-content-click="false"
                                        :v-model="'a.showTimePicker'+j"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            :value="a.action_time"
                                            :label="$t('foreigners.permits.time')"
                                            @change="value => a.action_time = value"
                                            v-on="on"
                                          />
                                        </template>
                                        <v-time-picker
                                          format="24hr"
                                          landscape
                                          :value="a.action_time"
                                          @change="value => a.action_time = value"
                                          @input="a.action_time"
                                        />
                                      </v-menu>
                                    </v-flex>
                                    <!-- places -->
                                    <v-flex
                                      ml-3
                                      xs3
                                    >
                                      <v-autocomplete
                                        :value="a.place_id"
                                        :autocomplete="$randomString()"
                                        :items="assistancePlacesOptions()"
                                        :label="$t('common.fields.place')"
                                        :menu-props="{ maxHeight: '400' }"
                                        item-text="short_name"
                                        item-value="id"
                                        @change="value => a.place_id = value"
                                      />
                                    </v-flex>
                                    <!-- assistance -->
                                    <v-flex
                                      ml-3
                                      xs2
                                    >
                                      <v-autocomplete
                                        :value="a.assistance_id"
                                        :autocomplete="$randomString()"
                                        :disabled="!(me.role === $roles.ADMIN || me.role === $roles.CONSULTANT)"
                                        :items="assistanceUsersOptions()"
                                        :label="$t('foreigners.permits.assistance_id')"
                                        :menu-props="{ maxHeight: '400' }"
                                        item-text="fullname"
                                        item-value="id"
                                        @change="value => a.assistance_id = value"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>

                                <v-container fluid>
                                  <v-layout row>
                                    <!-- note -->
                                    <v-flex xs6>
                                      <v-text-field
                                        :value="a.note"
                                        :label="$t('foreigners.permits.note')"
                                        class="blackInputText"
                                        :error-messages="getStepperValidationErrorMessage({
                                          field: 'note',
                                          index: j,
                                        })"
                                        @change="value => a.note = value"
                                      />
                                    </v-flex>
                                    <!-- share -->
                                    <v-flex
                                      ml-5
                                      xs5
                                    >
                                      <v-switch
                                        v-model="a.share_with_foreigner"
                                        :label="$t('foreigners.permits.share_date')"
                                        style="margin-top: 0;"
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </v-container>
                            </div>

                            <v-flex xs12>
                              <br>
                              <v-alert
                                v-if="errorHtml.length > 0"
                                type="error"
                                v-html="errorHtml"
                              />
                            </v-flex>

                            <v-flex
                              class="ml-3"
                              row
                            >
                              <v-flex xs2>
                                <v-switch
                                  v-model="editedStatus.is_current"
                                  :label="$t('foreigners.permits.current_status')"
                                />
                              </v-flex>
                            </v-flex>

                            <div
                              v-if="s.action_dates.length === 0"
                              class="text-center"
                            >
                              {{ $t('foreigners.permits.no_action_dates') }}
                            </div>
                          </v-stepper-content>
                        </div>
                        <!--   Edit mode   -->
                      </v-stepper-items>
                    </v-stepper>
                  </v-card-text>
                  <!--			Documents tab						-->
                  <v-card-text v-else-if="tab === 1">
                    <div v-if="me.role === $roles.HR || me.role === $roles.AGENCY">
                      <div id="documentsClientDiv">
                        <v-flex
                          mt-3
                          row
                          xs12
                        >
                          <v-flex xs4>
                            <b>{{ $t('foreigners.permits.document_type') }}</b>
                          </v-flex>
                          <v-flex xs3>
                            <b>{{ $t('foreigners.permits.file_name') }}</b>
                          </v-flex>
                          <v-flex xs3>
                            <b>{{ $t('foreigners.permits.note') }} </b>
                          </v-flex>
                          <v-flex xs2>
                            <b>{{ $t('common.filters.status') }}</b>
                          </v-flex>
                        </v-flex>
                        <v-flex
                          v-for="(document, indexDocument) in permit.documents"
                          :key="indexDocument"
                          mt-3
                          row
                          xs12
                        >
                          <v-flex xs4>
                            {{ document.document_type_name }}
                            <v-tooltip
                              v-if="document.document_type_description.length > 0"
                              :value="document.showToolTip"
                              :color="'grey darken-4'"
                              max-width="400"
                              right
                              style="word-wrap:break-word;"
                              @change="value => document.showToolTip = value"
                            >
                              <template>
                                <v-btn
                                  icon
                                  @click="showNeededTooltip(document, permit.documents)"
                                >
                                  <v-icon :color="$appColours.button.action">
                                    mdi-information-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <div v-html="prepareTooltipHtml(document.document_type_description)" />
                            </v-tooltip>
                          </v-flex>
                          <v-flex xs3>
                            <v-flex
                              xs12
                            >
                              <v-file-input
                                :disabled="isDocumentFileInputDisabled(document, permit)"
                                :label="$t('foreigners.permits.file_input')"
                                class="fileInput"
                                multiple
                                style="display: inline"
                                :error-messages="getDocumentValidationErrorMessage({
                                  field: 'document',
                                  id: document.id,
                                })"
                                @click="$event.target.value = null"
                                @change="(file) => { selectedDocument = document; currentPermit = permit; onFileSelect(file); }"
                              />

                              <span
                                v-if="document.document_name === null"
                                :title="document.document_name"
                                style="padding-bottom: 5px"
                              >
                                {{
                                  document.document_name !== null ?
                                    ((document.document_name.length > 20) ? document.document_name.slice(0, 20) + '...' : document.document_name)
                                    :
                                    $t('foreigners.permits.no_file')
                                }}
                              </span>
                              <a
                                v-else
                                :title="document.document_name"
                                @click="downloadFile(foreigner.id, permit.id, document.id, document.document_name)"
                              >
                                {{
                                  (document.document_name.length > 20) ? document.document_name.slice(0, 20) + '...' : document.document_name
                                }}
                              </a>
                            </v-flex>
                            <span style="min-height: 12px; font-size: 12px; color: #ff5252">
                              {{ getDocumentValidationErrorMessage({
                                field: 'document',
                                id: document.id,
                              }) ? getDocumentValidationErrorMessage({
                                field: 'document',
                                id: document.id,
                              }) : '' }}
                            </span>
                          </v-flex>
                          <v-flex xs3>
                            {{ (document.note) ? document.note : '-' }}
                          </v-flex>
                          <v-flex xs2>
                            <div>
                              <v-icon :color="$appColours.status.documents[String(document.status)]">
                                mdi-circle
                              </v-icon>
                              {{
                                $t($store.getters['coreData/foreignerPermitDocumentStatusById'](document.status)['key'])
                              }}
                            </div>
                          </v-flex>
                        </v-flex>
                      </div>
                    </div>
                    <div v-else-if="editing === 0">
                      <v-data-table
                        :headers="documentsHeaders"
                        :items="permit.documents"
                        :items-per-page="25"
                        class="elevation-1"
                        hide-default-footer
                      >
                        <template
                          slot="item.document_type_name"
                          slot-scope="props"
                        >
                          <span :title="props.item.document_type_description">
                            {{ props.item.document_type_name }}
                            <v-tooltip
                              v-if="props.item.document_type_description.length > 0"
                              :value="props.item.showToolTip"
                              :color="'grey darken-4'"
                              max-width="400"
                              right
                              style="word-wrap:break-word;"
                              @change="value => props.item.showToolTip = value"
                            >
                              <template v-slot:activator="on">
                                <v-btn
                                  icon
                                  v-on="on"
                                  @click="showNeededTooltip(props.item, permit.documents)"
                                >
                                  <v-icon :color="$appColours.button.action">
                                    mdi-information-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <div v-html="prepareTooltipHtml(props.item.document_type_description)" />
                            </v-tooltip>
                          </span>
                        </template>
                        <template
                          slot="item.document_name"
                          slot-scope="props"
                        >
                          <div v-if="props.item.document_name === null">
                            <i title="props.item.document_type_description">{{
                              $t('foreigners.permits.no_file')
                            }}</i>
                          </div>
                          <div v-else>
                            <a
                              :title="props.item.document_name"
                              @click="downloadFile(foreigner.id, permit.id, props.item.id, props.item.document_name)"
                            >
                              {{
                                (props.item.document_name.length > 20) ? props.item.document_name.slice(0, 20) + '...' : props.item.document_name
                              }}
                            </a>
                          </div>
                        </template>
                        <template
                          slot="item.note"
                          slot-scope="props"
                        >
                          <template v-if="props.item.note">
                            <v-icon
                              :color="$appColours.button.ok"
                              :title="props.item.note"
                            >
                              mdi-note
                            </v-icon>
                          </template>
                          <template v-else>
                            <v-icon
                              :color="$appColours.status.others.notactive"
                              :title="$t('foreigners.permits.no_note')"
                            >
                              mdi-note
                            </v-icon>
                          </template>
                        </template>
                        <template
                          slot="item.status"
                          slot-scope="props"
                        >
                          <v-icon :color="$appColours.status.documents[String(props.item.status)]">
                            mdi-circle
                          </v-icon>
                          {{
                            $t($store.getters['coreData/foreignerPermitDocumentStatusById'](props.item.status)['key'])
                          }}
                        </template>
                        <template
                          slot="item.editable_by_foreigner"
                          slot-scope="props"
                        >
                          <v-icon
                            :color="(props.item.editable_by_foreigner === true) ? $appColours.button.ok : $appColours.status.others.notactive"
                            :title="(props.item.editable_by_foreigner === true) ? $t('foreigners.permits.editable_foreigner') : $t('foreigners.permits.not_editable_foreigner')"
                          >
                            mdi-alpha-f-box-outline
                          </v-icon>
                          <v-icon
                            :color="(props.item.editable_by_client === true) ? $appColours.button.ok : $appColours.status.others.notactive"
                            :title="(props.item.editable_by_client === true) ? $t('foreigners.permits.editable_client') : $t('foreigners.permits.not_editable_client')"
                          >
                            mdi-alpha-c-box-outline
                          </v-icon>
                        </template>
                      </v-data-table>
                    </div>
                    <div v-else-if="editing === 2">
                      <v-data-table
                        :headers="documentsEditHeaders"
                        :items="editedDocuments"
                        :items-per-page="25"
                        class="elevation-1 editTable"
                        hide-default-footer
                      >
                        <template
                          slot="item.document_type_name"
                          slot-scope="props"
                        >
                          <v-text-field
                            :value="props.item.document_type_name"
                            :error-messages="getDocumentValidationErrorMessage({
                              field: 'document_type_name',
                              id: props.item.id,
                            })"
                            :label="$t('foreigners.permits.document_name')"
                            @change="value => props.item.document_type_name = value"
                          />
                        </template>
                        <template
                          slot="item.note"
                          slot-scope="props"
                        >
                          <v-text-field
                            :value="props.item.note"
                            :label="$t('foreigners.permits.note')"
                            :error-messages="getDocumentValidationErrorMessage({
                              field: 'note',
                              id: props.item.id,
                            })"
                            @change="value => props.item.note = value"
                          />
                        </template>
                        <template
                          slot="item.document_name"
                          slot-scope="props"
                        >
                          <div class="hideInputControl">
                            <v-file-input
                              v-model.lazy="props.item.document"
                              :label="'qq'"
                              :error-messages="getDocumentValidationErrorMessage({
                                field: 'document',
                                id: props.item.id,
                              })"
                              multiple
                              @change="props.item.document_name = props.item.document.name"
                            />
                            <span class="fileName">
                              <template v-if="props.item.document && props.item.document !== 'delete'">
                                {{
                                  (props.item.document.length === 1)
                                    ?
                                      ((props.item.document[0].name.length > 20) ? props.item.document[0].name.slice(0, 19) : props.item.document[0].name)
                                    :
                                      props.item.document.length + $t('foreigners.permits.files')
                                }}
                              </template>
                              <template v-else-if="props.item.document_name !== null">
                                <template v-if="props.item.document_name === 'To delete'">
                                  <i>{{ props.item.document_name }}</i>
                                </template>
                                <template v-else>
                                  {{ props.item.document_name }}
                                </template>
                              </template>
                              <template v-else>
                                {{ $t('foreigners.permits.no_file') }}
                              </template>
                            </span>
                            <template v-if="props.item.document_name !== null">
                              <v-icon
                                v-if="props.item.document_name !== 'To delete'"
                                :color="$appColours.button.delete"
                                class="pointerHover mb-3 ml-2"
                                title="Delete file"
                                @click="() => {
                                  props.item.document = 'delete';
                                  props.item.document_name = 'To delete'
                                }"
                              >
                                mdi-close
                              </v-icon>
                            </template>
                          </div>
                          <div
                            v-if="getDocumentValidationErrorMessage({
                              field: 'document',
                              id: props.item.id,
                            })"
                            class="v-messages theme--light error--text"
                          >
                            <span class="v-messages__message">
                              {{
                                getDocumentValidationErrorMessage({
                                  field: 'document',
                                  id: props.item.id,
                                })
                              }}
                            </span>
                          </div>
                        </template>
                        <template
                          slot="item.status"
                          slot-scope="props"
                        >
                          <!--  MAGIC BUG. Blue is here because the first icon cant be clicked, so I had to insert smth here and then hide it by CSS  -->
                          <v-icon
                            v-if="$store.getters['coreData/foreignerPermitDocumentStatusById'](props.item.status)['color'] === 'blue'"
                            :color="$appColours.button.action"
                            class="pointerHover"
                            style="display: none"
                            @click="changeStatusEditDocument(props.item, 0)"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            v-else
                            :color="$appColours.button.action"
                            class="pointerHover"
                            style="display: none"
                            @click="changeStatusEditDocument(props.item, 0)"
                          >
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                          <!--  MAGIC BUG finish here -->

                          <v-icon
                            v-if="$store.getters['coreData/foreignerPermitDocumentStatusById'](props.item.status)['color'] === 'red'"
                            :color="$appColours.button.delete"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 10)"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            v-else
                            :color="$appColours.button.delete"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 10)"
                          >
                            mdi-checkbox-blank-circle-outline
                          </v-icon>

                          <v-icon
                            v-if="$store.getters['coreData/foreignerPermitDocumentStatusById'](props.item.status)['color'] === 'yellow'"
                            :color="$appColours.button.edit"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 20)"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            v-else
                            :color="$appColours.button.edit"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 20)"
                          >
                            mdi-checkbox-blank-circle-outline
                          </v-icon>

                          <v-icon
                            v-if="$store.getters['coreData/foreignerPermitDocumentStatusById'](props.item.status)['color'] === 'green'"
                            :color="$appColours.button.ok"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 30)"
                          >
                            mdi-checkbox-blank-circle
                          </v-icon>
                          <v-icon
                            v-else
                            :color="$appColours.button.ok"
                            class="pointerHover"
                            @click="changeStatusEditDocument(props.item, 30)"
                          >
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </template>
                        <template
                          v-if="props.item.service_document"
                          slot="item.editable_by_foreigner"
                          slot-scope="props"
                        >
                          <v-switch
                            v-model="props.item.editable_by_foreigner"
                            :label="$t('foreigners.permits.by_foreigner')"
                          />
                          <v-switch
                            v-model="props.item.editable_by_client"
                            :label="$t('foreigners.permits.by_client')"
                          />
                        </template>
                        <template
                          slot="item.file_delete"
                          slot-scope="props"
                          style="width: 10%;"
                        >
                          <div
                            v-if="props.item.willBeDeletedRow"
                            style="color: #F44336 !important; word-wrap: break-word;"
                            @click="deleteRestoreDocumentRow(props.item.id, permit)"
                          >
                            <v-icon
                              :title="$t('common.actions.cancel')"
                              class="pointerHover"
                              style="color: #F44336"
                            >
                              mdi-delete-empty
                            </v-icon>
                          </div>
                          <div
                            v-else
                            @click="deleteDocumentRow(props.item.id, permit)"
                          >
                            <v-icon
                              :title="$t('foreigners.permits.delete_document')"
                              class="pointerHover redOnHover"
                            >
                              mdi-delete
                            </v-icon>
                          </div>
                        </template>
                      </v-data-table>
                      <v-flex xs12>
                        <br>
                        <v-alert
                          v-if="errorHtml.length > 0 && editing === 2"
                          type="error"
                          v-html="errorHtml"
                        />
                      </v-flex>
                    </div>
                  </v-card-text>

                  <v-card-actions v-if="me.role !== $roles.HR && me.role !== $roles.AGENCY">
                    <div
                      v-if="permit.permit_status !== 30 && editing === 0 && me.role !== $roles.HR"
                      class="ml-2"
                    >
                      <v-btn
                        :color="$appColours.button.ok"
                        outlined
                        @click="permitObtainedCustomDocuments=[]; permitObtainedDialog = true; currentPermit = permit"
                      >
                        {{ $t('foreigners.permits.permit_obtained') }}
                      </v-btn>
                    </div>
                    <div
                      v-if="permit.is_main === false"
                      class="ml-2"
                    >
                      <v-btn
                        v-if="editing === 0"
                        :color="$appColours.button.action"
                        outlined
                        @click="setPermitAsMain(permit.id)"
                      >
                        {{ $t('foreigners.permits.set_main') }}
                      </v-btn>
                    </div>
                    <v-btn
                      v-if="editing === 0"
                      :color="$appColours.button.delete"
                      class="ml-2"
                      outlined
                      @click="deletePermitConfirm(permit)"
                    >
                      {{ $t('foreigners.permits.delete_permit') }}
                    </v-btn>

                    <v-spacer />
                    <div v-if="permit.permit_status !== 30">
                      <v-btn
                        v-if="tab === 0 && editing === 0 && me.role !== $roles.HR"
                        color="blue"
                        outlined
                        text
                        @click="editActionDates"
                      >
                        {{ $t('foreigners.permits.edit_action_dates') }}
                      </v-btn>
                      <div v-else-if="tab === 0 && editing === 1">
                        <v-btn
                          :color="$appColours.button.close"
                          class="mr-2"
                          outlined
                          @click="cancelEditActionDates"
                        >
                          {{ $t('common.actions.cancel') }}
                        </v-btn>
                        <v-btn
                          :color="$appColours.button.ok"
                          :loading="disabledSaveButton"
                          outlined
                          @click="saveEditActionDates(permit)"
                        >
                          {{ $t('common.actions.save') }}
                        </v-btn>
                      </div>
                      <div v-else-if="tab === 1 && editing === 0">
                        <v-btn
                          :color="$appColours.button.action"
                          class="mr-2"
                          outlined
                          @click="addCustomDocuments = [{ name: '', editable_by_foreigner: false, editable_by_client: false }]; currentPermit = permit; addCustomDocumentsDialog = true"
                        >
                          {{ $t('foreigners.permits.add_custom_document') }}
                        </v-btn>
                        <v-btn
                          :color="$appColours.button.edit"
                          outlined
                          @click="editDocuments(permit)"
                        >
                          {{ $t('foreigners.permits.edit_documents') }}
                        </v-btn>
                      </div>
                      <div v-else-if="tab === 1 && editing === 2">
                        <v-btn
                          :color="$appColours.button.close"
                          class="mr-2"
                          outlined
                          @click="cancelEditDocuments"
                        >
                          {{ $t('common.actions.cancel') }}
                        </v-btn>
                        <v-btn
                          :color="$appColours.button.ok"
                          :loading="disabledSaveButton"
                          outlined
                          @click="saveEditedDocuments(permit)"
                        >
                          {{ $t('common.actions.save') }}
                        </v-btn>
                      </div>
                    </div>
                    <div v-else>
                      <v-btn
                        :color="$appColours.button.edit"
                        outlined
                        text
                        @click="openEditPermitObtainedDialog(permit); currentPermit = permit"
                      >
                        {{ $t('foreigners.permits.edit_obtained_permit') }}
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </v-flex>

          <v-flex
            v-if="(loading === false) && (foreignerPermits.length === 0)"
            mt-2
            xs12
          >
            <v-layout
              fill-height
              justify-center
              row
              wrap
            >
              {{ $t('foreigners.permits.no_permits') }}
            </v-layout>
          </v-flex>
          <v-flex
            v-else-if="(loading === true) && (foreignerPermits.length === 0)"
            mt-2
            xs12
          >
            <v-layout
              fill-height
              justify-center
              row
              wrap
            >
              <Loading
                style="margin-top: 10vh"
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-expansion-panels>

      <v-dialog
        v-model.lazy="newPermitDialog"
        max-width="800px"
        persistent
        @keydown.esc="newPermitDialog = false"
        @keydown.meta.s="saveForeignerPermitDialogShortcutHandler($event)"
        @keydown.ctrl.s="saveForeignerPermitDialogShortcutHandler($event)"
        @keydown.meta.enter="saveForeignerPermitDialogShortcutHandler($event)"
        @keydown.ctrl.enter="saveForeignerPermitDialogShortcutHandler($event)"
        @keydown.enter="saveForeignerPermitDialogShortcutHandler($event)"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('foreigners.permits.new_permit_dialog.title')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-autocomplete
                    :value="newPermit.permit_type_id"
                    :items="permitTypeOptions"
                    :label="$t('foreigners.permits.new_permit_dialog.permit_type_name') + ' *'"
                    :menu-props="{ maxHeight: '400' }"
                    item-text="name"
                    item-value="id"
                    @change="value => newPermit.permit_type_id = value"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    :value="newPermit.is_main"
                    :label="$t('foreigners.permits.new_permit_dialog.main_permit')"
                    flat
                    @change="value => newPermit.is_main = value"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-alert
                    v-if="newPermitAlertText.length > 0"
                    type="error"
                    v-html="newPermitAlertText"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="newPermitDialog=false"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.ok"
              :loading="disabledSaveButton"
              outlined
              @click="saveNewPermit"
            >
              {{ $t('foreigners.permits.new_permit_dialog.add_permit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model.lazy="deletePermitDialog"
        max-width="800px"
        persistent
        @keydown.esc="deletePermitDialog = false"
        @keydown.meta.s="deleteForeignerPermitDialogShortcutHandler($event)"
        @keydown.ctrl.s="deleteForeignerPermitDialogShortcutHandler($event)"
        @keydown.meta.enter="deleteForeignerPermitDialogShortcutHandler($event)"
        @keydown.ctrl.enter="deleteForeignerPermitDialogShortcutHandler($event)"
        @keydown.enter="deleteForeignerPermitDialogShortcutHandler($event)"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('foreigners.permits.delete_permit_dialog.title')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex
                  text-center
                  xs12
                >
                  <div class="body-1">
                    {{ $t('foreigners.permits.delete_permit_dialog.confirm_text') }} <b>{{
                      deletePermit.permit_type_name
                    }}</b>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-alert
                    v-if="deletePermitAlertText.length > 0"
                    type="error"
                    v-html="deletePermitAlertText"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="deletePermitDialog = false"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.delete"
              outlined
              @click="deletePermitMethod(deletePermit.id)"
            >
              {{ $t('common.actions.delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model.lazy="addCustomDocumentsDialog"
        max-width="800px"
        persistent
        @keydown.esc="addCustomDocumentsDialog = false"
        @keydown.meta.s="saveForeignerCustomDocumentDialogShortcutHandler($event)"
        @keydown.ctrl.s="saveForeignerCustomDocumentDialogShortcutHandler($event)"
        @keydown.meta.enter="saveForeignerCustomDocumentDialogShortcutHandler($event)"
        @keydown.ctrl.enter="saveForeignerCustomDocumentDialogShortcutHandler($event)"
        @keydown.enter="saveForeignerCustomDocumentDialogShortcutHandler($event)"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('foreigners.permits.custom_doc_dialog.title')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <template v-for="(i, index) in addCustomDocuments">
                  <v-flex
                    :key="`document_name_${index}`"
                    row
                    text-center
                    xs12
                  >
                    <v-flex xs12>
                      <v-text-field
                        :value="i.name"
                        :label="$t('foreigners.permits.document_name') + ' *'"
                        @change="value => i.name = value"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex
                    :key="`foreigner_editable_${index}`"
                    row
                    xs12
                  >
                    <v-flex
                      v-if="i && typeof i.editable_by_foreigner !== 'undefined'"
                      xs5
                    >
                      <v-switch
                        v-model="i.editable_by_foreigner"
                        :label="$t('common.fields.foreigner_editable')"
                      />
                    </v-flex>
                    <v-flex
                      v-if="i && i.editable_by_client !== 'undefined'"
                      xs5
                    >
                      <v-switch
                        v-model="i.editable_by_client"
                        :label="$t('common.fields.client_editable')"
                      />
                    </v-flex>
                  </v-flex>
                </template>
                <v-flex xs12>
                  <v-alert
                    v-if="addCustomDocumentsAlertText.length > 0"
                    type="error"
                    v-html="addCustomDocumentsAlertText"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="addCustomDocumentsDialog = false"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.ok"
              :loading="disabledSaveButton"
              outlined
              @click="saveCustomDocuments"
            >
              {{ $t('common.actions.add') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model.lazy="changeDocumentNoteDialog"
        max-width="800px"
        persistent
        @keydown.esc="changeDocumentNoteDialog = false"
        @keydown.meta.s="changeDocumentNoteDialog = false"
        @keydown.ctrl.s="changeDocumentNoteDialog = false"
        @keydown.meta.enter="changeDocumentNoteDialog = false"
        @keydown.ctrl.enter="changeDocumentNoteDialog = false"
        @keydown.enter="changeDocumentNoteDialog = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('foreigners.permits.doc_note_dialog.title')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-text-field
                :value="tmpNoteText"
                :label="$t('foreigners.permits.note')"
                @change="value => tmpNoteText = value"
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="changeDocumentNoteDialog = false"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.ok"
              outlined
              @click="changeEditedCustomNote"
            >
              {{ $t('common.actions.add') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model.lazy="permitObtainedDialog"
        max-width="800px"
        persistent
        @keydown.esc="permitObtainedDialog = false"
        @keydown.meta.s="permitObtainedDialogShortcutHandler($event)"
        @keydown.ctrl.s="permitObtainedDialogShortcutHandler($event)"
        @keydown.meta.enter="permitObtainedDialogShortcutHandler($event)"
        @keydown.ctrl.enter="permitObtainedDialogShortcutHandler($event)"
        @keydown.enter="permitObtainedDialogShortcutHandler($event)"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ $t('foreigners.permits.permit_obtain_dialog.title') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <div
                class="title"
                style="color: #111111"
              >
                {{ $t('foreigners.permits.permit_obtain_dialog.permit_document') }}
              </div>
              <v-layout wrap>
                <v-flex
                  row
                  xs12
                >
                  <v-flex xs6>
                    <v-menu
                      ref="menuIss"
                      v-model.lazy="menuIssDate"
                      :close-on-content-click="false"
                      :return-value.sync="permit_obtained.issue_date"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="permit_obtained.issue_date_good_format"
                          :label="$t('foreigners.permits.permit_obtain_dialog.issue_date')"
                          prepend-icon="event"
                          readonly
                          @change="value => permit_obtained.issue_date_good_format = value"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model.lazy="permit_obtained.issue_date"
                        :first-day-of-week="1"
                        no-title
                        scrollable
                        @input="function() {
                          $refs.menuIss.save(permit_obtained.issue_date);
                          permit_obtained.issue_date_good_format = parseDate(permit_obtained.issue_date);
                          menuIssDate = false;
                        }"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu
                      ref="menuExp"
                      v-model.lazy="menuExpDate"
                      :close-on-content-click="false"
                      :return-value.sync="permit_obtained.expire_date"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="permit_obtained.expire_date_good_format"
                          :label="$t('foreigners.permits.permit_obtain_dialog.expiry_date')"
                          prepend-icon="event"
                          readonly
                          @change="value => permit_obtained.expire_date_good_format = value"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model.lazy="permit_obtained.expire_date"
                        :first-day-of-week="1"
                        no-title
                        scrollable
                        @input="() => {
                          $refs.menuExp.save(permit_obtained.expire_date);
                          permit_obtained.expire_date_good_format = parseDate(permit_obtained.expire_date);
                          menuExpDate = false;
                        }"
                      />
                    </v-menu>
                  </v-flex>
                </v-flex>
                <v-flex
                  row
                  xs12
                >
                  <v-flex>
                    <v-file-input
                      :value="permit_obtained.document_1"
                      :label="$t('foreigners.permits.permit_obtain_dialog.doc_scan')"
                      multiple
                      @change="value => permit_obtained.document_1 = value"
                    />
                  </v-flex>
                </v-flex>
                <v-flex xs12>
                  <v-alert
                    v-if="permitObtainedAlertText.length > 0"
                    type="error"
                    v-html="permitObtainedAlertText"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="() => {
                permitObtainedDialog = false;
                permitObtainedAlertText = '';
                permit_obtained.issue_date = '';
                permit_obtained.expire_date = '';
              }"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.ok"
              :loading="disabledSaveButton"
              outlined
              @click="doPermitObtained"
            >
              {{ $t('common.actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model.lazy="editPermitObtainedDialog"
        max-width="800px"
        persistent
        @keydown.esc="editPermitObtainedDialog = false"
        @keydown.meta.s="permitObtainedDialogShortcutHandler($event)"
        @keydown.ctrl.s="permitObtainedDialogShortcutHandler($event)"
        @keydown.meta.enter="permitObtainedDialogShortcutHandler($event)"
        @keydown.ctrl.enter="permitObtainedDialogShortcutHandler($event)"
        @keydown.enter="permitObtainedDialogShortcutHandler($event)"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('foreigners.permits.permit_edit_obtain_dialog.title')
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <br>
              <div
                class="title"
                style="color: #111111"
              >
                {{ $t('foreigners.permits.permit_obtain_dialog.permit_document') }}
              </div>
              <v-layout wrap>
                <v-flex
                  row
                  xs12
                >
                  <v-flex xs6>
                    <v-menu
                      ref="menuEditIss"
                      v-model.lazy="menuEditIssDate"
                      :close-on-content-click="false"
                      :return-value.sync="edit_permit_obtained.issue_date"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="edit_permit_obtained.issue_date_view"
                          :label="$t('foreigners.permits.permit_obtain_dialog.issue_date')"
                          prepend-icon="event"
                          readonly
                          @change="value => edit_permit_obtained.issue_date_view = value"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model.lazy="edit_permit_obtained.issue_date"
                        :first-day-of-week="1"
                        no-title
                        scrollable
                        @input="$refs.menuEditIss.save(edit_permit_obtained.issue_date)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu
                      ref="menuEditExp"
                      v-model.lazy="menuEditExpDate"
                      :close-on-content-click="false"
                      :return-value.sync="edit_permit_obtained.expire_date"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="edit_permit_obtained.expire_date_view"
                          :label="$t('foreigners.permits.permit_obtain_dialog.expiry_date')"
                          prepend-icon="event"
                          readonly
                          @change="value => edit_permit_obtained.expire_date_view = value"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model.lazy="edit_permit_obtained.expire_date"
                        :first-day-of-week="1"
                        no-title
                        scrollable
                        @input="$refs.menuEditExp.save(edit_permit_obtained.expire_date)"
                      />
                    </v-menu>
                  </v-flex>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex
                  row
                  xs12
                >
                  <v-file-input
                    :value="permitObtainedEditDoc"
                    :label="$t('foreigners.permits.permit_obtain_dialog.doc_scan')"
                    multiple
                    @change="value => permitObtainedEditDoc = value"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :color="$appColours.button.close"
              outlined
              @click="editPermitObtainedDialog = false"
            >
              {{ $t('common.actions.cancel') }}
            </v-btn>
            <v-btn
              :color="$appColours.button.ok"
              :loading="disabledSaveButton"
              outlined
              @click="doEditPermitObtained"
            >
              {{ $t('common.actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="5000"
        bottom
        right
      >
        {{ snackbarText }}
        <v-btn
          color="blue"
          text
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import baseURL from '../../../services/APIConfig';
import axios from 'axios';
import ForeignerService from '../../../services/foreigners/ForeignersService';
import helpers from '../../../helpers';

import Loading from '@/assets/loading.svg';
import Vue from 'vue';

export default {
  name: 'ForeignerPermits',
  components: {
    Loading,
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      BASE_HOST: process.env.VUE_APP_HOSTURL,
      baseURL: baseURL,
      tab: 0,
      statusTab: '',
      statusTabs: [],
      editing: 0, // 0 is for no edit, 1 for editing statuses, 2 for editing documents
      expanded: 0,
      loading: true,
      enabledNavigation: true,
      enabledStatuses: true,
      enabledStatusesTabs: true,
      enabledDocuments: true,
      snackbar: false,
      snackbarText: '',
      file: {},
      selectedDocument: null,
      documentsHeaders: [
        {
          text: this.$t('common.fields.name'),
          align: 'left',
          sortable: false,
          value: 'document_type_name',
        },
        {
          text: this.$t('foreigners.permits.file_name'),
          align: 'left',
          sortable: false,
          value: 'document_name',
        },
        {
          text: this.$t('common.filters.status'),
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t('foreigners.permits.note'),
          align: 'left',
          sortable: false,
          value: 'note',
        },
        {
          text: this.$t('common.fields.modified_by'),
          align: 'left',
          sortable: false,
          value: 'modified_by',
        },
        {
          text: this.$t('common.fields.editable'),
          align: 'left',
          sortable: false,
          value: 'editable_by_foreigner',
        },
      ],
      documentsEditHeaders: [
        {
          text: this.$t('common.fields.name'),
          align: 'left',
          sortable: false,
          value: 'document_type_name',
        },
        {
          text: this.$t('foreigners.permits.file_name'),
          align: 'left',
          sortable: false,
          value: 'document_name',
        },
        {
          text: this.$t('common.filters.status'),
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t('foreigners.permits.note'),
          align: 'left',
          sortable: false,
          value: 'note',
        },
        {
          text: this.$t('common.fields.editable'),
          align: 'left',
          sortable: false,
          value: 'editable_by_foreigner',
        },
        {
          text: this.$t('adm.permits.documents.delete_documents_dialog.title'),
          align: 'left',
          sortable: false,
          value: 'file_delete',
        },
      ],
      documentValidationErrors: {},
      stepperValidationErrors: {},

      newPermitDialog: false,
      newPermit: {
        permit_type_id: '',
        is_main: false,
      },
      newPermitAlertText: '',

      deletePermitDialog: false,
      deletePermit: {},
      deletePermitAlertText: '',

      addCustomDocumentsDialog: false,
      addCustomDocuments: [
        {
          name: '',
          editable_by_foreigner: false,
          editable_by_client: false,
        },
      ],
      addCustomDocumentsAlertText: '',
      currentPermit: null,

      changeDocumentNoteDialog: false,
      editedDocumentId: null,
      tmpNoteText: '',

      permitObtainedDialog: false,
      permit_obtained: {
        expire_date: '',
        expire_date_good_format: '',
        issue_date: '',
        issue_date_good_format: '',
        document_type_id_1: '',
        document_type_name_1: 'FINAL DOCUMENT',
        document_1: null,
      },
      permitObtainedEditDoc: null,
      permitObtainedCustomDocuments: [],
      permitObtainedAlertText: '',
      menuIssDate: false,
      menuExpDate: false,

      editPermitObtainedDialog: false,
      edit_permit_obtained: {
        expire_date: '',
        expire_date_view: '',
        issue_date: '',
        issue_date_view: '',
      },
      editPermitObtainedAlertText: '',
      menuEditIssDate: false,
      menuEditExpDate: false,

      editedDocuments: [],
      datePickerActionDates: [],

      editedStatus: {
        is_current: '',
        on_hold: '',
        action_dates: [],
      },
      errorHtml: '',

      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapGetters({
      foreignerPermits: 'foreigners/permits',
      permitTypeOptions: 'permitTypes/selectOptions',
      permitDocumentOptions: 'permitDocuments/selectOptions',
      internalPlacesOptions: 'internalPlaces/selectOptions',
      internalUsers: 'internalUsers/users',

      me: 'coreData/me',
    }),
  },
  watch: {
    'edit_permit_obtained.expire_date'(newVal, oldVal) {
      const [y1, m1, d1] = newVal.split('-');
      const newDate1 = `${d1}.${m1}.${y1}`;
      this.edit_permit_obtained.expire_date_view = newDate1;
    },
    'edit_permit_obtained.issue_date'(newVal, oldVal) {
      const [y1, m1, d1] = newVal.split('-');
      const newDate1 = `${d1}.${m1}.${y1}`;
      this.edit_permit_obtained.issue_date_view = newDate1;
    },
  },
  mounted() {
    const that = this;
    let pid = null;

    this.$store.commit('foreigners/clearCurrentForeignerPermits');

    this.refreshPermits().then((_) => {
      if (
        this.me.role === this.$roles.ADMIN ||
        this.me.role === this.$roles.CONSULTANT
      ) {
        that.tab = 1;
      }

      if (localStorage.getItem('itrack_adm_forward_to_foreigner_permit')) {
        pid = localStorage.getItem('itrack_adm_forward_to_foreigner_permit');
        localStorage.removeItem('itrack_adm_forward_to_foreigner_permit');

        that.tab = 0;
      }

      let i = 0;
      if (pid !== null) {
        i = 0;
        pid = parseInt(pid);
        for (const p of that.foreignerPermits) {
          if (p.id === pid) {
            break;
          }
          i++;
        }
      }

      that.expanded = i;

      if (
        localStorage.getItem('itrack_adm_forward_to_foreigner_permit_documents')
      ) {
        localStorage.removeItem(
          'itrack_adm_forward_to_foreigner_permit_documents',
        );
        that.tab = 1;
      }

      helpers.removeAllLocalStorageForwarding();
    });

    if (this.$store.getters['permitTypes/ifEmpty']) {
      this.$store.dispatch('permitTypes/retrieveTypes', {
        page: 1,
        rows: 100,
        order_by: 'name',
      });
    }

    if (
      this.$store.getters['internalPlaces/ifEmpty'] &&
      (this.me.role === this.$roles.CONSULTANT ||
        this.me.role === this.$roles.ADMIN)
    ) {
      this.$store.dispatch('internalPlaces/retrievePlaces', {
        page: 1,
        rows: 100,
        order_by: 'name',
      });
    }

    this.$store.dispatch('permitDocuments/retrieveDocuments', {
      page: 1,
      rows: 100,
      order_by: 'name',
    });
  },
  methods: {
    isDocumentFileInputDisabled(document, permit) {
      if (this.me.role === this.$roles.ADMIN || this.me.role === this.$roles.CONSULTANT) {
        return false;
      }
      // status 30 means that permit already obtained
      return permit.permit_status === 30 || !document.editable_by_client;
    },
    prepareTooltipHtml(text) {
      return (
        '<div style=\'word-wrap:break-word;\'>' +
        text.replace(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm,
          '<a target="_blank" href=\'$1\'>$1</a>',
        ) +
        '</div>'
      );
    },
    showNeededTooltip(doc, documents) {
      if (this.me.role === this.$roles.HR) {
        this.$set(doc, 'showToolTip', !doc.showToolTip);

        for (const d of documents) {
          if (d.id !== doc.id) {
            this.$set(d, 'showToolTip', false);
          }
        }

        doc = JSON.parse(JSON.stringify(doc));

        this.$forceUpdate();
      } else {
        doc.showToolTip = !doc.showToolTip;
        for (const d of documents) {
          if (d.id !== doc.id) {
            d.showToolTip = false;
          }
        }
      }
    },
    ttt(event) {
    },
    saveForeignerPermitDialogShortcutHandler(event) {
      event.preventDefault();
      this.saveNewPermit();
    },
    deleteForeignerPermitDialogShortcutHandler(event) {
      event.preventDefault();
      this.deletePermitMethod(this.deletePermit.id);
    },
    saveForeignerCustomDocumentDialogShortcutHandler(event) {
      event.preventDefault();
      this.saveCustomDocuments();
    },
    permitObtainedDialogShortcutHandler(event) {
      event.preventDefault();
      this.doPermitObtained();
    },

    debug(ev) {
      this.stepperValidationErrors = {};
      setTimeout((_) => {
        if (this.expanded !== undefined) {
          if (
            document.querySelectorAll('.activePanel .activeStep').length > 0
          ) {
            for (const el of document.querySelectorAll(
              '.activePanel .activeStep',
            ))
              el.click();
          }
        }
      }, 180);
    },
    refreshPermits(setExpand = 0) {
      this.expanded = '';
      this.statusTabs = [];

      this.tab = 0;
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$store.commit('foreigners/clearCurrentForeignerPermits');
        this.$store
          .dispatch('foreigners/retrieveForeignerPermits', {
            fid: this.foreigner.id,
          })
          .then((_) => {
            let i = 0;
            const arr = [];
            for (const p of this.$store.getters['foreigners/permits']) {
              arr.push(
                this.$store.dispatch(
                  'foreigners/retrieveForeignerPermitStatusesDocuments',
                  {
                    fid: this.foreigner.id,
                    pid: p.id,
                  },
                ),
              );
              const permit = this.foreignerPermits[i];
              i++;
            }
            Promise.all(arr).then((_) => {
              // fill statusTabs with values: number of current status, start from 0. If no current - set to 0
              // statusTabs = [2, 3]
              const permits = JSON.parse(JSON.stringify(this.foreignerPermits));

              let i = 0;
              for (const permit of permits) {
                let j = 0;
                for (const status of permit.statuses) {
                  if (status.is_current === true) {
                    this.statusTabs[i] = j;
                    break;
                  }
                  this.statusTabs[i] = 0;
                  j++;
                }
                i++;
              }

              if (setExpand !== 0) this.expanded = setExpand;
              this.loading = false;
              setTimeout((_) => {
              }, 100);

              if (document.querySelector('.v-expansion-panel-header'))
                document.querySelector('.v-expansion-panel-header').click();

              resolve();
            });
          });
      });
    },

    clearNewPermitDialog() {
      this.newPermit = {
        permit_type_id: '',
        is_main: false,
      };
    },
    validateNewPermit() {
      if (this.newPermit.permit_type_id.length < 1) {
        this.newPermitAlertText = 'Permit type is empty';
        return false;
      }
      return true;
    },
    saveNewPermit() {
      this.disabledSaveButton = true;
      this.newPermitAlertText = '';
      if (this.validateNewPermit() === false) {
        this.disabledSaveButton = false;
        return false;
      }

      this.$store
        .dispatch('foreigners/addForeignerPermit', {
          id: this.foreigner.id,
          permit: this.newPermit,
        })
        .then((resp) => {
          this.newPermitDialog = false;
          this.disabledSaveButton = false;
          this.clearNewPermitDialog();
          this.refreshPermits();
        })
        .catch((err) => {
          this.disabledSaveButton = false;
          this.newPermitAlertText = helpers.createErrorHtml(err.response.data);
        })
        .finally((_) => {
          this.disabledSaveButton = false;
        });
    },

    deletePermitConfirm(obj) {
      // TODO 1) modal for confirmation
      // //console.log("HERE", obj)
      this.deletePermit = obj;
      this.deletePermitDialog = true;
      this.deletePermitAlertText = '';
    },
    deletePermitMethod(pid) {
      // TODO: 2) send request for delete
      this.deletePermitAlertText = '';
      this.$store
        .dispatch('foreigners/deleteForeignerPermit', {
          fid: this.foreigner.id,
          pid: pid,
        })
        .then(() => {
          this.deletePermitDialog = false;
          this.refreshPermits();
        })
        .catch((err) => {
          this.deletePermitAlertText = helpers.createErrorHtml(
            err.response.data,
          );
        });
    },

    editActionDates() {
      this.errorHtml = '';
      let tab = '';

      if (this.statusTab === '') tab = this.statusTabs[this.expanded];
      else tab = this.statusTab - 1;

      this.enabledNavigation = false;
      this.enabledDocuments = false;
      const selectedStatus = this.$store.getters['foreigners/permits'][this.expanded].statuses[tab];
      this.editedStatus = {
        is_current: selectedStatus['is_current'],
        on_hold: selectedStatus['on_hold'],
        place_id: selectedStatus['place_id'],
        action_dates: [],
      };
      const selectedActionDates = this.$store.getters['foreigners/permits'][this.expanded].statuses[tab]['action_dates'];

      for (const a of selectedActionDates) {
        this.editedStatus['action_dates'].push(JSON.parse(JSON.stringify(a)));
        this.datePickerActionDates.push(false);
      }

      console.log(this.editedStatus);
      this.editing = 1;
    },
    cancelEditActionDates() {
      this.editing = 0;
      this.editedStatus = { is_current: '', on_hold: '' };
      // this.editedActionDates = [];
      this.enabledNavigation = true;
      this.enabledDocuments = true;
      this.stepperValidationErrors = {};
    },
    saveEditActionDates(permit) {
      this.errorHtml = '';
      this.disabledSaveButton = true;
      let tab = '';
      if (this.statusTab === '') tab = this.statusTabs[this.expanded];
      else tab = this.statusTab - 1;
      const oldStatus = this.$store.getters['foreigners/permits'][this.expanded].statuses[tab];

      const obj = {
        fid: this.foreigner.id,
        pid: permit.id,
        sid: this.$store.getters['foreigners/permits'][this.expanded].statuses[tab]['id'],
        oldStatus: oldStatus,
        editedStatus: this.editedStatus,
      };

      if (Object.entries(obj.editedStatus).length === 0) {
        this.editing = 0;
        return;
      }

      const oldExpanded = this.expanded;
      this.stepperValidationErrors = {};
      this.$store
        .dispatch('foreigners/editForeignerPermitStatus', obj)
        .then((_) => {
          this.disabledSaveButton = false;
          this.refreshPermits(oldExpanded).then((_) => {
            this.editing = 0;
            this.enabledNavigation = true;
            this.enabledDocuments = true;
          });
        })
        .catch((err) => {
          if (err.response.data && err.response.data.data && err.response.data.data['action_dates'] && typeof err.response.data.data === 'object') {
            this.stepperValidationErrors = err.response.data.data['action_dates'];
          } else {
            this.errorHtml = helpers.createErrorHtml(err.response.data);
          }
          this.disabledSaveButton = false;
        });
    },

    getStepperValidationErrorMessage({ field, index }) {
      if (this.stepperValidationErrors[index]) {
        return this.stepperValidationErrors[index][field];
      }
      return false;
    },

    fixSelectCurrentState() {
      setTimeout((_) => {
        if (!this.expanded) return;
      }, 50);
    },

    downloadFile(fid, pid, did, filename) {
      const url = `${baseURL}/foreigner/${fid}/permit/${pid}/document/${did}/file/`;

      axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    downloadFileByUrl(url) {
      axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if (response.headers['content-disposition'])
          fileLink.setAttribute(
            'download',
            decodeURI(
              response.headers['content-disposition']
                .split('filename')[2]
                .slice(9),
            ),
          );
        else fileLink.setAttribute('download', 'obtained_document.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    editDocuments(permit) {
      this.errorHtml = '';
      this.editing = 2;
      this.editedDocuments = JSON.parse(JSON.stringify(permit.documents));
    },
    deleteDocumentRow(did, permit) {
      const params = {
        fid: this.foreigner.id,
        pid: this.foreignerPermits[this.expanded].id,
        did: did,
      };

      for (const doc of this.editedDocuments) {
        if (doc.id === params.did) {
          this.$set(doc, 'willBeDeletedRow', true);
          break;
        }
      }
    },
    deleteRestoreDocumentRow(did, permit) {
      const params = {
        fid: this.foreigner.id,
        pid: this.foreignerPermits[this.expanded].id,
        did: did,
      };
      for (const doc of this.editedDocuments) {
        if (doc.id === params.did) {
          if ('willBeDeletedRow' in doc) this.$delete(doc, 'willBeDeletedRow');
          break;
        }
      }
    },
    cancelEditDocuments() {
      this.editing = 0;
      this.editedDocuments = [];
      this.documentValidationErrors = {};
    },
    changeEditedCustomNote() {
      let doc = null;
      for (const d of this.editedDocuments) {
        if (d.id === this.editedDocumentId) {
          doc = d;
          break;
        }
      }
      doc.note = this.tmpNoteText;

      this.changeDocumentNoteDialog = false;
    },
    fileChangeHandler(ev, doc) {
    },
    changeStatusEditDocument(item, status) {
      item.status = status;
    },
    editChangeDocument() {
    },
    async saveEditedDocuments(permit) {
      this.errorHtml = '';
      this.disabledSaveButton = true;
      const oldExpanded = this.expanded;

      const oldDocsArr = permit.documents;
      const newDocArr = this.editedDocuments;

      const diffArr = [];
      for (const [i, value] of oldDocsArr.entries()) {
        for (const key in oldDocsArr[i]) {
          if (oldDocsArr[i][key] !== newDocArr[i][key]) {
            if (diffArr[i] === undefined) diffArr[i] = {};

            diffArr[i].id = newDocArr[i].id;
            diffArr[i].permit_id = newDocArr[i].permit_id;
            diffArr[i][key] = newDocArr[i][key];
            diffArr[i].name = newDocArr[i].document_type_name;
          }

          if (newDocArr[i].document != null) {
            if (diffArr[i] === undefined) diffArr[i] = {};

            diffArr[i].id = newDocArr[i].id;
            diffArr[i].permit_id = newDocArr[i].permit_id;
            diffArr[i].document = newDocArr[i].document;
            diffArr[i].name = newDocArr[i].document_type_name;
          }
        }

        diffArr[i].editable_by_foreigner = newDocArr[i].editable_by_foreigner;
        diffArr[i].editable_by_client = newDocArr[i].editable_by_client;

        if (
          diffArr[i]['editable_by_client'] ===
          oldDocsArr[i]['editable_by_client'] &&
          diffArr[i]['editable_by_foreigner'] ===
          oldDocsArr[i]['editable_by_foreigner']
        ) {
          delete diffArr[i]['editable_by_client'];
          delete diffArr[i]['editable_by_foreigner'];
        }

        if (
          diffArr[i].editable_by_foreigner === false &&
          diffArr[i].editable_by_client === false
        ) {
          this.errorHtml = diffArr[i].name + ': At least one \'editable by\' field is required';
          this.disabledSaveButton = false;
          return;
        }
      }

      const requests = [];
      for (const o of diffArr) {
        if (o.document && o.document === 'delete') {
          const obj = {
            fid: this.foreigner.id,
            pid: permit.id,
            did: o.id,
          };

          requests.push({
            ...obj,
            request: this.$store
              .dispatch('foreigners/deleteDocument', obj)
              .catch((err) => {
                throw err;
              }),
          });
          continue;
        }

        if (o == null) continue;
        const obj = {
          fid: this.foreigner.id,
          pid: permit.id,
          did: o.id,
          document: o,
        };
        if (obj.document && obj.document.name) delete obj.document.name;
        delete o.id;
        delete o.permit_id;
        delete o.modified_by_avatar_url;
        delete o.uploaded_by_avatar_url;

        if (Object.entries(o).length === 0) continue;

        requests.push({
          ...obj,
          request: this.$store.dispatch('foreigners/editForeignerPermitDocument', [obj]),
        });
      }

      for (const d of this.editedDocuments) {
        if (d.willBeDeletedRow) {
          const params = {
            fid: this.foreigner.id,
            pid: this.foreignerPermits[this.expanded].id,
            did: d.id,
          };
          requests.push({
            ...params,
            request: this.$store.dispatch('foreigners/deleteDocumentRow', params),
          });
        }
      }

      let hasError = false;
      this.documentValidationErrors = {};
      for (const item of requests) {
        await item.request.catch((err) => {
          this.handleDocumentValidationError(err, item);
          hasError = true;
        });
      }

      if (hasError) {
        this.editing = 2;
        this.disabledSaveButton = false;
      } else {
        this.$store.commit('foreigners/clearCurrentForeignerPermits');
        this.editing = 0;
        if (requests.length === 0) {
          this.snackbarText = 'No documents were modified';
        } else {
          this.snackbarText = `${this.$sanitize(requests.length)} documents were modified`;
        }
        this.snackbar = true;
        this.disabledSaveButton = false;
        this.refreshPermits(oldExpanded).then((_) => {
          this.editing = 0;
          this.tab = 1;
          this.editedDocuments = [];
        });
      }
    },

    handleDocumentValidationError(err, item) {
      this.errorHtml = err;
      if (err.response) {
        if (err.response.data && typeof err.response.data.data === 'object') {
          this.documentValidationErrors[item.did] = err.response.data.data;
        } else if (
          typeof err.response.data == 'string' && err.response.data.includes('413 Request Entity Too Large')
        ) {
          this.errorHtml = this.$t(
            'foreigners.permits.documents.file_too_big',
          );
        } else {
          this.errorHtml = helpers.createErrorHtml(err.response.data);
        }
      } else {
        this.errorHtml = this.$t(
          'foreigners.permits.documents.file_too_big',
        );
      }
    },

    getDocumentValidationErrorMessage({ field, id }) {
      if (this.documentValidationErrors[id] != null) {
        const message = this.documentValidationErrors[id][field];
        return Array.isArray(message) ? message.join(', ') : message;
      }
      return false;
    },

    saveCustomDocuments() {
      this.addCustomDocumentsAlertText = '';
      this.disabledSaveButton = true;
      const arr = [];
      this.addCustomDocumentsAlertText = '';

      for (const doc of this.addCustomDocuments) {
        if (doc.name.length === 0) {
          this.addCustomDocumentsAlertText = 'All fields are requires';
          return;
        }
        arr.push({
          document_type_name: doc.name,
          editable_by_foreigner: doc.editable_by_foreigner,
          editable_by_client: doc.editable_by_client,
        });
      }

      for (const a of arr) {
        if (
          a.editable_by_foreigner === false &&
          a.editable_by_client === false
        ) {
          this.disabledSaveButton = false;
          this.addCustomDocumentsAlertText =
            'At least one \'editable by\' field is required';
          return;
        }
      }

      const obj = {
        fid: this.foreigner.id,
        pid: this.currentPermit.id,
        documents: arr,
      };

      const oldExpanded = this.expanded;
      this.$store
        .dispatch('foreigners/addCustomDocuments', obj)
        .then((_) => {
          this.disabledSaveButton = false;
          this.refreshPermits(oldExpanded);
          this.tab = 1;
          this.addCustomDocumentsDialog = false;
        })
        .catch((e) => {
          this.disabledSaveButton = false;
          this.addCustomDocumentsAlertText = helpers.createErrorHtml(
            e.response.data,
          );
        })
        .finally((_) => {
          this.disabledSaveButton = false;
        });
    },

    async onFileSelect(file) {
      const obj = {
        fid: this.foreigner.id,
        pid: this.currentPermit.id,
        did: this.selectedDocument.id,
        document: file,
      };
      Vue.set(this.documentValidationErrors, obj.did, null);
      const oldExpanded = this.expanded;

      try {
        await ForeignerService.uploadPermitDocumentClient(
          obj.fid,
          obj.pid,
          obj.did,
          obj.document,
        );
        this.refreshPermits(oldExpanded);
        this.tab = 1;
      } catch (e) {
        this.handleDocumentValidationError(e, obj);
      }
    },

    doPermitObtained() {
      this.disabledSaveButton = true;
      this.permitObtainedAlertText = '';

      if (this.permit_obtained.issue_date.length === 0) {
        this.permitObtainedAlertText = 'Issue date is empty';
        this.disabledSaveButton = false;
        return;
      }
      if (this.permit_obtained.expire_date.length === 0) {
        this.permitObtainedAlertText = 'Expire date is empty';
        this.disabledSaveButton = false;
        return;
      }

      if (this.permit_obtained.document_1 == null) {
        this.permitObtainedAlertText = 'There is no document file ';
        this.disabledSaveButton = false;
        return;
      }

      const [y1, m1, d1] = this.permit_obtained.issue_date
        .replace(/-/g, '.')
        .split('.');
      const newDate1 = `${d1}.${m1}.${y1}`;
      const [y2, m2, d2] = this.permit_obtained.expire_date
        .replace(/-/g, '.')
        .split('.');
      const newDate2 = `${d2}.${m2}.${y2}`;

      const fd = new FormData();
      fd.append('issue_date', newDate1);
      fd.append('expire_date', newDate2);

      let i = 0;
      for (const doc of this.permit_obtained.document_1) {
        if (i === 1) i = 2;

        if (doc.type.length === 0) {
          this.permitObtainedAlertText = `${this.$t(
            'foreigners.permits.document_type',
          )} ${i} is empty`;
          return;
        }
        if (doc.name.length === 0) {
          this.permitObtainedAlertText = `${this.$t(
            'foreigners.permits.document_name',
          )} ${i} is empty`;
          return;
        }

        if (i === 0) {
          fd.append('document_name', doc.name);
          fd.append('document', doc);
        } else {
          fd.append('document_name_' + i, doc.name);
          fd.append('document_' + i, doc);
        }

        i++;
      }
      const obj = {
        fid: this.foreigner.id,
        pid: this.currentPermit.id,
        fd: fd,
      };

      for (const pair of fd.entries()) {
      }
      this.$store
        .dispatch('foreigners/doPermitObtain', obj)
        .then((_) => {
          this.disabledSaveButton = false;
          this.permit_obtained = {
            expire_date: '',
            issue_date: '',
            document_type_id_1: '',
            document_type_name_1: '',
            document_1: null,
          };
          const oldExpanded = this.expanded;
          this.refreshPermits(oldExpanded);
          this.permitObtainedDialog = false;
          this.permitObtainedCustomDocuments = [];
          this.permitObtainedAlertText = '';
        })
        .catch((err) => {
          this.disabledSaveButton = false;
          this.permitObtainedAlertText = helpers.createErrorHtml(
            err.response.data,
          );
        });
    },

    openEditPermitObtainedDialog(permit) {
      this.editPermitObtainedDialog = true;
      this.permitObtainedEditDoc = null;
      let a = permit.expire_date;
      let [d1, m1, y1] = a.split('.');
      let newDate1 = `${y1}-${m1}-${d1}`;
      this.edit_permit_obtained.expire_date = newDate1;

      a = permit.issue_date;
      [d1, m1, y1] = a.split('.');
      newDate1 = `${y1}-${m1}-${d1}`;
      this.edit_permit_obtained.issue_date = newDate1;
    },

    doEditPermitObtained() {
      this.disabledSaveButton = true;
      this.permitEditObtainedAlertText = '';

      const reqs = [];

      if (this.edit_permit_obtained.issue_date.length === 0) {
        this.editPermitObtainedAlertText = 'Issue date is empty';
        return;
      }
      if (this.edit_permit_obtained.expire_date.length === 0) {
        this.editPermitObtainedAlertText = 'Expire date is empty';
        return;
      }

      const obj = {
        fid: this.foreigner.id,
        pid: this.currentPermit.id,
        obj: {
          issue_date: this.edit_permit_obtained.issue_date_view,
          expire_date: this.edit_permit_obtained.expire_date_view,
        },
      };

      reqs.push(this.$store.dispatch('foreigners/doEditPermitObtain', obj));

      if (this.permitObtainedEditDoc) {
        const obj_d = {
          fid: this.foreigner.id,
          pid: this.currentPermit.id,
          did: this.currentPermit.final_document_id,
          document: this.permitObtainedEditDoc,
        };
        reqs.push(
          this.$store.dispatch('foreigners/doEditPermitObtainDocument', obj_d),
        );
      }

      Promise.all(reqs)
        .then((_) => {
          this.disabledSaveButton = false;
          this.permitObtainedEditDoc = null;
          const oldExpanded = this.expanded;
          this.refreshPermits(oldExpanded);
          this.editPermitObtainedDialog = false;
          this.editPermitObtainedAlertText = '';
        })
        .catch((_) => {
          this.disabledSaveButton = false;
        });
    },

    setPermitAsMain(permitId) {
      const obj = {
        pid: permitId,
        fid: this.foreigner.id,
        obj: {
          is_main: true,
        },
      };
      this.$store.dispatch('foreigners/editForeignerPermit', obj).then((_) => {
        this.$emit('refreshTable');
        const oldExpanded = this.expanded;
        this.refreshPermits(oldExpanded);
      });
    },

    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },

    unparseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    assistanceUsersOptions() {
      const opts = this.internalUsers;

      opts.unshift({
        fullname: this.$t('foreigners.permits.assistance.without'),
        id: -2,
      });
      opts.unshift({ fullname: ' ', id: -1 });

      return opts;
    },

    assistancePlacesOptions() {
      const opts = this.internalPlacesOptions;
      opts.unshift({ short_name: ' ', id: -1 });

      return opts;
    },
  },
};
</script>

<style>
.panel-content .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}

#documentsClientDiv .v-text-field .v-input__control {
  display: none !important;
}

.notactiveStep {
  pointer-events: none;
}

.redOnHover:hover {
  color: #f44336 !important;
}

.editTable tr td:nth-child(6) {
  width: 10%;
}

.edit_obtained_doc .v-input {
  display: inline;
  margin-left: 5px;
}

.edit_obtained_doc .v-input__control {
  display: none;
}
</style>
