import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import PermitTypesService from '../../services/administration/PermitTypesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    types: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setTypesAndParams(state, data) {
      state.types = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
  },
  actions: {
    retrieveTypes(context, params) {
      PermitTypesService.getPermitTypes(params.page, params.rows, params.order_by).then((data) => {
        context.commit('setTypesAndParams', data.data);
      });
    },
    createType(context, obj) {
      return PermitTypesService.createPermitType(obj);
    },
    editType(context, obj) {
      return PermitTypesService.editPermitType(obj.type, obj.id);
    },
    deleteType(context, id) {
      return PermitTypesService.deletePermitType(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.types) {
        data.push({
          id: u.id,
          name: u.name,
          description: u.description,
          documents: u.documents,
          documentsCount: u.documents.length,
          statuses: u.statuses,
          statusesCount: u.statuses.length,
          renewal_offset: u.renewal_offset,
          case_assessment: u.case_assessment,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.types.length === 0;
    },
    selectOptions: (state) => {
      const data = [];

      for (const u of state.types) {
        data.push({
          id: u.id,
          name: u.name,
        });
      }

      return data;
    },
    selectCaseAssessmentOptions: (state) => {
      const data = [];

      for (const u of state.types) {
        if (u.case_assessment === true)
          data.push({
            id: u.id,
            name: u.name,
          });
      }

      return data;
    },
  },
});
