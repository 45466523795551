import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"margin-top":"18vh"},attrs:{"id":"containerLogin","fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md6":""}},[_c(VCard,{staticClass:"elevation-12",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}}},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('adm.reset_password.expired.title')))]),_c(VSpacer)],1),_c(VCardText,{staticClass:"text-center",staticStyle:{"font-size":"14pt"}},[_vm._v(" "+_vm._s(_vm.$t('adm.reset_password.expired.text'))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"outlined":"","color":"primary lighten-3"},on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.back_to_login'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }