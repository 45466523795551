<template>
  <v-flex
    md12
  >
    <v-toolbar
      class="elevation-1"
      color="white accent-4"
      dense
    >
      <template v-if="hasFullscreen">
        <v-btn
          v-if="isInDialog"
          text
          icon
          small
          :title="$t('messaging.leave_fullscreen')"
          @click="$emit('close-fullscreen')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          small
          :title="$t('messaging.enter_fullscreen')"
          @click="$emit('open-fullscreen')"
        >
          <v-icon>mdi-dock-window</v-icon>
        </v-btn>
      </template>
      <v-btn
        text
        icon
        small
        :title="$t('common.actions.refresh')"
        @click="$emit('refresh')"
      >
        <v-icon :color="timerActive ? 'green' : 'grey'">
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-flex
        xs4
        ml-3
        mt-5
      >
        <v-text-field
          v-model="filterValues.bodySearch"
          :label="$t('common.actions.search')"
          :autocomplete="$randomString()"
          class="searchInput"
          @keydown.enter="function(){$emit('refresh'); $event.target.blur()}"
        />
      </v-flex>
      <div
        v-if="filterValues.bodySearch !== ''"
        class="pointerHover ml-2"
        @click="$emit('scroll-to-prev')"
      >
        <v-icon>expand_less</v-icon>
      </div>
      <div
        v-if="filterValues.bodySearch !== ''"
        class="pointerHover ml-2"
        @click="$emit('scroll-to-next')"
      >
        <v-icon>expand_more</v-icon>
      </div>

      <div
        v-if="filterValues.bodySearch !== ''"
        class="pointerHover ml-2"
        @click="filterValues.bodySearch = '' ; $emit('refresh')"
      >
        <v-icon :color="$appColours.button.delete">
          mdi-close
        </v-icon>
      </div>

      <v-flex
        xs2
        ml-3
        mt-5
      >
        <v-select
          v-model="filterValues.userFrom"
          :items="possibleMentions"
          multiple
          :menu-props="{ maxHeight: '400' }"
          :label="$t('common.fields.date_from')"
          item-text="fullname"
          item-value="id"
          @change="$emit('refresh')"
        />
      </v-flex>
      <div
        v-if="filterValues.userFrom !== ''"
        class="pointerHover"
        @click="filterValues.userFrom = '' ; $emit('refresh')"
      >
        <v-icon :color="$appColours.button.delete">
          mdi-close
        </v-icon>
      </div>

      <v-flex
        xs2
        ml-3
        mt-5
      >
        <v-select
          v-model="filterValues.permit"
          :items="permits"
          multiple
          :menu-props="{ maxHeight: '400' }"
          item-text="permit_type_name"
          item-value="id"
          :label="$t('common.fields.permit')"
          @change="$emit('refresh')"
        >
          <template v-slot:item="data">
            <template v-if="data.item.permit_type_name !== 'None'">
              <v-icon :color="$msgColours[data.item.color]">
                mdi-square
              </v-icon>
            </template>

            {{ data.item.permit_type_name }}
            <template v-if="data.item.permit_type_name !== 'None'">
              ({{ data.item.reference_number }})
            </template>
          </template>
        </v-select>
      </v-flex>
      <div
        v-if="filterValues.permit !== ''"
        class="pointerHover"
        @click="filterValues.permit = '' ; $emit('refresh')"
      >
        <v-icon :color="$appColours.button.delete">
          mdi-close
        </v-icon>
      </div>

      <v-flex
        xs2
        ml-3
        mt-5
      >
        <v-menu
          v-model="dateFromPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filterValues.dateFrom"
              :label="$t('common.fields.date_from')"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="filterValues.dateFrom"
            :first-day-of-week="1"
            @change="$emit('refresh')"
            @input="dateFromPicker = false"
          />
        </v-menu>
      </v-flex>
      <div
        v-if="filterValues.dateFrom !== ''"
        class="pointerHover"
        @click="filterValues.dateFrom = '' ; $emit('refresh')"
      >
        <v-icon :color="$appColours.button.delete">
          mdi-close
        </v-icon>
      </div>

      <v-flex
        xs2
        ml-3
        mt-5
      >
        <v-menu
          v-model="dateToPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filterValues.dateTo"
              :label="$t('common.fields.date_until')"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="filterValues.dateTo"
            :first-day-of-week="1"
            @change="$emit('refresh')"
            @input="dateToPicker = false"
          />
        </v-menu>
      </v-flex>
      <div
        v-if="filterValues.dateTo !== ''"
        class="pointerHover"
        @click="filterValues.dateTo = '' ; $emit('refresh')"
      >
        <v-icon :color="$appColours.button.delete">
          mdi-close
        </v-icon>
      </div>
    </v-toolbar>
  </v-flex>
</template>

<script>
export default {
  props: {
    timerActive: {
      type: Boolean,
      default: false,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    isInDialog: {
      type: Boolean,
      default: false,
    },
    hasFullscreen: {
      type: Boolean,
      default: true,
    },
    messagingStore: {
      type: String,
      required: true,
    },
    permits: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dateFromPicker: false,
    };
  },

  computed: {
    possibleMentions() {
      return this.$store.getters[`${this.messagingStore}/possibleMentions`];
    },
  },
};
</script>
