<template>
  <div>
    <!--   FOREIGNER MODE   -->
    <template v-if="mode === 1">
      <h1>FOREIGNER VIEW</h1>
      <h1>Questionnaires - {{ currentQuestionnaire.label }} (&lt;currentStep&gt;/&lt;totalSteps&gt;)</h1>
      <foreigners-questionnaire-form-generator />
    </template>
    <!--   ADMIN MODE   -->
    <template v-else>
      <v-flex
        v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT"
        md12
      >
        <v-toolbar
          class="elevation-1"
          color="white accent-4"
        >
          <v-flex
            xs12
            row
          >
            <v-btn
              :color="$appColours.button.ok"
              outlined
              @click="dialogAdd = true"
            >
              {{ $t('quest.add_questionnaire') }}
            </v-btn>
          </v-flex>
        </v-toolbar>
      </v-flex>
      <br>
      <v-flex
        xs10
        offset-xs1
        row
      >
        <v-flex xs6>
          <b>{{ $t('quest.quest_type') }}</b>
        </v-flex>
        <v-flex xs2>
          <b>{{ $t('quest.completed') }}</b>
        </v-flex>
        <v-flex xs2>
          <b>{{ $t('quest.submitted') }}</b>
        </v-flex>
        <v-flex
          v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT"
          xs2
        >
          <b>{{ $t('common.fields.actions') }}</b>
        </v-flex>
      </v-flex>
      <v-flex
        v-for="(q, index) in foreignersQuestionnaires"
        :key="index"
        xs10
        offset-xs1
        row
      >
        <v-flex xs6>
          <div style="margin-top: 5px">
            <a
              :href="'/foreigners/'+foreigner.id+'/questionnaires/'+q.id"
              target="_blank"
            >
              {{ q.survey_type_name }}
            </a>
          </div>
        </v-flex>
        <v-flex xs2>
          <v-icon
            v-if="q.completed === true"
            :color="$appColours.status.others.active"
            class="ml-3"
            small
            :title="$t('quest.completed')"
            style="margin-top: 5px"
          >
            mdi-checkbox-blank-circle
          </v-icon>
          <v-icon
            v-else-if="q.completed !== true"
            :color="$appColours.status.others.notactive"
            class="ml-3"
            small
            :title="$t('quest.not_completed')"
            style="margin-top: 5px"
          >
            mdi-checkbox-blank-circle
          </v-icon>
        </v-flex>
        <v-flex xs2>
          <template v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT">
            <v-checkbox
              v-model="q.submitted"
              style="margin-top: 0; margin-left: 10px"
              @change="function(val){changeSubmitStatus(q, val)}"
            />
          </template>
          <template v-else>
            <v-checkbox
              v-model="q.submitted"
              disabled
              style="margin-top: 0; margin-left: 10px"
              @change="function(val){changeSubmitStatus(q, val)}"
            />
          </template>
        </v-flex>
        <v-flex
          v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT"
          xs2
        >
          <template v-if="q.submitted">
            <img
              src="../../assets/fslogo.png"
              class="pointerHover"
              style="margin-top:5px; width: 20px"
              :title="$t('quest.export_hint')"
              @click="openExportDialog(q.id)"
            >
          </template>
          <template v-else>
            <img
              src="../../assets/fslogo_bw.png"
              style="margin-top:5px; width: 20px"
              :title="$t('quest.unable_export_hint')"
            >
          </template>
          <v-icon
            :color="$appColours.button.delete"
            class="pointerHover"
            :title="$t('quest.delete_questionnaire')"
            style="margin-top: -10px; margin-left: 15px"
            @click="qToDelete = q; dialogDelete = true;"
          >
            delete
          </v-icon>
        </v-flex>
      </v-flex>

      <br>
      <br>
    </template>

    <!-- Add -->
    <v-dialog
      v-model="dialogAdd"
      max-width="690"
      @keydown.esc="dialogAdd = false"
      @keydown.meta.s="addQuestionnaire"
      @keydown.meta.enter="addQuestionnaire"
      @keydown.ctrl.s="addQuestionnaire"
      @keydown.ctrl.enter="addQuestionnaire"
    >
      <v-card>
        <v-card-title
          class="headline "
          primary-title
        >
          {{ $t('quest.add_questionnaire') }}
        </v-card-title>
        <v-card-text>
          <v-flex row>
            <v-flex
              xs10
              offset-xs1
            >
              <v-autocomplete
                v-model="newQuestionnaireType"
                :items="questionnaireType"
                :label="$t('quest.quest_type')"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex
              xs10
              offset-xs1
            >
              <v-switch
                v-model="newQuestionnaireNotification"
                :label="$t('quest.notify')"
              />
            </v-flex>
          </v-flex>
          <v-flex row>
            <v-flex
              xs11
              ml-10
            >
              <v-alert
                v-if="newQuestionnaireAlertText.length > 0"
                type="error"
                v-html="newQuestionnaireAlertText"
              />
            </v-flex>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            outlined
            @click="function(){dialogAdd = false; newQuestionnaireType = null; newQuestionnaireNotification = true; newQuestionnaireAlertText = ''}"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.ok"
            outlined
            :loading="disabledSaveButton"
            @click="addQuestionnaire"
          >
            {{ $t('common.actions.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Export -->
    <v-dialog
      v-model="dialogExport"
      max-width="690"
      @keydown.esc="dialogExport = false"
    >
      <v-card>
        <v-card-title
          class="headline "
          primary-title
        >
          FormStudio questionnaire export
        </v-card-title>

        <v-card-text>
          <v-flex row>
            <v-expansion-panels multiple>
              <v-expansion-panel
                v-for="(e, index) in exportData"
                :key="index"
                class="mt-2 "
              >
                <v-expansion-panel-header class="subtitle-2">
                  {{ $capitalize(e.label) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card
                    v-for="(a, indexA) in e.applicants"
                    :key="indexA"
                    class="pointerHover mx-4 mt-2 px-3 py-3"
                    @click="downloadExport(e, a)"
                  >
                    {{ a.fullname }}
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>

          <v-flex row>
            <v-flex
              xs12
              mt-2
            >
              <v-alert
                v-if="exportQuestionnaireAlertText.length > 0"
                type="error"
                v-html="exportQuestionnaireAlertText"
              />
            </v-flex>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            outlined
            @click="function(){
              dialogExport = false; newQuestionnaireType = null; activeQid = null;
              exportQuestionnaireAlertText = ''; exportData = null
            }"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Submit -->
    <v-dialog
      v-model="dialog"
      max-width="690"
      @keydown.esc="dialog = false"
      @keydown.meta.s="setSubmit"
      @keydown.meta.enter="setSubmit"
      @keydown.ctrl.s="setSubmit"
      @keydown.ctrl.enter="setSubmit"
    >
      <v-card>
        <v-card-title
          class="headline "
          primary-title
        >
          Confirm action
        </v-card-title>

        <v-card-text>
          After setting questionnaire as <b>submitted</b>, foreigner will not be able to
          edit any data. Are you sure?
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.edit"
            outlined
            @click="dialog = false; setSubmitFalse()"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.ok"
            outlined
            @click="setSubmit"
          >
            {{ $t('common.actions.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete -->
    <v-dialog
      v-model="dialogDelete"
      max-width="690"
      @keydown.esc="dialog = false"
      @keydown.meta.s="deleteQuestionnaire(qToDelete.id)"
      @keydown.meta.enter="deleteQuestionnaire(qToDelete.id)"
      @keydown.ctrl.s="deleteQuestionnaire(qToDelete.id)"
      @keydown.ctrl.enter="deleteQuestionnaire(qToDelete.id)"
    >
      <v-card>
        <v-card-title
          class="headline "
          primary-title
        >
          Confirm action
        </v-card-title>

        <v-card-text>
          <div class="body-1">
            Are you sure? Delete questionnaire <b v-if="qToDelete">
              {{ qToDelete.survey_type_name }}
            </b>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.edit"
            outlined
            @click="dialogDelete = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            @click="deleteQuestionnaire(qToDelete.id)"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ForeignersQuestionnaireFormGenerator from './ForeignersQuestionnaireFormGenerator';
import ForeignersQuestionnaireService from '../../services/foreigners/ForeignersQuestionnaireService';
import helpers from '../../helpers';
import axios from 'axios';

export default {
  name: 'ForeignerQuestionnaire',
  components: {
    ForeignersQuestionnaireFormGenerator,
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zz: '',
      mode: 1, // 1 is for foreigner view mode, 2 is for manager view_mode
      newQuestionnaireType: 0,
      newQuestionnaireNotification: true,
      questionnaire: [],
      BASE_HOST: process.env.VUE_APP_HOSTURL,
      dialog: false,
      dialogExport: false,
      exportData: null,
      activeQid: null,

      qToChange: null,
      qToChangeValue: null,

      dialogDelete: false,
      qToDelete: null,

      newQuestionnaireAlertText: '',
      exportQuestionnaireAlertText: '',

      dialogAdd: false,

      snackbar: false,
      snackbarText: '',

      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      questionnaireType: 'coreData/surveyTypes',
      foreignersQuestionnaires: 'foreignerQuestionnaire/foreignersQuestionnaires',
      currentQuestionnaire: 'foreignerQuestionnaire/currentQuestionnaire',
      enum_gender: 'coreData/userGenders',
      enum_country: 'coreData/countries',
      enum_marital_status: 'coreData/maritalStatuses',
      enum_phone_code: 'coreData/dialCodes',
      exportApplicants: 'questionnaires/exportApplicants',

      /*
       *
       * Deep copy original questionnaire to model
       * During copy insert into enum possible values and then use them as :items (or datepicker things)
       * As v-model also use copied object. So use it and for template rendering
       * And on send copy values into original questionary object
       *
       * */
    }),
  },
  created() {
    this.mode = 2;

    if (this.mode === 1)
      //foreigner mode
      this.prepareQuestionnaireObject();
    else if (this.mode === 2) {
      //admin mode
      this.$store.dispatch(
        'foreignerQuestionnaire/retrieveForeignersQuestionnaires',
        { fid: this.foreigner.id },
      );
    }
  },
  mounted() {
    this.$store.dispatch('coreData/retrieveSurveyTypes');
  },
  methods: {
    debug: function() {},
    prepareQuestionnaireObject: function() {},
    openExportDialog: function(qid) {
      this.activeQid = qid;
      ForeignersQuestionnaireService.getQuestionnaireExports(
        this.foreigner.id,
        qid,
      )
        .then((r) => {
          this.exportData = r.data.data;
          this.dialogExport = true;
        })
        .catch((e) => {
          this.snackbarText = helpers.createErrorHtml(e.response.data);
          this.snackbar = true;
        });
    },
    downloadExport: function(exp, applicant) {
      this.exportQuestionnaireAlertText = '';
      const url = `/foreigner/${this.foreigner.id}/survey/${this.activeQid}/export/form_studio/${exp.type_id}/${applicant.id}/`;
      this.downloadFileByUrl(url);
    },
    downloadFileByUrl: function(urll) {
      const url = process.env.VUE_APP_BASEURL + urll;

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if (response.headers['content-disposition'])
            fileLink.setAttribute(
              'download',
              decodeURI(
                response.headers['content-disposition']
                  .split('filename')[2]
                  .slice(9),
              ),
            );
          else fileLink.setAttribute('download', 'exported_form.frmx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              error.response.data = JSON.parse(reader.result);
              this.exportQuestionnaireAlertText = error.response.data.message;
              // resolve(Promise.reject(error));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.response.data);
          }).then((err) => {
            // here your response comes
            this.exportQuestionnaireAlertText = error.response.data.message;
          });
        });
    },
    addQuestionnaire: function() {
      this.disabledSaveButton = true;
      this.newQuestionnaireAlertText = '';
      if (this.newQuestionnaireType < 1) return;

      const obj = {
        fid: this.foreigner.id,
        survey_type: this.newQuestionnaireType,
        enable_notification: this.newQuestionnaireNotification,
      };

      this.$store
        .dispatch('foreignerQuestionnaire/addForeignerQuestionnaire', obj)
        .then((_) => {
          this.disabledSaveButton = false;
          this.newQuestionnaireNotification = true;
          this.newQuestionnaireType = 0;
          this.dialogAdd = false;
          this.$store.dispatch(
            'foreignerQuestionnaire/retrieveForeignersQuestionnaires',
            { fid: this.foreigner.id },
          );
        })
        .catch((err) => {
          this.disabledSaveButton = false;
          this.newQuestionnaireAlertText = helpers.createErrorHtml(
            err.response.data,
          );
        });
    },
    deleteQuestionnaire: function(qid) {
      this.$store
        .dispatch('foreignerQuestionnaire/deleteQuestionnaire', {
          fid: this.foreigner.id,
          qid: qid,
        })
        .then((_) => {
          this.newQuestionnaireType = 0;
          this.$store.dispatch(
            'foreignerQuestionnaire/retrieveForeignersQuestionnaires',
            { fid: this.foreigner.id },
          );
          this.dialogDelete = false;
        });
    },
    openNewWindow: function(url) {
      window.open(url, 'newwindow', 'width=1200,height=900');
      return false;
    },
    changeSubmitStatus(q, s) {
      if (s === false) {
        this.qToChange = q;
        this.setSubmitFalse();
        return;
      }
      this.dialog = true;
      this.qToChange = q;
      this.qToChangeValue = s;
    },
    setSubmit() {
      if (this.qToChangeValue) this.setSubmitTrue();
      else this.setSubmitFalse();
    },
    setSubmitTrue() {
      ForeignersQuestionnaireService.editQuestionnaire(
        this.foreigner.id,
        this.qToChange.id,
        { submitted: true },
      ).then((_) => {
        this.$store.dispatch(
          'foreignerQuestionnaire/retrieveForeignersQuestionnaires',
          { fid: this.foreigner.id },
        );
        this.dialog = false;
      });
    },
    setSubmitFalse() {
      ForeignersQuestionnaireService.editQuestionnaire(
        this.foreigner.id,
        this.qToChange.id,
        { submitted: false },
      ).then((_) => {
        this.$store.dispatch(
          'foreignerQuestionnaire/retrieveForeignersQuestionnaires',
          { fid: this.foreigner.id },
        );
        this.dialog = false;
      });
    },
  },
};
</script>

<style scoped>
.sectionDiv {
  background-color: #f5f5f5;
  border-radius: 10px;
}
</style>
