<template>
  <div>
    <v-flex row>
      <v-flex>
        <div class="flex display-1 mx-2">
          {{ $t('adm.permits.statuses.title') }}
        </div>
      </v-flex>
      <v-flex class="text-right ">
        <v-flex
          xs6
          offset-xs6
        >
          <!--<v-overflow-btn
							:items="statusFilterItems"
							v-model="statusFilter"

					>
					</v-overflow-btn>-->
        </v-flex>
      </v-flex>
      <v-flex class="text-right my-3">
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newStatusDialog = true; clearNewStatusForm(); action = 'new'"
          >
            {{ $t('adm.permits.statuses.statuses_dialog.title_new') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>

      <template
        slot="item.editable_by_client"
        slot-scope="props"
      >
        <v-icon v-if="props.value === true">
          check
        </v-icon>
        <v-icon v-else>
          close
        </v-icon>
      </template>
      <template
        slot="item.editable_by_foreigner"
        slot-scope="props"
      >
        <v-icon v-if="props.value === true">
          check
        </v-icon>
        <v-icon v-else>
          close
        </v-icon>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editStatus(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteStatusOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newStatusDialog"
      persistent
      max-width="800px"
      @keydown.esc="newStatusDialog = false"
      @keydown.meta.s="editStatusDialogShortcutHandler($event)"
      @keydown.ctrl.s="editStatusDialogShortcutHandler($event)"
      @keydown.meta.enter="editStatusDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editStatusDialogShortcutHandler($event)"
      @keydown.enter="editStatusDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span
            v-if="action === 'new'"
            class="headline"
          >{{ $t('adm.permits.statuses.statuses_dialog.title_new') }}</span>
          <span
            v-else-if="action === 'edit'"
            class="headline"
          >{{ $t('adm.permits.statuses.statuses_dialog.title_edit') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="newStatus.name"
                  :label="$t('common.fields.name') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="newStatus.description"
                  name="description"
                  :label="$t('common.fields.description')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="newStatus.action_dates"
                  :items="actionDatesOptions"
                  :menu-props="{ maxHeight: '400' }"
                  :autocomplete="$randomString()"
                  :label="$t('common.fields.action_dates')"
                  multiple
                  item-text="name"
                  item-value="id"
                  chips
                />
              </v-flex>

              <v-flex
                xs12
                md9
              >
                <v-card tile>
                  <v-list>
                    <v-subheader>{{ $t('adm.permits.statuses.statuses_dialog.action_dates_order') }}</v-subheader>
                    <draggable
                      v-model="newStatus.action_dates"
                      :list="newStatus.action_dates"
                      @start="drag=true"
                      @end="drag=false"
                    >
                      <v-list-item
                        v-for="(opt, index) in newStatus.action_dates"
                        :key="index"
                      >
                        <v-list-item-content
                          :key="opt.id"
                          class="moveable"
                        >
                          <v-flex
                            xs12
                            row
                            mx-1
                          >
                            <v-flex xs10>
                              <v-list-item-title>
                                {{ $store.getters['permitActionDates/getById'](opt).name }}
                              </v-list-item-title>
                            </v-flex>
                            <v-flex
                              xs2
                              style="text-align: right"
                            >
                              <v-icon>reorder</v-icon>
                            </v-flex>
                          </v-flex>
                        </v-list-item-content>
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-alert
                  v-if="newStatusAlertText.length > 0"
                  type="error"
                  v-html="newStatusAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="newStatusDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledEditButton"
            @click="createStatus"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledEditButton"
            @click="saveStatus"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteStatusDialog"
      persistent
      max-width="800px"
      @keydown.esc="deleteStatusDialog = false"
      @keydown.meta.s="deleteStatusDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteStatusDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteStatusDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteStatusDialogShortcutHandler($event)"
      @keydown.enter="deleteStatusDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.permits.statuses.delete_statuses_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.permits.statuses.delete_statuses_dialog.confirm_text') }} <b>{{ deleteStatus.name }}</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteStatusAlertText.length > 0"
                  type="error"
                  v-html="deleteStatusAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteStatusDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            :loading="disabledDeleteButton"
            @click="deleteStatusMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import helpers from '../../helpers.js';

export default {
  name: 'PermitStatuses',
  components: {
    draggable,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',

      headers: [
        {
          text: this.$t('common.fields.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('common.fields.description'),
          value: 'description',
          sortable: true,
        },
        {
          text: this.$t('adm.permits.table_headers.dates_count'),
          value: 'count_of_dates',
          sortable: false,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 0,
      rowsOnPage: 10,
      //orderBy: 'id',
      sortBy: 'name',
      sortDesc: false,

      action: 'new', //new or edit
      newStatusDialog: false,
      newStatus: {
        name: '',
        description: '',
        //human_order: 0,
        action_dates: [],
      },
      newStatusAlertText: '',
      editOriginalStatus: null,
      disabledEditButton: false,

      deleteStatusDialog: false,
      deleteStatus: {
        id: 0,
        name: '',
      },
      deleteStatusAlertText: '',
      disabledDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'permitStatuses/tableData',
      tableParams: 'permitStatuses/tableParams',
      actionDatesOptions: 'permitActionDates/selectOptions',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    let refresh = localStorage.getItem('itrack_refresh_admin_perm_stat');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
      }
    }

    this.$store.dispatch('permitStatuses/retrieveStatuses', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
    });
    this.$store.dispatch('permitActionDates/retrieveActionDates', {
      page: 1,
      rows: 100,
      order_by: this.orderBy,
    });
  },
  methods: {
    editStatusDialogShortcutHandler: function(event) {
      event.preventDefault();
      if (this.action === 'new') this.createStatus();
      else if (this.action === 'edit') this.saveStatus();
    },
    deleteStatusDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deleteStatusMethod();
    },

    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('permitStatuses/retrieveStatuses', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('permitStatuses/retrieveStatuses', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    refresh: function() {
      //localStorage.setItem('itrack_rpp_admin_perm_stat', this.rowsOnPage);

      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_perm_stat');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_perm_stat',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('permitStatuses/retrieveStatuses', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    sorting: function(value, sortable) {
      // //console.log("sorting", value, sortable);
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },
    validateNewStatusForm: function() {
      if (this.newStatus.name.length < 3) {
        this.newStatusAlertText = 'Name is too short';
        return false;
      }
      return true;
    },
    clearNewStatusForm: function() {
      this.newStatus.name = '';
      this.newStatus.description = '';
      //this.newStatus.human_order = 0;
      this.newStatus.action_dates = [];
      this.newStatusAlertText = '';
    },

    createStatus: function() {
      this.disabledEditButton = true;
      this.newStatusAlertText = '';
      if (this.validateNewStatusForm() !== true) {
        this.disabledEditButton = false;
        return;
      }

      const obj = {};
      for (const k in this.newStatus) obj[k] = this.newStatus[k];

      // //console.log("Create Status", obj);
      this.$store
        .dispatch('permitStatuses/createStatus', obj)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitStatuses/retrieveStatuses', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newStatusDialog = false;
          this.clearNewStatusForm();
          this.disabledEditButton = false;
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newStatusAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newStatusAlertText = `Error occured`;
					}*/
          this.newStatusAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledEditButton = false;
        });
    },

    editStatus: function(obj) {
      // //console.log("EDITING", obj);
      this.action = 'edit';
      this.newStatus.name = obj.name;
      this.newStatus.description = obj.description;

      this.newStatus.action_dates = obj.action_dates;
      this.newStatus.id = obj.id;
      this.editOriginalStatus = JSON.parse(JSON.stringify(this.newStatus));
      this.newStatusDialog = true;
    },
    saveStatus: function() {
      // //console.log("SEVE EDITTED", this.newStatus);
      this.disabledEditButton = true;
      this.newStatusAlertText = '';
      if (this.validateNewStatusForm() !== true) {
        this.disabledEditButton = false;
        return;
      }

      /*let obj = {};
				for (let k in this.newStatus)
					obj[k] = this.newStatus[k];*/

      const diffObj = {};
      for (const key in this.editOriginalStatus) {
        //primitive
        if (typeof this.editOriginalStatus[key] !== 'object') {
          if (this.editOriginalStatus[key] !== this.newStatus[key]) {
            diffObj[key] = this.newStatus[key];
          }
        }
        //array
        else if (Array.isArray(this.editOriginalStatus[key])) {
          if (
            this.editOriginalStatus[key].join() !== this.newStatus[key].join()
          ) {
            diffObj[key] = this.newStatus[key];
          }
        }
        //object
        else {
          for (const key2 in this.newStatus[key]) {
            if (
              this.editOriginalStatus[key][key2] !== this.newStatus[key][key2]
            ) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = this.newStatus[key][key2];
            }
          }
        }
      }
      // //console.log("DIFF:", diffObj);

      // //console.log("Create status", obj);
      this.$store
        .dispatch('permitStatuses/editStatus', {
          id: this.newStatus.id,
          status: diffObj,
        })
        .then((data) => {
          /*this.snackbarText = data.data.message;
					this.snackbar = true;*/
          this.$store.dispatch('permitStatuses/retrieveStatuses', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newStatusDialog = false;
          this.clearNewStatusForm();
          this.disabledEditButton = false;
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newStatusAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newStatusAlertText = `Error occured`;
					}*/
          this.newStatusAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledEditButton = false;
        });
    },

    deleteStatusOpenDialog: function(obj) {
      this.deleteStatusAlertText = '';
      this.deleteStatus.name = obj.name;
      this.deleteStatus.id = obj.id;
      this.deleteStatusDialog = true;
    },
    deleteStatusMethod: function() {
      this.disabledDeleteButton = true;
      this.deleteStatusAlertText = '';
      this.$store
        .dispatch('permitStatuses/deleteStatus', this.deleteStatus.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitStatuses/retrieveStatuses', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteStatusDialog = false;
          this.disabledDeleteButton = false;
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newStatusAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newStatusAlertText = `Error occured`;
					}*/
          this.deleteStatusAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledDeleteButton = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
