import { render, staticRenderFns } from "./ForeignersOverview.vue?vue&type=template&id=46428927"
import script from "./ForeignersOverview.vue?vue&type=script&lang=js"
export * from "./ForeignersOverview.vue?vue&type=script&lang=js"
import style0 from "./ForeignersOverview.vue?vue&type=style&index=0&id=46428927&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports