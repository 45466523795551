import Vue from 'vue';
import Vuex from 'vuex';


import CoreDataService from '../services/CoreDataService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    foreignerAuditEvents: [],
    adminAuditEvents: [],
    auditSeverities: [],
    internalUserRoles: [],
    clientUserRoles: [],
    agencyUserRoles: [],
    countries: [],
    timezones: [],
    userGenders: [{
      id: 10,
      name: 'Male',
      name_text: 'male',
      key: 'foreigners.basic_info.gender.male',
    },
    {
      id: 20,
      name: 'Female',
      name_text: 'female',
      key: 'foreigners.basic_info.gender.female',
    },
    ],
    me: {},
    foreignerPermitDocumentStatus: [{
      id: 10,
      value: 'Missing',
      color: 'red',
      key: 'foreigners.permits.documents.statuses.missing',
    },
    {
      id: 20,
      value: 'Waiting for review',
      color: 'yellow',
      key: 'foreigners.permits.documents.statuses.waiting',
    },
    {
      id: 30,
      value: 'Accepted',
      color: 'green',
      key: 'foreigners.permits.documents.statuses.accepted',
    },
    ],
    permitStatuses: [{
      id: 0,
      value: 'Unknown',
      color: 'yellow',
    },
    {
      id: 10,
      value: 'In process',
      color: 'orange',
    },
    {
      id: 20,
      value: 'On hold',
      color: 'grey',
    },
    {
      id: 30,
      value: 'Obtained',
      color: 'green',
    },
    {
      id: 40,
      value: 'Archived',
      color: 'black',
    },
    ],
    surveyTypes: [],
    maritalStatuses: [],
    interfaceLanguages: [],
    supportSpecifications: [],
    supportTimelines: [],
    enums: [],
  },
  mutations: {
    setForeignerAuditEvents(state, data) {
      state.foreignerAuditEvents = data;
    },
    setAdminAuditEvents(state, data) {
      state.adminAuditEvents = data;
    },
    setAuditSeverities(state, data) {
      state.auditSeverities = data;
    },
    setInternalUsersRoles(state, data) {
      state.internalUserRoles = data;
    },
    setClientUsersRoles(state, data) {
      state.clientUserRoles = data;
    },
    setAgencyUsersRoles(state, data) {
      state.agencyUserRoles = data;
    },
    setCountries(state, data) {
      state.countries = data;
    },
    setTimezones(state, data) {
      state.timezones = data;
    },
    setMe(state, data) {
      state.me = data;
    },
    setSurveyTypes(state, data) {
      state.surveyTypes = data;
    },
    setMaritalStatuses(state, data) {
      state.maritalStatuses = data;
    },
    setInterfaceLanguages(state, data) {
      state.interfaceLanguages = data;
    },
    setSupportSpecifications(state, data) {
      state.supportSpecifications = data;
    },
    setSupportTimelines(state, data) {
      state.supportTimelines = data;
    },
    setEnums(state, data) {
      state.enums = data;
    },

  },
  actions: {
    retrieveForeignerAuditEvents(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getForeignerAuditEvents().then((data) => {
          context.commit('setForeignerAuditEvents', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveAdminAuditEvents(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getAdminAuditEvents().then((data) => {
          context.commit('setAdminAuditEvents', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveAuditSeverities(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getAuditSeverities().then((data) => {
          context.commit('setAuditSeverities', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    retrieveInternalUserRoles(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getInternalUserRoles().then((data) => {
          context.commit('setInternalUsersRoles', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveClientUserRoles(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getClientUserRoles().then((data) => {
          context.commit('setClientUsersRoles', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveAgencyUserRoles(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getAgencyUserRoles().then((data) => {
          context.commit('setAgencyUsersRoles', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    prepareCountries(context) {
      /*let storedCountries = localStorage.getItem("itrack_core_countries");
      let expireDate = localStorage.getItem("itrack_core_expire");*/
      const arr = JSON.parse(localStorage.getItem('itrack_core_countries'));
      context.commit('setCountries', arr);
    },
    retrieveCountries(context) {
      return new Promise((resolve, reject) => {
        // TODO : now countries (probably?) have no pagination - one request returns them all
        const r1 = CoreDataService.getCountries(100, 1);
        const r2 = CoreDataService.getCountries(100, 3);
        const r3 = CoreDataService.getCountries(100, 2);

        Promise.all([r1, r2, r3]).then((datas) => {
          const arr = [];
          for (const a of datas) {
            for (const d of a.data.data) {
              arr.push(d);
            }
          }
          arr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          localStorage.setItem('itrack_core_countries', JSON.stringify(arr));
          context.commit('setCountries', arr);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    prepareTimezones(context) {
      /*let storedCountries = localStorage.getItem("itrack_core_countries");
      let expireDate = localStorage.getItem("itrack_core_expire");*/
      const arr = JSON.parse(localStorage.getItem('itrack_core_timezones'));
      context.commit('setTimezones', arr);
    },
    retrieveTimezones(context) {
      return new Promise((resolve, reject) => {
        const r1 = CoreDataService.getTimezones(100, 1);
        const r2 = CoreDataService.getTimezones(100, 3);
        const r3 = CoreDataService.getTimezones(100, 2);
        const r4 = CoreDataService.getTimezones(100, 4);
        const r5 = CoreDataService.getTimezones(100, 5);

        Promise.all([r1, r2, r3, r4, r5]).then((datas) => {
          const arr = [];
          for (const a of datas) {
            for (const d of a.data.data) {
              arr.push(d);
            }
          }
          arr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          localStorage.setItem('itrack_core_timezones', JSON.stringify(arr));
          context.commit('setTimezones', arr);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveMe(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getMe().then((data) => {
          context.commit('setMe', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveSurveyTypes(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getSurveyTypes().then((data) => {
          context.commit('setSurveyTypes', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveMaritalStatuses(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getMaritalStatuses().then((data) => {
          context.commit('setMaritalStatuses', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveSupportSpecifications(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getSupportSpecifications().then((data) => {
          context.commit('setSupportSpecifications', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveSupportTimelines(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getSupportTimelines().then((data) => {
          context.commit('setSupportTimelines', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveInterfaceLanguages(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getInterfaceLanguages().then((data) => {
          context.commit('setInterfaceLanguages', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveEnums(context) {
      return new Promise((resolve, reject) => {
        CoreDataService.getEnums().then((data) => {
          context.commit('setEnums', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

  },
  getters: {
    //audit things
    foreignerAuditEvents: (state) => {
      const arr = [];
      for (const c of state.foreignerAuditEvents) {
        arr.push({
          id: c.id,
          name: c.name,
        });
      }
      return arr;
    },
    ifEmptyForeignerAuditEvents: (state) => {
      return (state.foreignerAuditEvents.length === 0);
    },
    adminAuditEvents: (state) => {
      const arr = [];
      for (const c of state.adminAuditEvents) {
        arr.push({
          id: c.id,
          name: c.name,
        });
      }
      return arr;
    },
    ifEmptyAdminAuditEvents: (state) => {
      return (state.adminAuditEvents.length === 0);
    },

    auditSeverities: (state) => {
      const arr = [];
      for (const c of state.auditSeverities) {
        arr.push({
          id: c.id,
          name: c.name,
        });
      }
      return arr;
    },
    ifEmptyAuditSeverities: (state) => {
      return (state.foreignerAuditEvents.length === 0);
    },


    //roles
    internalUserRoles: (state) => {
      return state.internalUserRoles;
    },
    clientUserRoles: (state) => {
      return state.clientUserRoles;
    },
    agencyUserRoles: (state) => {
      return state.agencyUserRoles;
    },

    //genders
    userGenders: (state) => {
      return state.userGenders;
    },

    //countries
    countries: (state) => {
      const arr = [];
      for (const c of state.countries) {
        arr.push({
          id: c.id,
          name: c.name,
        });
      }
      return arr;
    },
    ifCountriesEmpty: (state) => {
      return state.countries.length === 0;
    },
    countryById: (state) => (id) => {
      return state.countries.find((el) => el.id === id);
    },
    citizenshipById: (state) => (id) => {
      return state.countries.find((el) => el.id === id)['code_alpha3'];
    },
    timezones: (state) => {
      return state.timezones;
    },
    dialCodes: (state) => {
      const arr = [];
      for (const d of state.countries) {
        arr.push({
          id: d.id,
          code: d.dial_code,
          codePlus: '+ ' + d.dial_code + ' (' + d.code_alpha3 + ')',
        });
      }
      return arr;
    },

    checkDialCode: (state, code) => {
      const codeInt = parseInt(code);
      return state.countries.filter((el) => el.dial_code === codeInt).length !== 0;
    },

    //me
    me: (state) => {
      function rand() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        for (let i = 0; i < 4; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      if (state.me.notification_settings_global) {
        for (const n of state.me.notification_settings_global) {
          n.keyValue = rand();
        }
      }

      return state.me;
    },

    //statuses
    foreignerPermitDocumentStatusById: (state) => (id) => {
      return state.foreignerPermitDocumentStatus.find((el) => el.id === id);
    },

    permitStatusById: (state) => (id) => {
      return state.permitStatuses.find((el) => el.id === id);
    },
    permitStatuses: (state) => {
      return state.permitStatuses;
    },

    ifSurveyTypesEmpty: (state) => {
      return state.surveyTypes.length === 0;
    },
    surveyTypes: (state) => {
      return state.surveyTypes;
    },
    selectCaseAssessmentSurveyTypes: (state) => {
      const data = [];

      for (const u of state.surveyTypes)
        data.push({
          id: u.id,
          name: u.name,
        });

      return data;
    },
    maritalStatuses: (state) => {
      return state.maritalStatuses;
    },
    interfaceLanguages: (state) => {
      return state.interfaceLanguages;
    },
    supportSpecifications: (state) => {
      return state.supportSpecifications;
    },
    supportTimelines: (state) => {
      return state.supportTimelines;
    },
  },
});
