import Vue from 'vue';
import Vuex from 'vuex';

import FaqService from '../../services/faq/FaqService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    faqSections: [],
  },
  mutations: {
    setFaqSections(state, data) {
      state.faqSections = data.data;
    },
  },
  actions: {
    retrieveFaqSections(context, params) {
      FaqService.getFaqList()
        .then((data) => {
          context.commit('setFaqSections', data.data);
        });
    },
  },
  getters: {
    faqSections: (state) => {
      const data = [];

      for (const u of state.faqSections) {
        data.push({
          id: u.id,
          name_en: u.name_en,
          name_ru: u.name_ru,
          short_name: u.short_name,
          human_order: u.human_order,
          enabled: u.enabled,
          date_modified: u.date_modified,
          queries: u.queries,
        });
      }

      return data;
    },
    ifEmpty: (state) => {
      return state.faqSections.length === 0;
    },
  },
});
