<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    persistent
    @keydown.esc="$emit('close')"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('foreigners.basic_info.notifications_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex
              v-if="notifications.length > 0"
              text-center
              xs12
            >
              <v-checkbox
                v-for="notification in notifications"
                :key="notification.notification_type"
                v-model="notification.enabled"
                :disabled="disabled"
                :label="$capitalize(notification.notification_type_name)"
                dense
                hide-details
                style="width: 100%"
                @change="(value) => edit(notification, value)"
              />
            </v-flex>
            <v-flex xs12>
              <v-alert
                v-if="error.length > 0"
                type="error"
                v-html="foreignerDeleteError"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :color="$appColours.button.close"
          outlined
          text
          @click="$emit('close')"
        >
          {{ $t('common.actions.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ForeignersService from '../../../services/foreigners/ForeignersService';

export default {
  name: 'ForeignerBasicInfoNotificationsDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: false,
      notifications: [],
      disabled: false,
    };
  },

  async mounted() {
    await this.refreshNotifications();
    const showNotifications = window.localStorage.getItem('itrack_for_info_notifications');

    if (showNotifications) {
      this.$emit('open');
      window.localStorage.removeItem('itrack_for_info_notifications');
    }
  },

  methods: {
    refreshNotifications() {
      return ForeignersService.getCustomNotifications(this.foreigner.id).then((data) => {
        this.notifications = data.data.data;
      });
    },
    edit(notification, newValue) {
      this.error = '';
      this.disabled = true;
      const final = JSON.parse(JSON.stringify(this.notifications));
      for (const f of final) {
        if (f.notification_type === notification.notification_type) {
          f.value = newValue;
        }
      }
      ForeignersService.editCustomNotifications(this.foreigner.id, final)
        .then(() => {
          this.refreshNotifications();
          this.disabled = false;
        })
        .catch((error) => {
          this.refreshNotifications();
          this.disabled = false;
          this.error = helpers.createErrorHtml(error.response.data);
        });
    },
  },
};
</script>
