import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import MessagingService from '../../services/foreignerApi/MessagingService';


export default ({
  namespaced: true,
  state: {
    dialogs: [],
    //currentMessages: [],
    currentMessages: {},
    possibleMentions: [],

    page: 1,
    next: null,
    prev: null,
    tableData: {},
  },
  mutations: {
    setDialogs(state, data) {
      state.dialogs = data.data;
    },

    setMessagesAndParams(state, d) {
      if (d.data) {
        Vue.set(state.currentMessages, d.fid, d.data.data);
        Vue.set(state.tableData, d.fid, {});
        Vue.set(state.tableData[d.fid], 'page', d.data['page']);
        Vue.set(state.tableData[d.fid], 'prev', d.data['previous']);
        Vue.set(state.tableData[d.fid], 'next', d.data['next']);
      }
    },
    addMessagesAndSetParams(state, d) {
      Vue.set(state.currentMessages, d.fid, state.currentMessages[d.fid].concat(d.data.data));
      Vue.set(state.tableData, d.fid, {});
      Vue.set(state.tableData[d.fid], 'page', d.data['page']);
      Vue.set(state.tableData[d.fid], 'prev', d.data['previous']);
      Vue.set(state.tableData[d.fid], 'next', d.data['next']);
    },
    addMessage(state, d) {
      state.currentMessages[d.fid].unshift(d.data.data);
    },
    setPossibleMentions(state, data) {
      state.possibleMentions = data.data;
    },
  },
  actions: {
    retrieveDialogs(context, params) {
      return new Promise((resolve, reject) => {
        MessagingService.getDialogs()
          .then((data) => {
            context.commit('setDialogs', data.data);
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },

    retrieveMessages(context, params) {
      return new Promise((resolve, reject) => {
        const fid = params.fid;
        MessagingService.getMessages(params).then((data) => {
          context.commit('setMessagesAndParams', {
            data: data.data,
            fid: fid,
          });
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    loadPreviousMessages(context, params) {
      return new Promise((resolve, reject) => {
        MessagingService.getMessages(params)
          .then((data) => {
            context.commit('addMessagesAndSetParams', {
              data: data.data,
              fid: params.fid,
            });
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
    sendMessage(context, params) {
      return new Promise((resolve, reject) => {
        MessagingService.sendMessage(params.fid, params.text, params.permit_id, params.recipients)
          .then((data) => {
            // //console.log("Sending 4");
            context.commit('addMessage', {
              data: data.data,
              fid: params.fid,
            });
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });

    },
    retrievePossibleMentions(context, params) {
      return new Promise((resolve, reject) => {
        MessagingService.getPossibleMentiones()
          .then((data) => {
            context.commit('setPossibleMentions', data.data);
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    dialogs: (state) => {
      return state.dialogs;
    },

    messages: (state) => {
      //console.log(state.currentMessages);
      return state.currentMessages;
    },
    tableParams: (state) => {
      /*return {
      	page: state.page,
      	prev: state.prev,
      	next: state.next,
      }*/
      return state.tableData;
    },
    possibleMentions: (state) => {
      const arr = [];
      /*for (let k in state.possibleMentions) {
      	for (let kk of state.possibleMentions[k]) {
      		arr.push(kk);
      	}
      }*/
      if (state.possibleMentions.length === 0)
        return [];
      const roleNames = state.possibleMentions.map((el) => {
        return el.role_name;
      }).sort();
      // //console.log("RoleNames", roleNames)
      for (const role of roleNames) {
        const roleRealName = role;

        arr.push({
          header: role,
        });
        let arrTmp = [];
        //console.log("Looking for", role)
        for (const elem of state.possibleMentions) {
          // //console.log("DEBUG HERE", elem)
          if (elem.role_name === role) {
            arrTmp = elem;
            break;
          }
        }
        for (const elem of arrTmp.users) {
          elem.roleLetter = roleRealName[0];
          arr.push(elem);
        }
        if (role === 'clients') {
          arr.push({
            fullname: 'All HRs',
            id: -2,
            roleLetter: roleRealName[0],
          });
        }
      }

      return arr;
    },
    mentionById: (state) => (id) => {
      const arr = [];
      for (const k of state.possibleMentions) {
        // // //console.log("K IS", k)
        for (const kk of k.users) {
          arr.push(kk);
        }
      }
      return arr.find((el) => el.id === id)['fullname'];
    },
    mentionByName: (state) => (name) => {
      const arr = [];
      for (const k of state.possibleMentions) {
        // //console.log("K IS", k)
        for (const kk of k.users) {
          arr.push(kk);
        }
      }
      // //console.log("Arr in store is", arr);
      return arr.find((el) => el.fullname === name)['id'];
    },
  },
});
