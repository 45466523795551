<template>
  <div>
    <ForeignersBasicInfoView
      v-if="viewMode === 'view'"
      :foreigner="foreigner"
      @send-activation-mail="activationMailDialog = true"
      @open-foreigner-notifications-dialog="foreignerNotificationsDialog = true"
      @open-foreigner-delete-dialog="foreignerDeleteDialog = true"
      @open-send-reset-mail-dialog="sendResetMailDialog = true"
      @open-family-dialog="openFamilyDialog"
      @edit-foreigner="viewMode = 'edit'; $emit('set-avatareditmode', 1)"
    />

    <ForeignersBasicInfoEdit
      v-else-if="viewMode === 'edit'"
      :foreigner="foreigner"
      @set-view-mode="(payload) => viewMode = payload"
      @set-avatareditmode="(payload) => $emit('set-avatareditmode', payload)"
      @refresh-foreigner="refreshForeigner"
    />


    <ForeignerBasicInfoFamilyDialog
      ref="ForeignerBasicInfoFamilyDialog"
      :foreigner="foreigner"
      @refresh-foreigner="refreshForeigner"
    />

    <ForeignerBasicInfoSendResetMailDialog
      :is-open="sendResetMailDialog"
      :foreigner="foreigner"
      @open="sendResetMailDialog = true"
      @close="sendResetMailDialog = false"
    />

    <ForeignerBasicInfoDeleteDialog
      :is-open="foreignerDeleteDialog"
      :foreigner="foreigner"
      @open="foreignerDeleteDialog = true"
      @close="foreignerDeleteDialog = false"
    />

    <ForeignerBasicInfoNotificationsDialog
      :is-open="foreignerNotificationsDialog"
      :foreigner="foreigner"
      @open="foreignerNotificationsDialog = true"
      @close="foreignerNotificationsDialog = false"
    />

    <ForeignersBasicInfoActivationMailDialog
      :is-open="activationMailDialog"
      :foreigner="foreigner"
      @close="activationMailDialog = false"
      @refresh-foreigner="refreshForeigner"
    />
  </div>
</template>

<script>
import ForeignersBasicInfoView from './ForeignersBasicInfoView';
import ForeignersBasicInfoEdit from './ForeignersBasicInfoEdit';
import ForeignersBasicInfoActivationMailDialog from './ForeignersBasicInfoActivationMailDialog';
import ForeignerBasicInfoNotificationsDialog from './ForeignerBasicInfoNotificationsDialog';
import ForeignerBasicInfoDeleteDialog from './ForeignerBasicInfoDeleteDialog';
import ForeignerBasicInfoSendResetMailDialog from './ForeignerBasicInfoSendResetMailDialog';
import ForeignerBasicInfoFamilyDialog from './ForeignerBasicInfoFamilyDialog';

export default {
  name: 'ForeignerBasicInfo',
  components: {
    ForeignersBasicInfoView,
    ForeignersBasicInfoEdit,
    ForeignersBasicInfoActivationMailDialog,
    ForeignerBasicInfoNotificationsDialog,
    ForeignerBasicInfoDeleteDialog,
    ForeignerBasicInfoSendResetMailDialog,
    ForeignerBasicInfoFamilyDialog,
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewMode: 'view', // view | edit

      foreignerDeleteDialog: false,
      foreignerNotificationsDialog: false,
      activationMailDialog: false,
      sendResetMailDialog: false,
    };
  },
  mounted() {
    this.$store.dispatch('foreigners/retrieveRelationTypes');
  },
  methods: {
    openFamilyDialog() {
      this.$refs.ForeignerBasicInfoFamilyDialog.open();
    },

    refreshForeigner({ foreigner, tab }) {
      this.$emit('refresh-foreigner', foreigner, tab);
    },
  },
};

</script>
