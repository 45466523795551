import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getPermitActionDates(page, rows, order_by) {
    // //console.log("qqqq", page, rows, order_by);
    return axios.get(`${baseURL}/permit/action_date/?page=${page}&page_size=${rows}&order_by=${order_by}`);
  },
  getPermitActionDatesDetails(id) {
    return axios.get(`${baseURL}/permit/action_date/${id}/`);
  },
  createPermitActionDate(obj) {
    return axios.post(`${baseURL}/permit/action_date/`, obj);
  },
  editPermitActionDate(obj, id) {
    return axios.patch(`${baseURL}/permit/action_date/${id}/`, obj);
  },
  deletePermitActionDate(id) {
    return axios.delete(`${baseURL}/permit/action_date/${id}/`);
  },
};
