import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('close')}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.notifications_dialog.title'))+" ")])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"wrap":""}},[(_vm.notifications.length > 0)?_c(VFlex,{attrs:{"text-center":"","xs12":""}},_vm._l((_vm.notifications),function(notification){return _c(VCheckbox,{key:notification.notification_type,staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled,"label":_vm.$capitalize(notification.notification_type_name),"dense":"","hide-details":""},on:{"change":(value) => _vm.edit(notification, value)},model:{value:(notification.enabled),callback:function ($$v) {_vm.$set(notification, "enabled", $$v)},expression:"notification.enabled"}})}),1):_vm._e(),_c(VFlex,{attrs:{"xs12":""}},[(_vm.error.length > 0)?_c(VAlert,{attrs:{"type":"error"},domProps:{"innerHTML":_vm._s(_vm.foreignerDeleteError)}}):_vm._e()],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.$appColours.button.close,"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }