import axios from 'axios';
import baseURL from '@/services/APIConfig.js';


export default {
  prepareUrl(
    page,
    rows,
    order_by,
    companyId,
    assignedTo,
    status,
    mainPermitStatus,
    includeFamily,
    ids,
    search,
    permit_obtained,
    case_assessment,
  ) {
    let afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    if (companyId !== undefined && companyId.length !== 0)
      afterUrl += `&client_company=${companyId}`;

    if (assignedTo !== undefined && assignedTo.length !== 0)
      afterUrl += `&assigned_to=${assignedTo}`;

    if (status !== undefined && status.length !== 0)
      afterUrl += `&status=${status}`;

    if (mainPermitStatus !== undefined && mainPermitStatus.length !== 0)
      afterUrl += `&permit_status=${mainPermitStatus}`;

    if (includeFamily !== undefined)
      afterUrl += `&include_family=${(includeFamily === false) ? 0 : 1}`;

    if (case_assessment !== undefined)
      afterUrl += `&case_assessment=${(case_assessment === false) ? 0 : 1}`;

    if (ids !== undefined && ids.length !== 0)
      afterUrl += `&ids=${ids}`;

    if (search !== undefined && search.length !== 0)
      afterUrl += `&search=${search}`;

    if (permit_obtained !== undefined && permit_obtained.length !== 0)
      afterUrl += `&permit_obtained=${permit_obtained}`;

    return afterUrl;
  },

  getForeigners(
    page,
    rows,
    order_by,
    companyId,
    assignedTo,
    status,
    mainPermitStatus,
    includeFamily,
    ids,
    search,
    permit_obtained,
    case_assessment,
  ) {
    const afterUrl = this.prepareUrl(
      page,
      rows,
      order_by,
      companyId,
      assignedTo,
      status,
      mainPermitStatus,
      includeFamily,
      ids,
      search,
      permit_obtained,
      case_assessment,
    );
    return axios.get(`${baseURL}/foreigner/${afterUrl}`);
  },

  getForeignersSimple(page, rows, order_by) {
    const afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;
    return axios.get(`${baseURL}/foreigner/simple/${afterUrl}`);
  },

  getForeignerDetails(id) {
    return axios.get(`${baseURL}/foreigner/${id}/`);
  },

  // FAMILY means FAMILY_MEMBER
  getForeignerFamily(id) {
    return axios.get(`${baseURL}/foreigner/${id}/family/`);
  },

  createForeignerFamily(id, obj) {
    return axios.post(`${baseURL}/foreigner/${id}/family/`, obj);
  },

  editForeignerFamily(id, fid, obj) {
    return axios.patch(`${baseURL}/foreigner/${id}/family/${fid}/`, obj);
  },

  deleteForeignerFamily(fid, rid) {
    return axios.delete(`${baseURL}/foreigner/${fid}/family/${rid}/` );
  },

  createForeigner(obj) {
    return axios.post(`${baseURL}/foreigner/`, obj);
  },

  editForeigner(obj, id) {
    return axios.patch(`${baseURL}/foreigner/${id}/`, obj);
  },

  editForeignerAvatar(id, obj) {
    const fd = new FormData();

    for (const p in obj) {
      fd.append(p, obj[p]);
    }
    return axios({
      method: 'post',
      url: `${baseURL}/foreigner/${id}/avatar/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

  deleteForeigner(id) {
    return axios.delete(`${baseURL}/foreigner/${id}/`);
  },

  getRelationsTypes() {
    return axios.get(`${baseURL}/foreigner/family/relation/`);
  },

  getHireOriginOptions() {
    return axios.get(`${baseURL}/foreigner/hire_origin/`);
  },

  //permits
  getForeignerPermits(id) {
    const afterUrl = '?page=1&page_size=15';
    return axios.get(`${baseURL}/foreigner/${id}/permit/${afterUrl}`);
  },

  editForeignerPermit(fid, pid, obj) {
    return axios.patch(`${baseURL}/foreigner/${fid}/permit/${pid}/`, obj);
  },

  addForeignerPermit(id, obj) {
    return axios.post(`${baseURL}/foreigner/${id}/permit/`, obj);
  },

  deleteForeignerPermit(fid, pid) {
    return axios.delete(`${baseURL}/foreigner/${fid}/permit/${pid}`);
  },

  getForeignerPermitsStatuses(fid, pid) {
    const afterUrl = '?page=1&page_size=100';
    return axios.get(
      `${baseURL}/foreigner/${fid}/permit/${pid}/status/${afterUrl}`,
    );
  },

  getForeignerPermitsDocuments(fid, pid) {
    const afterUrl = '?page=1&page_size=100';
    return axios.get(
      `${baseURL}/foreigner/${fid}/permit/${pid}/document/${afterUrl}`,
    );
  },

  doPermitObtain(fid, pid, fd) {
    return axios({
      method: 'post',
      url: `${baseURL}/foreigner/${fid}/permit/${pid}/achieved/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

  doEditPermitObtain(fid, pid, obj) {
    return axios.patch(`${baseURL}/foreigner/${fid}/permit/${pid}/`, obj);
  },

  doEditPermitObtainDocument(fid, pid, did, docs) {
    const fdata = new FormData();
    const f_id = fid;
    const p_id = pid;
    const d_id = did;

    let i = 1;
    for (const doc of docs) {

      if (i === 1) {
        fdata.append(`document_${d_id}`, doc);
        fdata.append(`document_name_${d_id}`, doc.name);
      } else {
        fdata.append(`document_${i}_${d_id}`, doc);
        fdata.append(`document_name_${i}_${d_id}`, doc.name);
      }
      i++;
    }

    return axios({
      method: 'patch',
      url: `${baseURL}/foreigner/${f_id}/permit/${p_id}/document/`,
      data: fdata,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

  //permit status
  editForeignerPermitStatus(fid, pid, sid, obj) {
    return axios.patch(`${baseURL}/foreigner/${fid}/permit/${pid}/status/${sid}/`, obj);
  },

  //permit documents
  editForeignerPermitDocument(arr) {
    const fd = new FormData();
    const fid = arr[0]['fid'];
    const pid = arr[0]['pid'];

    for (const doc of arr) {
      const did = doc['did'];
      const d = doc.document;

      if (d) {
        for (const k in d) {
          if (k !== 'document' && d[k] !== undefined) {
            fd.append(`${k}_${did}`, d[k]);
          }
        }

        if (d.document) {
          let i = 1;
          for (const doc of d.document) {
            if (i === 1) {
              fd.append(`document_${did}`, doc);
              fd.append(`document_name_${did}`, doc.name);
            } else {
              fd.append(`document_${i}_${did}`, doc);
              fd.append(`document_name_${i}_${did}`, doc.name);
            }
            i++;
          }
        }
      }
    }

    return axios({
      method: 'patch',
      url: `${baseURL}/foreigner/${fid}/permit/${pid}/document/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

  uploadPermitDocumentClient(fid, pid, did, obj) {
    const fd = new FormData();

    let i = 0;
    for (const f of obj) {
      if (i === 1) i++;

      if (i === 0) {
        fd.append('document', f);
        fd.append('document_name', f.name);
      } else {
        fd.append('document_' + i, f);
        fd.append('document_name_' + i, f.name);
      }

      i++;
    }

    return axios({
      method: 'patch',
      url: `${baseURL}/foreigner/${fid}/permit/${pid}/document/${did}/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

  deleteForeignerPermitDocument(fid, pid, did) {
    return axios.delete(
      `${baseURL}/foreigner/${fid}/permit/${pid}/document/${did}/file/`,
    );
  },

  deleteForeignerPermitDocumentRow(fid, pid, did) {
    return axios.delete(
      `${baseURL}/foreigner/${fid}/permit/${pid}/document/${did}/`,
    );
  },

  addCustomDocuments(fid, pid, obj) {
    return axios.post(
      `${baseURL}/foreigner/${fid}/permit/${pid}/document/`, obj,
    );
  },

  getCustomNotifications(fid) {
    return axios.get(`${baseURL}/foreigner/${fid}/notification/`);
  },

  editCustomNotifications(fid, obj) {
    return axios.patch(`${baseURL}/foreigner/${fid}/notification/`, obj);
  },

  exportForeigners(
    page,
    rows,
    order_by,
    companyId,
    assignedTo,
    isActive,
    mainPermitStatus,
    includeFamily,
    ids,
    search,
    permit_obtained,
    case_assessment,
  ) {
    const afterUrl = this.prepareUrl(
      page,
      rows,
      order_by,
      companyId,
      assignedTo,
      isActive,
      mainPermitStatus,
      includeFamily,
      ids,
      search,
      permit_obtained,
      case_assessment,
    );
    return axios({
      url: `${baseURL}/foreigner/export/xlsx/${afterUrl}`,
      method: 'GET',
      responseType: 'blob',
    });
  },
};
