<template>
  <NotificationsWrapper>
    <NotificationsToolbar
      :unique-icons="uniqueIcons"
      :per-page-value="perPageValue"
      :per-page-options="perPageOptions"
      class="mb-6"
      @change-type-filter="changeTypeFilter"
      @per-page-changed="(value) => {
        perPageValue = value;
        setFiltersToLocalStorage();
        totalRefreshNotifications();
      }"
    />

    <h2>{{ $t('notifications.title_new') }}</h2>
    <v-flex
      v-if="isLoading"
      xs12
      text-center
    />
    <v-flex
      v-else-if="unseenNotifications.length === 0"
      xs12
      text-center
    >
      {{ $t('notifications.no_notifications') }}
    </v-flex>

    <NotificationsNotification
      v-for="(notification, index) in unseenNotifications"
      :key="index"
      :notification="notification"
      :notification-icons="notificationIcons"
      :is-seen="false"
      @see-notification="seeNotification"
      @unsee-notification="unseeNotification"
      @link-unseen="(event, id) => linkUnseen(event, id)"
    />

    <v-card
      v-if="unseenData.next !== null"
      class="mt-3"
    >
      <v-card-title class="py-1">
        <v-flex
          row
          px-4
        >
          <v-flex
            xs12
            text-center
            class="pointerHover"
            @click="loadUnseenNext"
          >
            {{ $t('notifications.load_next') }}
          </v-flex>
        </v-flex>
      </v-card-title>
    </v-card>

    <br>
    <h2>{{ $t('notifications.title_seen') }}</h2>
    <v-flex
      v-if="isLoading"
      xs12
      text-center
    />
    <v-flex
      v-else-if="seenNotifications.length === 0"
      xs12
      text-center
    >
      {{ $t('notifications.no_notifications') }}
    </v-flex>

    <NotificationsNotification
      v-for="(notification, index) in seenNotifications"
      :key="index"
      :notification="notification"
      :notification-icons="notificationIcons"
      :is-seen="true"
      @see-notification="seeNotification"
      @unsee-notification="unseeNotification"
      @link-unseen="(event, id) => linkUnseen(event, id)"
    />

    <v-card
      v-if="seenData.next !== null"
      class="mt-3"
    >
      <v-card-title class="py-1">
        <v-flex
          row
          px-4
        >
          <v-flex
            xs12
            text-center
            class="pointerHover"
            @click="loadSeenNext"
          >
            {{ $t('notifications.load_next') }}
          </v-flex>
        </v-flex>
      </v-card-title>
    </v-card>
  </NotificationsWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import baseURL from '@/services/APIConfig';

import NotificationsWrapper from '@/components/notifications/NotificationsWrapper';
import NotificationsToolbar from '@/components/notifications/NotificationsToolbar';
import NotificationsNotification from '@/components/notifications/NotificationsNotification';

export default {
  name: 'Notifications',
  components: {
    NotificationsWrapper,
    NotificationsToolbar,
    NotificationsNotification,
  },
  data() {
    return {
      isLoading: true,

      seenPage: 1,
      seenRows: 5,

      unseenPage: 1,
      unseenRows: 5,

      uniqueIcons: [],
      filterTypesIds: [],

      perPageOptions: [5, 10, 15, 20],
      perPageValue: 5,

      notificationIcons: {
        0: 'icon-general-notification',
        10: 'icon-new-message',
        20: 'icon-new-permit',
        21: 'icon-new-permit',
        22: 'icon-permit-date',
        23: 'icon-permit-date',
        24: 'icon-document-status',
        25: 'icon-document-upload',
        26: 'icon-questionnaire',
        27: 'icon-assigned-foreigner',
        28: 'icon-document-status',
        29: 'icon-permit-obtained',
        30: 'icon-questionnaire',
        31: 'icon-document-upload',
        40: 'icon-document-upload',
        41: 'icon-questionnaire',
        50: 'icon-document-upload',
        1000: 'icon-permit-expires',
        1001: 'icon-action-date-without-assistance',
        1002: 'icon-permit-without-update',
      },
    };
  },
  computed: {
    ...mapGetters({
      seenNotifications: 'notifications/seenNotifications',
      unseenNotifications: 'notifications/unseenNotifications',
      seenData: 'notifications/seen_data',
      unseenData: 'notifications/unseen_data',
      me: 'coreData/me',
    }),
  },
  async created() {
    const response = await axios.get(`${baseURL}/notification/type/`, {
      token: window.localStorage.getItem('token'),
    });

    let uniqueIcons = [...new Set(Object.values(this.notificationIcons))];
    uniqueIcons = uniqueIcons.map((uniqueIcon) => {
      const ids = Object.entries(this.notificationIcons)
        .filter(([key, icon]) => icon === uniqueIcon)
        .map(([key]) => Number(key));

      const item = response.data.data.filter((item) => ids.includes(item.id));

      return {
        icon: uniqueIcon,
        ids,
        selected: true,
        titles: item.map(({ name }) => this.$capitalize(name)),
        roles: [...new Set(item.map(({ roles }) => roles).flat())],
      };
    });
    this.uniqueIcons = uniqueIcons.filter(({ roles }) => roles.includes(this.me.role));

    this.filterTypesIds = this.getFiltersFromLocalStorage() || response.data.data.map(({ id }) => id);

    this.uniqueIcons = this.uniqueIcons.map((uniqueIcon) => {
      uniqueIcon.selected = this.filterTypesIds.some((id) => uniqueIcon.ids.includes(Number(id)));
      return uniqueIcon;
    });

    this.totalRefreshNotifications();
  },
  methods: {
    linkUnseen(event, nid) {
      event.stopPropagation();
      this.seeNotification(nid);
    },
    refreshNotificationsCount() {
      this.$store.dispatch('notifications/retrieveUnseenNotificationsCount');
    },
    seeNotification(nid) {
      this.$store
        .dispatch('notifications/seeNotification', { nid })
        .then(() => {
          this.totalRefreshNotifications();
          this.refreshNotificationsCount();
        });
    },
    unseeNotification(nid) {
      this.$store
        .dispatch('notifications/unseeNotification', { nid })
        .then(() => {
          this.totalRefreshNotifications();
          this.refreshNotificationsCount();
        });
    },
    loadUnseenNext() {
      this.unseenPage++;
      this.$store.dispatch('notifications/loadUnseenNext', {
        page: this.unseenPage,
        rows: this.perPageValue,
        types: this.filterTypesIds,
      });
      this.refreshNotificationsCount();
    },
    loadSeenNext() {
      this.seenPage++;
      this.$store.dispatch('notifications/loadSeenNext', {
        page: this.seenPage,
        rows: this.perPageValue,
        types: this.filterTypesIds,
      });
      this.refreshNotificationsCount();
    },
    refreshNotifications() {
      this.$store.dispatch('notifications/retrieveSeenNotifications', {
        page: this.seenPage,
        rows: this.seenRows,
      });
      this.$store.dispatch('notifications/retrieveUnseenNotifications', {
        page: this.unseenPage,
        rows: this.unseenRows,
      });
      this.refreshNotificationsCount();
    },
    async totalRefreshNotifications() {
      this.seenPage = 1;
      this.seenRows = this.perPageValue;
      this.unseenPage = 1;
      this.unseenRows = this.perPageValue;

      const requests = [
        this.$store.dispatch('notifications/retrieveSeenNotifications', {
          page: this.seenPage,
          rows: this.seenRows,
          types: this.filterTypesIds,
        }),
        this.$store.dispatch('notifications/retrieveUnseenNotifications', {
          page: this.unseenPage,
          rows: this.unseenRows,
          types: this.filterTypesIds,
        }),
      ];

      await Promise.all(requests);

      this.isLoading = false;

      this.refreshNotificationsCount();
    },
    changeTypeFilter(payload) {
      if (payload.selected) {
        this.filterTypesIds = this.filterTypesIds.filter((item) => !payload.ids.includes(Number(item)));
      } else {
        this.filterTypesIds.push(...payload.ids);
      }
      payload.selected = !payload.selected;

      this.setFiltersToLocalStorage();
      this.totalRefreshNotifications();
    },
    setFiltersToLocalStorage() {
      const payload = {
        perPageValue: this.perPageValue,
        filterTypesIds: this.filterTypesIds,
      };
      window.localStorage.setItem('itrack_notifs_filters', JSON.stringify(payload));
    },
    getFiltersFromLocalStorage() {
      let payload = window.localStorage.getItem('itrack_notifs_filters');
      if (payload) {
        payload = JSON.parse(payload);
        this.perPageValue = payload.perPageValue || this.perPageValue;

        if (payload.filterTypesIds) {
          return payload.filterTypesIds;
        }
      }
      return false;
    },
  },
};
</script>
