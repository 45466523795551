import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import PermitStatusesService from '../../services/administration/PermitStatusesService';
import PermitDocumentsService from '../../services/administration/PermitDocumentsService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    statuses: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setStatusesAndParams(state, data) {
      // //console.log("Setting", data);
      state.statuses = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
      // //console.log("new users:", state.users)
    },
  },
  actions: {
    retrieveStatuses(context, params) {
      // //console.log("DDD", params);
      PermitStatusesService.getPermitStatuses(params.page, params.rows, params.order_by).then((data) => {
        // //console.log("DATA: ", data.data);
        // //console.log("DATA next: ", data.data.next);
        context.commit('setStatusesAndParams', data.data);
      });
    },
    createStatus(context, obj) {
      return PermitStatusesService.createPermitStatus(obj);
    },
    editStatus(context, obj) {
      return PermitStatusesService.editPermitStatus(obj.status, obj.id);
    },
    deleteStatus(context, id) {
      return PermitStatusesService.deletePermitStatus(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.statuses) {
        data.push({
          id: u.id,
          name: u.name,
          description: u.description,
          action_dates: u.action_dates,
          count_of_dates: u.action_dates.length,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.statuses.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.statuses) {
        arr.push({
          name: u.name,
          id: u.id,
        });
      }
      arr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return arr;
    },
    selectOptionsShorten: (state) => {
      const arr = [];
      for (const u of state.statuses) {
        let n = u.name;
        if (n.length > 20)
          n = n.slice(0, 20) + '...';
        arr.push({
          shortname: n,
          fullname: u.name,
          id: u.id,
        });
      }
      //console.log("Arr before sort", arr);
      arr.sort((a, b) => (a.shortname > b.shortname) ? 1 : ((b.shortname > a.shortname) ? -1 : 0));
      //console.log("Arr after sort", arr);
      return arr;
    },
    statusById: (state) => (id) => {
      return state.statuses.find((el) => el.id === id);
    },
  },

});
