<template>
  <v-container
    fluid
    fill-height
    @keydown.enter="sendRequest"
  >
    <v-layout
      align-center
      justify-center
      style="margin-top: 8vh;"
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('main.recovery.title_first') }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                prepend-icon="person"
                name="username"
                :label="$t('common.fields.login')"
                type="text"
              />
            </v-form>
          </v-card-text>
          <v-alert
            v-if="errors"
            class="mx-2"
            :dense="true"
            type="error"
          >
            {{ errors.message }}
          </v-alert>
          <v-alert
            v-if="success.length != 0"
            class="mx-2"
            :dense="true"
            type="success"
          >
            {{ success }}
          </v-alert>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="disabledSaveButton"
              @click="sendRequest"
            >
              {{ $t('common.actions.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import AuthService from '../services/AuthService';
import helpers from '../helpers';
export default {
  name: 'PassRecoveryFirst',
  data() {
    return {
      username: '',
      errors: null,
      success: '',
      disabledSaveButton: false,
    };
  },
  methods: {
    sendRequest() {
      this.disabledSaveButton = true;
      AuthService.resetUserPassword(this.username)
        .then((data) => {
          this.success = 'Email was sent';
          setTimeout((_) => {
            location.replace('/login');
          }, 5000);
        })
        .catch((e) => {
          this.error = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
