<template>
  <v-card
    class="mt-3"
  >
    <v-card-title
      class="pointerHover py-1"
      style="font-size: 12pt"
      @click="() => {
        $emit('see-notification', notification.id);
        $router.push({ path: notification.link });
      }"
    >
      <v-flex
        row
        px-4
      >
        <v-flex
          xs1
          class="d-flex"
          align-center
        >
          <component
            :is="notificationIcons[notification.notification_type]"
            class="grey--text text--darken-1"
          />
        </v-flex>
        <v-flex xs4>
          {{ $capitalize(notification.notification_type_name) }}
        </v-flex>
        <v-flex xs4>
          <div
            v-if="notification.description.length > 45"
            :title="notification.description"
          >
            {{ notification.description.slice(0, 45) + '...' }}
          </div>
          <div v-else>
            {{ notification.description }}
          </div>
        </v-flex>
        <v-flex xs2>
          {{ notification.date_created }}
        </v-flex>
        <v-flex
          xs1
          text-right
        >
          <v-icon
            v-if="isSeen"
            :title="$t('notifications.mark_unseen')"
            class="mr-3"
            @click.stop="$emit('unsee-notification', notification.id)"
          >
            mdi-eye-off-outline
          </v-icon>
          <v-icon
            v-else
            :title="$t('notifications.mark_seen')"
            class="mr-3"
            @click.stop="$emit('see-notification', notification.id)"
          >
            mdi-eye-outline
          </v-icon>
          <a
            target="_blank"
            :href="notification.link"
            class="newWindowIcon"
          >
            <v-icon
              :title="$t('notifications.open_new_tab')"
              @click.stop="$emit('link-unseen', { event: $event, id: notification.id })"
            >
              mdi-open-in-new
            </v-icon>
          </a>
        </v-flex>
      </v-flex>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'NotificationsNotification',

  props: {
    notification: {
      type: Object,
      required: true,
    },
    notificationIcons: {
      type: Object,
      required: true,
    },
    isSeen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.newWindowIcon {
  text-decoration: none;
}
</style>
