<template>
  <v-app :id="(!tokenVerified) ? 'worldMap' : ''">
    <v-container
      v-if="tokenVerified && interceptorsPrepared && userDataLoaded"
      fluid
    >
      <v-layout v-if="showOnlyMessaging && foreignerMessaging !== null">
        <ForeignerMessaging
          :foreigner="foreignerMessaging"
          :fullscreen="true"
          style="width: 100%"
        />
      </v-layout>
      <v-layout
        v-else-if="showOnlyMessaging === false"
        wrap
      >
        <v-content>
          <AppNav />
          <router-view
            :key="$route.fullPath"
            class="page"
          />
        </v-content>
      </v-layout>
    </v-container>
    <v-container v-else-if="ifNoToken">
      <v-layout wrap>
        <v-content>
          <router-view
            :key="$route.fullPath"
            class="page"
          />
        </v-content>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import AppNav from '@/components/AppNav.vue';
import { authComputed } from '@/vuex/helpers.js';
import helpers from '@/helpers';
import ForeignerMessaging from '@/components/foreigners/ForeignerMessaging';
import CoreDataService from '@/services/CoreDataService';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    ForeignerMessaging,
    AppNav,
  },
  data() {
    return {
      tokenVerified: false,
      userDataLoaded: false,
      interceptorsPrepared: false,
      ifNoToken: window.localStorage.getItem('token') == null,
      LS_VERSION: process.env.VUE_APP_LS_VERSION,

      showOnlyMessaging: false,
      foreignerMessaging: null,
    };
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
      me: 'coreData/me',
    }),
  },
  async created() {
    if (!window.localStorage.getItem('locale_code')) {
      window.localStorage.setItem('locale_code', 'en');
      const langData = this.$i18n.messages.en;
      for (const k in langData) {
        if (k !== 'main.login.a3') {
          langData[k] = this.$sanitize(langData[k]);
        }
      }
      window.localStorage.setItem('locale_data', JSON.stringify(langData));
    }
    this.$i18n.setLocaleMessage(
      window.localStorage.getItem('locale_code'),
      JSON.parse(window.localStorage.getItem('locale_data')),
    );
    this.$i18n.locale = window.localStorage.getItem('locale_code');

    await this.verifyToken().catch(() => {});
    this.interceptorsPrepared = true;

    axios.interceptors.request.use(
      (config) => {
        config.headers['User-TZ'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const token = window.localStorage.getItem('token');
        if (token !== null) config.headers.Authorization = `JWT ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        this.$refreshCounter = this.$refreshCounter + 1;
        if (this.$refreshCounter > this.$refreshCounterLimit) {
          this.$store.dispatch('auth/logout');
        }
        if (error.message === 'Network Error') {
          return Promise.reject(error);
        }
        if (
          typeof error.response === 'undefined' &&
          error.message !== 'Network Error'
        ) {
          return this.$store
            .dispatch('auth/refreshToken', { location: 'A' })
            .then(({ data }) => {
              window.localStorage.setItem('token', data.token);
              axios.defaults.headers.common['Authorization'] = `JWT ${data.token}`;
              return axios.request(error.config);
            })
            .catch(() => {
              this.$refreshCounter++;
              if (this.$refreshCounter === this.$refreshCounterLimit) {
                this.$store.dispatch('auth/logout');
              }
            });
        }
        if (error.response.status === 401 && localStorage.getItem('token') !== null) {
          return this.$store
            .dispatch('auth/refreshToken', { location: 'B' })
            .then(({ data }) => {
              this.$refreshCounter = 0;
              localStorage.setItem('token', data.token);
              axios.defaults.headers.common['Authorization'] = `JWT ${data.token}`;
              return axios.request(error.config);
            })
            .catch(() => {
              this.$refreshCounter++;
              this.$store.dispatch('auth/logout');
            });
        }

        if(error.response.status === 400 && error.response.data.non_field_errors[0] === 'Refresh has expired.') {
          this.$store.dispatch('auth/logout');
        }
        return Promise.reject(error);
      },
    );

    this.interceptorsPrepared = true;

    const lsVersionNew = parseInt(this.LS_VERSION);
    let lsVersion = window.localStorage.getItem('itrack_ls_version');

    if (lsVersion === null) {
      helpers.removeLocalStorageFiltersRpp();
      window.localStorage.setItem('itrack_ls_version', String(lsVersionNew));
    } else {
      lsVersion = parseInt(lsVersion);
      if (lsVersion < lsVersionNew) {
        helpers.removeLocalStorageFiltersRpp();
        window.localStorage.setItem('itrack_ls_version', String(lsVersionNew));
      }
    }

    if (this.$store.getters['auth/loggedIn']) {
      await this.$store.dispatch('coreData/retrieveMe');
      await this.$store.dispatch('coreData/retrieveInterfaceLanguages');
      this.setLang();
      this.userDataLoaded = true;
    }

    const urls = window.location.href.split('/');
    if (urls[urls.length - 2] === 'messaging' && urls[urls.length - 1] === 'window') {
      this.showOnlyMessaging = true;
      const id = urls[urls.length - 3];

      this.$store
        .dispatch('foreigners/getForeignerDetails', id)
        .then((data) => {
          const obj = data.data.data;
          this.foreignerMessaging = {
            id: obj.id,
            avatar_url: obj.avatar_url,
            first_name: obj.first_name,
            last_name: obj.last_name,
            is_active: obj.is_active,
            contact_email: obj.email,
            phone_primary: obj.phone_primary,
            profile: obj.profile,
            date_modified: obj.date_modified,
            modified_by: obj.modified_by,
            modified_by_avatar_url: obj.modified_by_avatar_url,
            main_permit_expiration_date: obj.main_permit_expiration_date,
            family: obj.family,
            reference_number: obj.reference_number,
            created_by: obj.created_by,
          };
        });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async setLang() {
      const data = await Promise.all([
        CoreDataService.getMe(),
        CoreDataService.getCurrentLanguage(),
      ]);

      const code = this.interfaceLanguagesOptions.find((el) => el.id === data[0].data.data.interface_language).name_text;

      window.localStorage.setItem('locale_code', code);
      const langData = data[1].data.data;
      for (const k in langData) {
        if (k !== 'main.login.a3') {
          langData[k] = this.$sanitize(langData[k]);
        }
      }
      window.localStorage.setItem('locale_data', JSON.stringify(langData));
      this.$i18n.setLocaleMessage(
        code,
        JSON.parse(window.localStorage.getItem('locale_data')),
      );
      this.$i18n.locale = code;
    },
    async verifyToken() {
      return new Promise((resolve, reject) => {
        if (window.localStorage.getItem('token') !== null) {
          this.$store
            .dispatch('auth/verifyToken')
            .then(() => {
              this.tokenVerified = true;
              resolve();
            })
            .catch(() => {
              this.$store
                .dispatch('auth/refreshToken', { location: 'C' })
                .then(({ data }) => {
                  this.$refreshCounter = 0;
                  window.localStorage.setItem('token', data.token);
                  this.tokenVerified = true;
                  resolve();
                })
                .catch((err) => {
                  this.$refreshCounter = this.$refreshCounter + 1;
                  if (this.$refreshCounter > this.$refreshCounterLimit) {
                    this.$store.dispatch('auth/logout');
                  }
                  if (
                    err.response.status === 403 &&
                    err.response.data.message === 'Incorrect token'
                  ) {
                    this.$store.dispatch('auth/logout');
                  }
                });
            });
        } else {
          reject();
        }
      });
    },
  },
};
</script>

<style>
.v-data-footer__pagination {
  display: none;
}

.header_sortable_true {
  cursor: pointer;
}

.moveable:hover {
  cursor: move;
}

.v-stepper__step {
  width: 8%;
  padding: 0;
}

.hideInputControl .v-input__control {
  display: none;
}

.hideInputControl .v-input__icon {
  /*margin-top: 16px;*/
}

.hideInputControl .v-file-input {
  display: inline;
}

.hideInputControl .fileName {
  vertical-align: super;
}

.noHide {
  display: flex;
}

.pointerHover:hover {
  cursor: pointer;
}

.blackInputText input {
  color: black !important;
}

.tableSmallFont td {
  font-size: 12px;
}

.flex0-5 {
  -ms-flex-preferred-size: 4.16666%;
  flex-basis: 4.16666%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 4.16666%;
}

.flex1-5 {
  -ms-flex-preferred-size: 12.5%;
  flex-basis: 12.5%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 12.5%;
}

.flex1-5 {
  -ms-flex-preferred-size: 20.75%;
  flex-basis: 20.75%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 20.75%;
}

.flex10-5 {
  -ms-flex-preferred-size: 79.05%;
  flex-basis: 79.05%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 79.05%;
}

.flex11-5 {
  -ms-flex-preferred-size: 95.83333%;
  flex-basis: 95.83333%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 95.83333%;
}

.flex11-3 {
  -ms-flex-preferred-size: 94.1666%;
  flex-basis: 94.1666%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 94.1666%;
}

#msgFlex .v-menu__content {
  left: 1vw !important;
}

#msgFlex .v-menu__content .v-select-list {
  margin-top: -1.5vh;
}

.active_item {
  background-color: #f2edf1;
}

#questionnaire .v-text-field__details {
  display: none;
}

#questionnaire .v-input__slot {
  margin-bottom: 0;
}

.avatar-mask {
  -webkit-mask-image: url("./assets/avatar_edit1.png");
  mask-image: url("./assets/avatar_edit1.png");
  -webkit-mask-size: auto;
  mask-size: auto;
}

#worldMap {
  background: url("./assets/worldmap3.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

#messagingComponent .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}
</style>
