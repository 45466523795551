<template>
  <v-flex md12>
    <v-toolbar color="white accent-4">
      <v-flex
        xs7
        row
      >
        <div class="d-flex">
          <v-flex
            v-for="(icon, index) in uniqueIcons"
            :key="index"
            justify-center
            align-center
          >
            <span
              class="pointerHover d-flex mx-4"
              :title="icon.titles.map((title) => $capitalize(title)).join('. ')"
              @click="$emit('change-type-filter', icon)"
            >
              <component
                :is="icon.icon"
                :class="icon.selected ? 'blue--text lighten-1' : 'grey--text text--darken-1'"
              />
            </span>
          </v-flex>
        </div>
      </v-flex>
      <v-flex
        offset-xs1
        xs4
      >
        <v-spacer />
        <v-flex class="mt-4 offset-xs7 xs3">
          <v-autocomplete
            :value="perPageValue"
            :title="$t('common.rows_per_page')"
            :items="perPageOptions"
            prepend-icon="none"
            dense
            @change="(value) => $emit('per-page-changed', value)"
          />
        </v-flex>
        <v-btn
          fixed
          small
          depressed
          :color="$appColours.button.close"
          outlined
          dark
          top
          right
          class="mr-2"
          @click="$router.push('/me')"
        >
          <v-icon>mdi-settings</v-icon>
        </v-btn>
        <v-fab-transition />
      </v-flex>
    </v-toolbar>
  </v-flex>
</template>

<script>
export default {
  name: 'NotificationsToolbar',
  props: {
    uniqueIcons: {
      type: Array,
      required: true,
    },
    perPageValue: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
svg {
  display: block;
}
</style>
