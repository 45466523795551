import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import PermitActionDatesService
from '../../services/administration/PermitActionDatesService';
import PermitStatusesService from '../../services/administration/PermitStatusesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    actionDates: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setActionDatesAndParams(state, data) {
      state.actionDates = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
  },
  actions: {
    retrieveActionDates(context, params) {
      PermitActionDatesService.getPermitActionDates(params.page, params.rows, params.order_by).then((data) => {
        context.commit('setActionDatesAndParams', data.data);
      }).catch((err) => {
        if (err.response.status === 401) {}
      });
    },
    createActionDate(context, obj) {
      return PermitActionDatesService.createPermitActionDate(obj);
    },
    editActionDate(context, obj) {
      return PermitActionDatesService.editPermitActionDate(obj.actionDate, obj.id);
    },
    deleteActionDate(context, id) {
      return PermitActionDatesService.deletePermitActionDate(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.actionDates) {
        data.push({
          id: u.id,
          name: u.name,
          description: u.description,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    getById: (state) => (id) => {
      return state.actionDates.find((el) => el.id === id);
    },
    ifEmpty: (state) => {
      return state.actionDates.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.actionDates) {
        arr.push({
          name: u.name,
          id: u.id,
        });
      }
      arr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return arr;
    },
  },
});
