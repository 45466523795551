<template>
  <div>
    <v-flex>
      <v-flex
        md12
        mt-2
      >
        <v-flex
          md12
          mt-2
        >
          <v-toolbar
            class="elevation-1"
            color="white accent-4"
          >
            <v-flex
              xs2
              ml-3
            >
              <v-btn
                v-if="tableParams.prev !== null"
                text
                small
                icon
                color="black"
                @click="prevPage"
              >
                <v-icon>arrow_back_ios</v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                small
                icon
                color="grey"
                disabled
                @click="prevPage"
              >
                <v-icon>arrow_back_ios</v-icon>
              </v-btn>
              <span class="mx-1">
                {{ tableParams.page }}
              </span>
              <v-btn
                v-if="tableParams.next !== null"
                text
                small
                icon
                color="black"
                @click="nextPage"
              >
                <v-icon>arrow_forward_ios</v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                small
                icon
                color="grey"
                disabled
                @click="nextPage"
              >
                <v-icon>arrow_forward_ios</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs2>
              <div class="mt-6">
                <v-autocomplete
                  v-model="filterValues.action_user"
                  :items="users"
                  :label="$t('adm.audit.filter.by_user')"
                  prepend-icon="none"
                  item-text="fullname"
                  item-value="id"
                  @change="refresh"
                />
              </div>
            </v-flex>
            <div
              v-if="filterValues.action_user !== ''"
              class="pointerHover"
              @click="filterValues.action_user = '' ; refresh()"
            >
              <v-icon :color="$appColours.button.delete">
                mdi-close
              </v-icon>
            </div>

            <v-flex xs2>
              <div class="mt-6 ml-6">
                <v-autocomplete
                  v-model="filterValues.action_name"
                  :items="filterValuesActionName"
                  :label="$t('adm.audit.filter.action')"
                  item-text="name"
                  item-value="id"
                  @change="refresh"
                />
              </div>
            </v-flex>
            <div
              v-if="filterValues.action_name !== ''"
              class="pointerHover"
              @click="filterValues.action_name = '' ; refresh()"
            >
              <v-icon :color="$appColours.button.delete">
                mdi-close
              </v-icon>
            </div>

            <v-flex xs2>
              <div class="mt-6 ml-6">
                <v-autocomplete
                  v-model="filterValues.severity"
                  :items="filterValuesSeverity"
                  :label="$t('adm.audit.filter.severity')"
                  item-text="name"
                  item-value="id"
                  @change="refresh"
                />
              </div>
            </v-flex>
            <div
              v-if="filterValues.severity !== ''"
              class="pointerHover"
              @click="filterValues.severity = '' ; refresh()"
            >
              <v-icon :color="$appColours.button.delete">
                mdi-close
              </v-icon>
            </div>

            <v-flex xs2>
              <div class="mt-6 ml-6">
                <v-menu
                  v-model="actionDateFromPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterValues.action_date_from"
                      :label="$t('common.fields.date_from')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filterValues.action_date_from"
                    :first-day-of-week="1"
                    @change="refresh"
                    @input="actionDateFromPicker = false"
                  />
                </v-menu>
              </div>
            </v-flex>
            <div
              v-if="filterValues.action_date_from !== ''"
              class="pointerHover"
              @click="filterValues.action_date_from = '' ; refresh()"
            >
              <v-icon :color="$appColours.button.delete">
                mdi-close
              </v-icon>
            </div>

            <v-flex xs2>
              <div class="mt-6 ml-6">
                <v-menu
                  v-model="actionDateToPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filterValues.action_date_to"
                      :label="$t('common.fields.date_until')"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filterValues.action_date_to"
                    :first-day-of-week="1"
                    @change="refresh"
                    @input="actionDateToPicker = false"
                  />
                </v-menu>
              </div>
            </v-flex>
            <div
              v-if="filterValues.action_date_to !== ''"
              class="pointerHover"
              @click="filterValues.action_date_to = '' ; refresh()"
            >
              <v-icon :color="$appColours.button.delete">
                mdi-close
              </v-icon>
            </div>
          </v-toolbar>
        </v-flex>

        <v-data-table
          mt-2
          :headers="recordsTableHeaders"
          :items="tableData"
          :items-per-page.sync="rowsOnPage"
          class="elevation-1 tableSmallFont mt-2"
          :page.sync="tablePage"
          hide-default-header
          :footer-props="{
            'items-per-page-options': [5, 10, 25, 50, 100],
            'disable-pagination': true,
            'items-per-page-all-text': '',
            'show-current-page': false,
            firstIcon: 'null',
            lastIcon: 'null',
            prevIcon: 'null',
            nextIcon: 'null',
          }"
          @update:items-per-page="refresh"
        >
          <template
            slot="header"
          >
            <tr>
              <th
                v-for="(h, index) in recordsTableHeaders"
                :key="index"
                :class="'header_sortable_'+h.sortable"
                @click="sorting(h.value, h.sortable)"
              >
                <v-icon
                  v-if="sortBy === h.value && sortDesc === true"
                  small
                >
                  arrow_downward
                </v-icon>
                <v-icon
                  v-if="sortBy === h.value && sortDesc === false"
                  small
                >
                  arrow_upward
                </v-icon>
                {{ $t(h.text) }}
              </th>
            </tr>
          </template>
          <template
            slot="item.action_name"
            slot-scope="props"
          >
            {{ props.item.action_name }}
          </template>
          <template
            slot="item.value_old"
            slot-scope="props"
          >
            <div v-html="formatObjToTreeView(props.item.value_old)" />
          </template>
          <template
            slot="item.value_new"
            slot-scope="props"
          >
            <div v-html="formatObjToTreeView(props.item.value_new)" />
          </template>
          <template
            slot="item.severity"
            slot-scope="props"
          >
            <div
              v-if="props.item.severity === 'DEBUG'"
              class="grey--text text--darken-1"
            >
              {{ props.item.severity }}
            </div>
            <div
              v-else-if="props.item.severity === 'INFO'"
              class="green--text text--darken-1"
            >
              {{ props.item.severity }}
            </div>
            <div
              v-else-if="props.item.severity === 'WARNING'"
              class="yellow--text text--darken-1"
            >
              {{ props.item.severity }}
            </div>
            <div
              v-else-if="props.item.severity === 'ERROR'"
              class="red--text text--darken-1"
            >
              {{ props.item.severity }}
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ForeignersAuditLog',
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    },
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tablePage: 0,
      rowsOnPage: 10,
      sortDesc: false,
      sortBy: 'action_date',

      filterValues: {
        action_user: '',
        action_name: '',
        severity: '',
        action_date_from: '',
        action_date_to: '',
      },
      actionDateFromPicker: false,
      actionDateToPicker: false,

      recordsTableHeaders: [
        {
          text: this.$t('common.fields.date'),
          align: 'left',
          sortable: true,
          value: 'action_date',
          width: '20px',
        },
        {
          text: this.$t('adm.audit.filter.action'),
          align: 'left',
          sortable: true,
          value: 'action_name',
        },
        {
          text: this.$t('adm.audit.filter.by_user'),
          align: 'left',
          sortable: true,
          value: 'action_user',
        },
        {
          text: this.$t('adm.audit.table_header.old_value'),
          align: 'left',
          sortable: false,
          value: 'value_old',
        },
        {
          text: this.$t('adm.audit.table_header.new_value'),
          align: 'left',
          sortable: false,
          value: 'value_new',
        },
        {
          text: this.$t('adm.audit.filter.severity'),
          align: 'left',
          sortable: true,
          value: 'severity',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      auditLog: 'foreignerAuditLog/records',
      tableData: 'foreignerAuditLog/tableData',
      tableParams: 'foreignerAuditLog/tableParams',
      internalUsers: 'internalUsers/users',
      clientsUsers: 'clientsUsers/selectOptions',
      filterValuesActionName: 'coreData/foreignerAuditEvents',
      filterValuesSeverity: 'coreData/auditSeverities',
    }),
    users() {
      return this.clientsUsers
        .concat(this.internalUsers)
        .concat([
          {
            id: this.foreigner.id,
            fullname:
              this.foreigner.first_name + ' ' + this.foreigner.last_name,
          },
        ]);
    },

    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    this.$store.dispatch('foreignerAuditLog/retrieveRecords', {
      fid: this.foreigner.id,
      page: 1,
      rows: 10,
      order_by: '-' + this.orderBy,
    });

    if (this.$store.getters['clientsUsers/ifEmpty']) {
      this.$store.dispatch('clientsUsers/retrieveUsers', {
        page: 1,
        rows: 100,
        order_by: 'fullname',
      });
    }
    if (this.$store.getters['internalUsers/ifEmpty']) {
      this.$store.dispatch('internalUsers/retrieveUsers', {
        page: 1,
        rows: 100,
        order_by: 'fullname',
      });
    }

    if (this.$store.getters['coreData/ifEmptyForeignerAuditEvents']) {
      this.$store.dispatch('coreData/retrieveForeignerAuditEvents', {
        page: 1,
        rows: 100,
        order_by: 'name',
      });
    }
    if (this.$store.getters['coreData/ifEmptyAuditSeverities']) {
      this.$store.dispatch('coreData/retrieveAuditSeverities', {
        page: 1,
        rows: 100,
        order_by: 'name',
      });
    }
  },
  methods: {
    nextPage: function() {
      const obj = {
        fid: this.foreigner.id,
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        action_user: this.filterValues.action_user,
        action_name: this.filterValues.action_name,
        severity: this.filterValues.severity,
      };
      if (this.filterValues.action_date_from) {
        obj.action_date_from =
          this.parseDate(this.filterValues.action_date_from) + ' 00:00:00';
      }
      if (this.filterValues.action_date_to) {
        obj.action_date_to =
          this.parseDate(this.filterValues.action_date_to) + ' 23:59:59';
      }
      this.$store.dispatch('foreignerAuditLog/retrieveRecords', obj);
    },
    prevPage: function() {
      const obj = {
        fid: this.foreigner.id,
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        action_user: this.filterValues.action_user,
        action_name: this.filterValues.action_name,
        severity: this.filterValues.severity,
      };
      if (this.filterValues.action_date_from) {
        obj.action_date_from =
          this.parseDate(this.filterValues.action_date_from) + ' 00:00:00';
      }
      if (this.filterValues.action_date_to) {
        obj.action_date_to =
          this.parseDate(this.filterValues.action_date_to) + ' 23:59:59';
      }
      this.$store.dispatch('foreignerAuditLog/retrieveRecords', obj);
    },
    refresh: function() {
      const obj = {
        fid: this.foreigner.id,
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        action_user: this.filterValues.action_user,
        action_name: this.filterValues.action_name,
        severity: this.filterValues.severity,
      };
      if (this.filterValues.action_date_from) {
        obj.action_date_from =
          this.parseDate(this.filterValues.action_date_from) + ' 00:00:00';
      }
      if (this.filterValues.action_date_to) {
        obj.action_date_to =
          this.parseDate(this.filterValues.action_date_to) + ' 23:59:59';
      }
      this.$store.dispatch('foreignerAuditLog/retrieveRecords', obj);
    },
    sorting: function(value, sortable) {
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },
    formatObjToTreeView: function(obj) {
      let final = '';
      for (const key in obj) {
        if (Array.isArray(obj[key]) === true) {
          //final += "arr ";
          if (obj[key].length === 0) {
            final += `${key}: null<br>`;
          } else {
            final += `${key}:[<br>`;
            for (const key2 of obj[key]) {
              if (typeof key2 === 'object') {
                final += '&nbsp;&nbsp;{<br>';
                for (const key3 in key2) {
                  final += `&nbsp;&nbsp;&nbsp;&nbsp;${key3}: ${key2[key3]}<br>`;
                }
                final += '&nbsp;&nbsp;},<br>';
              } else final += `&nbsp;&nbsp;&nbsp;&nbsp;${key2}<br>`;
            }
            final += ']<br>';
          }
        } else if (typeof obj[key] === 'object') {
          if (obj[key]) {
            if (Object.entries(obj[key]).length === 0) {
              final += `${key}: null<br>`;
            } else {
              final += `${key}:<br>`;
              for (const key2 in obj[key]) {
                final += `&nbsp;&nbsp;&nbsp;&nbsp;${key2}: ${obj[key][key2]}<br>`;
              }
            }
          }
        } else {
          final += `${key}: ${obj[key]}<br>`;
        }

      }
      final = final.slice(0, -4);

      return final;
    },

    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },

    formatObjToTreeViewNew: function(obj) {
      let final = '';
      for (const key in obj) {
        final += `${key}: ${JSON.stringify(obj[key])} <br>`;
      }
      final = final.slice(0, -4);

      // // //console.log("Final", final);
      return final;
    },
  },
};
</script>

<style scoped>
</style>
