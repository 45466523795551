import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.me.role && _vm.me.role === _vm.$roles.FOREIGNER ? 'container--foreigner' : ''},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%","margin-bottom":"24px"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.backToFaq}},[_vm._v(" "+_vm._s(_vm.$t('common.faq.detail.back'))+" ")]),_c('h1',[_vm._v(" "+_vm._s(_vm.me.interface_language === 2 ? _vm.faqSectionDetail.name_ru : _vm.faqSectionDetail.name_en)+" ")]),_c('div',{staticStyle:{"width":"132px"}})],1),_c(VExpansionPanels,_vm._l((_vm.faqSectionDetail.queries),function(faqItem,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,{staticStyle:{"white-space":"pre"}},[_c('p',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"pre-line","margin-bottom":"0","line-height":"normal"}},[_vm._v(" "+_vm._s(_vm.me.interface_language === 2 ? faqItem.name_ru : faqItem.name_en)+" ")])]),_c(VExpansionPanelContent,{staticStyle:{"white-space":"pre"}},[_c('p',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"pre-line","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.me.interface_language === 2 ? faqItem.answer_ru : faqItem.answer_en)+" ")])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }