import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import AgencyCompaniesService from '../../services/administration/AgencyCompaniesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    companies: [],
    aresSearchResults: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setCompaniesAndParams(state, data) {
      // //console.log("Setting", data);
      state.companies = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
      // //console.log("new users:", state.users)
    },
    setAresSearchResults(state, data) {
      state.aresSearchResults = data;

      for (const d of state.aresSearchResults) {
        d.openShortName = false;
        d.short_name = '';
      }
    },
    openShortName(state, ico) {
      const tmp = [];
      for (const i of state.aresSearchResults) {
        tmp.push(i);
      }
      // //console.log("SET openshortname for", ico);
      for (const i of tmp) {
        if (i.ic_number === ico) {
          // //console.log("found!", i.fullname)
          i.openShortName = true;
          break;
        }
      }

      state.aresSearchResults = [];
      for (const i of tmp) {
        state.aresSearchResults.push(i);
      }

    },
    clearSearchResults(state) {
      // //console.log("debug4")
      state.aresSearchResults = [];
    },

  },
  actions: {
    retrieveCompanies(context, params) {
      // //console.log("PARAMS", params)
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.getAgencyCompanies(params.page, params.rows, params.order_by, params.is_active)
          .then((data) => {
            context.commit('setCompaniesAndParams', data.data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
      });
    },
    createCompany(context, obj) {
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.createAgencyCompany(obj).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    aresSearch(context, data) {
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.aresSearch(data.name, data.ico).then((data) => {
          context.commit('setAresSearchResults', data.data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    openShortNameByICO(context, ico) {
      context.commit('openShortName', ico);
    },
    clearAresSearchResults(context) {
      // //console.log("debug3")
      context.commit('clearSearchResults');
    },
    getCompanyDetails(context, id) {
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.getAgencyCompanyDetails(id).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    editCompany(context, obj) {
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.editCompany(obj.company, obj.id).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteCompany(context, id) {
      return new Promise((resolve, reject) => {
        AgencyCompaniesService.deleteCompany(id).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.companies) {
        data.push({
          id: u.id,
          short_name: u.short_name,
          fullname: u.fullname,
          client_companies: u.client_companies,
          client_companies_length: u.client_companies.length,
          is_active: u.is_active,
          contact_email: u.contact_email,
          contact_phone: u.contact_phone,
          contact_phone_replaced: u.contact_phone.replace(/[() ]/g, ''),
          address_city: u.address_city,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    aresSearchResults: (state) => {
      return state.aresSearchResults;
    },
    ifEmpty: (state) => {
      return state.companies.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.companies.filter((el) => el.is_active === true)) {
        arr.push({
          name: u.short_name,
          id: u.id,
        });
      }
      arr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return arr;
    },
  },
});
