import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VTabs,{attrs:{"color":"blue","grow":""},on:{"change":_vm.debug},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.dialogs),function(dialog){return _c(VTab,{key:dialog.foreigner_id},[_vm._v(" "+_vm._s(dialog.foreigner_fullname)+" "),(dialog.message_count > 0)?[_c(VBadge,{staticClass:"ml-3",attrs:{"color":"red"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(dialog.message_count)+" ")]},proxy:true}],null,true)})]:_vm._e()],2)}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.dialogs),function(dialog){return _c(VTabItem,{key:dialog.foreigner_id},[_c(VCard,[_c(VCardText,[_c('messaging_dialogue',{ref:'md'+dialog.foreigner_id,refInFor:true,attrs:{"fid":dialog.foreigner_id}})],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }