import axios from 'axios';
import baseURL from '@/services/APIConfig.js';


export default {
  getPlaces(page, rows, order_by) {
    const afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    return axios.get(`${baseURL}/permit/place/${afterUrl}`);
  },
  getPlaceDetail(id) {
    return axios.get(`${baseURL}/permit/place/${id}/`);
  },
  createPlace(obj) {
    return axios.post(`${baseURL}/permit/place/`, obj);
  },
  editPlace(obj, id) {
    return axios.patch(`${baseURL}/permit/place/${id}/`, obj);
  },
  deletePlace(id) {
    return axios.delete(`${baseURL}/permit/place/${id}/`);
  },
};
