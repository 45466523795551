<template>
  <!--    First toolbar    -->
  <div>
    <v-flex md12>
      <v-toolbar color="white accent-4">
        <!-- New foreigner button -->
        <v-btn
          v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT || (me.role === $roles.HR && me.features.case_assessments)"
          color="success"
          dark
          @click="$emit('open-new-foreigner-dialog')"
        >
          {{ $t('foreigners.new_foreigner') }}
        </v-btn>
        <!-- Full-text search -->
        <v-flex
          md4
          offset-md1
        >
          <div class="mt-5">
            <v-text-field
              v-model="filterValues.search"
              :autocomplete="$randomString()"
              prepend-icon="search"
              @change="refresh(true)"
            />
          </div>
        </v-flex>
        <v-flex
          ml-1
          xs1
        >
          <div
            v-if="filterValues.search !== ''"
            class="pointerHover"
            @click="filterValues.search = '' ; refresh(true)"
          >
            <v-icon color="red darken-2">
              mdi-close
            </v-icon>
          </div>
        </v-flex>
        <!-- Export button -->
        <v-flex
          md3
          offset-md2
          text-right
        >
          <v-btn
            v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT || me.role === $roles.HR || me.role === $roles.AGENCY"
            color="success"
            dark
            @click="exportForeigners"
          >
            <img
              src="../../../assets/ms_excel.png"
              style="width: 20px"
            > &nbsp;Export foreigners
          </v-btn>
        </v-flex>
      </v-toolbar>
    </v-flex>
    <v-flex
      md12
      mt-2
    >
      <v-toolbar color="white accent-4">
        <!-- Filter client company -->
        <v-flex
          ml-1
          pr-2
          row
        >
          <v-flex xs11>
            <div
              v-if="me.role !== $roles.HR "
              class="mt-6"
            >
              <v-autocomplete
                v-model="filterValues.client_company_id"
                :autocomplete="$randomString()"
                :items="companiesOptions"
                :label="$t('foreigners.filters.client_company')"
                item-text="name"
                item-value="id"
                style="display: inline;"
                @change="refresh(true)"
              />
            </div>
          </v-flex>
          <v-flex
            mt-7
            xs1
          >
            <div
              v-if="filterValues.client_company_id !== ''"
              class="pointerHover"
              @click="filterValues.client_company_id = '' ; refresh(true)"
            >
              <v-icon color="red darken-2">
                mdi-close
              </v-icon>
            </div>
          </v-flex>
        </v-flex>
        <!-- Filter assigned to -->
        <v-flex
          pr-2
          row
        >
          <v-flex xs11>
            <div class="mt-6 ml-6">
              <v-autocomplete
                v-if="me.role !== $roles.HR && me.role !== $roles.AGENCY"
                v-model="filterValues.assigned_to"
                :autocomplete="$randomString()"
                :items="internalUserOptions"
                :label="$t('foreigners.filters.assigned_to')"
                item-text="fullname"
                item-value="id"
                @change="refresh(true)"
              />
            </div>
          </v-flex>
          <v-flex
            mt-7
            xs1
          >
            <div
              v-if="filterValues.assigned_to !== ''"
              class="pointerHover"
              @click="filterValues.assigned_to = '' ; refresh(true)"
            >
              <v-icon color="red darken-2">
                mdi-close
              </v-icon>
            </div>
          </v-flex>
        </v-flex>
        <!-- Filter current status -->
        <v-flex
          pr-2
          row
        >
          <v-flex xs11>
            <div class="mt-6 ml-6">
              <v-autocomplete
                v-model="filterValues.status"
                :autocomplete="$randomString()"
                :items="filters.status"
                :label="$t('foreigners.permits.current_status')"
                item-text="name"
                item-value="id"
                multiple
                @change="refresh(true)"
              >
                <template
                  slot="item"
                  slot-scope="props"
                >
                  <span :title="props.item.name">
                    {{props.item.name}}
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </v-flex>
          <v-flex
            mt-7
            xs1
          >
            <div
              v-if="filterValues.status !== ''"
              class="pointerHover"
              @click="filterValues.status = '' ; refresh(true)"
            >
              <v-icon color="red darken-2">
                mdi-close
              </v-icon>
            </div>
          </v-flex>
        </v-flex>
        <!-- Filter main permit status -->
        <v-flex
          pr-2
          row
        >
          <v-flex xs11>
            <div class="mt-6 ml-6">
              <v-autocomplete
                v-model="filterValues.main_permit_status"
                :autocomplete="$randomString()"
                :items="permitStatusesShorten"
                :label="$t('foreigners.filters.main_status')"
                item-text="shortname"
                item-value="id"
                multiple
                class="MainPermitStatus"
                @change="refresh(true)"
              >
                <template
                  slot="item"
                  slot-scope="props"
                >
                  <span :title="props.item.fullname">
                    {{ props.item.shortname }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </v-flex>
          <v-flex
            mt-7
            xs1
          >
            <div
              v-if="filterValues.main_permit_status !== ''"
              class="pointerHover"
              @click="filterValues.main_permit_status = '' ; refresh(true)"
            >
              <v-icon color="red darken-2">
                mdi-close
              </v-icon>
            </div>
          </v-flex>
        </v-flex>
        <!-- Filter permit obtained -->
        <v-flex
          pr-4
          row
        >
          <v-flex xs11>
            <div class="mt-6 ml-6">
              <v-autocomplete
                v-model="filterValues.permit_obtained"
                :autocomplete="$randomString()"
                :items="filters.permit_obtained"
                :label="$t('foreigners.filters.permit_obtained')"
                item-text="name"
                item-value="id"
                @change="refresh(true)"
              />
            </div>
          </v-flex>
          <v-flex
            mt-7
            xs1
          >
            <div
              v-if="filterValues.permit_obtained !== ''"
              class="pointerHover"
              @click="filterValues.permit_obtained = '' ; refresh(true)"
            >
              <v-icon color="red darken-2">
                mdi-close
              </v-icon>
            </div>
          </v-flex>
        </v-flex>
        <!-- Filter include family -->
        <v-flex>
          <div v-if="familyFilterData.familyOf">
            <v-flex class="mt-1 text-right">
              <div style="font-size:11pt">
                <span>{{ familyFilterData.familyOf }}</span>
                <v-icon
                  class="pointerHover ml-1 mb-1"
                  color="red darken-2"
                  @click="function (){
                    familyFilterData.familyOf = '';
                    familyFilterData.familyIds = '';
                    refresh(true)
                  }"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <v-flex class="mb-1 ml-3">
          <div>
            <span :title="$t('foreigners.filters.include_family')">
              <v-icon
                id="includeFamilyIcon"
                :color="(filterValues.include_family || familyFilterData.familyIds) ? 'blue' : 'grey'"
                style="display: inline"
                @click="function(){
                  filterValues.include_family = !filterValues.include_family;
                  if (familyFilterData.familyIds || familyFilterData.familyOf){
                    filterValues.include_family = false;
                    familyFilterData.familyOf = '';
                    familyFilterData.familyIds = '';
                  }
                  refresh(true);
                }"
              >mdi-account-group
              </v-icon>
            </span>
          </div>
        </v-flex>
        <!-- Filter case assessment -->
        <v-flex
          v-if="me.role === $roles.ADMIN || (me.role === $roles.HR && me.features.case_assessments)"
          class="mb-1 ml-3"
        >
          <v-icon
            :color="(filterValues.case_assessment) ? 'blue' : 'grey'"
            :title="$t('foreigners.filters.case_assessment')"
            class="pr-0"
            style="display: inline"
            @click="function(){
              filterValues.case_assessment = !filterValues.case_assessment;
              refresh(true);
            }"
          >
            mdi-account-arrow-left
          </v-icon>
        </v-flex>
      </v-toolbar>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ForeignersToolbar',
  props: {
    refresh: {
      type: Function,
      default: undefined,
    },
    filterValues: {
      type: Object,
      default: undefined,
    },
    exportForeigners: {
      type: Object,
      default: undefined,
    },
    permitStatusesShorten: {
      type: Array,
      default: undefined,
    },
    filters: {
      type: Object,
      default: undefined,
    },
    familyFilterData: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      internalUserOptions: 'internalUsers/users',
      companiesOptions: 'clientsCompanies/selectOptions',
      permitStatusesShorten: 'permitStatuses/selectOptionsShorten',
      me: 'coreData/me',
    }),
  },
};
</script>

<style lang="scss" scoped>
.MainPermitStatus {
  ::v-deep .v-select__selections {
    flex-wrap: nowrap;
    max-width: 200px;
    overflow: auto;

    .v-select__selection {
      overflow: initial;
      text-overflow: initial;
      max-width: initial;
    }
  }
}
</style>
