import axios from 'axios';

export default {
  getDialogs(page, rows) {
    return axios.get(`${process.env.VUE_APP_FORURL}/message/`);
  },

  getMessages(params) {
    let url = `${process.env.VUE_APP_FORURL}/${params.fid}/message/?page=${params.page}&page_size=${params.rows}`;
    // //console.log("URLLLL", url);
    if (params.user_from != undefined && params.user_from.length !== 0)
      url += `&user_from=${params.user_from}`;
    if (params.permit != undefined && params.permit.length !== 0)
      url += `&permit=${params.permit}`;
    /*if (params.bodySearch != undefined && params.bodySearch.length !== 0)
    	url += `&body_search=${params.bodySearch}`;*/
    if (params.date_create_from != undefined && params.date_create_from.length !== 0)
      url += `&date_create_from=${params.date_create_from}`;
    if (params.date_create_to != undefined && params.date_create_to.length !== 0)
      url += `&date_create_to=${params.date_create_to}`;

    return axios.get(url);
  },
  sendMessage(fid, text, permit_id, recipients) {
    // //console.log("Sending 3");
    const url = `${process.env.VUE_APP_FORURL}/${fid}/message/`;

    const obj = {
      body: text,
      recipients: recipients,
    };
    if (permit_id) {
      obj.permit_id = permit_id;
    }
    return axios.post(url, obj);
    // return axios.post(url, {body: text})
  },
  getPossibleMentiones() {
    const url = `${process.env.VUE_APP_FORURL}/message/user/`;
    return axios.get(url);
  },


};
