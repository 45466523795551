<template>
  <div id="questionnaire">
    <v-flex row>
      <v-flex
        v-if="currentQuestionnaire.survey_type_name"
        xs2
        ml-3
      >
        <v-card style="width: 100%">
          <v-navigation-drawer
            permanent
            style="width: 100%"
          >
            <v-divider />
            <v-list nav>
              <v-list-item
                v-for="(item, index) in survey_json.chapters"
                :key="item.title"
                link
                :class="chapter_number === index ? 'active_item' : 'a'"
                :title="item.label"
                @click.native.stop="openChapter(index)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <div v-if="item.id in survey_json.errors || (survey_json.errors.length === 0 && survey_json.completed === false)">
                      <span style="color: red">
                        {{ item.label }}
                      </span>
                    </div>
                    <div v-else>
                      <span>{{ item.label }}</span>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="item.id in survey_json.errors || (survey_json.errors.length === 0 && survey_json.completed === false)"
                    color="red"
                  >
                    mdi-exclamation
                  </v-icon>
                  <v-icon
                    v-else
                    color="green"
                  >
                    mdi-check
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-divider />
            <div
              v-if="survey_json.chapters"
              class="my-3 text-center"
            >
              <div>
                <template v-if="editMode === false">
                  <v-btn
                    v-if="(
                      me.role === $roles.ADMIN ||
                      me.role === $roles.CONSULTANT ||
                      (me.role === $roles.HR && survey_json.case_assessment)
                    )"
                    xs12
                    :loading="blockControlButtons"
                    color="warning"
                    dark
                    @click="editMode = true"
                  >
                    {{ $t('quest.switch_to_edit') }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    v-if="(
                      me.role === $roles.ADMIN ||
                      me.role === $roles.CONSULTANT ||
                      (me.role === $roles.HR && survey_json.case_assessment)
                    )"
                    xs12
                    :loading="blockControlButtons"
                    color="warning"
                    dark
                    @click="editMode = false"
                  >
                    {{ $t('quest.switch_to_view') }}
                  </v-btn>
                </template>
              </div>
              <div class="mt-3">
                <v-btn
                  v-if="(
                    me.role === $roles.ADMIN ||
                    me.role === $roles.CONSULTANT ||
                    me.role === $roles.FOREIGNER ||
                    (me.role === $roles.HR && survey_json.case_assessment)
                  ) && editMode"
                  xs12
                  color="success"
                  :loading="blockControlButtons"
                  dark
                  @click="sendData(false)"
                >
                  {{ $t('common.actions.save_data') }}
                </v-btn>
              </div>
            </div>
          </v-navigation-drawer>
        </v-card>
      </v-flex>
      <v-flex
        xs9
        ml-3
      >
        <h1>
          <template v-if="currentQuestionnaire.survey_type_name">
            {{ $capitalize(currentQuestionnaire.survey_type_name) }}
          </template>
        </h1>
        <div>
          <template v-if="'sections' in chapter_json && chapter_json.sections.length > 0">
            <form>
              <div :key="randomKey">
                <div
                  v-for="(section, index) in chapter_json.sections"
                  :key="index"
                  class="sectionDiv mt-3 mx-3 px-3 py-3"
                  style="background-color: #ececec; border-radius:10px"
                >
                  <h3>
                    <template v-if="section.label">
                      {{ $capitalize(section.label) }}
                    </template>
                  </h3>
                  <div
                    v-for="(question, questionIndex) in section.questions"
                    :key="questionIndex"
                  >
                    <template v-if="question.type === 'string'">
                      <v-text-field
                        :id="b64EncodeUnicode(question.label)"
                        v-model="question.value"
                        :disabled="!(me.role === $roles.AGENCY) && editMode ? false : true"
                        :label="question.label"
                        :autocomplete="$randomString()"
                        :name="$randomString()"
                        @change="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                      />
                    </template>
                    <template v-else-if="question.type.split('_')[0] === 'enum'">
                      <template v-if="question.force_refresh">
                        <v-autocomplete
                          :id="b64EncodeUnicode(question.label)"
                          v-model="question.value"
                          :disabled="!(
                            (me.role === $roles.HR && !survey_json.case_assessment) || me.role === $roles.AGENCY)
                            && editMode ? false : true"
                          :autocomplete="$randomString()"
                          :label="question.label"
                          :items="question.items"
                          :item-text="question.keySelect"
                          :item-value="question.valueSelect"
                          :name="$randomString()"
                          @change="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                        >
                          <template
                            slot="selection"
                            slot-scope="data"
                          >
                            <template v-if="question.type.split('_')[1] === 'gender'">
                              {{ $t(data.item.key) }}
                            </template>
                            <template v-else>
                              {{ data.item[question.keySelect] }}
                            </template>
                          </template>
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <template v-if="question.type.split('_')[1] === 'gender'">
                              {{ $t(data.item.key) }}
                            </template>
                            <template v-else>
                              {{ data.item[question.keySelect] }}
                            </template>
                          </template>
                        </v-autocomplete>
                      </template>
                      <template v-else>
                        <v-autocomplete
                          :id="b64EncodeUnicode(question.label)"
                          v-model="question.value"
                          :disabled="!( me.role === $roles.AGENCY)&& editMode ? false : true"
                          :label="question.label"
                          :items="question.items"
                          :item-text="question.keySelect"
                          :item-value="question.valueSelect"
                          :autocomplete="$randomString()"
                          :name="$randomString()"
                          @blur="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                        >
                          <template
                            slot="selection"
                            slot-scope="data"
                          >
                            <template v-if="question.type.split('_')[1] === 'gender'">
                              {{ $t(data.item.key) }}
                            </template>
                            <template v-else>
                              {{ data.item[question.keySelect] }}
                            </template>
                          </template>
                          <template
                            slot="item"
                            slot-scope="data"
                          >
                            <template v-if="question.type.split('_')[1] === 'gender'">
                              {{ $t(data.item.key) }}
                            </template>
                            <template v-else>
                              {{ data.item[question.keySelect] }}
                            </template>
                          </template>
                        </v-autocomplete>
                      </template>
                    </template>
                    <template v-else-if="question.type === 'datetime'">
                      <v-text-field
                        :id="b64EncodeUnicode(question.label)"
                        v-model="question.value"
                        :disabled="!((me.role === $roles.HR && !survey_json.case_assessment) || me.role === $roles.AGENCY) && editMode ? false : true"
                        :label="question.label + ' (use format DD.MM.YYYY)'"
                        :autocomplete="$randomString()"
                        :name="$randomString()"
                        @change="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                      />
                    </template>
                    <template v-else-if="question.type === 'integer'">
                      <v-autocomplete
                        :id="b64EncodeUnicode(question.label)"
                        v-model="question.value"
                        :disabled="!((me.role === $roles.HR && !survey_json.case_assessment) || me.role === $roles.AGENCY) && editMode ? false : true"
                        :label="question.label"
                        :items="integerItems"
                        :autocomplete="$randomString()"
                        :name="$randomString()"
                        @change="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                      />
                    </template>
                    <template v-else-if="question.type === 'boolean'">
                      {{ question.label }}
                      <v-radio-group
                        :id="b64EncodeUnicode(question.label)"
                        v-model="question.value"
                        :disabled="!((me.role === $roles.HR && !survey_json.case_assessment) || me.role === $roles.AGENCY) && editMode ? false : true"
                        row
                        style="margin-top:0"
                        @change="function() { if (question.force_refresh) {openChapter(chapter_number)} ; saveCurrentPosition(question.label)}"
                      >
                        <v-radio
                          :label="$t('common.values.no')"
                          :value="false"
                        />
                        <v-radio
                          :label="$t('common.values.yes')"
                          :value="true"
                        />
                      </v-radio-group>
                    </template>
                    <template v-else>
                      {{ question }}
                    </template>
                    <template v-if="showErrors && chapter_json['id'] in survey_json.errors && section['id'] in survey_json.errors[chapter_json['id']] && question['id'] in survey_json.errors[chapter_json['id']][section['id']]">
                      <small style="color: red">
                        {{ survey_json.errors[chapter_json['id']][section['id']][question['id']][0] }}
                        <br></small>
                    </template>
                    <template v-if="question.localError">
                      <small style="color: red">
                        {{ question.localError }}
                        <br>
                      </small>
                    </template>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </div>

        <v-flex
          row
          xs12
        >
          <v-flex>
            <div
              v-if="chapter_number !== 0"
              class="mt-4 ml-6 text-left"
            >
              <v-btn
                v-if="(
                  me.role === $roles.ADMIN ||
                  me.role === $roles.CONSULTANT ||
                  me.role === $roles.FOREIGNER ||
                  (me.role === $roles.HR && survey_json.case_assessment)
                ) && editMode"
                color="success"
                :loading="blockControlButtons"
                dark
                @click="openChapter(chapter_number-1)"
              >
                &lt; {{ $t('common.actions.back') }}
              </v-btn>
            </div>
          </v-flex>
          <v-flex>
            <template v-if="'chapters' in survey_json">
              <div
                v-if="chapter_number !== survey_json.chapters.length - 1 && !ifFocusOnInput"
                class="mt-4 text-right"
              >
                <v-btn
                  v-if="(
                    me.role === $roles.ADMIN ||
                    me.role === $roles.CONSULTANT ||
                    me.role === $roles.FOREIGNER ||
                    (me.role === $roles.HR && survey_json.case_assessment)
                  ) && editMode "
                  color="success"
                  :loading="blockControlButtons"
                  dark
                  @click="openChapter(chapter_number+1)"
                >
                  {{ $t('common.actions.next') }} &gt;
                </v-btn>
              </div>
              <div
                v-if="chapter_number === survey_json.chapters.length - 1 && !ifFocusOnInput"
                class="mt-4 text-right"
              >
                <v-btn
                  v-if="(
                    me.role === $roles.ADMIN ||
                    me.role === $roles.CONSULTANT ||
                    me.role === $roles.FOREIGNER ||
                    (me.role === $roles.HR && survey_json.case_assessment)
                  ) && editMode"
                  xs12
                  color="success"
                  :loading="blockControlButtons"
                  dark
                  @click="dialog = true"
                >
                  {{ $t('quest.submit_questionnaire') }}
                </v-btn>
              </div>
            </template>
          </v-flex>
        </v-flex>
        <br>
        <br>
      </v-flex>
      <v-snackbar
        v-model="snackbar"
        bottom
        right
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          color="blue"
          text
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-flex>
    <v-dialog
      v-model="dialog"
      max-width="690"
      @keydown.esc="dialog = false"
      @keydown.meta.s="function() {sendData(true); dialog = false}"
      @keydown.meta.enter="function() {sendData(true); dialog = false}"
      @keydown.ctrl.s="function() {sendData(true); dialog = false}"
      @keydown.ctrl.enter="function() {sendData(true); dialog = false}"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('quest.submit_questionnaire') }}
        </v-card-title>
        <v-card-text>
          {{ $t('quest.submit_questionnaire_confirm_text') }}

          <template v-if="ifCompleted === false">
            <br>
            <br>
            {{ $t('quest.submit_questionnaire_not_complete') }}
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="yellow darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="function() {sendData(true); dialog = false}"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ForeignersQuestionnaireFormGenerator',
  props: {
    fid: {
      type: Number,
      default: undefined,
    },
    qid: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      randomKey: 0,
      survey_json: [],
      chapter_json: [],
      chapter_number: 0,
      errors: [],
      mode: 1,
      snackbar: false,
      snackbarText: 'some result',
      dialog: false,
      showErrors: true,
      blockControlButtons: false,
      integerItems: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      timeout: null,
      editMode: false,
    };
  },
  computed: {
    ...mapGetters({
      foreignersQuestionnaires: 'foreignerQuestionnaire/foreignersQuestionnaires',
      currentQuestionnaire: 'foreignerQuestionnaire/currentQuestionnaire',
      currentQuestionnaireF: 'questionnaires/currentQuestionnaire',
      enum_gender: 'coreData/userGenders',
      enum_country: 'coreData/countries',
      enum_marital_status: 'coreData/maritalStatuses',
      enum_phone_code: 'coreData/dialCodes',
      enum_support_specification: 'coreData/supportSpecifications',
      enum_support_timeline: 'coreData/supportTimelines',
      me: 'coreData/me',
    }),
    ifCompleted: function() {
      return this.survey_json.completed === true;
    },
    ifFocusOnInput: function() {
      return document.activeElement.tagName !== 'BODY';
    },
  },
  created() {
    this.$store.dispatch('coreData/retrieveMaritalStatuses');
    this.$store.dispatch('coreData/retrieveSupportSpecifications');
    this.$store.dispatch('coreData/retrieveSupportTimelines');
  },
  mounted() {
    const that = this;
    if (this.me.role === this.$roles.FOREIGNER) {
      this.$store
        .dispatch('questionnaires/retrieveForeignersSelectedQuestionnaire', {
          qid: this.qid,
        })
        .then((_) => {
          that.generateDataForForm();
          that.chapter_json = that.survey_json.chapters[0];
          that.chapter_number = 0;
        });
    } else {
      this.$store
        .dispatch(
          'foreignerQuestionnaire/retrieveForeignersSelectedQuestionnaire',
          { fid: this.fid, qid: this.qid },
        )
        .then((_) => {
          that.generateDataForForm();
          that.chapter_json = that.survey_json.chapters[0];
          that.chapter_number = 0;
        });
    }
  },
  methods: {
    debug: function() {},
    generateDataForForm: function() {
      if (this.me.role === this.$roles.FOREIGNER) {
        this.survey_json = JSON.parse(
          JSON.stringify(this.currentQuestionnaireF.survey_json),
        );
      } else {
        this.survey_json = JSON.parse(
          JSON.stringify(this.currentQuestionnaire),
        ).survey_json;
      }

      for (const chapter of this.survey_json.chapters) {
        for (const section of chapter.sections) {
          for (const question of section.questions) {
            if (question.type.split('_')[0] === 'enum') {
              const enumKey = question.type;

              switch (enumKey) {
                case 'enum_gender': {
                  question.items = this.enum_gender;
                  question.keySelect = 'name';
                  question.valueSelect = 'id';
                  break;
                }
                case 'enum_country': {
                  question.items = this.enum_country;
                  question.keySelect = 'name';
                  question.valueSelect = 'id';
                  break;
                }
                case 'enum_marital_status': {
                  question.items = this.enum_marital_status;
                  question.keySelect = 'name';
                  question.valueSelect = 'id';
                  break;
                }
                case 'enum_support_specification': {
                  question.items = this.enum_support_specification;
                  question.keySelect = 'name';
                  question.valueSelect = 'id';
                  break;
                }
                case 'enum_support_timeline': {
                  question.items = this.enum_support_timeline;
                  question.keySelect = 'name';
                  question.valueSelect = 'id';
                  break;
                }
                case 'enum_phone_code': {
                  question.items = this.enum_phone_code;
                  question.keySelect = 'codePlus';
                  question.valueSelect = 'id';
                  question.value = parseInt(question.value);
                  break;
                }
              }
            } else if (question.type === 'boolean') {
            } else if (question.type === 'datetime') {
              question.showDateWidget = false;

              if (question.value.length > 1) {
                question.valueGoodFormat = question.value.slice(0, -9);
                question.value = question.value.slice(0, -9);
              }
            }
            question.localError = '';
          }
        }
      }
      this.survey_json.completed = this.currentQuestionnaire.completed;
      this.survey_json.case_assessment =
        this.currentQuestionnaire.case_assessment;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },
    unparseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month}-${day}`;
    },
    prepareFormObject: function(obj, submitted) {
      const newObj = JSON.parse(JSON.stringify(obj));

      for (const chapter of newObj) {
        for (const section of chapter.sections) {
          for (const question of section.questions) {
            if (question.type.split('_')[0] === 'enum') {
              delete question.items;
              const enumKey = question.type;
              if (enumKey === 'enum_phone_code' && question.value == null) {
                question.value = '';
              }
            } else if (question.type.split('_')[0] === 'integer') {
              if (question.value !== '')
                question.value = parseInt(question.value);
            } else if (question.type.split('_')[0] === 'datetime') {
              if (question.value !== '')
                question.value = question.value + ' 00:00:00';
            } else if (question.type.split('_')[0] === 'boolean') {
            }
            delete question.label;
            delete question.type;
          }
        }
      }

      const final = { survey_json: newObj };
      if (submitted === true) {
        final.submitted = true;
      }

      return final;
    },
    sendData: function(submited, switchToViewMode = true) {
      const that = this;
      const obj = {
        fid: this.fid,
        qid: this.qid,
        survey_json: this.prepareFormObject(
          this.survey_json.chapters,
          submited,
        ),
        completed: this.currentQuestionnaire.completed,
      };
      this.blockControlButtons = true;

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('foreignerQuestionnaire/addDataQuestionnaire', obj)
          .then((_) => {
            that.generateDataForForm();

            const id = that.survey_json.chapters[that.chapter_number].id;
            const tmp = Object.entries(that.survey_json.errors).find(
              (el) => el[0] === id,
            );
            let errCount = 0;

            if (tmp === undefined) errCount = 0;
            else {
              errCount = Object.entries(Object.entries(tmp)[1][1])
                .map((el) => (el = Object.entries(el[1]).length))
                .reduce((a, b) => a + b, 0);
            }

            that.blockControlButtons = false;
            if (errCount > 0) {
              that.randomKey = Math.random() * 100;
              resolve({ errors: errCount });
            } else {
              that.randomKey = Math.random() * 100;
              resolve({ errors: 0 });
            }

            if (switchToViewMode === true) {
              if (
                this.me.role === this.$roles.ADMIN ||
                this.me.role === this.$roles.CONSULTANT ||
                (this.me.role === this.$roles.CONSULTANT &&
                  this.survey_json.case_assessment)
              ) {
                this.editMode = false;
              }
            }
          })
          .catch((error) => {
            that.blockControlButtons = false;
            reject(error);
          });
      });
    },
    openChapter: function(index, send = true) {
      const that = this;
      clearTimeout(this.timeout);

      if (
        this.me.role !== this.$roles.ADMIN &&
        this.me.role === this.$roles.HR &&
        !this.survey_json.case_assessment
      ) {
        that.showErrors = true;
        that.chapter_number = index;
        that.chapter_json = that.survey_json.chapters[index];
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        if (this.editMode === false) {
          console.log('here');
          that.chapter_number = index;
          that.chapter_json = that.survey_json.chapters[index];
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          console.log('there');

          this.sendData(false, false).then((errorCount) => {
            let errCountInIndex = 0;
            const id = that.survey_json.chapters[index].id;
            const tmp = Object.entries(that.survey_json.errors).find(
              (el) => el[0] === id,
            );

            if (tmp === undefined) errCountInIndex = 0;
            else errCountInIndex = 1;

            if (errorCount.errors > 0) {
              if (errCountInIndex === 0) {
                that.chapter_number = index;
                that.chapter_json = that.survey_json.chapters[index];
                window.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                that.showErrors = true;
                that.chapter_json =
                  that.survey_json.chapters[that.chapter_number];
              }
            } else {
              that.chapter_number = index;
              that.chapter_json = that.survey_json.chapters[index];
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          });
        }
      }
    },
    b64EncodeUnicode: function(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
          },
        ),
      );
    },
    b64DecodeUnicode: function(str) {
      return decodeURIComponent(
        Array.prototype.map
          .call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
    },
    saveCurrentPosition: function(label) {
      const questionnaireId = this.qid;
      const obj = {
        chapter: this.chapter_number,
        question: this.b64EncodeUnicode(label),
      };
      let qdatas = localStorage.getItem('quest_positions');
      if (qdatas == null) {
        qdatas = '{}';
      }
      qdatas = JSON.parse(qdatas);
      qdatas[questionnaireId] = obj;
      localStorage.setItem('quest_positions', JSON.stringify(qdatas));
    },
    loadCurrentPosition: function() {
      const questionnaireId = this.qid;
      let qdatas = localStorage.getItem('quest_positions');
      if (qdatas == null) {
        return;
      }
      qdatas = JSON.parse(qdatas);
      const qdata = qdatas[questionnaireId];
      if (qdata != undefined) {
        this.openChapter(qdata.chapter);
      }
    },
  },
};
</script>
