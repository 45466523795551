<template>
  <div>
    <template v-if="me.role == null">
      <div style="height: 500px; width: 500px; background-color: transparent">
        &nbsp;
      </div>
    </template>
    <template v-else-if="me.role === $roles.FOREIGNER">
      <foreigners-view />
    </template>
    <!--<template v-else-if="">
			<client-user-view></client-user-view>
		</template>-->
    <template v-else>
      <!--<h1>Dashboard</h1>
			<users-me></users-me>-->
      <ForeignersOverview />
    </template>
  </div>
</template>

<script>
import { authComputed } from '../vuex/helpers.js';
import { mapGetters } from 'vuex';
import ForeignersView from '../components/ForeignersView';
import ForeignersOverview from '../components/foreigners/ForeignersOverview';

export default {
  components: {
    ForeignersView,
    ForeignersOverview,
  },
  data() {
    return {
      isLoading: true,
      events: [],
    };
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      me: 'coreData/me',
    }),
  },
  created() {
    //console.log("LOCATION", String(location));
    //console.log("CHECK CHECK DASHBOARD", localStorage.getItem("itrack_adm_forward_to_foreigner_permit"));
  },
  methods: {},
};
</script>
