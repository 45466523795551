<template>
  <div>
    <v-flex row>
      <v-flex xs4>
        <div class="flex display-1 mx-2">
          {{ $t('adm.internal.places.title') }}
        </div>
      </v-flex>
      <v-flex
        xs8
        class="text-right my-3"
      >
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newPlaceDialog = true; action = 'new'"
          >
            {{ $t('adm.internal.places.create_button') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editPlaceMethod(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deletePlaceOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newPlaceDialog"
      persistent
      max-width="800px"
      @keydown.esc="newPlaceDialog = false; clearNewPlaceForm()"
      @keydown.meta.s="editPlaceDialogShortcutHandler($event)"
      @keydown.ctrl.s="editPlaceDialogShortcutHandler($event)"
      @keydown.meta.enter="editPlaceDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editPlaceDialogShortcutHandler($event)"
      @keydown.enter="editPlaceDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span
            v-if="action === 'new'"
            class="headline"
          >{{
            $t('adm.internal.places.places_dialog.title_new')
          }}</span>
          <span
            v-else-if="action === 'edit'"
            class="headline"
          >{{
            $t('adm.internal.places.places_dialog.title_edit')
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="newPlace.short_name"
                  :label="$t('common.fields.short_name') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newPlace.address_street"
                  :label="$t('adm.companies.company_dialog.street') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="newPlace.address_house_number"
                  :label="$t('adm.companies.company_dialog.house_number') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="newPlace.address_city"
                  :label="$t('adm.companies.company_dialog.city') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="newPlace.address_zip"
                  :label="$t('adm.companies.company_dialog.zip') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="newPlace.address_country_id"
                  :autocomplete="$randomString()"
                  :items="countriesOptions"
                  :label="$t('common.fields.country') + ' *'"
                  :menu-props="{ maxHeight: '400' }"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newPlace.google_calendar_id"
                  :label="$t('adm.companies.company_dialog.google_calendar_id') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="newPlaceAlertText.length > 0"
                  type="error"
                  v-html="newPlaceAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="newPlaceDialog = false; clearNewPlaceForm()"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="createPlace"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="savePlace"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deletePlaceDialog"
      persistent
      max-width="800px"
      @keydown.esc="newActionDateDialog = false"
      @keydown.meta.s="deletePlaceDialogShortcutHandler($event)"
      @keydown.ctrl.s="deletePlaceDialogShortcutHandler($event)"
      @keydown.meta.enter="deletePlaceDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deletePlaceDialogShortcutHandler($event)"
      @keydown.enter="deletePlaceDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t('adm.internal.places.delete_dialog.title')
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.permits.places.delete_dialog.confirm_text') }}
                  <v-flex text-center>
                    <b>{{ deletePlace.full_address }}</b>
                  </v-flex>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deletePlaceAlertText.length > 0"
                  type="error"
                  v-html="deletePlaceAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deletePlaceDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            :loading="disabledDeleteButton"
            @click="deletePlaceMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import InternalPlacesService from '../../services/administration/InternalPlacesService';
import helpers from '../../helpers';

export default {
  name: 'InternalPlaces',
  data() {
    return {
      snackbar: false,
      snackbarText: '',

      headers: [
        {
          text: this.$t('common.fields.short_name'),
          value: 'short_name',
          sortable: true,
        },
        {
          text: this.$t('common.fields.full_address'),
          value: 'full_address',
          sortable: true,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],

      tablePage: 0,
      rowsOnPage: 10,

      sortBy: 'short_name',
      sortDesc: false,

      filters: {},
      filterValues: {},

      action: 'new', // new or edit

      newPlaceDialog: false,
      newPlace: {
        short_name: '',
        address_street: '',
        address_house_number: '',
        address_city: '',
        address_zip: '',
        address_country_id: null,
        google_calendar_id: null,
      },
      newPlaceAlertText: '',

      editPlaceDialog: false,
      editPlace: {
        id: '',
        short_name: '',
        address_city: '',
        address_street: '',
        address_zip: '',
        address_house_number: '',
        address_country_id: null,
        google_calendar_id: null,
      },
      editPlaceAlertText: '',
      editOriginalPlace: null,

      deletePlaceDialog: false,
      deletePlace: {
        id: 0,
        full_address: '',
      },
      deletePlaceAlertText: '',
      disabledDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'internalPlaces/tableData',
      tableParams: 'internalPlaces/tableParams',
      countriesOptions: 'coreData/countries',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },

  mounted() {
    let refresh = localStorage.getItem('itrack_refresh_admin_int_places');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
      }
    }

    this.$store.dispatch('internalPlaces/retrievePlaces', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
    });
  },
  methods: {
    editPlaceDialogShortcutHandler: function(event) {
      event.preventDefault();
      if (this.action === 'new') this.createPlace();
      else if (this.action === 'edit') this.savePlace();
    },

    deletePlaceDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deletePlaceMethod();
    },

    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('internalPlaces/retrievePlaces', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },

    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('internalPlaces/retrievePlaces', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },

    refresh: function() {
      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_int_places');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_int_places',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('internalPlaces/retrievePlaces', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },

    sorting: function(value, sortable) {
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },

    clearNewPlaceForm: function() {
      this.newPlace.short_name = '';
      this.newPlace.address_city = '';
      this.newPlace.address_street = '';
      this.newPlace.address_zip = '';
      this.newPlace.address_house_number = '';
      this.newPlace.address_country_id = null;
      this.newPlace.google_calendar_id = null;
    },

    createPlace: function() {
      this.disabledSaveButton = true;
      this.newPlaceAlertText = '';

      const obj = {};
      for (const k in this.newPlace) obj[k] = this.newPlace[k];

      this.$store
        .dispatch('internalPlaces/createPlace', obj)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalPlaces/retrievePlaces', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newPlaceDialog = false;
          this.clearNewPlaceForm();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          this.newPlaceAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    editPlaceMethod: function(obj) {
      this.action = 'edit';

      this.newPlace.short_name = obj.short_name;
      this.newPlace.address_city = obj.address_city;
      this.newPlace.address_street = obj.address_street;
      this.newPlace.address_zip = obj.address_zip;
      this.newPlace.address_house_number = obj.address_house_number;
      this.newPlace.address_country_id = obj.address_country_id;
      this.newPlace.id = obj.id;
      this.newPlace.google_calendar_id = obj.google_calendar_id;

      this.editOriginalPlace = JSON.parse(JSON.stringify(this.newPlace));
      this.newPlaceDialog = true;
    },

    savePlace: function() {
      this.disabledSaveButton = true;
      this.newPlaceAlertText = '';

      this.$store
        .dispatch('internalPlaces/editPlace', {
          id: this.newPlace.id,
          place: this.newPlace,
        })
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalPlaces/retrievePlaces', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newPlaceDialog = false;
          this.clearNewPlaceForm();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          this.newPlaceAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    deletePlaceOpenDialog: function(obj) {
      this.deletePlaceAlertText = '';
      this.deletePlace.full_address = obj.full_address;
      this.deletePlace.id = obj.id;
      this.deletePlaceDialog = true;
    },

    deletePlaceMethod: function() {
      this.disabledDeleteButton = true;
      this.$store
        .dispatch('internalPlaces/deletePlace', this.deletePlace.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalPlaces/retrievePlaces', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deletePlaceDialog = false;
          this.disabledDeleteButton = false;
        })
        .catch((e) => {
          this.deletePlaceAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledDeleteButton = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
