<template>
  <div>
    <v-flex row>
      <v-flex>
        <div class="flex display-1 mx-2">
          {{ $t('adm.permits.types.title') }}
        </div>
      </v-flex>
      <v-flex class="text-right ">
        <v-flex
          xs6
          offset-xs6
        />
      </v-flex>
      <v-flex class="text-right my-3">
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newTypeDialog = true; clearNewTypeForm(); action = 'new'"
          >
            {{ $t('adm.permits.types.types_dialog.title_new') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>
      <template
        slot="item.renewal_offset"
        slot-scope="props"
      >
        {{ props.item.renewal_offset }} {{ $t('common.days') }}
      </template>
      <template
        slot="item.case_assessment"
        slot-scope="props"
      >
        <v-checkbox
          v-model="props.item.case_assessment"
          disabled
        />
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editType(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteTypeOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newTypeDialog"
      persistent
      max-width="800px"
      @keydown.esc="newTypeDialog = false"
      @keydown.meta.s="editTypeDialogShortcutHandler($event)"
      @keydown.ctrl.s="editTypeDialogShortcutHandler($event)"
      @keydown.meta.enter="editTypeDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editTypeDialogShortcutHandler($event)"
      @keydown.enter="editTypeDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span
            v-if="action === 'new'"
            class="headline"
          >{{ $t('adm.permits.types.types_dialog.title_new') }}</span>
          <span
            v-else-if="action === 'edit'"
            class="headline"
          >{{ $t('adm.permits.types.types_dialog.title_edit') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="newType.name"
                  :label="$t('common.fields.name') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="newType.description"
                  name="description"
                  :label="$t('common.fields.description')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="newType.renewal_offset"
                  :label="$t('foreigners.filters.renewal_date') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs6>
                <v-switch
                  v-model="newType.case_assessment"
                  :label="$t('foreigners.filters.case_assessment')"
                />
              </v-flex>
              <v-flex xs12>
                <div class="title black--text">
                  {{ $t('adm.permits.types.types_dialog.statuses') }}
                </div>
              </v-flex>
              <v-flex
                xs12
                md9
              >
                <v-autocomplete
                  v-model="selectedStatuses"
                  :items="statusesOptions"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('adm.permits.types.types_dialog.statuses')"
                  multiple
                  item-text="name"
                  item-value="id"
                  return-object
                  chips
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                md9
              >
                <v-card tile>
                  <v-list>
                    <v-subheader>{{ $t('adm.permits.types.types_dialog.statuses_order') }}</v-subheader>
                    <draggable
                      v-model="selectedStatusesOrdered"
                      :list="selectedStatuses"
                      @start="drag=true"
                      @end="drag=false"
                    >
                      <v-list-item
                        v-for="(opt, index) in selectedStatuses"
                        :key="index"
                      >
                        <v-list-item-content
                          :key="opt.id"
                          class="moveable"
                        >
                          <v-flex
                            xs12
                            row
                            mx-1
                          >
                            <v-flex xs10>
                              <v-list-item-title>
                                {{ opt.name }}
                              </v-list-item-title>
                            </v-flex>
                            <v-flex
                              xs2
                              style="text-align: right"
                            >
                              <v-icon>reorder</v-icon>
                            </v-flex>
                          </v-flex>
                        </v-list-item-content>
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <div class="title black--text">
                  {{ $t('adm.permits.types.types_dialog.documents') }}
                </div>
              </v-flex>
              <v-flex
                xs12
                md9
              >
                <v-autocomplete
                  v-model="selectedDocuments"
                  :items="documentsOptions"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('adm.permits.types.types_dialog.documents')"
                  multiple
                  item-text="name"
                  item-value="id"
                  return-object
                  chips
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="newTypeAlertText.length > 0"
                  type="error"
                  v-html="newTypeAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="newTypeDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledEditButton"
            @click="createType"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledEditButton"
            @click="saveType"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteTypeDialog"
      persistent
      max-width="800px"
      @keydown.esc="deleteTypeDialog = false"
      @keydown.meta.s="deleteTypeDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteTypeDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteTypeDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteTypeDialogShortcutHandler($event)"
      @keydown.enter="deleteTypeDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.permits.types.delete_types_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.permits.types.delete_types_dialog.confirm_text') }} <b>{{ deleteType.name }}</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteTypeAlertText.length > 0"
                  type="error"
                  v-html="deleteTypeAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteTypeDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            :loading="disabledDeleteButton"
            @click="deleteTypeMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import helpers from '../../helpers';

export default {
  name: 'PermitTypes',
  components: {
    draggable,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',

      headers: [
        {
          text: this.$t('common.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('common.fields.description'),
          value: 'description',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.renewal_date'),
          value: 'renewal_offset',
          sortable: false,
        },
        {
          text: this.$t('foreigners.filters.case_assessment'),
          value: 'case_assessment',
          sortable: true,
        },
        {
          text: this.$t('adm.permits.types.types_dialog.documents'),
          value: 'documentsCount',
          sortable: false,
        },
        {
          text: this.$t('adm.permits.types.types_dialog.statuses'),
          value: 'statusesCount',
          sortable: false,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 0,
      rowsOnPage: 10,
      //orderBy: 'id',
      sortBy: 'name',
      sortDesc: false,

      action: 'new', //new or edit
      newTypeDialog: false,
      newType: {
        name: '',
        description: '',
        renewal_offset: '',
        case_assessment: false,
        statuses: [],
        documents: [],
      },
      editOriginalType: null,
      newTypeAlertText: '',
      selectedStatuses: [],
      selectedStatusesOrdered: [],
      selectedDocuments: [],
      selectedDocumentsOrdered: [],
      disabledEditButton: false,

      deleteTypeDialog: false,
      deleteType: {
        id: 0,
        name: '',
      },
      deleteTypeAlertText: '',
      disabledDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'permitTypes/tableData',
      tableParams: 'permitTypes/tableParams',
      statusesOptions: 'permitStatuses/selectOptions',
      documentsOptions: 'permitDocuments/selectOptions',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    let refresh = localStorage.getItem('itrack_refresh_admin_perm_types');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
      }
    }

    this.$store.dispatch('permitTypes/retrieveTypes', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
    });
    this.$store.dispatch('permitStatuses/retrieveStatuses', {
      page: 1,
      rows: 100,
      order_by: 'name',
    });
    this.$store.dispatch('permitDocuments/retrieveDocuments', {
      page: 1,
      rows: 100,
      order_by: 'name',
    });
  },
  methods: {
    editTypeDialogShortcutHandler: function(event) {
      event.preventDefault();
      if (this.action === 'new') this.createType();
      else if (this.action === 'edit') this.saveType();
    },
    deleteTypeDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deleteTypeMethod();
    },
    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('permitTypes/retrieveTypes', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('permitTypes/retrieveTypes', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    refresh: function() {
      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_perm_types');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_perm_types',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('permitTypes/retrieveTypes', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    sorting: function(value, sortable) {
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },
    validateNewTypeForm: function() {
      if (this.newType.name.length < 3) {
        this.newTypeAlertText = 'Name is too short';
        return false;
      }
      if (isNaN(parseInt(this.newType.renewal_offset))) {
        this.newTypeAlertText = 'Renewal must be a number';
        return false;
      }
      if (parseInt(this.newType.renewal_offset) < 1) {
        this.newTypeAlertText = 'Renewal must be a positive number';
        return false;
      }
      if (this.selectedStatuses.length === 0) {
        this.newTypeAlertText = 'There must be at least one status';
        return false;
      }
      return true;
    },
    clearNewTypeForm: function() {
      this.newType.name = '';
      this.newType.description = '';
      this.newType.documents = [];
      this.renewal_offset = '';
      this.case_assessment = false;
      this.newType.statuses = [];
      this.newTypeAlertText = '';
    },
    createType: function() {
      this.disabledEditButton = true;
      this.newTypeAlertText = '';
      if (this.validateNewTypeForm() !== true) {
        this.disabledEditButton = false;
        return;
      }

      const obj = {};
      for (const k in this.newType) obj[k] = this.newType[k];

      obj.renewal_offset = parseInt(obj.renewal_offset);

      obj.statuses = [];
      for (const el of this.selectedStatuses) {
        obj.statuses.push(el.id);
      }
      obj.documents = [];
      for (const el of this.selectedDocuments) {
        obj.documents.push(el.id);
      }

      this.$store
        .dispatch('permitTypes/createType', obj)
        .then((data) => {
          this.$store.dispatch('permitTypes/retrieveTypes', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newTypeDialog = false;
          this.clearNewTypeForm();
          this.disabledEditButton = false;
        })
        .catch((e) => {
          let errorMsg = '';
          try {
            for (const m in e.response.data.data) {
              errorMsg += m + ': ' + e.response.data.data[m].join(' ');
            }
            this.newTypeAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message};
					<br>
					${errorMsg}`;
          } catch (e) {
            this.newTypeAlertText = 'Error occurred';
          }
          this.disabledEditButton = false;
        });
    },

    editType: function(obj) {
      this.newTypeAlertText = '';
      this.action = 'edit';
      this.selectedStatuses = [];
      this.selectedDocuments = [];
      this.newType.name = obj.name;
      this.newType.renewal_offset = obj.renewal_offset;
      this.newType.case_assessment = obj.case_assessment;
      this.newType.description = obj.description;
      this.newType.documents = obj.documents;
      this.newType.statuses = [];
      this.newType.id = obj.id;

      this.selectedStatuses = [];
      this.selectedStatusesOrdered = [];
      this.selectedDocuments = [];
      this.selectedDocumentsOrdered = [];
      for (const el of obj.statuses) {
        this.selectedStatuses.push({
          id: el,
          name: this.$store.getters['permitStatuses/statusById'](el)['name'],
        });
        this.selectedStatusesOrdered.push({
          id: el,
          name: this.$store.getters['permitStatuses/statusById'](el)['name'],
        });
      }

      for (const el of obj.documents) {
        this.selectedDocuments.push({
          id: el,
          name: this.$store.getters['permitDocuments/documentById'](el)['name'],
        });
        this.selectedDocumentsOrdered.push({
          id: el,
          name: this.$store.getters['permitDocuments/documentById'](el)['name'],
        });
      }

      this.editOriginalType = JSON.parse(JSON.stringify(this.newType));
      this.editOriginalType.statuses = obj.statuses;
      this.editOriginalType.documents = obj.documents;

      this.newTypeDialog = true;
    },
    saveType: function() {
      this.disabledEditButton = true;
      this.newTypeAlertText = '';

      if (this.validateNewTypeForm() !== true) {
        this.disabledEditButton = false;
        return;
      }

      const obj = {};
      for (const k in this.newType) obj[k] = this.newType[k];

      obj.renewal_offset = obj.renewal_offset;

      obj.statuses = [];
      for (const el of this.selectedStatuses) {
        obj.statuses.push(el.id);
      }

      obj.documents = [];
      for (const el of this.selectedDocuments) {
        obj.documents.push(el.id);
      }

      const diffObj = {};
      for (const key in this.editOriginalType) {
        // primitive
        if (typeof this.editOriginalType[key] !== 'object') {
          if (this.editOriginalType[key] !== obj[key]) {
            diffObj[key] = obj[key];
          }
        }
        // array
        else if (Array.isArray(this.editOriginalType[key])) {
          if (this.editOriginalType[key].join() !== obj[key].join()) {
            diffObj[key] = obj[key];
          }
        }
        // object
        else {
          for (const key2 in obj[key]) {
            if (this.editOriginalType[key][key2] !== obj[key][key2]) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = obj[key][key2];
            }
          }
        }
      }

      this.$store
        .dispatch('permitTypes/editType', {
          id: this.newType.id,
          type: diffObj,
        })
        .then((data) => {
          this.$store.dispatch('permitTypes/retrieveTypes', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newTypeDialog = false;
          this.clearNewTypeForm();
          this.disabledEditButton = false;
        })
        .catch((e) => {
          this.newTypeAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledEditButton = false;
        });
    },
    deleteTypeOpenDialog: function(obj) {
      this.deleteTypeAlertText = '';
      this.deleteType.name = obj.name;
      this.deleteType.id = obj.id;
      this.deleteTypeDialog = true;
    },
    deleteTypeMethod: function() {
      this.disabledDeleteButton = true;
      this.deleteTypeAlertText = '';
      this.$store
        .dispatch('permitTypes/deleteType', this.deleteType.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitTypes/retrieveTypes', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteTypeDialog = false;
          this.disabledDeleteButton = false;
        })
        .catch((e) => {
          this.deleteTypeAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledDeleteButton = false;
        });
    },
  },
};
</script>
<style scoped>
</style>
