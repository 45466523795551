const sanitizeHtml = require('sanitize-html');
const sanitizeOpts = {
  allowedTags: ['br'],
  allowedAttributes: {},
};

module.exports = {
  createErrorHtml: (data) => {
    try {
      let txt = data.message.capitalize() + '<br>';
      if (Array.isArray(data.data)) {
        for (const e of data.data) {
          for (const ee in e) {
            txt += `${ee}: ${e[ee].join(', ')}<br>`;
          }
        }
      } else {
        for (const k in data.data) {
          if (k === 'profile') {
            for (const kk in data.data[k])
              txt += `${k} - ${kk}: ${data.data[k][kk].join(', ')}<br>`;
          } else {
            if (typeof data.data[k] === 'object' && data.data[k] !== null)
              txt += `${k}: ${JSON.stringify(data.data[k])}<br>`;
            else
              txt += `${k}: ${data.data[k]}<br>`;
          }
        }
      }

      return sanitizeHtml(txt, sanitizeOpts);
    } catch {
      return `Error occurred: ${sanitizeHtml(JSON.stringify(data), sanitizeOpts) }`;
    }
  },

  removeAllLocalStorage: () => {
    localStorage.removeItem('itrack_core_timezones');
    localStorage.removeItem('itrack_core_countries');
    localStorage.removeItem('quest_positions');

    localStorage.removeItem('itrack_notifs_filters');

    localStorage.removeItem('itrack_forward_to_foreigner');
    localStorage.removeItem('itrack_forward_to_messaging');
    localStorage.removeItem('itrack_forward_to_questionnaires');

    localStorage.removeItem('itrack_for_forward_to_q');
    localStorage.removeItem('itrack_for_forward_to_permit');
    localStorage.removeItem('itrack_for_forward_to_permit_document');
    localStorage.removeItem('itrack_for_forward_to_messaging');

    localStorage.removeItem('itrack_adm_forward_to_foreigner');
    localStorage.removeItem('itrack_adm_forward_to_foreigner_permit');
    localStorage.removeItem('itrack_adm_forward_to_foreigner_permit_documents');
    localStorage.removeItem('itrack_adm_forward_to_foreigner_messaging');
    localStorage.removeItem('itrack_adm_forward_to_foreigner_questionnaires');

    localStorage.removeItem('itrack_forward_to_me');

    localStorage.removeItem('itrack_flagPermit');
    localStorage.removeItem('itrack_flagDocument');

    for (const el of Object.keys(localStorage).filter((el) => el.includes('itrack_msg'))) {
      localStorage.removeItem(el);
    }
  },

  removeLocalStorageFiltersRpp: () => {
    localStorage.removeItem('itrack_foreigner_filters');
    localStorage.removeItem('itrack_foreigner_data');
    localStorage.removeItem('itrack_rpp_admin_fors');
    localStorage.removeItem('itrack_foreigners_data');

    localStorage.removeItem('itrack_notifs_filters');

    localStorage.removeItem('itrack_refresh_admin_agency_comp');
    localStorage.removeItem('itrack_refresh_admin_agency_user');
    localStorage.removeItem('itrack_refresh_admin_cli_comp');
    localStorage.removeItem('itrack_refresh_admin_cli_user');
    localStorage.removeItem('itrack_refresh_admin_int_user');
    localStorage.removeItem('itrack_refresh_admin_int_places');
    localStorage.removeItem('itrack_refresh_admin_perm_types');
    localStorage.removeItem('itrack_refresh_admin_perm_doc');
    localStorage.removeItem('itrack_refresh_admin_perm_date');
    localStorage.removeItem('itrack_refresh_admin_perm_stat');
    localStorage.removeItem('itrack_refresh_admin_perm_types');
  },

  removeAllLocalStorageForwarding: () => {},
};
