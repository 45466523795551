import 'core-js';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import icons from './plugins/icons';
import './plugins/recaptcha';
import VueSanitize from 'vue-sanitize';

Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.prototype.$whistory = window.history;
Vue.prototype.$refreshCounter = 0;
Vue.prototype.$refreshCounterLimit = 30;
Vue.prototype.$refreshNotificationsInterval = null;

Vue.prototype.$msgColours = {
  c1: '#ffcccb',
  c1d: '#ef9a9a',
  c2: '#ffc4ff',
  c2d: '#ce93d8',
  c3: '#d1d9ff',
  c3d: '#9fa8da',
  c4: '#b6ffff',
  c4d: '#81d4fa',
  c5: '#b2fef7',
  c5d: '#80cbc4',
  c6: '#f8ffd7',
  c6d: '#c5e1a5',
  c7: '#ffffcf',
  c7d: '#fff59d',
  c8: '#ffffb0',
  c8d: '#ffcc80',
  c9: '#ffc1e3',
  c9d: '#f48fb1',
  c10: '#e6ceff',
  c10d: '#b39ddb',
  c11: '#c3fdff',
  c11d: '#90caf9',
  c12: '#b4ffff',
  c12d: '#80deea',
  c13: '#d7ffd9',
  c13d: '#a5d6a7',
  c14: '#ffffce',
  c14d: '#e6ee9c',
  c15: '#ffffb3',
  c15d: '#ffe082',
  c16: '#ffddc1',
  c16d: '#ffab91',
};
Vue.prototype.$roles = {
  ADMIN: 10,
  CONSULTANT: 11,
  HR: 20,
  FOREIGNER: 30,
  FAMILY: 31,
  AGENCY: 40,

  // TODO: LEGACY REMOVE
  INT: {
    ADMIN: 10,
    CONS: 11,
  },
  CLI: {
    HR: 20,
  },
  FOR: {
    USER: 30,
    FAMILY: 31,
  },
  AGE: {
    USER: 40,
  },
};
Vue.prototype.$appColours = {
  button: {
    cancel: 'yellow',
    edit: 'yellow darken-2',
    ok: 'green',
    'delete': 'red',
    action: 'blue lighten-1',
    close: 'grey darken-3',
  },
  status: {
    documents: {
      '10': 'red',
      '20': 'yellow',
      '30': 'green',
    },
    others: {
      active: 'green',
      notactive: 'grey lighten-1',
    },
  },

};
Vue.prototype.$randomString = function() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < 9; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
Vue.prototype.$browserLangCode = (navigator.language || navigator.userLanguage).split('-')[0];

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
String.prototype.capitalize = function() {
  return capitalize(this);
};
Vue.prototype.$capitalize = capitalize;
Vue.prototype.$lowercase = (string) => string.toLowerCase();

Vue.prototype.$isEmailValid = (email) => {
  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegex.test(email);
};

const sanitizeOpts = {
  allowedTags: ['br', 'b'],
  allowedAttributes: {},
  parser: {
    decodeEntities: false,
  },
};
Vue.use(VueSanitize, sanitizeOpts);
Vue.use(icons);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      this.$store.commit('auth/SET_TOKEN_DATA', tokenString);
    }
  },

  render: (h) => h(App),
}).$mount('#app');
