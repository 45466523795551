<template>
  <div class="MobileLandingPage">
    <div>
      <br>
    </div>

    <div>
      <div>
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="76"
            height="76"
            rx="16"
            fill="#BB2F56"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.255 38.313c-.11-2.626-1.323-5.067-1.434-7.693 2.491.818 4.921 1.087 6.772 3.27 2.238 2.636 1.935 6.133 1.635 9.386-.498 5.417-.997 10.938-.997 16.38 3.888 0 8.125.489 11.952-.248-.5-4.109-.465-8.28-.738-12.409-.317-4.799-2.083-10.514 2.173-14.051.71-.59 1.538-1.041 2.39-1.366 1.065-.406 2.219-.62 3.347-.714-.053 2.64-1.194 5.028-1.196 7.693 2.053-.92 3.74-2.595 5.498-4 3.036-2.427 6.188-4.814 9.084-7.416-1.016-1.377-2.506-2.39-3.825-3.444-1.856-1.484-3.666-3.03-5.498-4.546-1.601-1.325-3.266-3.06-5.259-3.674.007 2.647 1.143 5.065 1.196 7.694-2.816 0-5.532.352-8.128 1.566-1.057.495-2.12 1.043-3.107 1.676-.566.363-1.219 1.015-1.913 1.06-.835.055-1.717-.85-2.39-1.274a19.326 19.326 0 0 0-4.542-2.105c-1.392-.435-2.851-.777-4.302-.902-.61-.052-1.516.033-1.865-.625-.313-.59.026-1.525.164-2.126.368-1.614 1.181-3.313.744-4.963-3.808 1.967-7.176 5.377-10.517 8.084-.902.73-3.183 1.937-3.503 3.126-.35 1.301 2.216 2.68 3.025 3.289a188.786 188.786 0 0 1 6.932 5.465c1.349 1.12 2.546 2.537 4.302 2.867Z"
            fill="#fff"
          />
        </svg>
      </div>

      <h1>
        <span>i</span>Track
      </h1>

      <p>
        iTrack is a unique solution offered to clients of CzechRelocate. Try our app for the best experience <!-- TODO: translate -->
      </p>

      <a :href="appLink">
        <button class="Button">
          Get the app <!-- TODO: translate -->
        </button>
      </a>

      <div>
        <router-link to="/reset_password">
          Reset password <!-- TODO: translate -->
        </router-link>
        <span>
          or <!-- TODO: translate -->
        </span>
        <a href="mailto:help@app.itrack.cz">
          Contact us <!-- TODO: translate -->
        </a>
      </div>
    </div>

    <div>
      <br>
      <router-link to="/login?noMobileRedirect=true">
        Continue to desktop version <!-- TODO: translate -->
      </router-link>
    </div>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';

export default {
  computed: {
    appLink() {
      if (isMobile().apple.device) {
        return 'https://apps.apple.com/cz/app/itrack/id1572514911';
      }

      if (isMobile().android.device) {
        return 'https://play.google.com/store/apps/details?id=cz.czechrelocate.itrack';
      }

      return '/login?noMobileRedirect=true';
    },
  },
};
</script>

<style lang="scss" scoped>
.MobileLandingPage {
  $PrimaryColor: #BB2F56;
  $LightColor: #9591AB;
  text-align: center;
  width: 90%;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;

  a {
    color: $PrimaryColor;
    text-decoration: none;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    span {
      color: $PrimaryColor;
    }
  }

  p {
    color: $LightColor;
    margin-bottom: 1.5rem;
    letter-spacing: 0.1px;
    font-size: 1rem;
  }

  .Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1.5rem;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;

    background: $PrimaryColor;
    border-radius: 0.75rem;
    color: #fff;
  }
}
</style>
