export default {
  en: {
    'adm.reset_password.autorefresh.text': 'The link you have used is unfortunately already expired. You shall receive a new link to your email address within one minute.',
    'adm.reset_password.expired.text': 'Reset link has expired. Please, try sending new password reset request again.',
    'adm.reset_password.expired.title': 'Password reset',

    'common.actions.back_to_login': 'Back to login',
    'common.actions.login': 'Login',
    'common.actions.save': 'Save',
    'common.actions.send': 'Send',
    'common.fields.login': 'Username / Email',
    'common.fields.new_password': 'New password',
    'common.fields.new_password_confirm': 'New password confirm',
    'common.fields.password': 'Password',
    'common.fields.password_confirm': 'Password confirm',
    'common.values.no_data': 'No data',

    'main.login.forgot_pass': 'Forgot password?',
    'main.login.a1': 'iTrack is a unique solution offered to clients of CzechRelocate. iTrack is designed to make your relocation to the Czech Republic simple and straightforward. It provides seamless collection of documents required for Czech work permit, residence permit and other official applications. As an iTrack user you gain full control over your applications - you can easily track the status, follow the progress and exchange instant messages with the CzechRelocate team.',
    'main.login.a2': 'iTrack serves exclusively the clients of CzechRelocate. Please contact us to find out how we can assist with your relocation to the Czech Republic',
    'main.login.a3': 'Please follow <a :href=\'BASE_HOST\' + \'reset_password\'>this link</a> in case you need to reset your password. Please <a href=\'mailto: help@app.itrack.cz\'>contact us</a> with any other questions.',
    'main.login.q1': 'What is iTrack?',
    'main.login.q2': 'How to become a user?',
    'main.login.q3': 'Already a user - need support?',
    'main.login.subtitle': 'Your shortcut to proper permits',
    'main.login.welcome': 'Welcome to',

    'main.recovery.pass_rule': 'Password must contain',
    'main.recovery.title_first': 'Password recovery',
    'main.recovery.title_second': 'Set new password',
  },
  ru: {
    'adm.reset_password.autorefresh.text': 'К сожалению, ссылка больше недействительна. На Вашу почту была отправлена новая ссылка.',
    'adm.reset_password.expired.text': 'Ссылка для обновления пароля недействительна. Пожалуйста, отправьте новый запрос.',
    'adm.reset_password.expired.title': 'Обновление пароля',

    'common.actions.back_to_login': 'Назад к логину',
    'common.actions.login': 'Войти',
    'common.actions.save': 'Сохранить',
    'common.actions.send': 'Отправить',
    'common.fields.login': 'Имя пользователя / Эл. почта',
    'common.fields.new_password': 'Новый пароль',
    'common.fields.new_password_confirm': 'Подтверждение нового пароля',
    'common.fields.password': 'Пароль',
    'common.fields.password_confirm': 'Подтверждение пароля',
    'common.values.no_data': 'Нет данных',

    'main.login.forgot_pass': 'Забыли пароль?',
    'main.login.a1': 'iTrack - это уникальное решение, предлагаемое клиентам CzechRelocate. iTrack разработан, чтобы сделать ваш переезд в Чешскую Республику простым и понятным. Он обеспечивает беспрепятственный сбор документов, необходимых для получения разрешения на работу в Чехии, вида на жительство и других официальных заявлений. Как пользователь iTrack вы получаете полный контроль над своими приложениями - вы можете легко отслеживать статус, следить за прогрессом и обмениваться мгновенными сообщениями с командой CzechRelocate.',
    'main.login.a2': 'iTrack обслуживает исключительно клиентов CzechRelocate. Свяжитесь с нами, чтобы узнать, как мы можем помочь с переездом в Чешскую Республику:',
    'main.login.a3': 'Пожалуйста, перейдите по <a :href="BASE_HOST +\'reset_password\'"> этой ссылке </a>, если вам нужно изменить пароль. Если у вас возникнут вопросы, <a href="mailto:help@app.itrack.cz"> свяжитесь с нами </a>.',
    'main.login.q1': 'Что такое iTrack?',
    'main.login.q2': 'Как стать пользователем?',
    'main.login.q3': 'Вы являетесь пользователем - нужна поддержка?',
    'main.login.subtitle': 'Ваш короткий путь для необходимых разрешений',
    'main.login.welcome': 'Добро пожаловать в',

    'main.recovery.pass_rule': 'Пароль должен содержать:',
    'main.recovery.title_first': 'Восстановление пароля',
    'main.recovery.title_second': 'Укажите новый пароль',
  },
};
