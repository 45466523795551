import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getNotifications(page, rows, seen, types) {
    let url = `${baseURL}/notification/?page=${page}&page_size=${rows}&is_seen=${seen}`;
    if (types)
      url += `&notification_types=${types}`;
    return axios.get(url);
  },
  getUnseenNotificationsCount() {
    return axios.get(`${baseURL}/notification/count/`);
  },
  seeNotification(nid) {
    return axios.patch(`${baseURL}/notification/${nid}/`, {
      seen: true,
    });
  },
  unseeNotification(nid) {
    return axios.patch(`${baseURL}/notification/${nid}/`, {
      seen: false,
    });
  },
};
