import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.ctrlKey)return null;return _vm.sendResetPass.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"s",undefined,$event.key,undefined))return null;if(!$event.ctrlKey)return null;return _vm.sendResetPass.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendResetPass.apply(null, arguments)}]},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.reset_dialog.title'))+" ")])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.reset_dialog.confirm_text'))+" "),(_vm.error.length > 0)?_c(VFlex,{attrs:{"xs12":""}},[(_vm.error.length > 0)?_c(VAlert,{attrs:{"type":"error"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1):_vm._e(),(_vm.success.length > 0)?_c(VFlex,{attrs:{"xs12":""}},[(_vm.success.length > 0)?_c(VAlert,{attrs:{"type":"success"},domProps:{"innerHTML":_vm._s(_vm.success)}}):_vm._e()],1):_vm._e()],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"yellow darken-1","outlined":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.close'))+" ")]),(_vm.showBtn)?[_c(VBtn,{attrs:{"loading":_vm.isLoading,"color":"green darken-1","outlined":""},on:{"click":_vm.sendResetPass}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.send'))+" ")])]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }