import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messagingComponent",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.isMessagingDialogOpen = false}}},[_c(VTabs,{attrs:{"color":"blue","grow":""},on:{"change":_vm.tabChanged},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.family),function(dialog){return _c(VTab,{key:dialog.foreigner_id},[_vm._v(" "+_vm._s(dialog.foreigner_fullname)+" "),(dialog.message_count > 0)?[_c(VBadge,{staticClass:"ml-3",attrs:{"color":"red"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(dialog.message_count)+" ")]},proxy:true}],null,true)})]:_vm._e()],2)}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.family),function(dialog){return _c(VTabItem,{key:dialog.foreigner_id},[_c(VCard,[_c(VCardText,[_c('ForeignerMessagingChat',{attrs:{"fid":dialog.foreigner_id,"fullscreen":_vm.isMessagingDialogOpen},on:{"update-is-messaging-dialog-open":(payload) => _vm.isMessagingDialogOpen = payload}})],1)],1)],1)}),1),_c(VDialog,{staticClass:"dialModal",staticStyle:{"max-height":"100% !important","height":"100% !important"},attrs:{"persistent":"","max-width":"100%","height":"100%","fullscreen":"true"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.isMessagingDialogOpen = false}},model:{value:(_vm.isMessagingDialogOpen),callback:function ($$v) {_vm.isMessagingDialogOpen=$$v},expression:"isMessagingDialogOpen"}},[_c(VCard,[_c(VCardText,{staticStyle:{"padding-top":"15px"}},[_c('ForeignerMessagingChat',{attrs:{"fid":_vm.foreigner.id,"fullscreen":_vm.isMessagingDialogOpen,"is-in-dialog":""},on:{"update-is-messaging-dialog-open":(payload) => _vm.isMessagingDialogOpen = payload}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }