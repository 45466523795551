import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  resetUserPassword(username) {
    return axios.post(`${baseURL}/user/auth/reset_password/`, {
      'username': username,
    });
  },
  resetForeignerPassword(id) {
    return axios.post(`${baseURL}/foreigner/${id}/reset_password/`, {});
  },
  sendActivationEmail(id, email) {
    //console.log("sending to ", email);
    return axios.post(`${baseURL}/foreigner/${id}/reset_password/`, {
      'email': email,
    });
  },
  setNewUserPassword(uid, password, password_confirm) {
    return axios.post(`${baseURL}/user/auth/reset_password/${uid}/`, {
      password,
      password_confirm,
    });
  },
  simulateRedirect(uid) {
    return axios.get(`${baseURL}/user/auth/reset_password/${uid}/`);
  },
  getPasswordPolicies() {
    return axios.get(`${baseURL}/user/auth/password_policy/`);
  },
};
