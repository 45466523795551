import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getClientsCompanies(page, rows, order_by, isActive) {
    let afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    if (isActive != undefined && isActive.length !== 0)
      afterUrl += `&is_active=${isActive}`;

    return axios.get(`${baseURL}/client_company/${afterUrl}`);
  },
  getClientsCompanyDetails(id) {
    return axios.get(`${baseURL}/client_company/${id}/`);
  },
  createClientsCompany(obj) {
    return axios.post(`${baseURL}/client_company/`, obj);
  },
  aresSearch(name, ico) {
    if (name === '' || name == undefined)
      return axios.get(`${baseURL}/client_company/ares/?ico_number=${ico}&page_size=200`);
    else if (ico === '' || ico == undefined)
      return axios.get(`${baseURL}/client_company/ares/?company_name=${name}&page_size=200`);
    else
      return axios.get(`${baseURL}/client_company/ares/?company_name=${name}&ico_number=${ico}&page_size=200`);
  },
  editCompany(obj, id) {
    return axios.patch(`${baseURL}/client_company/${id}/`, obj);
  },
  deleteCompany(id) {
    return axios.delete(`${baseURL}/client_company/${id}/`);
  },
};
