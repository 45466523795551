import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getForeignerAuditEvents() {
    return axios.get(`${baseURL}/audit/event/foreigner/`);
  },
  getAdminAuditEvents() {
    return axios.get(`${baseURL}/audit/event/administration/`);
  },
  getAuditSeverities() {
    return axios.get(`${baseURL}/audit/severity/`);
  },
  getGenders() {
    return axios.get(`${baseURL}/core/genders/`);
  },
  getInternalUserRoles() {
    return axios.get(`${baseURL}/user/internal/role/`);
  },
  getClientUserRoles() {
    return axios.get(`${baseURL}/user/client/role/`);
  },
  getAgencyUserRoles() {
    return axios.get(`${baseURL}/user/agency/role/`);
  },
  getCountries(size, page) {
    return axios.get(`${baseURL}/core/country/?page=${page}&page_size=${size}`);
  },
  getTimezones(size, page) {
    return axios.get(`${baseURL}/core/time_zone/?page=${page}&page_size=${size}`);
  },
  getMe() {
    return axios.get(`${baseURL}/user/me/`);
  },
  getSurveyTypes() {
    return axios.get(`${baseURL}/survey/type/`);
  },
  getMaritalStatuses() {
    return axios.get(`${baseURL}/survey/marital_status/`);
  },
  getSupportSpecifications() {
    return axios.get(`${baseURL}/survey/support_specification/`);
  },
  getSupportTimelines() {
    return axios.get(`${baseURL}/survey/support_timeline/`);
  },
  getInterfaceLanguages() {
    return axios.get(`${baseURL}/core/interface_language/`);
  },
  getCurrentLanguage() {
    return axios.get(`${baseURL}/user/me/translation/`);
  },
  getEnums() {
    return axios.get(`${baseURL}/core/enum/`);
  },
};
