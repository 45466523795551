import Vue from 'vue';
import Vuex from 'vuex';

import AgencyUsersService from '../../services/administration/AgencyUsersService';
import InternalUserService from '../../services/administration/InternalUsersService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    users: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setUsersAndParams(state, data) {
      // //console.log("Setting", data);
      state.users = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
      // //console.log("new users:", state.users)
    },
  },
  actions: {
    retrieveUsers(context, params) {
      // //console.log("PARAMS", params)
      AgencyUsersService.getClientsUsers(params.page, params.rows, params.order_by, params.is_active)
        .then((data) => {
          context.commit('setUsersAndParams', data.data);
        }).catch((error) => {
          /*if (error.response.status === 401){
          	context.dispatch('auth/refreshToken', {}, {root:true}).then(_ => {
          		AgencyUsersService.getClientsUsers(params.page, params.rows, params.order_by).then( data => {
          			context.commit('setUsersAndParams', data.data)
          		})
          	})
          }*/
        });
    },
    createUser(context, obj) {
      return AgencyUsersService.createClientsUser(obj);
    },
    getUserDetails(context, id) {
      return AgencyUsersService.getClientsUserDetails(id);
    },
    editUser(context, obj) {
      return AgencyUsersService.editClientsUser(obj.user, obj.id);
    },
    uploadAvatar(context, data) {
      return AgencyUsersService.uploadAgencyUserAvatar(data.id, data.avatar);
    },
    deleteUser(context, id) {
      return AgencyUsersService.deleteClientsUser(id);
    },
  },
  getters: {
    tableData: (state) => {
      // //console.log("HMMMMMM", state.users)
      const data = [];

      for (const u of state.users) {
        data.push({
          id: u.id,
          fullname: u.first_name + ' ' + u.last_name,
          is_active: u.is_active,
          last_login: u.last_login,
          username: u.username,
          email: u.email,
          phone_primary: u.phone_primary,
          phone_primary_replaced: u.phone_primary.replace(/[() ]/g, ''),
          client_company: u.client_company,
          avatar_url: u.avatar_url['32'],
          agency_company: u.agency_company,
          address_city: u.address_city,
          address_street: u.address_street,
          address_zip: u.address_zip,
          address_house_number: u.address_house_number,
        });
      }
      // //console.log("====RETURN", data)

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.users.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.users.filter((el) => el.is_active === true)) {
        arr.push({
          id: u.id,
          fullname: u.first_name + ' ' + u.last_name,
        });
      }
      return arr;
    },
  },
});
