import Vue from 'vue';
import Vuex from 'vuex';
import ForeignersQuestionnaireService from '../../services/foreigners/ForeignersQuestionnaireService';


Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    foreignersQuestionnaires: [],
    currentQuestionnaire: [],
  },
  mutations: {
    setQuestionnaires(state, data) {
      state.foreignersQuestionnaires = data.data;
    },
    setQuestionnaire(state, data) {
      state.currentQuestionnaire = data.data;
    },
  },
  actions: {
    retrieveForeignersQuestionnaires(context, params) {
      return new Promise((resolve, reject) => {
        ForeignersQuestionnaireService.getQuestionnaires(params.fid).then((data) => {
          context.commit('setQuestionnaires', data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveForeignersSelectedQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        ForeignersQuestionnaireService.getQuestionnaire(params.fid, params.qid)
          .then((data) => {
            context.commit('setQuestionnaire', data.data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
      });
    },
    editQuestionnaire(context, params) {

    },
    addDataQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        ForeignersQuestionnaireService.sendDataForQuestionnaire(params.fid, params.qid, params.survey_json, params.completed)
          .then((data) => {
            context.commit('setQuestionnaire', data.data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
      });
    },
    addForeignerQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        ForeignersQuestionnaireService.createQuestionnaireForForeigner(params.fid, params.survey_type, params.enable_notification).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteQuestionnaire(context, params) {
      return new Promise((resolve, reject) => {
        ForeignersQuestionnaireService.deleteQuestionnaire(params.fid, params.qid).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

  },
  getters: {
    foreignersQuestionnaires: (state) => {
      return state.foreignersQuestionnaires;
    },
    currentQuestionnaire: (state) => {
      return state.currentQuestionnaire;
    },
  },
});
