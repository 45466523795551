import Vue from 'vue';
import Vuex from 'vuex';

import PermitsService from '../../services/foreignerApi/PermitsService';
import ForeignerService from '../../services/foreigners/ForeignersService';
import CoreDataService from '../../services/CoreDataService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    permits: [],
    permitsWithFid: {},

    currentPermitStatuses: [],
    currentPermitDocuments: [],
  },
  mutations: {
    setPermits(state, data) {
      let j = 1;
      for (let i = data.data.length - 1; i >= 0; i--) {
        data.data[i]['color'] = 'c' + j;
        j += 1;
      }

      state.permits = data.data;
    },
    setPermitsWithFid(state, d) {
      let j = 1;
      for (let i = d.data.data.length - 1; i >= 0; i--) {
        d.data.data[i]['color'] = 'c' + j;
        j += 1;
      }

      Vue.set(state.permitsWithFid, d.fid, d.data.data);
    },
    setCurrentPermitStatuses(state, data) {
      for (const s of data.data)
        for (const a of s.action_dates)
          a.showToolTip = false;

      state.currentPermitStatuses = data.data;
    },
    setCurrentPermitDocuments(state, data) {
      for (const d of data.data)
        d.showToolTip = false;

      state.currentPermitDocuments = data.data;
    },
  },
  actions: {
    retrievePermits(context, params) {
      return new Promise((resolve, reject) => {
        PermitsService.getPermits(1, 50).then((data) => {
          context.commit('setPermits', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrievePermitsWithFid(context, params) {
      return new Promise((resolve, reject) => {
        PermitsService.getPermitsWithFid(1, 200, params.fid).then((data) => {
          context.commit('setPermitsWithFid', {
            data: data.data,
            fid: params.fid,
          });
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrievePermitStatus(context, params) {
      return new Promise((resolve, reject) => {
        PermitsService.getPermitStatus(params.pid).then((data) => {
          context.commit('setCurrentPermitStatuses', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrievePermitDocuments(context, params) {
      return new Promise((resolve, reject) => {
        PermitsService.getPermitDocument(params.pid).then((data) => {
          context.commit('setCurrentPermitDocuments', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
  },
  getters: {
    data: (state) => {
      return state.permits;
    },
    dataWithFid: (state) => {
      return state.permitsWithFid;
    },
    currentPermitData: (state) => {
      return {
        statuses: state.currentPermitStatuses,
        documents: state.currentPermitDocuments,
      };
    },
  },
});
