import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import InternalClientsService from '../../services/administration/ClientsUsersService';
import InternalUserService from '../../services/administration/InternalUsersService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    users: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setUsersAndParams(state, data) {
      state.users = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
  },
  actions: {
    retrieveUsers(context, params) {
      InternalClientsService.getClientsUsers(params.page, params.rows, params.order_by, params.is_active)
        .then((data) => {
          context.commit('setUsersAndParams', data.data);
        }).catch((error) => {
          /*if (error.response.status === 401){
          	context.dispatch('auth/refreshToken', {}, {root:true}).then(_ => {
          		InternalClientsService.getClientsUsers(params.page, params.rows, params.order_by).then( data => {
          			context.commit('setUsersAndParams', data.data)
          		})
          	})
          }*/
        });
    },
    createUser(context, obj) {
      return InternalClientsService.createClientsUser(obj);
    },
    uploadAvatar(context, data) {
      return InternalClientsService.uploadClientsUserAvatar(data.id, data.avatar);
    },
    getUserDetails(context, id) {
      return InternalClientsService.getClientsUserDetails(id);
    },
    editUser(context, obj) {
      return InternalClientsService.editClientsUser(obj.user, obj.id);
    },
    deleteUser(context, id) {
      return InternalClientsService.deleteClientsUser(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];
      for (const u of state.users) {
        data.push({
          id: u.id,
          fullname: u.first_name + ' ' + u.last_name,
          is_active: u.is_active,
          last_login: u.last_login,
          username: u.username,
          email: u.email,
          phone_primary: u.phone_primary,
          phone_primary_replaced: u.phone_primary.replace(/[() ]/g, ''),
          client_company: u.client_company,
          client_company_id: u.client_company_id,
          avatar_url: u.avatar_url['32'],
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.users.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.users.filter((el) => el.is_active === true)) {
        arr.push({
          id: u.id,
          fullname: u.first_name + ' ' + u.last_name,
        });
      }
      return arr;
    },
  },
});
