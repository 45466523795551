<template>
  <v-container
    id="containerLogin"
    fluid
    fill-height
    style="margin-top: 18vh"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md6
      >
        <v-card
          class="elevation-12"
          @keyup.enter="login"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('adm.reset_password.expired.title') }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <v-card-text
            class="text-center"
            style="font-size: 14pt"
          >
            {{ $t('adm.reset_password.expired.text') }}
          </v-card-text>

          <v-card-actions>
            <v-btn
              outlined
              color="primary lighten-3"
              @click="$router.push('/login')"
            >
              {{ $t('common.actions.back_to_login') }}
            </v-btn>
            <!--<v-spacer></v-spacer>
						<v-btn @click="login" color="primary" :loading="loading" :disabled="loading">Login</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ResetLinkExpired',
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    debug: function() {
      // //console.log("Debug")
    },
  },
};
</script>

<style scoped>
</style>
