import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import baseURL from '../services/APIConfig';

import helpers from '../helpers';

Vue.use(Vuex);

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default ({
  namespaced: true,
  state: {
    token: null,
    refreshToken: null,
  },
  mutations: {
    SET_TOKEN_DATA(state, tokenString) {
      state.token = tokenString;
      localStorage.setItem('token', tokenString);

    },
    SET_TOKENS(state, tokens) {
      state.token = tokens[0];
      state.refreshToken = tokens[1];
      localStorage.setItem('token', tokens[0]);
      localStorage.setItem('refreshToken', tokens[1]);
      //axios.defaults.headers.common["Authorization"] = `JWT ${tokens[0]}`;

      const that = this;

      /*axios.interceptors.request.use(function (config) {
      	// Do something before request is sent
      	let token = localStorage.getItem('token');
      	if (token !== null)
      		config.headers.Authorization = `JWT ${token}`;
      	return config;
      }, function (error) {
      	// Do something with request error
      	return Promise.reject(error);
      });

      axios.interceptors.response.use(
      	response => {
      		// //console.log("RESPONSE GOOD1")
      		return response
      	},
      	(error) => {
      		// //console.log("HERE SOME ERROR1", error.response)
      		if (error.response.status === 401) {
      			return this.dispatch("auth/refreshToken").then(async ({data}) => {
      				//that.commit("auth/SET_TOKEN_DATA", data.token);
      				state.token = data.token;
      				localStorage.setItem("token", data.token);
      				// //console.log("BEFORE:", axios.defaults.headers.common["Authorization"]);
      				axios.defaults.headers.common["Authorization"] = `JWT ${data.token}`;
      				// //console.log("AFTER:", axios.defaults.headers.common["Authorization"]);
      				await sleep(1000);
      				return axios.request(error.config);
      			});


      			// // //console.log("YES!")
      			// this.dispatch("auth/logout");
      		}
      		return Promise.reject(error);
      	}
      )*/
    },
    CLEAR_TOKEN_DATA(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      //location.reload();
      location.replace('/login');
    },
  },
  actions: {
    login({
      commit,
    }, credentials) {
      return axios
        .post(baseURL + '/user/auth/obtain_token/', credentials)
        .then(({
          data,
        }) => {
          commit('SET_TOKENS', [data.token, data.refresh_token]);
        });
    },
    refreshToken({
      commit,
      dispatch,
    }, payload) {
      if (!localStorage.getItem('token')) {
        dispatch('auth/logout');
        return;
      }
      // //console.log("Refreshing token...")
      const refreshToken = localStorage.getItem('refreshToken');
      //return axios.post(baseURL+'/user/auth/refresh_token/', {token: refreshToken}, {headers: {'Authorization': `JWT ${refreshToken}`}})
      /*.then(({data}) => {
      	commit("SET_TOKEN_DATA", data.token);
      })*/
      return new Promise((resolve, reject) => {
        axios.post(baseURL + '/user/auth/refresh_token/', {
          token: refreshToken,
        }, {
          headers: {
            'Authorization': `JWT ${refreshToken}`,
            'X-IT-CODE-LOCATION': payload && payload.location ? payload.location : 'unknown',
          },
        })
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
      });
    },
    verifyToken({
      commit,
    }) {
      return new Promise((resolve, reject) => {
        axios.post(baseURL + '/user/auth/verify_token/', {
          token: localStorage.getItem('token'),
        })
          .then((response) => {
            resolve(response);
          }, (error) => {
            reject(error);
          });
      });
    },
    async logout({
      commit,
    }) {
      await axios.delete(baseURL + '/user/auth/invalidate_token/', {
        refresh_token: localStorage.getItem('refreshToken'),
      }).catch((error) => console.error(error));

      helpers.removeAllLocalStorage();

      clearInterval(this.$refreshNotificationsInterval);
      commit('CLEAR_TOKEN_DATA');
    },
  },
  getters: {
    loggedIn(state) {
      return !!state.token;
    },
  },
});
