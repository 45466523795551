<template>
  <div>
    <div v-if="me.role && me.role !== $roles.FOREIGNER">
      <v-flex row>
        <v-flex xs6>
          <v-flex class="mx-auto">
            <v-card-title>
              <v-icon>person</v-icon>
              {{ me.username }} {{ me.first_name }} {{ me.last_name }}
            </v-card-title>
            <v-card-text>
              <v-container
                v-if="editingProfile === false"
                grid-list-md
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.first_name') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.first_name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.last_name') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.last_name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.email') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.email }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.primary_phone') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.phone_primary }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.secondary_phone') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.phone_secondary }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('main.me.interface_language') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ interfaceLanguagesOptions.find(el => el.id === me.interface_language).name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Timezone:</b></v-flex>
                      <v-flex xs8>{{me.time_zone}}</v-flex>
                    </v-layout>
                  </v-flex>-->
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Role id:</b></v-flex>
                      <v-flex xs8>{{me.data.role}}</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Role:</b></v-flex>
                      <v-flex xs8>{{me.data.role_name}}</v-flex>
                    </v-layout>
                  </v-flex>-->
                  <v-flex
                    xs3
                    ml-auto
                  >
                    <v-btn
                      :color="$appColours.button.edit"
                      outlined
                      style="margin-left: 5px"
                      @click="editUserMethod"
                    >
                      {{ $t('common.actions.edit') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container
                v-else
                grid-list-md
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.first_name"
                          :label="$t('common.fields.first_name')"
                          required
                          :autocomplete="$randomString()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.last_name"
                          :label="$t('common.fields.last_name')"
                          required
                          :autocomplete="$randomString()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex
                        v-if="me.role === $roles.ADMIN"
                        xs10
                      >
                        <v-text-field
                          v-model="editUser.email"
                          :label="$t('common.fields.email')"
                          required
                          :autocomplete="$randomString()"
                        />
                      </v-flex>
                      <v-flex
                        v-else
                        xs10
                      >
                        <v-text-field
                          v-model="editUser.email"
                          :label="$t('common.fields.email')"
                          :autocomplete="$randomString()"
                          disabled
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.phone_primary"
                          :label="$t('common.fields.primary_phone')"
                          required
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.phone_secondary"
                          :label="$t('common.fields.secondary_phone')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <!--													<v-text-field :label="$t('main.me.interface_language')" v-model="editUser.interface_language"></v-text-field>-->
                        <v-autocomplete
                          v-model="editUser.interface_language"
                          name="lang"
                          :items="interfaceLanguagesOptions"
                          :label="$t('main.me.interface_language')"
                          item-text="name"
                          item-value="id"
                          :autocomplete="$randomString()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        &lt;!&ndash;													<v-text-field label="Timezone" v-model="me.data.time_zone"></v-text-field>&ndash;&gt;
                        <v-autocomplete v-model="editUser.time_zone"
                                        :items="timezoneOptions"
                                        :menu-props="{ maxHeight: '400' }"
                                        label="Timezone"
                                        item-text="name"
                                        item-value="name"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-flex>-->
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password_current"
                          type="password"
                          :label="$t('common.fields.password_current')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password"
                          type="password"
                          :label="$t('common.fields.new_password')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password_confirm"
                          type="password"
                          :label="$t('common.fields.new_password_confirm')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs10>
                    <v-alert
                      v-if="editUserAlertText.length > 0"
                      type="error"
                      v-html="editUserAlertText"
                    />
                  </v-flex>
                  <v-flex xs5>
                    <v-btn
                      outlined
                      :color="$appColours.button.action"
                      @click="$refs.avatarFile.click()"
                    >
                      {{ $t('common.fields.avatar_hint') }}
                    </v-btn>
                    <input
                      ref="avatarFile"
                      accept="image/*"
                      type="file"
                      style="display: none"
                      @change="uploadAvatar($event.target.files[0])"
                    >
                  </v-flex>
                  <v-flex
                    xs5
                    ml-auto
                  >
                    <v-btn
                      :color="$appColours.button.close"
                      outlined
                      style="margin-left: -5px"
                      @click="cancelEdit"
                    >
                      {{ $t('common.actions.cancel') }}
                    </v-btn>
                    <v-btn
                      :color="$appColours.button.ok"
                      outlined
                      style="margin-left: 5px"
                      @click="saveUser"
                    >
                      {{ $t('common.actions.save') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-flex>
        </v-flex>
        <v-flex xs6>
          <v-flex class="mx-auto mt-3">
            <v-card-text>
              <v-container grid-list-md>
                <v-layout
                  :key="randomKey"
                  wrap
                >
                  <v-flex xs12>
                    <v-layout wrap>
                      <h3>{{ $t('main.me.notifications_title') }}</h3>
                      <span
                        class="body-2"
                        style="font-size: 10pt !important"
                      ><i>{{ $t('main.me.notifications_hint') }}</i></span>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-for="(s, index) in me.notification_settings_global"
                    :key="index"
                    xs12
                    style="width: 100%"
                  >
                    <!--<v-checkbox
                          :class="`notifCheckbox ${(s.exceptions.length > 0) ? 'forceYellow': ''}`"
                                v-model="s.enabled"
                                :disabled="(s.can_be_override === false || globalNotifDisabled === true) ? true : false"
                                dense
                                :label="''"
                                :indeterminate="(s.exceptions.length > 0) ? true: false"
                                hide-details
                                :color="(s.exceptions.length > 0) ? 'yellow darken-1' : $appColours.button.action"
                                @change="function(value) {editNotificationSettingsModal(s, value)}"
                                style="width: 60%"
                    >-->
                    <v-checkbox
                      v-model="s.enabled"
                      :class="`notifCheckbox ${(s.exceptions.length > 0) ? 'forceYellow': ''}`"
                      :disabled="(s.can_be_override === false || globalNotifDisabled === true)"
                      dense
                      :label="''"
                      hide-details
                      :color="(s.exceptions.length > 0) ? 'yellow darken-1' : $appColours.button.action"
                      style="width: 60%"
                      @change="function(value) {editNotificationSettingsModal(s, value)}"
                    >
                      <template v-slot:append>
                        <span
                          :class="`mt-1 ${(s.exceptions.length > 0) ? 'pointerHover' : ''}`"
                          :title="(s.exceptions.length > 0) ? $t('main.me.notification_item_hint') : ''"
                          style="color: rgba(0, 0, 0, 0.6);"
                          @click="function(){
                            if (s.exceptions.length > 0) debug(s)
                          }"
                        >
                          {{ $capitalize(s.notification_type) }}
                        </span>
                        <template v-if="s.exceptions.length > 0">
                          <!--<div @click="debug(s)"
                                class="pt-1 pointerHover" style="color: rgba(0, 0, 0, 0.6);">
                            <span>
                              {{s.exceptions.length}}&nbsp;exceptions
                            </span>
                          </div>-->
                        </template>
                      </template>
                    </v-checkbox>
                    <div
                      v-if="s.exceptions.length > 0 && s.show_exceptions"
                      class="ml-9 pt-2"
                    >
                      <div class="">
                        <div
                          v-for="(ss, indexS) in s.exceptions"
                          :key="indexS"
                        >
                          <!--														<v-icon small>mdi-account</v-icon>-->
                          <v-icon
                            v-if="s.enabled === true"
                            small
                          >
                            mdi-close-box
                          </v-icon>
                          <v-icon
                            v-else
                            small
                          >
                            mdi-checkbox-marked
                          </v-icon>
                          <a
                            class="ml-2"
                            @click="switchToForeigner(ss.foreigner_id)"
                          >{{ ss.foreigner_fullname }}</a>
                        </div>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-flex>
        </v-flex>
      </v-flex>
    </div>

    <div v-if="me.role && me.role === $roles.FOREIGNER">
      <v-card
        width="90%"
        class="px-3 py-3"
        style="margin-left: 5%; margin-top: 15px"
      >
        <v-flex row>
          <v-flex xs6>
            <v-card-title>
              <v-icon>person</v-icon>
              {{ me.username }} {{ me.first_name }} {{ me.last_name }}
            </v-card-title>
            <v-card-text>
              <v-container
                v-if="editingProfile === false"
                grid-list-md
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.first_name') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.first_name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.last_name') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.last_name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.email') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.email }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.primary_phone') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.phone_primary }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('common.fields.secondary_phone') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.phone_secondary }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <b>{{ $t('main.me.interface_language') }}:</b>
                      </v-flex>
                      <v-flex xs8>
                        {{ me.interface_language_name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Timezone:</b></v-flex>
                      <v-flex xs8>{{me.time_zone}}</v-flex>
                    </v-layout>
                  </v-flex>-->
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Role id:</b></v-flex>
                      <v-flex xs8>{{me.data.role}}</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4><b>Role:</b></v-flex>
                      <v-flex xs8>{{me.data.role_name}}</v-flex>
                    </v-layout>
                  </v-flex>-->
                  <v-flex
                    xs3
                    ml-auto
                  >
                    <v-btn
                      color="primary"
                      style="margin-left: 5px"
                      @click="editUserMethod"
                    >
                      {{ $t('common.actions.edit') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container
                v-else
                grid-list-md
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.first_name"
                          :label="$t('common.fields.first_name')"
                          required
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.last_name"
                          :label="$t('common.fields.last_name')"
                          required
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.email"
                          :label="$t('common.fields.email')"
                          disabled
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.phone_primary"
                          :label="$t('common.fields.primary_phone')"
                          required
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.phone_secondary"
                          :label="$t('common.fields.secondary_phone')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <!--													<v-text-field :label="$t('main.me.interface_language')" v-model="editUser.interface_language_name"></v-text-field>-->
                        <v-autocomplete
                          v-model="editUser.interface_language"
                          name="lang"
                          :items="interfaceLanguagesOptions"
                          :label="$t('main.me.interface_language')"
                          item-text="name"
                          item-value="id"
                          :autocomplete="$randomString()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!--<v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        &lt;!&ndash;													<v-text-field label="Timezone" v-model="me.data.time_zone"></v-text-field>&ndash;&gt;
                        <v-autocomplete v-model="editUser.time_zone"
                                        :items="timezoneOptions"
                                        :menu-props="{ maxHeight: '400' }"
                                        label="Timezone"
                                        item-text="name"
                                        item-value="name"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-flex>-->
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password_current"
                          type="password"
                          :label="$t('common.fields.password_current')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password"
                          type="password"
                          :label="$t('common.fields.new_password')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs10>
                        <v-text-field
                          v-model="editUser.password_confirm"
                          type="password"
                          :label="$t('common.fields.new_password_confirm')"
                          required
                          :hint="$t('common.fields.password_hint')"
                          persistent-hint
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs10>
                    <v-alert
                      v-if="editUserAlertText.length > 0"
                      type="error"
                      v-html="editUserAlertText"
                    />
                  </v-flex>
                  <v-flex xs5>
                    <v-btn
                      text
                      outlined
                      color="primary"
                      @click="$refs.avatarFile.click()"
                    >
                      {{ $t('common.fields.avatar_hint') }}
                    </v-btn>
                    <input
                      ref="avatarFile"
                      accept="image/*"
                      type="file"
                      style="display: none"
                      @change="uploadAvatar($event.target.files[0])"
                    >
                  </v-flex>
                  <v-flex
                    xs5
                    ml-auto
                  >
                    <v-btn
                      outlined
                      @click="cancelEdit"
                    >
                      {{ $t('common.actions.cancel') }}
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      outlined
                      color="success"
                      @click="saveUser"
                    >
                      {{ $t('common.actions.save') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-flex>
          <v-flex xs6>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap>
                      <h3>{{ $t('main.me.notifications_title') }}</h3>
                    </v-layout>
                  </v-flex>

                  <v-checkbox
                    v-for="(s, index) in me.notification_settings_global"
                    :key="index"
                    v-model="s.enabled"
                    style="width: 100%"
                    :label="$capitalize(s.notification_type)"
                    :disabled="(s.can_be_override === false)"
                    dense
                    hide-details
                    @change="function(value) {editNotificationSettings(s, value)}"
                  />
                </v-layout>
              </v-container>
            </v-card-text>
          </v-flex>
        </v-flex>
      </v-card>
    </div>

    <v-dialog
      v-model="notificationDialog"
      persistent
      max-width="800px"
      @keydown.esc="notificationDialog = false"
      @keydown.meta.s="editNotificationSettings"
      @keydown.ctrl.s="editNotificationSettings"
      @keydown.meta.enter="editNotificationSettings"
      @keydown.ctrl.enter="editNotificationSettings"
      @keydown.enter="editNotificationSettings"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('common.actions.confirm_action') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('main.me.notifications_dialog_hint') }}
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="notificationDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.edit"
            outlined
            @click="editNotificationSettings"
          >
            {{ $t('common.actions.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from '@/services/UserService.js';
import { mapGetters } from 'vuex';
import baseURL from '../services/APIConfig';
import helpers from '../helpers';
import CoreDataService from '@/services/CoreDataService';

export default {
  name: 'UsersMe',
  data() {
    return {
      baseURL: baseURL,
      BASE_HOST: window.location,
      editingProfile: false,
      editUser: null,
      editUserAlertText: '',
      globalNotifDisabled: false,

      randomKey: '',

      notificationDialog: false,
      notificationEditVal: null,
      notificationEdit: null,
      randomKeys: [],
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      foreignersQuestionnaires: 'foreignerQuestionnaire/foreignersQuestionnaires',
      timezoneOptions: 'coreData/timezones',
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
    }),
  },
  created() {
    this.reloadRandomKeys();
  },
  methods: {
    debug(s) {
      s.show_exceptions = !s.show_exceptions;
      this.randomKey = this.$randomString();
    },
    reloadRandomKeys() {
      for (let i = 0; i < 40; i++) {
        this.randomKeys[i] = this.$randomString();
      }
    },
    switchToForeigner(fid) {
      this.$router.push(`/foreigners/${fid}/info/notifications`);
    },
    openNewWindow(url) {
      window.open(url, 'newwindow', 'width=1200,height=900');
      return false;
    },
    editUserMethod() {
      this.editUser = {
        first_name: this.me.first_name,
        last_name: this.me.last_name,
        email: this.me.email,
        phone_primary: this.me.phone_primary,
        phone_secondary: this.me.phone_secondary,
        interface_language: this.me.interface_language,
      };
      this.editingProfile = true;
    },
    async uploadAvatar(data) {
      await UserService.updateAvatar(data);
      await UserService.getUserMe();
      this.$store.dispatch('coreData/retrieveMe');
    },
    saveUser() {
      const that = this;

      if (this.me.password) {
      }

      //get diff with this.editUser
      const finalObj = {};
      const newUser = JSON.parse(JSON.stringify(this.editUser));

      for (const k in newUser) {
        if (newUser[k] !== this.me[k]) finalObj[k] = newUser[k];
      }
      this.editUserAlertText = '';

      let needChangeLanguage = null;
      if (finalObj.interface_language) {
        needChangeLanguage = finalObj.interface_language;
      }

      UserService.updateUser(finalObj)
        .then((_) => {
          that.editingProfile = false;
          this.$store.dispatch('coreData/retrieveMe');
          if (needChangeLanguage) {
            CoreDataService.getCurrentLanguage().then((data) => {
              //console.log("NEW LANG DATA", data.data);
              const code = this.interfaceLanguagesOptions.find(
                (el) => el.id === needChangeLanguage,
              ).name_text;

              localStorage.setItem('locale_code', code);
              const langData = data.data.data;
              for (const k in langData) {
                if (k !== 'main.login.a3') {
                  langData[k] = that.$sanitize(langData[k]);
                }
              }
              localStorage.setItem('locale_data', JSON.stringify(langData));
              this.$i18n.setLocaleMessage(
                code,
                JSON.parse(localStorage.getItem('locale_data')),
              );
              this.$i18n.locale = code;
            });
          }
        })
        .catch((err) => {
          this.editUserAlertText = helpers.createErrorHtml(err.response.data);
        });
    },
    async cancelEdit() {
      await UserService.getUserMe();
      this.editingProfile = false;
    },
    editNotificationSettingsModal(notification, val) {
      this.notificationEditVal = val;
      this.notificationEdit = notification;
      if (notification.exceptions.length > 0) {
        this.notificationDialog = true;
      } else {
        this.editNotificationSettings();
      }
    },
    async editNotificationSettings() {
      const notification = this.notificationEdit;
      const val = this.notificationEditVal;
      this.globalNotifDisabled = true;
      const notification_settings_global = [];
      for (const n of this.me.notification_settings_global) {
        if (n.id === notification.id) {
          // //console.log("HERE IT IS", n);
          notification_settings_global.push({
            id: n.id,
            enabled: val,
          });
        } else {
          notification_settings_global.push({
            id: n.id,
            enabled: n.enabled,
          });
        }
      }
      await UserService.updateUser({ notification_settings_global });
      await this.$store.dispatch('coreData/retrieveMe');
      this.globalNotifDisabled = false;
      this.notificationDialog = false;
    },
  },
};
</script>

<style>
.notifCheckbox .v-input__control {
  flex-grow: 0;
  width: 30px;
}
.forceYellow .v-icon,
.forceYellow .v-input--selection-controls__ripple.primary--text {
  color: #fdd835 !important;
  caret-color: #fdd835 !important;
}
</style>
