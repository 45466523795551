<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    persistent
    @keydown.ctrl.enter="saveFamily"
    @keydown.ctrl.s="saveFamily"
    @keydown.enter="saveFamily"
    @keydown.esc="isOpen = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('foreigners.basic_info.family_dialog.title') }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-flex
          v-if="isLoading"
          justify-center
          row
          class="pt-10 pb-10"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-flex>
        <v-container
          v-else
          grid-list-md
        >
          <v-flex
            mt-2
            row
          >
            <v-flex xs10>
              <div
                class="title font-weight-regular"
                style="color: #111111; display: inline"
              >
                {{ $t('foreigners.basic_info.family_dialog.new_member') }}
              </div>
            </v-flex>
            <v-flex
              class="text-right"
              xs2
              @click="addNewForeignerFamily"
            >
              <v-btn
                color="green"
                dark
                fab
                outlined
                x-small
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex
            v-for="(item, index) in editFamily.newForeigners.filter(Boolean)"
            :key="index"
            class="mt-2 pl-3"
            row
          >
            <v-flex xs5>
              <v-text-field
                v-model="editFamily.newForeigners[index].first_name"
                :label="`${$t('common.fields.first_name')} *`"
                class="pr-2"
                required
              />
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="editFamily.newForeigners[index].last_name"
                :label="`${$t('common.fields.last_name')} *`"
                class="pl-2"
                required
              />
            </v-flex>

            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.newForeigners[index].citizenship_id"
                :items="citizenshipOptions"
                :label="`${$t('common.fields.citizenship')} *`"
                class="pr-2"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.newForeigners[index].relation_type"
                :items="relationTypes"
                :label="`${$t('common.fields.relation_type')} *`"
                class="pl-2"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex
              text-right
              xs1
            >
              <v-btn
                class="mt-4"
                color="red darken-2"
                dark
                fab
                outlined
                x-small
                @click="removeEditForeigner('new', index)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>
          <hr
            class="mt-3"
            style="color: rgba(238,238,238,0.47)"
          >

          <v-flex
            mt-4
            row
          >
            <v-flex xs10>
              <div
                class="title font-weight-regular"
                style="color: #111111; display: inline"
              >
                {{ $t('foreigners.basic_info.family_dialog.new_member_existing') }}
              </div>
            </v-flex>
            <v-flex
              class="text-right"
              xs2
            >
              <v-btn
                color="green"
                dark
                fab
                outlined
                x-small
                @click="addExistingForeignerFamily"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex
            v-for="(item, index) in editFamily.newConnectionsToExisting.filter(Boolean)"
            :key="index"
            class="pl-3"
            row
          >
            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.newConnectionsToExisting[index].relation_with_id"
                :items="foreignerOptions"
                :label="`${$t('common.fields.foreigner')} *`"
                class="pr-2"
                item-text="fullname"
                item-value="id"
              />
            </v-flex>
            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.newConnectionsToExisting[index].relation_type"
                :items="relationTypes"
                :label="`${$t('common.fields.relation_type')} *`"
                class="pl-2"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex
              text-right
              xs1
            >
              <v-btn
                class="mt-4"
                color="red darken-2"
                dark
                fab
                outlined
                x-small
                @click="removeEditForeigner('exist', index)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>
          <hr
            class="mt-3"
            style="color: rgba(238,238,238,0.47)"
          >

          <v-flex
            mt-4
            row
          >
            <v-flex xs10>
              <div
                class="title font-weight-regular"
                style="color: #111111; display: inline"
              >
                {{ $t('foreigners.basic_info.family_dialog.edit_members') }}
              </div>
            </v-flex>
          </v-flex>
          <v-flex
            v-for="(item, index) in editFamily.existingForeigners.filter(Boolean)"
            :key="index"
            class="pl-3"
            row
            style="margin-bottom: 0"
          >
            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.existingForeigners[index].foreigner_id"
                :items="foreignerOptions"
                :label="`${$t('common.fields.foreigner')} *`"
                class="pr-2"
                item-text="fullname"
                item-value="id"
                readonly
              />
            </v-flex>
            <v-flex xs5>
              <v-autocomplete
                v-model="editFamily.existingForeigners[index].relationship_id"
                :items="relationTypes"
                :label="`${$t('common.fields.relation_type')} *`"
                class="pl-2"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex
              text-right
              xs1
            >
              <v-btn
                class="mt-4"
                color="red darken-2"
                dark
                fab
                outlined
                x-small
                @click="removeEditForeigner('delete', index)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>

          <v-flex xs12>
            <v-alert
              v-if="error.length > 0"
              type="error"
              v-html="error"
            />
          </v-flex>
        </v-container>
      </v-card-text>

      <v-divider />
      <v-card-actions v-if="!isLoading">
        <v-spacer />
        <v-btn
          color="yellow darken-1"
          outlined
          @click="isOpen = false"
        >
          {{ $t('common.actions.close') }}
        </v-btn>
        <v-btn
          :loading="disabledSaveButton"
          color="green darken-1"
          outlined
          @click="saveFamily"
        >
          {{ $t('common.actions.save_changes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import helpers from '@/helpers';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ForeignerBasicInfoFamilyDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabledSaveButton: false,
      error: false,
      isLoading: false,

      editFamily: {
        existingFamilyBonds: [],
        newForeigners: [
          //	{ relationship_id, first_name, last_name, citizenship_id }
        ],

        newConnectionsToExisting: [
          // relation_with_id , relation_type
        ],

        existingForeigners: [
          //	{ foreigner_id, relationship_id }
        ],

        toDelete: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      citizenshipOptions: 'coreData/countries',

      relationTypes: 'foreigners/relationTypes',
      foreignerOptions: 'foreigners/dataSimple',
    }),
  },

  methods: {
    async open() {
      this.isOpen = true;
      this.isLoading = true;

      await this.$store.dispatch('foreigners/retrieveForeignersSimple');

      this.editFamily.newForeigners = [];
      this.editFamily.existingForeigners = _cloneDeep(this.foreigner.family.map((foreigner) => ({
        id: foreigner.id,
        foreigner_id: foreigner.relation_with_id,
        relationship_id: foreigner.relation_type,
      })) || []);
      this.editFamily.existingFamilyBonds = _cloneDeep(this.editFamily.existingForeigners);

      this.isLoading = false;
    },

    saveFamily() {
      this.error = '';
      const promises = [];
      let addForeigners = [];
      if (this.editFamily.newConnectionsToExisting.length > 0) {
        for (const foreigner of this.editFamily.newConnectionsToExisting) {
          if (!foreigner.relation_with_id || !foreigner.relation_type) {
            this.error = 'Some fields are still empty';
            return;
          }
        }
        addForeigners = this.editFamily.newConnectionsToExisting;
      }
      if (this.editFamily.newForeigners.length > 0) {
        for (const foreigner of this.editFamily.newForeigners) {
          if (
            !foreigner.first_name ||
            !foreigner.last_name ||
            !foreigner.citizenship_id ||
            !foreigner.relation_type
          ) {
            this.error = 'Some fields are still empty';
            return;
          }
          const tmp = {
            first_name: foreigner.first_name,
            last_name: foreigner.last_name,
            citizenship_id: foreigner.citizenship_id,
          };
          addForeigners.push({
            new_user: tmp,
            relation_type: foreigner.relation_type,
          });
        }
      }

      let deleteFamilyMembers = [];
      if (this.editFamily.toDelete.length > 0) {
        deleteFamilyMembers = this.editFamily.toDelete.map((el) => (el = el.id));
        for (const member of deleteFamilyMembers) {
          promises.push(
            this.$store.dispatch('foreigners/deleteForeignerFamily', {
              fid: this.foreigner.id,
              rid: member,
            }),
          );
        }
      }

      const changeFamilyMembers = [];
      for (const foreigner of this.editFamily.existingForeigners) {
        const orig = this.editFamily.existingFamilyBonds.find((el) => el.foreigner_id === foreigner.foreigner_id);

        if (foreigner.relationship_id !== orig.relationship_id) {
          changeFamilyMembers.push(foreigner);
        }
      }

      // new foreigners and new connections with existing foreigners
      promises.push(
        this.$store.dispatch('foreigners/createForeignerFamily', {
          id: this.foreigner.id,
          family: addForeigners,
        }),
      );
      for (const c of changeFamilyMembers) {
        promises.push(
          this.$store.dispatch('foreigners/editForeignerFamily', {
            id: this.foreigner.id,
            fid: c.id,
            change: {
              relation_type: c.relationship_id,
            },
          }),
        );
      }

      this.disabledSaveButton = true;
      Promise.all(promises)
        .then(() => {
          this.$emit('refresh-foreigner', { foreigner: this.foreigner, tab: 0 });
          this.disabledSaveButton = false;
          this.isOpen = false;
        })
        .catch((error) => {
          this.disabledSaveButton = false;
          this.error = helpers.createErrorHtml(error.response.data);
        });
    },

    addExistingForeignerFamily() {
      this.editFamily.newConnectionsToExisting.push({
        relation_with_id: '',
        relation_type: '',
      });
    },

    addNewForeignerFamily() {
      this.editFamily.newForeigners.push({
        first_name: '',
        last_name: '',
        citizenship_id: '',
        relation_type: '',
      });
    },

    removeEditForeigner(type, num) {
      if (type === 'exist') {
        this.editFamily.newConnectionsToExisting.splice(num, 1);
      } else if (type === 'new') {
        this.editFamily.newForeigners.splice(num, 1);
      } else if (type === 'delete') {
        const tmp = this.editFamily.existingForeigners[num];
        this.editFamily.toDelete.push(tmp);
        this.editFamily.existingForeigners.splice(num, 1);
      }
    },
  },
};
</script>
