<template>
  <div>
    <MessagingToolbar
      :timer-active="timerActive"
      :filter-values="filterValues"
      :class="isInDialog ? '' : 'mt-2'"
      :is-in-dialog="isInDialog"
      :messaging-store="'foreignerMessaging'"
      :permits="permits"
      @scroll-to-prev="scrollToPrev"
      @scroll-to-next="scrollToNext"
      @refresh="refresh"
      @open-fullscreen="$emit('update-is-messaging-dialog-open', true); stopTimer()"
      @close-fullscreen="$emit('update-is-messaging-dialog-open', false)"
    />

    <v-container
      fluid
      class="elevation-1 mt-1"
    >
      <v-flex>
        <v-card class="mx-auto elevation-1">
          <v-card-text>
            <div
              ref="MessagesWrapper"
              :style="`height: ${(fullscreen) ? 'calc(100vh - 256px);':'45vh'}; overflow-y: auto; padding-right: 10px; display: ${isMessagingDialogOpen ? 'none' : 'block'}`"
            >
              <div
                v-if="showingSearchResults && messages[fid] && messages[fid].filter(el => el.body.match(new RegExp(filterValues.bodySearch,'gi'))).length === 0"
                class="text-center mt-3"
              >
                {{ $t('messaging.search_no_result.line_1') }}
              </div>
              <template v-else>
                <div
                  v-if="tableParams.next != null && showingSearchResults === false"
                  class="text-center pointerHover"
                  @click="loadPrevious"
                >
                  <h3>{{ $t('messaging.load_previous') }}</h3>
                  <br>
                  <br>
                </div>
                <template v-if="messages[fid]">
                  <MessagingMessage
                    v-for="(message, index) in messages[fid].slice().reverse()"
                    :key="index"
                    :message="message"
                    :filter-values="filterValues"
                  />
                </template>
              </template>
            </div>
          </v-card-text>
        </v-card>

        <MessagingSend
          :fid="fid"
          :messaging-store="'foreignerMessaging'"
          :permits-with-none="permitsWithNone"
          :possible-mentions="possibleMentions"
          @scroll-to-bottom="scrollToBottom"
        />
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import _cloneDeep from 'lodash/cloneDeep';

import MessagingMessage from '@/components/messaging/MessagingMessage';
import MessagingToolbar from '@/components/messaging/MessagingToolbar';
import MessagingSend from '@/components/messaging/MessagingSend';

export default {
  name: 'ForeignerMessagingChat',
  components: {
    MessagingMessage,
    MessagingToolbar,
    MessagingSend,
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
    fid: {
      type: Number,
      default: 0,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMessagingDialogOpen: {
      type: Boolean,
      default: false,
    },
    isInDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      rowsOnPage: 10,

      filterValues: {
        userFrom: '',
        permit: '',
        dateFrom: '',
        dateTo: '',
        bodySearch: '',
      },
      emptySearchResult: false,

      showingSearchResults: false,

      timer: null,
      timerActive: false,

      foundMatches: 0,
    };
  },
  computed: {
    ...mapGetters({
      messages: 'foreignerMessaging/messages',
      tableParams: 'foreignerMessaging/tableParams',
      possibleMentions: 'foreignerMessaging/possibleMentions',
      me: 'coreData/me',
      permits: 'foreigners/permits',
    }),

    permitsWithNone() {
      const permits = _cloneDeep(this.permits);
      permits.push({ permit_type_name: 'Others', id: -1 });
      return permits;
    },
  },
  watch: {
    messages() {
      const re = new RegExp(this.filterValues.bodySearch, 'gi');
      const found = (this.messages[this.fid] || []).some((message) => message.body.match(re));

      if (found) {
        this.emptySearchResult = false;
        this.foundMatches = 1;
      }
    },
  },
  mounted() {
    this.$store.dispatch('foreignerMessaging/retrievePossibleMentions', {
      fid: this.fid,
    });
    this.$store
      .dispatch('foreignerMessaging/retrieveMessages', {
        fid: this.fid,
        page: this.page,
        rows: this.rowsOnPage,
      })
      .then((_) => {
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
      });
    this.$store.dispatch('foreigners/retrieveForeignerPermits', {
      fid: this.fid,
    });
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
    this.$store.dispatch('foreignerMessaging/clearMessages');
  },
  destroyed() {
    this.stopTimer();
    this.$store.dispatch('foreignerMessaging/clearMessages');
  },
  methods: {
    loadPrevious() {
      this.stopTimer();
      const messagesWrapper = this.$refs.MessagesWrapper;
      const pos = messagesWrapper.scrollHeight;

      this.page++;
      this.$store
        .dispatch('foreignerMessaging/loadPreviousMessages', {
          fid: this.fid,
          page: this.page,
          rows: this.rowsOnPage,
        })
        .then((_) => {
          messagesWrapper.scrollTop = parseInt(messagesWrapper.scrollHeight) - parseInt(pos);
        });
    },
    scrollToBottom() {
      if (this.$refs.MessagesWrapper) {
        this.$refs.MessagesWrapper.scrollTop = this.$refs.MessagesWrapper.scrollHeight;
      }
    },
    scrollToNext() {
      window.find(
        this.filterValues.bodySearch,
        false,
        false,
        true,
        false,
        true,
        true,
      );

      if (window.getSelection().getRangeAt(0).startContainer.parentNode.nodeName === 'DIV') {
        window.getSelection().getRangeAt(0).startContainer.parentNode.blur();
        window.find(
          this.filterValues.bodySearch,
          false,
          false,
          true,
          false,
          true,
          true,
        );
      }

      window
        .getSelection()
        .getRangeAt(0)
        .startContainer.parentNode.scrollIntoView();
    },
    scrollToPrev() {
      window.find(
        this.filterValues.bodySearch,
        false,
        true,
        true,
        false,
        true,
        true,
      );

      if (
        window.getSelection().getRangeAt(0).startContainer.parentNode
          .nodeName === 'DIV'
      ) {
        window.getSelection().getRangeAt(0).startContainer.parentNode.blur();
        window.find(
          this.filterValues.bodySearch,
          false,
          true,
          true,
          false,
          true,
          true,
        );
      }

      window
        .getSelection()
        .getRangeAt(0)
        .startContainer.parentNode.scrollIntoView();
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },
    refresh() {
      this.emptySearchResult = false;
      this.page = 1;
      this.foundMatches = 0;

      const obj = {
        fid: this.fid,
        page: 1,
        rows: this.rowsOnPage,
      };

      if (this.filterValues.userFrom.length !== 0) {
        obj.user_from = this.filterValues.userFrom;
      }
      if (this.filterValues.permit.length !== 0) {
        obj.permit = this.filterValues.permit;
      }
      if (this.filterValues.dateFrom.length !== 0) {
        obj.date_create_from = this.parseDate(this.filterValues.dateFrom) + ' 00:00:00';
      }
      if (this.filterValues.dateTo.length !== 0) {
        obj.date_create_to = this.parseDate(this.filterValues.dateTo) + ' 23:59:59';
      }
      if (this.filterValues.bodySearch.length !== 0) {
        obj.bodySearch = this.filterValues.bodySearch;
        obj.rows = 300;
      }

      this.$store.dispatch('foreignerMessaging/retrieveMessages', obj).then((_) => {
        setTimeout((_) => {
          if (this.filterValues.bodySearch.length !== 0) {
            this.stopTimer();
            this.showingSearchResults = true;
            if (this.messages[this.fid].length === 0) {
              this.emptySearchResult = true;
            } else {
              setTimeout(this.scrollToPrev, 400);
            }
          } else {
            this.showingSearchResults = false;
            this.stopTimer();
            this.startTimer();

            if ((this.$refs.MessagesWrapper.scrollTop + this.$refs.MessagesWrapper.offsetHeight) > this.$refs.MessagesWrapper.scrollHeight) {
              this.scrollToBottom();
            }

            const s = window.getSelection();
            if (s && s.rangeCount > 1) {
              for (let i = 1; i < s.rangeCount; i++) {
                s.removeRange(s.getRangeAt(i));
              }
            }
          }
        }, 200);
        this.stopTimer();
        this.startTimer();
      });
    },
    startTimer() {
      window.timer = setInterval(this.refresh, 20 * 1000);
      this.timerActive = true;
    },
    stopTimer() {
      clearInterval(window.timer);
      this.timerActive = false;
    },
  },
};
</script>
