<template>
  <div>
    <v-flex row>
      <v-flex>
        <div class="flex display-1 mx-2">
          {{ $t('adm.permits.documents.title') }}
        </div>
      </v-flex>
      <v-flex class="text-right ">
        <v-flex
          xs6
          offset-xs6
        />
      </v-flex>
      <v-flex class="text-right my-3">
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newDocumentDialog = true; clearNewDocumentForm(); action = 'new'"
          >
            {{ $t('adm.permits.documents.documents_dialog.title_new') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>
      <template
        slot="item.editable_by_client"
        slot-scope="props"
      >
        <v-icon v-if="props.value === true">
          check
        </v-icon>
        <v-icon v-else>
          close
        </v-icon>
      </template>
      <template
        slot="item.editable_by_foreigner"
        slot-scope="props"
      >
        <v-icon v-if="props.value === true">
          check
        </v-icon>
        <v-icon v-else>
          close
        </v-icon>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editDocument(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteDocumentOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newDocumentDialog"
      persistent
      max-width="800px"
      @keydown.esc="newDocumentDialog = false; clearNewDocumentForm"
      @keydown.meta.s="editDocumentDialogShortcutHandler($event)"
      @keydown.ctrl.s="editDocumentDialogShortcutHandler($event)"
      @keydown.meta.enter="editDocumentDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editDocumentDialogShortcutHandler($event)"
      @keydown.enter="editDocumentDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span
            v-if="action === 'new'"
            class="headline"
          >{{
            $t('adm.permits.documents.documents_dialog.title_new')
          }}</span>
          <span
            v-else-if="action === 'edit'"
            class="headline"
          >{{
            $t('adm.permits.documents.documents_dialog.title_edit')
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="newDocument.name"
                  :label="$t('common.fields.name') + ' *'"
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="newDocument.description"
                  name="description"
                  :label="$t('common.fields.description')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-switch
                  v-model="newDocument.editable_by_client"
                  :label="$t('common.fields.client_editable')"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-switch
                  v-model="newDocument.editable_by_foreigner"
                  :label="$t('common.fields.foreigner_editable')"
                />
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="newDocumentAlertText.length > 0"
                  type="error"
                  v-html="newDocumentAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="newDocumentDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="createDocument"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="saveDocument"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDocumentDialog"
      persistent
      max-width="800px"
      @keydown.esc="newActionDateDialog = false"
      @keydown.meta.s="deleteDocumentDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteDocumentDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteDocumentDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteDocumentDialogShortcutHandler($event)"
      @keydown.enter="deleteDocumentDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.permits.documents.delete_documents_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.permits.documents.delete_documents_dialog.confirm_text') }}
                  <b>{{ deleteDocument.name }}</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteDocumentAlertText.length > 0"
                  type="error"
                  v-html="deleteDocumentAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteDocumentDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            :loading="disabledDeleteButton"
            @click="deleteDocumentMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PermitDocuments',
  data() {
    return {
      snackbar: false,
      snackbarText: '',

      headers: [
        {
          text: this.$t('common.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('common.fields.description'),
          value: 'description',
          sortable: true,
        },
        {
          text: this.$t('common.fields.client_editable'),
          value: 'editable_by_client',
          sortable: false,
        },
        {
          text: this.$t('common.fields.foreigner_editable'),
          value: 'editable_by_foreigner',
          sortable: false,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 0,
      rowsOnPage: 10,
      sortBy: 'name',
      sortDesc: false,

      action: 'new', //new or edit
      newDocumentDialog: false,
      newDocument: {
        name: '',
        description: '',
        editable_by_client: false,
        editable_by_foreigner: false,
      },
      newDocumentAlertText: '',
      editOriginalDocument: null,
      disabledSaveButton: false,

      deleteDocumentDialog: false,
      deleteDocument: {
        id: 0,
        name: '',
      },
      deleteDocumentAlertText: '',
      disabledDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'permitDocuments/tableData',
      tableParams: 'permitDocuments/tableParams',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    let refresh = localStorage.getItem('itrack_refresh_admin_perm_doc');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
      }
    }

    this.$store.dispatch('permitDocuments/retrieveDocuments', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
    });
  },
  methods: {
    editDocumentDialogShortcutHandler: function(event) {
      event.preventDefault();
      if (this.action === 'new') this.createDocument();
      else if (this.action === 'edit') this.saveDocument();
    },
    deleteDocumentDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deleteDocumentMethod();
    },

    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('permitDocuments/retrieveDocuments', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('permitDocuments/retrieveDocuments', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    refresh: function() {
      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_perm_doc');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_perm_doc',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('permitDocuments/retrieveDocuments', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    sorting: function(value, sortable) {
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },
    validateNewDocumentForm: function() {
      if (this.newDocument.name.length < 3) {
        this.newDocumentAlertText = 'Name is too short';
        return false;
      }
      return true;
    },
    clearNewDocumentForm: function() {
      this.newDocument.name = '';
      this.newDocument.description = '';
      this.newDocument.editable_by_foreigner = false;
      this.newDocument.editable_by_client = false;
      this.newDocumentAlertText = '';
    },
    createDocument: function() {
      this.disabledSaveButton = true;
      this.newDocumentAlertText = '';
      if (this.validateNewDocumentForm() !== true) {
        this.disabledSaveButton = false;
        return;
      }

      const obj = {};
      for (const k in this.newDocument) obj[k] = this.newDocument[k];

      this.$store
        .dispatch('permitDocuments/createDocument', obj)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitDocuments/retrieveDocuments', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newDocumentDialog = false;
          this.clearNewDocumentForm();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          this.newDocumentAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    editDocument: function(obj) {
      this.action = 'edit';
      this.newDocument.name = obj.name;
      this.newDocument.description = obj.description;
      this.newDocument.editable_by_client = obj.editable_by_client;
      this.newDocument.editable_by_foreigner = obj.editable_by_foreigner;
      this.newDocument.id = obj.id;
      this.editOriginalDocument = JSON.parse(JSON.stringify(this.newDocument));
      this.newDocumentDialog = true;
    },

    saveDocument: function() {
      this.disabledSaveButton = true;
      this.newDocumentAlertText = '';
      if (this.validateNewDocumentForm() !== true) {
        this.disabledSaveButton = false;
        return;
      }

      const diffObj = {};
      for (const key in this.editOriginalDocument) {
        if (typeof this.editOriginalDocument[key] !== 'object') {
          if (this.editOriginalDocument[key] !== this.newDocument[key]) {
            diffObj[key] = this.newDocument[key];
          }
        } else {
          for (const key2 in this.newDocument[key]) {
            if (
              this.editOriginalDocument[key][key2] !==
              this.newDocument[key][key2]
            ) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = this.newDocument[key][key2];
            }
          }
        }
      }

      this.$store
        .dispatch('permitDocuments/editDocument', {
          id: this.newDocument.id,
          document: diffObj,
        })
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitDocuments/retrieveDocuments', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newDocumentDialog = false;
          this.clearNewDocumentForm();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          this.newDocumentAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    deleteDocumentOpenDialog: function(obj) {
      this.deleteDocumentAlertText = '';
      this.deleteDocument.name = obj.name;
      this.deleteDocument.id = obj.id;
      this.deleteDocumentDialog = true;
    },
    deleteDocumentMethod: function() {
      this.disabledDeleteButton = true;
      this.$store
        .dispatch('permitDocuments/deleteDocument', this.deleteDocument.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('permitDocuments/retrieveDocuments', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteDocumentDialog = false;
          this.disabledDeleteButton = false;
        })
        .catch((e) => {
          this.deleteDocumentAlertText = helpers.createErrorHtml(
            e.response.data,
          );
          this.disabledDeleteButton = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
