import Vue from 'vue';
import Vuex from 'vuex';
import AuditLogService from '../../services/administration/AuditLogService';


Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    records: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setRecordsAndParams(state, data) {
      state.records = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
      // //console.log("Now is ", state)
    },
  },
  actions: {
    retrieveRecords(context, params) {
      /*
		action_user: this.filterValues.action_user,
		action_name: this.filterValues.action_name,
		severity: this.filterValues.severity,
		action_date_from: this.filterValues.action_date_from,
		action_date_to: this.filterValues.action_date_to,
		 */
      AuditLogService.getAuditLog(params.page, params.rows, params.order_by, params.action_user, params.action_name,
        params.severity, params.action_date_from, params.action_date_to)
        .then((data) => {
          context.commit('setRecordsAndParams', data.data);
        });
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];
      for (const u of state.records) {
        data.push({
          id: u.id,
          action_date: u.action_date,
          action_user: u.action_user,
          action_name: u.action_name,
          severity: u.severity,
          value_old: u.value_old,
          value_new: u.value_new,
        });
      }
      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    records: (state) => {
      return state.records;
    },
  },
});
