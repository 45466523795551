import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mt-3"},[_c(VCardTitle,{staticClass:"pointerHover py-1",staticStyle:{"font-size":"12pt"},on:{"click":() => {
      _vm.$emit('see-notification', _vm.notification.id);
      _vm.$router.push({ path: _vm.notification.link });
    }}},[_c(VFlex,{attrs:{"row":"","px-4":""}},[_c(VFlex,{staticClass:"d-flex",attrs:{"xs1":"","align-center":""}},[_c(_vm.notificationIcons[_vm.notification.notification_type],{tag:"component",staticClass:"grey--text text--darken-1"})],1),_c(VFlex,{attrs:{"xs4":""}},[_vm._v(" "+_vm._s(_vm.$capitalize(_vm.notification.notification_type_name))+" ")]),_c(VFlex,{attrs:{"xs4":""}},[(_vm.notification.description.length > 45)?_c('div',{attrs:{"title":_vm.notification.description}},[_vm._v(" "+_vm._s(_vm.notification.description.slice(0, 45) + '...')+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.notification.description)+" ")])]),_c(VFlex,{attrs:{"xs2":""}},[_vm._v(" "+_vm._s(_vm.notification.date_created)+" ")]),_c(VFlex,{attrs:{"xs1":"","text-right":""}},[(_vm.isSeen)?_c(VIcon,{staticClass:"mr-3",attrs:{"title":_vm.$t('notifications.mark_unseen')},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('unsee-notification', _vm.notification.id)}}},[_vm._v(" mdi-eye-off-outline ")]):_c(VIcon,{staticClass:"mr-3",attrs:{"title":_vm.$t('notifications.mark_seen')},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('see-notification', _vm.notification.id)}}},[_vm._v(" mdi-eye-outline ")]),_c('a',{staticClass:"newWindowIcon",attrs:{"target":"_blank","href":_vm.notification.link}},[_c(VIcon,{attrs:{"title":_vm.$t('notifications.open_new_tab')},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('link-unseen', { event: $event, id: _vm.notification.id })}}},[_vm._v(" mdi-open-in-new ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }