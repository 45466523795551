import Vue from 'vue';
import VueI18n from 'vue-i18n';
import defaultData from './defaulti18data';

Vue.use(VueI18n);

const messages = {

};

export default new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: JSON.parse(JSON.stringify(defaultData)), // set locale messages
});
