import Vue from 'vue';
import Vuex from 'vuex';
import ForeignerMessagingService from '../../services/foreigners/ForeignersMessagingService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    currentMessages: {},
    possibleMentions: [],
    page: 1,
    next: null,
    prev: null,
    family: [],
  },
  mutations: {
    setMessages(state, data) {
      state.currentMessages = data;
    },
    setMessagesAndParams(state, { fid, data }) {
      Vue.set(state.currentMessages, fid, data.data);
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
    clearMessagesAndParams(state) {
      state.currentMessages = [];
      state.page = 1;
      state.prev = null;
      state.next = null;
    },
    addMessagesAndSetParams(state, { fid, data }) {
      Vue.set(state.currentMessages, fid, state.currentMessages[fid].concat(data.data));
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
    addMessage(state, { fid, data }) {
      state.currentMessages[fid].unshift(data.data);
    },
    setPossibleMentions(state, data) {
      state.possibleMentions = data.data;
    },
    setFamily(state, data) {
      state.family = data;
    },
  },
  actions: {
    retrieveMessages(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerMessagingService.getMessages(params)
          .then((data) => {
            context.commit('setMessagesAndParams', {
              data: data.data,
              fid: params.fid,
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadPreviousMessages(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerMessagingService.getMessages(params)
          .then((data) => {
            context.commit('addMessagesAndSetParams', {
              data: data.data,
              fid: params.fid,
            });
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
    sendMessage(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerMessagingService.sendMessage(params.fid, params.text, params.permit_id, params.recipients)
          .then((data) => {
            context.commit('addMessage', {
              data: data.data,
              fid: params.fid,
            });
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
    retrievePossibleMentions(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerMessagingService.getPossibleMentiones(params.fid)
          .then((data) => {
            context.commit('setPossibleMentions', data.data);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    clearMessages(context) {
      context.commit('clearMessagesAndParams');
    },
    async loadFamily({ commit }, { foreignerId }) {
      const response = await ForeignerMessagingService.getFamily(foreignerId);
      commit('setFamily', response.data.data);
    },
  },
  getters: {
    messages: (state) => {
      return state.currentMessages;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    possibleMentions: (state) => {
      const arr = [];
      /*for (let k in state.possibleMentions) {
      	for (let kk of state.possibleMentions[k]) {
      		arr.push(kk);
      	}
      }*/
      if (state.possibleMentions.length === 0)
        return [];
      const roleNames = state.possibleMentions.map((el) => {
        return el.role_name;
      }).sort();
      // //console.log("RoleNames", roleNames)
      for (const role of roleNames) {
        const roleRealName = role;

        arr.push({
          header: role,
        });
        let arrTmp = [];
        //console.log("Looking for", role)
        for (const elem of state.possibleMentions) {
          // //console.log("DEBUG HERE", elem)
          if (elem.role_name === role) {
            arrTmp = elem;
            break;
          }
        }
        for (const elem of arrTmp.users) {
          elem.roleLetter = roleRealName[0];
          arr.push(elem);
        }
        if (role === 'clients') {
          arr.push({
            fullname: 'All HRs',
            id: -2,
            roleLetter: roleRealName[0],
          });
        }
      }

      return arr;
    },
    mentionById: (state) => (id) => {
      const arr = [];
      for (const k of state.possibleMentions) {
        for (const kk of k.users) {
          arr.push(kk);
        }
      }
      return arr.find((el) => el.id === id)['fullname'];
    },
    mentionByName: (state) => (name) => {
      const arr = [];
      for (const k of state.possibleMentions) {
        for (const kk of k.users) {
          arr.push(kk);
        }
      }
      return arr.find((el) => el.fullname === name)['id'];
    },
    family: (state) => {
      return state.family;
    },
  },
});
