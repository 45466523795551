<template>
  <div>
    <v-container>
      <v-layout row>
        <v-flex xs4>
          <v-text-field
            v-model="newEditForeigner.first_name"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :label="$t('common.fields.first_name') + ' *'"
          />
        </v-flex>
        <v-flex
          offset-xs2
          xs4
        >
          <v-text-field
            v-model="newEditForeigner.phone_primary"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :label="$t('common.fields.phone')"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <v-text-field
            v-model="newEditForeigner.last_name"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :label="$t('common.fields.last_name') + ' *'"
          />
        </v-flex>
        <v-flex
          offset-xs2
          xs4
        >
          <v-text-field
            v-model="newEditForeigner.contact_email"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :label="$t('common.fields.email') + ' *'"
          />
        </v-flex>
      </v-layout>
      <v-layout
        v-if="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT"
        row
      >
        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.gender"
            :autocomplete="$randomString()"
            :items="userGenders"
            :label="$t('common.fields.gender') + ' *'"
            :menu-props="{ maxHeight: '400' }"
            item-text="name"
            item-value="id"
          >
            <template
              slot="item"
              slot-scope="props"
            >
              {{ $t(props.item.key) }}
            </template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.profile.assigned_to_id"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.ADMIN ? false : true"
            :items="internalUsers"
            :label="$t('foreigners.filters.assigned_to')"
            :menu-props="{ maxHeight: '400' }"
            item-text="fullname"
            item-value="id"
          />
        </v-flex>
        <v-flex
          offset-xs2
          xs4
        >
          <v-switch
            v-model="newEditForeigner.is_active"
            :disabled="me.role === $roles.ADMIN ? false : true"
            :label="$t('common.fields.is_active')"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.profile.citizenship_id"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :items="citizenshipOptions"
            :label="$t('common.fields.citizenship') + ' *'"
            :menu-props="{ maxHeight: '400' }"
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex
          offset-xs2
          xs4
        >
          <v-autocomplete
            v-model="newEditForeigner.profile.client_company_id"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :items="companiesOptions"
            :label="$t('common.fields.company') + ' *'"
            :menu-props="{ maxHeight: '400' }"
            item-text="name"
            item-value="id"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.profile.hire_origin"
            :autocomplete="$randomString()"
            :disabled="(me.role === $roles.HR || me.role === $roles.ADMIN || me.role === $roles.CONSULTANT) ? false : true"
            :items="hireOriginOptions"
            :label="$t('foreigners.basic_info.fields.hire_origin')"
            :menu-props="{ maxHeight: '400' }"
            item-text="name"
            item-value="id"
          />
        </v-flex>
      </v-layout>

      <v-layout
        mt-4
        row
      >
        <v-flex xs6>
          <v-switch
            v-model="newEditForeigner.profile.master"
            :disabled="me.role === $roles.ADMIN ? false : true"
            :label="$t('foreigners.basic_info.family.is_head')"
          />
        </v-flex>

        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.profile.assigned_to_hr_id"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.HR"
            :items="clientUsersFilteredOptions()"
            :label="$t('foreigners.basic_info.assigned_hr')"
            :menu-props="{ maxHeight: '400' }"
            item-text="fullname"
            item-value="id"
          />
        </v-flex>
      </v-layout>

      <v-layout
        mt-4
        row
      >
        <v-flex xs6 />

        <v-flex xs4>
          <v-text-field
            v-model="newEditForeigner.profile.client_reference_number"
            :autocomplete="$randomString()"
            :disabled="(me.role === $roles.HR || me.role === $roles.ADMIN || me.role === $roles.CONSULTANT) ? false : true"
            :label="$t('foreigners.basic_info.fields.cli_ref_number')"
          />
        </v-flex>
      </v-layout>

      <v-layout
        mt-4
        row
      >
        <v-flex xs6 />

        <v-flex xs4>
          <v-text-field
            v-model="newEditForeigner.profile.official_job_code"
            :autocomplete="$randomString()"
            :disabled="(me.role === $roles.HR || me.role === $roles.ADMIN || me.role === $roles.CONSULTANT) ? false : true"
            :label="$t('foreigners.basic_info.fields.off_job_code')"
          />
        </v-flex>
      </v-layout>

      <v-layout
        mt-4
        row
      >
        <v-flex xs6 />

        <v-flex xs4>
          <v-text-field
            v-model="newEditForeigner.profile.supervisor"
            :autocomplete="$randomString()"
            :disabled="(me.role === $roles.HR || me.role === $roles.ADMIN || me.role === $roles.CONSULTANT) ? false : true"
            :label="$t('foreigners.basic_info.fields.supervisor')"
          />
        </v-flex>
      </v-layout>

      <v-layout
        mt-4
        row
      >
        <v-flex xs6 />

        <v-flex xs4>
          <v-autocomplete
            v-model="newEditForeigner.interface_language"
            :autocomplete="$randomString()"
            :disabled="me.role === $roles.ADMIN || me.role === $roles.CONSULTANT ? false : true"
            :items="interfaceLanguagesOptions"
            :label="$t('main.me.interface_language')"
            item-text="name"
            item-value="id"
            name="lang"
          />
        </v-flex>
      </v-layout>

      <v-flex row>
        <v-flex xs10>
          <v-alert
            v-if="editForeignerAlertText.length > 0"
            class="mt-3"
            type="error"
            v-html="editForeignerAlertText"
          />
        </v-flex>
      </v-flex>
      <v-layout
        mt-4
        row
      >
        <v-spacer />
        <v-btn
          :color="$appColours.button.close"
          outlined
          @click="close"
        >
          {{ $t('common.actions.cancel') }}
        </v-btn>
        <div class="ml-4">
          <v-btn
            :color="$appColours.button.ok"
            :loading="disabledSaveButton"
            outlined
            @click="saveEditForeigner"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import helpers from '@/helpers';
import ForeignersService from '@/services/foreigners/ForeignersService';

export default {
  name: 'ForeignersBasicInfoEdit',

  props: {
    foreigner: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      originalEditForeigner: {},
      newEditForeigner: {
        first_name: '',
        last_name: '',
        gender: '',
        contact_email: '',
        phone_primary: '',
        is_active: '',
        profile: {
          citizenship_id: '',
          client_company_id: '',
          assigned_to_id: '',
          assigned_to_hr_id: '',
        },
      },
      editForeignerAlertText: '',

      foreignerNotificationsDialog: false,
      foreignerNotificationsError: false,
      foreignerNotifications: [],

      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapGetters({
      internalUsers: 'internalUsers/users',
      clientUsers: 'clientsUsers/tableData',
      citizenshipOptions: 'coreData/countries',
      companiesOptions: 'clientsCompanies/selectOptions',
      userGenders: 'coreData/userGenders',

      hireOriginOptions: 'foreigners/hireOriginOptions',

      me: 'coreData/me',
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
    }),
  },
  mounted() {
    this.$store.dispatch('foreigners/retrieveHireOriginOptions');

    this.refreshNotifications().then(() => {
      const showNotifications = window.localStorage.getItem('itrack_for_info_notifications');

      if (showNotifications) {
        this.foreignerNotificationsDialog = true;
        window.localStorage.removeItem('itrack_for_info_notifications');
      }
    });

    this.$store.dispatch('coreData/retrieveInterfaceLanguages');

    this.editForeigner();
  },
  methods: {
    clientUsersFilteredOptions() {
      const opts = this.clientUsers.filter((el) => el.client_company_id === this.foreigner.profile.client_company_id && el.is_active);
      opts.unshift({ fullname: ' ', id: -1 });
      return opts;
    },
    validateForeigner(obj) {
      if (this.newEditForeigner.first_name.length < 1) {
        this.editForeignerAlertText = 'First name is too short';
        return false;
      }

      if (this.newEditForeigner.last_name.length < 1) {
        this.editForeignerAlertText = 'Last name is too short';
        return false;
      }

      const emailre = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (emailre.test(this.newEditForeigner.contact_email) === false) {
        this.editForeignerAlertText = 'Email is not valid';
        return false;
      }

      if (this.newEditForeigner.profile.citizenship_id.length < 1) {
        this.editForeignerAlertText = 'Citizenship value is invalid';
        return false;
      }

      return true;
    },
    close() {
      this.$emit('set-view-mode', 'view');
      this.$emit('set-avatareditmode', 0);
      this.originalEditForeigner = {
        first_name: '',
        last_name: '',
        gender: '',
        contact_email: '',
        phone_primary: '',
        is_active: '',
        profile: {
          citizenship_id: '',
          client_company_id: '',
          assigned_to_id: '',
          assigned_to_hr_id: '',
          supervisor: '',
          hire_origin: '',
        },
      };
    },
    editForeigner() {
      this.newEditForeigner = {
        first_name: this.foreigner.first_name,
        last_name: this.foreigner.last_name,
        gender: this.foreigner.gender,
        contact_email: this.foreigner.contact_email,
        phone_primary: this.foreigner.phone_primary,
        is_active: this.foreigner.is_active,
        interface_language: this.foreigner.interface_language,
        profile: {
          citizenship_id: this.foreigner.profile.citizenship_id,
          client_company_id: this.foreigner.profile.client_company_id,
          assigned_to_id: this.foreigner.profile.assigned_to_id,
          assigned_to_hr_id: this.foreigner.profile.assigned_to_hr_id,
          client_reference_number: this.foreigner.profile.client_reference_number,
          official_job_code: this.foreigner.profile.official_job_code,
          master: this.foreigner.profile.master,
          supervisor: this.foreigner.profile.supervisor,
          hire_origin: this.foreigner.profile.hire_origin,
        },
      };

      this.originalEditForeigner = JSON.parse(JSON.stringify(this.foreigner));
    },

    saveEditForeigner() {
      this.disabledSaveButton = true;
      if (this.validateForeigner(this.newEditForeigner) === false) {
        this.disabledSaveButton = false;
        return;
      }

      const diffObj = {};
      delete this.originalEditForeigner.family;
      for (const key in this.originalEditForeigner) {
        //primitive
        if (typeof this.originalEditForeigner[key] !== 'object') {
          if (this.originalEditForeigner[key] !== this.newEditForeigner[key]) {
            diffObj[key] = this.newEditForeigner[key];
          }
        }
        //array
        else if (Array.isArray(this.originalEditForeigner[key])) {
          if (
            this.originalEditForeigner[key].join() !==
            this.newEditForeigner[key].join()
          ) {
            diffObj[key] = this.newEditForeigner[key];
          }
        }
        //object
        else {
          for (const key2 in this.newEditForeigner[key]) {
            if (
              this.originalEditForeigner[key][key2] !==
              this.newEditForeigner[key][key2]
            ) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = this.newEditForeigner[key][key2];
            }
          }
        }
      }

      if (diffObj.phone_primary)
        diffObj['phone_primary'] = diffObj.phone_primary.toString();

      if (diffObj.contact_email) diffObj['email'] = diffObj.contact_email;

      if (diffObj.profile && diffObj.profile.assigned_to_hr_id === -1)
        diffObj.profile.assigned_to_hr_id = null;

      delete diffObj.date_modified;
      delete diffObj.id;
      delete diffObj.modified_by;

      const obj = {
        id: this.foreigner.id,
        foreigner: diffObj,
      };

      this.$store
        .dispatch('foreigners/editForeigner', obj)
        .then((_) => {
          this.disabledSaveButton = false;
          this.$emit('set-avatareditmode', 0);

          for (const key in diffObj) {
            if (typeof this.foreigner[key] === 'object') {
              this.foreigner[key] = {
                ...this.foreigner[key],
                ...diffObj[key],
              };
            } else if (key === 'phone_primary') {
              this.foreigner.phone_primary = diffObj[key];
            } else {
              if (diffObj[key] !== undefined) {
                this.foreigner[key] = diffObj[key];
              }
            }
          }

          this.$emit('refresh-foreigner', { foreigner: this.foreigner, tab: 0 });
          this.close();
        })
        .catch((e) => {
          this.disabledSaveButton = false;
          this.editForeignerAlertText = helpers.createErrorHtml(e.response.data);
          this.$emit('set-avatareditmode', 0);
        });
    },
    refreshNotifications() {
      return ForeignersService.getCustomNotifications(this.foreigner.id)
        .then((data) => {
          this.foreignerNotifications = data.data.data;
        });
    },
  },
};
</script>
