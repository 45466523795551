import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getClientsUsers(page, rows, order_by, isActive) {
    let afterUrl = `?page=${page}&page_size=${rows}&order_by=${order_by}`;

    if (isActive != undefined && isActive.length !== 0)
      afterUrl += `&is_active=${isActive}`;

    return axios.get(`${baseURL}/user/client/${afterUrl}`);
  },
  getClientsUserDetails(id) {
    return axios.get(`${baseURL}/user/client/${id}/`);
  },
  uploadClientsUserAvatar(id, avatar) {
    // //console.log("Avatar:", avatar);
    const fd = new FormData();

    fd.append('file', avatar);
    for (const p of fd) {
      // //console.log("FD:",p);
    }
    return axios({
      method: 'post',
      url: `${baseURL}/user/client/${id}/avatar/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },
  createClientsUser(obj) {
    return axios.post(`${baseURL}/user/client/`, obj);
  },
  editClientsUser(obj, id) {
    return axios.patch(`${baseURL}/user/client/${id}/`, obj);
  },
  deleteClientsUser(id) {
    return axios.delete(`${baseURL}/user/client/${id}/`);
  },
};
