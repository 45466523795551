<script>
import { mapGetters } from 'vuex';

export default ({
  computed: {
    ...mapGetters({
      faqSections: 'faq/faqSections',
      noFaqSections: 'faq/ifEmpty',
      me: 'coreData/me',
    }),
  },
  mounted() {
    this.$store.dispatch('faq/retrieveFaqSections');
  },
});
</script>

<template>
  <div
    :class="me.role && me.role === $roles.FOREIGNER ? 'container--foreigner' : ''"
  >
    <div
      :class="me.role && me.role === $roles.FOREIGNER ? 'title--foreigner' : ''"
    >
      <h1>{{ $t('common.faq.title') }}</h1>
    </div>

    <div v-if="noFaqSections">
      <p class="subtitle-1">
        {{ $t('common.faq.no_sections') }}
      </p>
    </div>
    <div v-else>
      <div
        class="d-flex flex-column"
        style="gap: 16px"
      >
        <router-link
          v-for="faqSection in faqSections"
          :key="faqSection.id"
          :to="'/faq/' + faqSection.id"
          style="width: 100%; text-decoration: none;"
        >
          <v-card
            class="elevation-2"
            style="padding: 0px; width: 100%"
          >
            <v-card-title>
              {{ me.interface_language === 2 ? faqSection.name_ru : faqSection.name_en }}
            </v-card-title>
          </v-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container--foreigner {
  width: 90%;
  margin-left: 5%;
  margin-top: 24px;
}

.title--foreigner {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

</style>
