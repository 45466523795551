import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import ClientCompaniesService from '../../services/administration/ClientsCompaniesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    companies: [],
    aresSearchResults: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setCompaniesAndParams(state, data) {
      state.companies = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
    setAresSearchResults(state, data) {
      state.aresSearchResults = data;

      for (const d of state.aresSearchResults) {
        d.openShortName = false;
        d.short_name = '';
      }
    },
    openShortName(state, ico) {
      const tmp = [];
      for (const i of state.aresSearchResults) {
        tmp.push(i);
      }
      for (const i of tmp) {
        if (i.ic_number === ico) {
          i.openShortName = true;
          break;
        }
      }

      state.aresSearchResults = [];
      for (const i of tmp) {
        state.aresSearchResults.push(i);
      }
    },
    clearSearchResults(state) {
      state.aresSearchResults = [];
    },
  },
  actions: {
    retrieveCompanies(context, params) {
      ClientCompaniesService.getClientsCompanies(params.page, params.rows, params.order_by, params.is_active)
        .then((data) => {
          context.commit('setCompaniesAndParams', data.data);
        });
    },
    createCompany(context, obj) {
      return ClientCompaniesService.createClientsCompany(obj);
    },
    aresSearch(context, data) {
      ClientCompaniesService.aresSearch(data.name, data.ico).then((data) => {
        context.commit('setAresSearchResults', data.data.data);
      });
    },
    openShortNameByICO(context, ico) {
      context.commit('openShortName', ico);
    },
    clearAresSearchResults(context) {
      context.commit('clearSearchResults');
    },
    getCompanyDetails(context, id) {
      return ClientCompaniesService.getClientsCompanyDetails(id);
    },
    editCompany(context, obj) {
      return ClientCompaniesService.editCompany(obj.company, obj.id);
    },
    deleteCompany(context, id) {
      return ClientCompaniesService.deleteCompany(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.companies) {
        data.push({
          id: u.id,
          short_name: u.short_name,
          fullname: u.fullname,
          is_active: u.is_active,
          contact_email: u.contact_email,
          contact_phone: u.contact_phone,
          contact_phone_replaced: u.contact_phone.replace(/[() ]/g, ''),
          address_city: u.address_city,
          address_street: u.address_street,
          address_zip: u.address_zip,
          address_house_number: u.address_house_number,
          case_assessment_assigned_to_id: u.case_assessment_assigned_to_id,
          case_assessment_permit_type_id: u.case_assessment_permit_type_id,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    aresSearchResults: (state) => {
      return state.aresSearchResults;
    },
    ifEmpty: (state) => {
      return state.companies.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.companies.filter((el) => el.is_active === true)) {
        arr.push({
          tmpname: (u.short_name.length) ? u.short_name.toLowerCase() : u.fullname.toLowerCase(),
          name: (u.short_name.length) ? u.short_name : u.fullname,
          id: u.id,
        });
      }
      arr.sort((a, b) => (a.tmpname > b.tmpname) ? 1 : ((b.tmpname > a.tmpname) ? -1 : 0));
      return arr;
    },
  },
});
