import baseURL from '@/services/APIConfig.js';
import axios from 'axios';

export default {
  getQuestionnaires(fid) {
    const url = `${baseURL}/foreigner/${fid}/survey/`;
    return axios.get(url);
  },
  getQuestionnaire(fid, qid) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/`;
    return axios.get(url);
  },
  getQuestionnaireExports(fid, qid) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/export/`;
    return axios.get(url);
  },
  createQuestionnaireForForeigner(fid, survey_type, enable_notification) {
    const url = `${baseURL}/foreigner/${fid}/survey/`;
    return axios.post(url, {
      survey_type: survey_type,
      enable_notification: enable_notification,
    });
  },
  editQuestionnaire(fid, qid, data) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/`;
    return axios.patch(url, data);
  },
  sendDataForQuestionnaire(fid, qid, survey_json, completed) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/`;
    return axios.patch(url, survey_json);
  },
  deleteQuestionnaire(fid, qid) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/`;
    return axios.delete(url);
  },
  exportQuestionnaire(fid, qid) {
    const url = `${baseURL}/foreigner/${fid}/survey/${qid}/export/form_studio/`;
    return axios.get(url);
  },
};
