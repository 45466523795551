<template>
  <div>
    <MessagingToolbar
      :filter-values="filterValues"
      class="mt-2"
      :has-fullscreen="false"
      :messaging-store="'messaging'"
      :permits="permitsWithFid[fid]"
      @scroll-to-prev="scrollToPrev"
      @scroll-to-next="scrollToNext"
      @refresh="refresh"
    />

    <v-flex
      md12
      mt-2
    >
      <v-container
        fluid
        class="elevation-1 mt-1"
      >
        <v-flex>
          <v-card class="mx-auto elevation-1">
            <v-card-text>
              <div
                ref="MessagesWrapper"
                style="height: 45vh; overflow-y: auto; padding-right: 10px"
              >
                <template v-if="showingSearchResults && messages[fid] && messages[fid].filter(el => el.body.match(new RegExp(filterValues.bodySearch,'gi'))).length === 0">
                  <div class="text-center mt-3">
                    {{ $t('messaging.search_no_result.line_1') }}
                  </div>
                </template>

                <template v-else>
                  <template v-if="tableParams[fid] && tableParams[fid].next != null && showingSearchResults === false">
                    <div
                      class="text-center pointerHover"
                      @click="loadPrevious"
                    >
                      <h3>{{ $t('messaging.load_previous') }}</h3>
                      <br>
                      <br>
                    </div>
                  </template>
                  <template v-if="messages[fid]">
                    <MessagingMessage
                      v-for="(message, index) in messages[fid].slice().reverse()"
                      :key="index"
                      :message="message"
                      :filter-values="filterValues"
                      :fid="fid"
                    />
                  </template>
                </template>
              </div>
            </v-card-text>
          </v-card>

          <MessagingSend
            :fid="fid"
            :messaging-store="'messaging'"
            :permits-with-none="permitsWithNone"
            :possible-mentions="possibleMentions"
            @scroll-to-bottom="scrollToBottom"
          />
        </v-flex>
      </v-container>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import _cloneDeep from 'lodash/cloneDeep';

import MessagingMessage from '@/components/messaging/MessagingMessage';
import MessagingToolbar from '@/components/messaging/MessagingToolbar';
import MessagingSend from '@/components/messaging/MessagingSend';

export default {
  name: 'MessagingDialogue',
  components: {
    MessagingMessage,
    MessagingToolbar,
    MessagingSend,
  },
  props: {
    fid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
      rowsOnPage: 10,

      filterValues: {
        userFrom: '',
        permit: '',
        dateFrom: '',
        dateTo: '',
        bodySearch: '',
      },

      emptySearchResult: false,
      showingSearchResults: false,

      foundMatches: 0,
    };
  },
  computed: {
    ...mapGetters({
      messages: 'messaging/messages',
      tableParams: 'messaging/tableParams',
      possibleMentions: 'messaging/possibleMentions',

      me: 'coreData/me',
      permits: 'permits/data',
      permitsWithFid: 'permits/dataWithFid',
    }),
    permitsWithNone() {
      let permits = [];
      if (this.permitsWithFid[this.fid]) {
        permits = _cloneDeep(this.permitsWithFid[this.fid]);
      }

      permits.push({ permit_type_name: 'Others', id: -1 });
      return permits;
    },
  },
  watch: {
    messages() {
      const re = new RegExp(this.filterValues.bodySearch, 'gi');
      const found = (this.messages[this.fid] || []).some((message) => message.body.match(re));

      if (found) {
        this.emptySearchResult = false;
        this.foundMatches = 1;
      }
    },
  },
  mounted() {
    this.$store.dispatch('messaging/retrievePossibleMentions');
    this.$store.dispatch('permits/retrievePermitsWithFid', { fid: this.fid });
    this.$store
      .dispatch('messaging/retrieveMessages', {
        fid: this.fid,
        page: this.page,
        rows: this.rowsOnPage,
      })
      .then((_) => {
        setTimeout((_) => {
          this.scrollToBottom();
        }, 200);
      });
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    loadPrevious() {
      this.stopTimer();
      const messagesWrapper = this.$refs.MessagesWrapper;
      const pos = messagesWrapper.scrollHeight;

      this.page++;
      this.$store
        .dispatch('messaging/loadPreviousMessages', {
          fid: this.fid,
          page: this.page,
          rows: this.rowsOnPage,
        })
        .then((_) => {
          messagesWrapper.scrollTop = parseInt(messagesWrapper.scrollHeight) - parseInt(pos);
        });
    },
    scrollToBottom() {
      if (this.$refs.MessagesWrapper) {
        this.$refs.MessagesWrapper.scrollTop = this.$refs.MessagesWrapper.scrollHeight;
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },
    refresh() {
      this.emptySearchResult = false;
      this.page = 1;
      this.foundMatches = 0;

      const obj = {
        fid: this.fid,
        page: 1,
        rows: this.rowsOnPage,
      };

      if (this.filterValues.userFrom.length !== 0) {
        obj.user_from = this.filterValues.userFrom;
      }
      if (this.filterValues.permit.length !== 0) {
        obj.permit = this.filterValues.permit;
      }
      if (this.filterValues.dateFrom.length !== 0) {
        obj.date_create_from = this.parseDate(this.filterValues.dateFrom) + ' 00:00:00';
      }
      if (this.filterValues.dateTo.length !== 0) {
        obj.date_create_to = this.parseDate(this.filterValues.dateTo) + ' 23:59:59';
      }
      if (this.filterValues.bodySearch.length !== 0) {
        obj.bodySearch = this.filterValues.bodySearch;
        obj.rows = 300;
      }

      this.$store.dispatch('messaging/retrieveMessages', obj).then((_) => {
        setTimeout((_) => {
          if (this.filterValues.bodySearch.length !== 0) {
            this.stopTimer();
            this.showingSearchResults = true;
            if (this.messages.length === 0) {
              this.emptySearchResult = true;
            } else {
              setTimeout(this.scrollToPrev, 400);
            }
          } else {
            this.showingSearchResults = false;
            this.stopTimer();
            this.startTimer();

            if ((this.$refs.MessagesWrapper.scrollTop + this.$refs.MessagesWrapper.offsetHeight) > this.$refs.MessagesWrapper.scrollHeight) {
              this.scrollToBottom();
            }

            const s = window.getSelection();
            if (s && s.rangeCount > 1) {
              for (let i = 1; i < s.rangeCount; i++) {
                s.removeRange(s.getRangeAt(i));
              }
            }
          }
        }, 200);
        this.stopTimer();
        this.startTimer();
      });
    },
    scrollToNext() {
      window.find(
        this.filterValues.bodySearch,
        false,
        false,
        true,
        false,
        true,
        true,
      );
      if (window.getSelection().getRangeAt(0).startContainer.parentNode.nodeName === 'DIV') {
        window.getSelection().getRangeAt(0).startContainer.parentNode.blur();
        window.find(
          this.filterValues.bodySearch,
          false,
          false,
          true,
          false,
          true,
          true,
        );
      }
      window
        .getSelection()
        .getRangeAt(0)
        .startContainer.parentNode.scrollIntoView();
    },
    scrollToPrev() {
      window.find(
        this.filterValues.bodySearch,
        false,
        true,
        true,
        false,
        true,
        true,
      );
      if (window.getSelection().getRangeAt(0).startContainer.parentNode.nodeName === 'DIV') {
        window.getSelection().getRangeAt(0).startContainer.parentNode.blur();
        window.find(
          this.filterValues.bodySearch,
          false,
          true,
          true,
          false,
          true,
          true,
        );
      }
      window
        .getSelection()
        .getRangeAt(0)
        .startContainer.parentNode.scrollIntoView();
    },
    startTimer() {
      window.timer = setInterval(this.refresh, 20 * 1000);
    },
    stopTimer() {
      clearInterval(window.timer);
    },
  },
};
</script>
