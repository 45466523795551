<template>
  <div
    v-if="routerPath !== 'ForeignersQuestionnaireFormGenerator' && routerPath !== 'form_generator' && routerPath !== 'linkExpired'"
    id="nav"
  >
    <template v-if="this['auth/loggedIn']">
      <template v-if="me.role && me.role === $roles.FOREIGNER">
        <v-card
          class="py-4 px-3 "
          width="90%"
          style="margin-left: 5%;"
        >
          <v-flex row>
            <v-flex xs2>
              <div class="ml-3">
                <v-avatar>
                  <Logo />
                </v-avatar>
              </div>
            </v-flex>
            <v-spacer />
            <v-flex
              xs10
              text-right
            >
              <router-link
                to="/"
                style="text-decoration: none"
              >
                <v-btn text>
                  {{ $t('main.navigation.permits') }}
                </v-btn>
              </router-link>

              <router-link
                to="/notifications"
                style="text-decoration: none"
              >
                <v-btn text>
                  {{ $t('main.navigation.notifications') }}
                  <v-badge
                    v-if="unseenNotificationsCount > 0"
                    color="red darken-2"
                    class="mb-5 ml-1"
                  >
                    <template v-slot:badge>
                      {{ unseenNotificationsCount }}
                    </template>
                  </v-badge>
                </v-btn>
              </router-link>

              <router-link
                v-if="!emptyFaqSections"
                style="text-decoration: none"
                :to="'/faq'"
              >
                <v-btn text>
                  {{ $t('main.navigation.faq') }}
                </v-btn>
              </router-link>

              <router-link
                to="/me"
                style="text-decoration: none"
              >
                <v-btn text>
                  {{ me.first_name }} {{ me.last_name }}
                  <v-avatar
                    size="36"
                    class="ml-2"
                  >
                    <v-img
                      v-if="!me.avatar_url[64]"
                      src="../assets/avatar_big.png"
                      style="height: 64px; width: 64px"
                    />

                    <v-img
                      v-else
                      :src="BASE_HOST + '/' + me.avatar_url[64]"
                    />
                  </v-avatar>
                </v-btn>
              </router-link>

              <v-btn
                text
                :title="$t('main.navigation.logout')"
                @click.stop="logout"
              >
                <v-icon>logout</v-icon>
              </v-btn>
            </v-flex>
          </v-flex>
        </v-card>
      </template>

      <template v-else-if="me.role && me.role !== $roles.FOREIGNER">
        <v-card>
          <v-navigation-drawer
            v-model="drawer"
            :mini-variant.sync="mini"
            :width="280"
            :mini-variant-width="68"
            permanent
            app
            class="py-0"
          >
            <v-layout
              column
              fill-height
            >
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <Logo />
                  </v-list-item-avatar>

                  <v-list-item-title>iTrack App</v-list-item-title>

                  <v-btn
                    icon
                    @click.stop="mini = !mini"
                  >
                    <v-icon>chevron_left</v-icon>
                  </v-btn>
                </v-list-item>

                <v-divider />

                <v-list-item
                  class="mt-2"
                  link
                  :to="'/notifications'"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-bell</v-icon>
                    <v-badge
                      v-if="unseenNotificationsCount > 0"
                      color="red darken-2"
                    >
                      <template v-slot:badge>
                        {{ unseenNotificationsCount }}
                      </template>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.notifications') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="me.role === $roles.ADMIN"
                  class="mt-2"
                  link
                >
                  <v-list-item-icon>
                    <v-icon>dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.dashboard') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="me.role !== $roles.FOREIGNER"
                  class="mt-3"
                  link
                  :to="'/foreigners'"
                  @click="$router.push('foreigners')"
                >
                  <v-list-item-icon>
                    <v-icon>people</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.foreigners') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list v-if="me.role === $roles.ADMIN">
                <v-list-group prepend-icon="list_alt">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('main.navigation.administration') }}</v-list-item-title>
                  </template>

                  <v-list-group
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('main.navigation.administration.agency') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      link
                      :to="'/administration/agency/companies'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.agency.companies') }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      :to="'/administration/agency/users'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.agency.users') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <v-list-group
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('main.navigation.administration.clients') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      link
                      :to="'/administration/clients/companies'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.clients.companies') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="'/administration/clients/users'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.clients.users') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <v-list-group
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('main.navigation.administration.internal') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      link
                      :to="'/administration/internal/users'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.internal.users') }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      :to="'/administration/internal/places'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.internal.places') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="'/administration/internal/faq'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.internal.faq') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <v-list-group
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('main.navigation.administration.permits') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      link
                      :to="'/administration/permit/documents'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.permits.documents') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="'/administration/permit/statuses'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.permits.statuses') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="'/administration/permit/action_dates'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.permits.action_dates') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="'/administration/permit/types'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.permits.types') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <v-list-group
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('main.navigation.administration.log') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      link
                      :to="'/administration/audit_log'"
                    >
                      <v-list-item-title>{{ $t('main.navigation.administration.log.audit_log') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-list-group>
              </v-list>

              <v-spacer />

              <v-list class="py-0">
                <v-list-item
                  link
                  :to="'/me'"
                >
                  <v-list-item-icon>
                    <v-icon>person</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.my_account') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!emptyFaqSections"
                  link
                  :to="'/faq'"
                >
                  <v-list-item-icon>
                    <v-icon>quiz</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.faq') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click.stop="logout">
                  <v-list-item-icon>
                    <v-icon>indeterminate_check_box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('main.navigation.logout') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-layout>
          </v-navigation-drawer>
        </v-card>
      </template>
    </template>
  </div>
</template>

<script>
import { authComputed } from '../vuex/helpers.js';
import { mapGetters } from 'vuex';
import Logo from '@/assets/logo.svg';
import NotificationsService from '../services/notifications/NotificationsService';
import baseURL from '../services/APIConfig';
import defaultLangData from '@/plugins/defaulti18data';

export default {
  components: {
    Logo,
  },
  data() {
    return {
      BASE_HOST: process.env.VUE_APP_HOSTURL,
      drawer: true,
      mini: true,
      notificationCount: 0,
    };
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      me: 'coreData/me',
      unseenNotifications: 'notifications/unseenNotifications',
      unseenNotificationsCount: 'notifications/unseenNotificationsCount',
      unseenData: 'notifications/unseen_data',
      emptyFaqSections: 'faq/ifEmpty',
    }),
    routerPath() {
      return this.$route.name;
    },
    notCount() {
      if (this.unseenData.next != null) return '5+';
      else return String(this.unseenNotifications.length);
    },
  },
  mounted() {
    const that = this;
    if (this['auth/loggedIn']) {
      this.$store.dispatch('coreData/retrieveInternalUserRoles');
      this.$store.dispatch('coreData/retrieveClientUserRoles');
      this.$store.dispatch('coreData/prepareCountries');
      this.$store.dispatch('coreData/prepareTimezones');
      this.$store.dispatch('faq/retrieveFaqSections');
    }
  },
  created() {
    if (this['auth/loggedIn']) {
      const that = this;
      that.refreshNotificationsCount();

      this.$refreshNotificationsInterval = setInterval(function() {
        that.refreshNotificationsCount();
      }, 30 * 1000);
    }
  },
  methods: {
    // remove all data from i18n except login page
    clearLangData() {
      const localeCode = localStorage.getItem('locale_code');
      localStorage.removeItem('locale_data');

      const newLocaleCode =
        localeCode && localeCode in defaultLangData ? localeCode : 'en';
      localStorage.setItem(
        'locale_data',
        JSON.stringify(defaultLangData[newLocaleCode]),
      );
    },
    logout() {
      clearInterval(this.$refreshNotificationsInterval);
      this.clearLangData();
      this.$store.dispatch('auth/logout');
    },
    refreshNotificationsCount: function() {
      this.$store.dispatch('notifications/retrieveUnseenNotificationsCount');
    },
  },
};
</script>

<style>
</style>
