import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getPermits(page, rows) {
    return axios.get(`${process.env.VUE_APP_FORURL}/permit/?page=${page}&page_size=${rows}`);
  },
  getPermitsWithFid(page, rows, fid) {
    return axios.get(`${process.env.VUE_APP_FORURL}/permit/?page=${page}&page_size=${rows}&foreigner_id=${fid}`);
  },
  getPermitStatus(permitId) {
    return axios.get(`${process.env.VUE_APP_FORURL}/permit/${permitId}/status/?page=1&page_size=50`);
  },
  getPermitDocument(permitId) {
    return axios.get(`${process.env.VUE_APP_FORURL}/permit/${permitId}/document/?page=1&page_size=50`);
  },

  uploadDocument(file, permitId, documentId) {
    const fd = new FormData();
    let i = 0;
    for (const f of file) {
      if (i === 1) i++;

      if (i === 0) {
        fd.append('document', f);
        fd.append('document_name', f.name);
      } else {
        fd.append('document_' + i, f);
        fd.append('document_name_' + i, f.name);
      }

      i++;
    }

    return axios({
      method: 'patch',
      url: `${process.env.VUE_APP_FORURL}/permit/${permitId}/document/${documentId}/`,
      data: fd,
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  },

};
