import Vue from 'vue';
import Vuex from 'vuex';
import InternalFaqService from '@/services/administration/InternalFaqService';
import { nanoid } from 'nanoid';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    faqs: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setFaqsAndParams(state, data) {
      state.faqs = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.faqs) {
        data.push({
          id: u.id,
          name_en: u.name_en,
          name_ru: u.name_ru,
          short_name: u.short_name,
          human_order: u.human_order,
          enabled: u.enabled,
          date_modified: u.date_modified,
          queries: u.queries.map((q) => ({ ...q, id: nanoid() })),
        });
      }

      return data;
    },
    ifEmpty: (state) => {
      return state.places.length === 0;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.places) {
        arr.push({
          id: u.id,
          short_name: u.short_name,
          full_address: u.full_address,
        });
      }
      return arr;
    },
  },

  actions: {
    retrieveFaqs(context, params) {
      InternalFaqService.getFaqList(params.page, params.rows, params.order_by)
        .then((data) => {
          context.commit('setFaqsAndParams', data.data);
        });
    },
    createFaq(context, obj) {
      return InternalFaqService.createFaq(obj);
    },
    getFaqDetail(context, id) {
      return InternalFaqService.getFaqDetail(id);
    },
    editFaq(context, obj) {
      return InternalFaqService.editFaq(obj.faq, obj.id);
    },
    deleteFaq(context, id) {
      return InternalFaqService.deleteFaq(id);
    },
  },
});
