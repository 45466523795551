import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import PermitDocumentsService from '../../services/administration/PermitDocumentsService';
import ClientCompaniesService from '../../services/administration/ClientsCompaniesService';

Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    documents: [],
    page: 1,
    next: null,
    prev: null,
  },
  mutations: {
    setDocumentsAndParams(state, data) {
      // //console.log("Setting", data);
      for (const d of data.data) {
        d.actions = '';
      }
      state.documents = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
      // //console.log("new users:", state.users)
    },
  },
  actions: {
    retrieveDocuments(context, params) {
      PermitDocumentsService.getPermitDocuments(params.page, params.rows, params.order_by).then((data) => {
        context.commit('setDocumentsAndParams', data.data);
      });
    },
    createDocument(context, obj) {
      return PermitDocumentsService.createPermitDocument(obj);
    },
    editDocument(context, obj) {
      return PermitDocumentsService.editPermitDocument(obj.document, obj.id);
    },
    deleteDocument(context, id) {
      return PermitDocumentsService.deletePermitDocument(id);
    },
  },
  getters: {
    tableData: (state) => {
      const data = [];

      for (const u of state.documents) {
        data.push({
          id: u.id,
          name: u.name,
          description: u.description,
          editable_by_client: u.editable_by_client,
          editable_by_foreigner: u.editable_by_foreigner,
        });
      }

      return data;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    ifEmpty: (state) => {
      return state.documents.length === 0;
    },
    selectOptions: (state) => {
      const arr = [];
      for (const u of state.documents) {
        arr.push({
          name: u.name,
          id: u.id,
        });
      }
      return arr;
    },
    documentById: (state) => (id) => {
      return state.documents.find((el) => el.id === id);
    },
  },

});
