import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.isOpen = false}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.delete_dialog.title'))+" ")])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"text-center":"","xs12":""}},[_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.delete_dialog.confirm_text'))+" "),(_vm.foreigner)?_c('strong',[_vm._v(" "+_vm._s(_vm.foreigner.first_name)+" "+_vm._s(_vm.foreigner.last_name)+" ")]):_vm._e()])]),_c(VFlex,{attrs:{"xs12":""}},[(_vm.error.length > 0)?_c(VAlert,{attrs:{"type":"error"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.$appColours.button.close,"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.cancel'))+" ")]),_c(VBtn,{attrs:{"color":_vm.$appColours.button.delete,"outlined":""},on:{"click":_vm.deleteForeigner}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.delete'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }