import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeActivationMailDialog.apply(null, arguments)}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('foreigners.basic_info.activation_dialog.title'))+" ")])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"autocomplete":_vm.$randomString(),"label":_vm.$t('common.fields.email') + ' *'},model:{value:(_vm.activationMail),callback:function ($$v) {_vm.activationMail=$$v},expression:"activationMail"}})],1),_c(VFlex,{attrs:{"xs12":""}},[(_vm.error.length > 0)?_c(VAlert,{attrs:{"type":"error"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.$appColours.button.close,"outlined":"","text":""},on:{"click":_vm.closeActivationMailDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.close'))+" ")]),(_vm.showButton)?[_c(VBtn,{attrs:{"loading":_vm.isLoading,"color":"green darken-1","outlined":""},on:{"click":_vm.sendActivationMail}},[_vm._v(" "+_vm._s(_vm.$t('common.actions.save_changes'))+" ")])]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }