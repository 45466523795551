<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    persistent
    @keydown.esc="isOpen = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('foreigners.basic_info.delete_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex
              text-center
              xs12
            >
              <div class="body-1">
                {{ $t('foreigners.basic_info.delete_dialog.confirm_text') }}
                <strong v-if="foreigner">
                  {{ foreigner.first_name }} {{ foreigner.last_name }}
                </strong>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-alert
                v-if="error.length > 0"
                type="error"
                v-html="error"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :color="$appColours.button.close"
          outlined
          text
          @click="$emit('close')"
        >
          {{ $t('common.actions.cancel') }}
        </v-btn>
        <v-btn
          :color="$appColours.button.delete"
          outlined
          @click="deleteForeigner"
        >
          {{ $t('common.actions.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from '@/helpers';

export default {
  name: 'ForeignerBasicInfoDeleteDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: '',
    };
  },

  watch: {
    isOpen() {
      this.error = '';
    },
  },

  methods: {
    deleteForeigner() {
      this.error = '';
      this.$store
        .dispatch('foreigners/deleteForeigner', this.foreigner.id)
        .then(() => {
          document.querySelector('#backBtn').click();
          this.$emit('refreshForeigners');
        })
        .catch((error) => {
          this.error = helpers.createErrorHtml(error.response.data);
        });
    },
  },
};
</script>
