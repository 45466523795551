<template>
  <div>
    <v-card
      width="90%"
      class="mt-2 px-3 py-3"
      style="margin-left: 5%"
    >
      <v-tabs
        v-model="tabs"
        grow
        @change="setUrl"
      >
        <v-tab>
          {{ $t('main.for_view.navigation.permits') }}
        </v-tab>
        <v-tab>
          {{ $t('main.for_view.navigation.messaging') }}
        </v-tab>
        <v-tab>
          {{ $t('main.for_view.navigation.questionnaires') }}
        </v-tab>
      </v-tabs>
      <br>
      <v-tabs-items v-model="tabs">
        <v-tab-item :value="0">
          <permits />
        </v-tab-item>
        <v-tab-item :value="1">
          <messaging :foreigner="me" />
        </v-tab-item>
        <v-tab-item :value="2">
          <v-flex
            xs10
            offset-xs2
            row
          >
            <v-flex xs6>
              <b>{{ $t('quest.quest_type') }}</b>
            </v-flex>
            <v-flex xs2>
              <b>{{ $t('quest.completed') }}</b>
            </v-flex>
            <v-flex xs2>
              <b>{{ $t('quest.submitted') }}</b>
            </v-flex>
          </v-flex>
          <v-flex
            v-for="(q, index) in foreignersQuestionnaires"
            :key="index"
            xs10
            offset-xs2
            row
          >
            <v-flex xs6>
              <div style="margin-top: 5px">
                <a
                  :href="BASE_HOST+'/foreigners'+'/questionnaires/'+q.id"
                  target="_blank"
                >
                  {{ q.survey_type_name }}
                </a>
              </div>
            </v-flex>
            <v-flex xs2>
              <v-icon
                v-if="q.completed === true"
                :color="$appColours.status.others.active"
                class="ml-3"
                small
                :title="$t('quest.completed')"
                style="margin-top: 5px"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <v-icon
                v-else-if="q.completed !== true"
                :color="$appColours.status.others.notactive"
                class="ml-3"
                small
                :title="$t('quest.not_completed')"
                style="margin-top: 5px"
              >
                mdi-checkbox-blank-circle
              </v-icon>
            </v-flex>
            <v-flex xs2>
              <v-checkbox
                v-if="q.submitted === true"
                v-model="t"
                style="margin-top: 0; margin-left: 10px"
                disabled
              />
              <v-checkbox
                v-else
                v-model="f"
                style="margin-top: 0; margin-left: 10px"
                disabled
              />
            </v-flex>
          </v-flex>
          <br>
          <br>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import permits from './foreigners/foreigner_view_components/permits';
import messaging from './foreigners/foreigner_view_components/messaging';
import { mapGetters } from 'vuex';

export default {
  name: 'ForeignersView',
  components: { messaging, permits },
  data() {
    return {
      tabs: null,
      BASE_HOST: window.location.origin,
      t: true,
      f: false,

      flagMessaging: null,
      flagPermits: null,
      flagPermitId: null,
      flagDocuments: null,
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      foreignersQuestionnaires: 'questionnaires/foreignersQuestionnaires',
    }),
  },
  created() {
    this.flagMessaging = String(location).split('/').includes('messaging');
    this.flagPermits = String(location).split('/').includes('permits');
    this.flagDocuments = String(location).split('/').includes('documents');
  },
  mounted() {
    this.$store.dispatch('questionnaires/retrieveForeignersQuestionnaires');

    const forwardMessaging = localStorage.getItem(
      'itrack_for_forward_to_messaging',
    );
    if (forwardMessaging != null) {
      localStorage.removeItem('itrack_for_forward_to_messaging');
      this.tabs = 1;
    }

    const forwardQ = localStorage.getItem('itrack_for_forward_to_q');
    if (forwardQ != null) {
      localStorage.removeItem('itrack_for_forward_to_q');
      this.tabs = 2;
    }
  },
  methods: {
    debug: function() {},
    setUrl: function(tab) {
      if (tab === 0) {
        window.history.pushState('objevfdvdfng', 'vdfvtle', '/');
      } else if (tab === 1) {
        window.history.pushState('objevfdvdfng', 'vdfvtle', '/messaging');
      } else if (tab === 2) {
        window.history.pushState('objevfdvdfng', 'vdfvtle', '/questionnaires');
      }
    },
  },
};
</script>

<style scoped>
</style>
