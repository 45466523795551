import Vue from 'vue';
import Vuex from 'vuex';
import NotifcationsService from '../../services/notifications/NotificationsService';


Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    seen_notifications: [],
    seen_page: 1,
    seen_next: null,
    seen_prev: null,

    unseen_notifications: [],
    unseen_page: 1,
    unseen_next: null,
    unseen_prev: null,

    unseen_notifications_count: 0,

  },
  mutations: {
    setSeenNotifications(state, data) {
      state.seen_notifications = data.data;
      state.seen_page = data['page'];
      state.seen_prev = data['previous'];
      state.seen_next = data['next'];
    },
    setUnseenNotifications(state, data) {
      state.unseen_notifications = data.data;
      state.unseen_page = data['page'];
      state.unseen_prev = data['previous'];
      state.unseen_next = data['next'];
    },
    setUnseenNotificationsCount(state, data) {
      state.unseen_notifications_count = data.data;
    },
    addUnseenNotifications(state, data) {
      state.unseen_notifications = state.unseen_notifications.concat(data.data);
      state.unseen_page = data['page'];
      state.unseen_prev = data['previous'];
      state.unseen_next = data['next'];
    },
    addSeenNotifications(state, data) {
      state.seen_notifications = state.seen_notifications.concat(data.data);
      state.seen_page = data['page'];
      state.seen_prev = data['previous'];
      state.seen_next = data['next'];
    },
  },
  actions: {
    retrieveSeenNotifications(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.getNotifications(obj.page, obj.rows, 1, obj.types).then((data) => {
          context.commit('setSeenNotifications', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveUnseenNotifications(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.getNotifications(obj.page, obj.rows, 2, obj.types).then((data) => {
          context.commit('setUnseenNotifications', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveUnseenNotificationsCount(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.getUnseenNotificationsCount().then((data) => {
          context.commit('setUnseenNotificationsCount', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    seeNotification(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.seeNotification(obj.nid).then((data) => {
          //context.commit('setForeignerAuditEvents', data.data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    unseeNotification(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.unseeNotification(obj.nid).then((data) => {
          //context.commit('setForeignerAuditEvents', data.data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    loadUnseenNext(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.getNotifications(obj.page, obj.rows, 2, obj.types).then((data) => {
          context.commit('addUnseenNotifications', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    loadSeenNext(context, obj) {
      return new Promise((resolve, reject) => {
        NotifcationsService.getNotifications(obj.page, obj.rows, 1, obj.types).then((data) => {
          context.commit('addSeenNotifications', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
  },
  getters: {
    seenNotifications: (state) => {
      return state.seen_notifications;
    },
    seen_data: (state) => {
      return {
        page: state.seen_page,
        next: state.seen_next,
        prev: state.seen_prev,
      };
    },

    unseenNotifications: (state) => {
      return state.unseen_notifications;
    },
    unseen_data: (state) => {
      return {
        page: state.unseen_page,
        next: state.unseen_next,
        prev: state.unseen_prev,
      };
    },

    unseenNotificationsCount: (state) => {
      return state.unseen_notifications_count.count;
    },
  },
});
