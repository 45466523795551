import axios from 'axios';
import baseURL from '@/services/APIConfig.js';

export default {
  getPermitStatuses(page, rows, order_by) {
    // //console.log("qqqq", page, rows, order_by);
    return axios.get(`${baseURL}/permit/status/?page=${page}&page_size=${rows}&order_by=${order_by}`);
  },
  getPermitStatusDetails(id) {
    return axios.get(`${baseURL}/permit/status/${id}/`);
  },
  createPermitStatus(obj) {
    return axios.post(`${baseURL}/permit/status/`, obj);
  },
  editPermitStatus(obj, id) {
    return axios.patch(`${baseURL}/permit/status/${id}/`, obj);
  },
  deletePermitStatus(id) {
    return axios.delete(`${baseURL}/permit/status/${id}/`);
  },
};
