<template>
  <v-flex>
    <v-flex style="height: 95vh">
      <toolbar
        :filter-values="filterValues"
        :refresh="refresh"
        :export-foreigners="exportForeigners"
        :filters="filters"
        :family-filter-data="familyFilterData"
        @open-new-foreigner-dialog="isNewForeignerDialogOpen = true"
      />
      <v-flex
        md12
        mt-2
      >
        <v-card
          class="mx-auto"
          fill-height
          height="100%"
        >
          <v-card-text>
            <v-data-table
              id="foreignerTable"
              :footer-props="{
                'items-per-page-options': [5, 10, 25, 50, 100],
                'disable-pagination': true,
                'items-per-page-all-text': '',
                'show-current-page': false,
                'firstIcon': 'null',
                'lastIcon': 'null',
                'prevIcon': 'null',
                'nextIcon': 'null',
              }"
              :headers="foreignerTableHeaders"
              :items="foreignersData"
              :items-per-page.sync="rowsOnPage"
              :page.sync="tablePage"
              class="elevation-1 tableSmallFont"
              hide-default-header
              @click:row="({ id }) => $router.push(`/foreigners/${id}`)"
              @update:items-per-page="refresh(true)"
            >
              <template
                slot="header"
              >
                <tr>
                  <th
                    v-for="(h, index) in foreignerTableHeaders"
                    :key="index"
                    :class="'header_sortable_'+h.sortable"
                    @click="sorting(h.value, h.sortable)"
                  >
                    <v-icon
                      v-if="sortBy === h.value && sortDesc === true"
                      small
                    >
                      arrow_downward
                    </v-icon>
                    <v-icon
                      v-if="sortBy === h.value && sortDesc === false"
                      small
                    >
                      arrow_upward
                    </v-icon>
                    {{ $t(h.text) }}
                  </th>
                </tr>
              </template>

              <template
                slot="item.fullname"
                slot-scope="props"
              >
                {{ props.item.fullname }}
                <span
                  v-if="props.item.family_profiles.length > 0"
                  :title="props.item.family_profiles.map(el => el.fullname + ' - ' + el.relationship_type_name ).join(``)"
                >
                  ( <span
                    class="pointerHover"
                    @click="filterForeignerFamily($event, props.item)"
                  ><u>
                    +{{ props.item.family_members.length }}<v-icon style="font-size: 12pt;">
                      mdi-account
                    </v-icon>
                  </u></span> )
                </span>
              </template>
              <template
                slot="item.status"
                slot-scope="props"
              >
                <v-icon
                  v-if="props.value === 20"
                  color="green"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <v-icon
                  v-else-if="props.value === 10"
                  color="orange"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <v-icon
                  v-else
                  color="grey lighten-1"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
              </template>
              <template
                slot="item.client_company"
                slot-scope="props"
              >
                {{ props.item.client_company }}
              </template>
              <template
                slot="item.survey_submitted"
                slot-scope="props"
              >
                <v-icon
                  v-if="props.value === true"
                  color="green"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <v-icon
                  v-else
                  color="grey lighten-1"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
              </template>
              <template
                slot="item.main_permit_expiration_date"
                slot-scope="props"
              >
                <div v-if="props.value !== null">
                  {{ props.value }}
                </div>
                <div v-else>
                  {{ $t('common.values.no_data') }}
                </div>
              </template>
              <template
                slot="item.main_permit_renewal_date"
                slot-scope="props"
              >
                <div v-if="props.value !== null">
                  {{ props.value }}
                </div>
                <div v-else>
                  {{ $t('common.values.no_data') }}
                </div>
              </template>
              <template
                slot="item.main_permit_status"
                slot-scope="props"
              >
                <template v-if="!props.item.main_permit_status">
                  {{ $t('common.values.no_data') }}
                </template>
                <template v-else>
                  <template v-if="props.item.main_permit_type === 30">
                    {{ $t('foreigners.filters.obtained') }}
                  </template>
                  <template v-else>
                    <template v-if="props.item.main_permit_status.length > 20">
                      <span :title="props.item.main_permit_status">
                        {{ props.item.main_permit_status.slice(0, 20) }}...
                        {{ (props.item.main_permit_status_index) ? "(" + props.item.main_permit_status_index + ")" : "" }}
                      </span>
                    </template>
                    <template v-else>
                      {{ props.item.main_permit_status }} {{ (props.item.main_permit_status_index) ? "(" + props.item.main_permit_status_index + ")" : "" }}
                    </template>
                  </template>
                </template>
              </template>
              <template
                slot="item.date_modified"
                slot-scope="props"
              >
                <div v-if="props.value !== null">
                  {{ props.value.slice(0, -3) }}
                </div>
                <div v-else>
                  {{ $t('common.values.no_data') }}
                </div>
              </template>
              <template
                slot="item.profile.citizenship_id"
                slot-scope="props"
              >
                <div>{{ $store.getters['coreData/citizenshipById'](props.value) }}</div>
              </template>
              <template
                slot="item.profile.assigned_to_avatar_url"
                slot-scope="props"
              >
                <template v-if="props.item.profile.assigned_to_id !== null">
                  <div v-if="Object.entries(props.value).length === 0">
                    <v-img
                      :title="props.item.profile.assigned_to"
                      src="../../assets/avatar.png"
                      style="width: 32px; height: 32px"
                    />
                  </div>
                  <div v-else>
                    <v-img
                      :src="BASE_HOST + props.value[32]"
                      :title="props.item.profile.assigned_to"
                      style="width: 32px; height: 32px"
                    />
                  </div>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template
                slot="item.actions"
                slot-scope="props"
              >
                <a
                  :href="`/foreigners/${props.item.id}`"
                  target="_blank"
                  class="newWindowIcon"
                >
                  <v-icon
                    :title="$t('notifications.open_new_tab')"
                    class="mr-2"
                    medium
                    @click.stop="stopEvent($event)"
                  >mdi-open-in-new</v-icon>
                </a>
              </template>

              <template
                slot="footer.page-text"
              >
                <v-btn
                  v-if="tableParams.prev !== null"
                  color="black"
                  icon
                  small
                  text
                  @click="prevPage"
                >
                  <v-icon>arrow_back_ios</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="grey"
                  disabled
                  icon
                  small
                  text
                  @click="prevPage"
                >
                  <v-icon>arrow_back_ios</v-icon>
                </v-btn>
                <span class="mx-1">
                  {{ tableParams.page }}
                </span>
                <v-btn
                  v-if="tableParams.next !== null"
                  color="black"
                  icon
                  small
                  text
                  @click="nextPage"
                >
                  <v-icon>arrow_forward_ios</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="grey"
                  disabled
                  icon
                  small
                  text
                  @click="nextPage"
                >
                  <v-icon>arrow_forward_ios</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-flex>

    <ForeignersNewForeignerDialog
      :is-open="isNewForeignerDialogOpen"
      @refresh="refresh"
      @close="isNewForeignerDialogOpen = false"
      @set-snackbar="(message) => {
        snackbarText = message;
        snackbar = true;
      }"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      bottom
      right
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import ForeignersService from '../../services/foreigners/ForeignersService';
import ForeignersNewForeignerDialog from '@/components/foreigners/dialogs/ForeignersNewForeignerDialog';
import Toolbar from './foreigner_view_components/Toolbar';

export default {
  name: 'ForeignersOverview',
  components: {
    ForeignersNewForeignerDialog,
    Toolbar,
  },
  data() {
    return {
      f: false,

      foreignerTableHeaders: [
        {
          text: this.$t('common.filters.status'),
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('common.fields.name'),
          value: 'fullname',
          sortable: true,
        },
        {
          text: this.$t('common.fields.citizenship'),
          value: 'profile.citizenship_id',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.main_status'),
          value: 'main_permit_status',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.expiry_date'),
          value: 'main_permit_expiration_date',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.renewal_date'),
          value: 'main_permit_renewal_date',
          sortable: true,
        },
        {
          text: 'Q',
          value: 'survey_submitted',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.client_company'),
          value: 'client_company',
          sortable: true,
        },
        {
          text: this.$t('common.fields.last_update'),
          value: 'date_modified',
          sortable: true,
        },
        {
          text: this.$t('foreigners.filters.assigned_to'),
          value: 'profile.assigned_to_avatar_url',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 1,
      rowsOnPage: 10,
      sortBy: 'fullname',
      sortDesc: false,
      filters: {
        status: [],
        permit_obtained: [
          {
            name: this.$t('foreigners.filters.obtained'),
            id: 10,
          },
          {
            name: this.$t('foreigners.filters.not_obtained'),
            id: 20,
          },
        ],
        main_permit_status: [
          {
            name: 'Unknown',
            id: 10,
          },
          {
            name: 'On hold',
            id: 20,
          },
          {
            name: 'Obtained',
            id: 30,
          },
        ],
        otherFilters: [this.$t('foreigners.filters.include_family')],
      },
      filterValues: {
        client_company_id: '',
        assigned_to: '',
        status: '',
        main_permit_status: '',
        include_family: true,
        search: '',
        permit_obtained: '',
        case_assessment: false,
      },
      familyFilterData: {
        familyOf: '',
        familyIds: '',
      },

      selectedForeigner: -1, // -1 for nobody, 0 for loading, else for foreigner_id

      BASE_HOST: process.env.VUE_APP_HOSTURL,
      isNewForeignerDialogOpen: false,
      newForeigner: {
        first_name: '',
        last_name: '',
        email: '',
        profile: {
          client_company_id: '',
          assigned_to_id: '',
          citizenship_id: '',
        },
        gender: '',
        password: '',
        password_confirm: '',
        phone_primary_code: '',
        phone_primary: '',
        phone_secondary_code: '',
        phone_secondary: '',
      },

      snackbar: false,
      snackbarText: '',
    };
  },
  computed: {
    ...mapGetters({
      tableParams: 'foreigners/tableParams',
      foreignersData: 'foreigners/data',
      me: 'coreData/me',
    }),
    orderBy() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  async mounted() {
    const rpp = window.localStorage.getItem('itrack_rpp_admin_fors');
    if (rpp !== null) {
      this.rowsOnPage = parseInt(rpp);
    }

    this.refresh(false);

    this.$store.dispatch('clientsCompanies/retrieveCompanies', {
      page: 1,
      rows: 200,
      order_by: 'short_name',
    });
    this.$store.dispatch('internalUsers/retrieveUsers', {
      page: 1,
      rows: 100,
      order_by: 'fullname',
    });
    this.$store.dispatch('clientsUsers/retrieveUsers', {
      page: 1,
      rows: 200,
      order_by: 'fullname',
    });
    this.$store.dispatch('permitStatuses/retrieveStatuses', {
      page: 1,
      rows: 100,
      order_by: this.orderBy,
    });

    await this.$store.dispatch('coreData/retrieveEnums');
    this.filters.status = this.$store.state.coreData.enums.user_statuses.map((status) => {
      status.name = this.$capitalize(status.name);
      return status;
    });
  },
  methods: {
    stopEvent(event, nid) {
      event.stopPropagation();
    },
    nextPage() {
      this.tablePage = this.tablePage + 1;
      this.refresh(true, this.tablePage);
    },
    prevPage() {
      this.tablePage = this.tablePage - 1;
      this.refresh(true, this.tablePage);
    },
    refresh(rewriteLS = false, setPage = 1) {
      this.tablePage = setPage;

      if (this.filterValues.include_family === null) {
        this.filterValues.include_family = false;
      }

      window.localStorage.setItem('itrack_rpp_admin_fors', this.rowsOnPage);
      let obj = {};

      if (rewriteLS === true) {
        obj = {
          page: setPage !== 0 ? setPage : this.tablePage,
          rows: this.rowsOnPage,
          order_by: this.orderBy,
          client_company_id: this.filterValues.client_company_id,
          assigned_to: this.filterValues.assigned_to,
          status: this.filterValues.status,
          main_permit_status: this.filterValues.main_permit_status,
          ids: null,
          search: this.filterValues.search,
          include_family: this.filterValues.include_family,
          permit_obtained: this.filterValues.permit_obtained,
          case_assessment: this.filterValues.case_assessment,
          familyFilterData: this.familyFilterData,
        };

        if (this.familyFilterData['familyIds'] !== null) {
          obj.ids = this.familyFilterData['familyIds'];
          for (const f of this.foreignersData) {
            if (f.id === obj.ids[0]) {
              obj.familyFilterData['familyOf'] = `${f.first_name} ${f.last_name}`;
              break;
            }
          }
        }

        const obj1 = {};
        obj1[String(this.me.id)] = obj;

        let tmpObj = window.localStorage.getItem('itrack_foreigner_filters');
        if (tmpObj == null) {
          window.localStorage.setItem('itrack_foreigner_filters', JSON.stringify(obj1));
        } else {
          tmpObj = JSON.parse(tmpObj);
          tmpObj[String(this.me.id)] = obj;
          window.localStorage.setItem('itrack_foreigner_filters', JSON.stringify(tmpObj));
        }
      } else {
        obj = window.localStorage.getItem('itrack_foreigner_filters');

        if (obj == null) {
          obj = {
            page: setPage !== 0 ? setPage : this.tablePage,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
            client_company_id: this.filterValues.client_company_id,
            assigned_to: this.filterValues.assigned_to,
            status: this.filterValues.status,
            main_permit_status: this.filterValues.main_permit_status,
            ids: null,
            search: this.filterValues.search,
            include_family: this.filterValues.include_family,
            permit_obtained: this.filterValues.permit_obtained,
            familyFilterData: this.familyFilterData,
            case_assessment: this.filterValues.case_assessment,
          };

          if (this.familyFilterData['familyIds'] !== null)
            obj.ids = this.familyFilterData['familyIds'];
        } else {
          obj = JSON.parse(obj);
          obj = obj[String(this.me.id)];

          if (obj !== undefined) {
            this.rowsOnPage = obj.rows;

            if (obj.order_by[0] === '-') {
              this.sortDesc = true;
              this.sortBy = obj.order_by.slice(1);
            } else {
              this.sortDesc = false;
              this.sortBy = obj.order_by;
            }

            this.filterValues.client_company_id = obj.client_company_id;
            this.filterValues.assigned_to = obj.assigned_to;
            this.filterValues.status = obj.status;
            this.filterValues.main_permit_status = obj.main_permit_status;
            this.filterValues.permit_obtained = obj.permit_obtained;
            this.filterValues.case_assessment = obj.case_assessment;

            this.familyFilterData['familyOf'] = obj.familyFilterData.familyOf;
            this.familyFilterData['familyIds'] = obj.familyFilterData.familyIds;

            this.filterValues.search = obj.search;
            this.filterValues.include_family = obj.include_family;
          } else {
            obj = {
              page: setPage !== 0 ? setPage : this.tablePage,
              rows: this.rowsOnPage,
              order_by: this.orderBy,
              client_company_id: this.filterValues.client_company_id,
              assigned_to: this.filterValues.assigned_to,
              status: this.filterValues.status,
              main_permit_status: this.filterValues.main_permit_status,
              ids: null,
              search: this.filterValues.search,
              include_family: this.filterValues.include_family,
              permit_obtained: this.filterValues.permit_obtained,
              case_assessment: this.filterValues.case_assessment,
            };
          }
        }
      }
      this.$store
        .dispatch('foreigners/retrieveForeigners', obj)
        .then((r) => {});
    },
    sorting(value, sortable) {
      const oldVal = value;
      if (sortable === false && value != null) return;

      if (value == null) value = this.sortBy;

      if (value === 'profile.assigned_to_avatar_url') value = 'assigned_to';
      else if (value === 'profile.citizenship_id') value = 'citizenship';

      const old = this.sortBy;
      if (old === value && oldVal !== null) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh(true);
    },
    filterForeignerFamily(evt, foreigner) {
      evt.stopPropagation();

      this.$store
        .dispatch('foreigners/getForeignerDetails', foreigner.id)
        .then((data) => {
          const details = data.data.data;

          const familyIds = [foreigner.id];
          for (const d of details.family) familyIds.push(d.relation_with_id);

          this.familyFilterData['familyOf'] = foreigner.first_name + ' ' + foreigner.last_name;
          this.familyFilterData['familyIds'] = familyIds;

          this.refresh(true);
        });
    },
    exportForeigners() {
      const afterUrl = ForeignersService.prepareUrl();

      ForeignersService.exportForeigners(
        this.tablePage,
        this.rowsOnPage,
        this.orderBy,
        this.filterValues.client_company_id,
        this.filterValues.assigned_to,
        this.filterValues.status,
        this.filterValues.main_permit_status,
        this.filterValues.include_family,
        this.familyFilterData['familyIds'],
        this.filterValues.search,
        this.filterValues.permit_obtained,
      ).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if (response.headers['content-disposition'])
          fileLink.setAttribute(
            'download',
            decodeURI(
              response.headers['content-disposition']
                .split('filename')[1]
                .slice(1),
            ),
          );
        else
          fileLink.setAttribute(
            'download',
            'itrack_foreigners_export_test.xlsx',
          );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>

<style>
#foreignerTable .v-data-footer__icons-before,
#foreignerTable .v-data-footer__icons-after {
  display: none;
}

#foreignerTable .v-data-footer__pagination {
  margin-right: 5px;
  display: block;
}

.newWindowIcon {
  text-decoration: none;
}
</style>
