<template>
  <div
    class="messagingComponent"
    @keydown.esc="isMessagingDialogOpen = false"
  >
    <v-tabs
      v-model="tab"
      color="blue"
      grow
      @change="tabChanged"
    >
      <v-tab
        v-for="dialog in family"
        :key="dialog.foreigner_id"
      >
        {{ dialog.foreigner_fullname }}
        <template v-if="dialog.message_count > 0">
          <v-badge
            color="red"
            class="ml-3"
          >
            <template v-slot:badge>
              {{ dialog.message_count }}
            </template>
          </v-badge>
        </template>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="dialog in family"
        :key="dialog.foreigner_id"
      >
        <v-card>
          <v-card-text>
            <ForeignerMessagingChat
              :fid="dialog.foreigner_id"
              :fullscreen="isMessagingDialogOpen"
              @update-is-messaging-dialog-open="(payload) => isMessagingDialogOpen = payload"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="isMessagingDialogOpen"
      class="dialModal"
      persistent
      max-width="100%"
      height="100%"
      fullscreen="true"
      style="max-height: 100% !important; height: 100% !important;"
      @keydown.esc="isMessagingDialogOpen = false"
    >
      <v-card>
        <v-card-text style="padding-top: 15px">
          <ForeignerMessagingChat
            :fid="foreigner.id"
            :fullscreen="isMessagingDialogOpen"
            is-in-dialog
            @update-is-messaging-dialog-open="(payload) => isMessagingDialogOpen = payload"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ForeignerMessagingChat from '@/components/foreigners/ForeignerMessagingChat';

export default {
  name: 'ForeignerMessaging',
  components: {
    ForeignerMessagingChat,
  },
  props: {
    foreigner: {
      type: Object,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isMessagingDialogOpen: false,
      tab: 0,
    };
  },

  computed: {
    ...mapGetters({
      family: 'foreignerMessaging/family',
    }),
  },

  async mounted() {
    await this.$store.dispatch('foreignerMessaging/loadFamily', {
      foreignerId: this.foreigner.id,
    });
  },

  methods: {
    tabChanged(payload) {
      this.$store.dispatch('foreignerMessaging/retrieveMessages', {
        fid: this.family[payload].foreigner_id,
        page: 1,
        rows: 10,
      });

      this.$store.dispatch('foreigners/retrieveForeignerPermits', {
        fid: this.family[payload].foreigner_id,
      });
    },
  },
};
</script>
