<template>
  <div>
    <v-flex row>
      <v-flex xs4>
        <div class="flex display-1 mx-2">
          {{ $t('adm.clients.companies.title') }}
        </div>
      </v-flex>
      <v-flex
        xs4
        row
        class="text-right mt-3 "
      >
        <v-flex
          xs8
          offset-xs2
        >
          <div>
            <v-autocomplete
              v-model="filterValues.is_active"
              :items="filters.is_active"
              :label="$t('common.filters.status')"
              item-text="name"
              item-value="id"
              style="display: inline"
              :autocomplete="$randomString()"
              @change="refresh"
            />
          </div>
        </v-flex>
        <div
          v-if="filterValues.is_active !== ''"
          class="pointerHover"
          @click="filterValues.is_active = '' ; refresh()"
        >
          <v-icon :color="$appColours.button.delete">
            mdi-close
          </v-icon>
        </div>
      </v-flex>
      <v-flex
        xs4
        class="text-right my-3"
      >
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newCompanyDialog = true"
          >
            {{ $t('adm.clients.companies.create_button') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>
      <template
        slot="item.is_active"
        slot-scope="props"
      >
        <v-icon
          v-if="props.value === true"
          :color="$appColours.status.others.active"
        >
          mdi-checkbox-blank-circle
        </v-icon>
        <v-icon
          v-else
          :color="$appColours.status.others.notactive"
        >
          mdi-checkbox-blank-circle
        </v-icon>
      </template>
      <template
        slot="item.contacts"
        slot-scope="props"
      >
        <a :href="&quot;mailto:&quot;+props.item.contact_email">{{ props.item.contact_email }}</a><br>
        <a :href="&quot;tel:&quot;+props.item.contact_phone_replaced">{{ props.item.contact_phone }}</a>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editCompanyMethod(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteCompanyOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newCompanyDialog"
      persistent
      max-width="1000px"
      @keydown.esc="newCompanyDialog = false; clearNewTypeForm()"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.companies.company_dialog.title_new') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                md5
                lg5
              >
                <v-text-field
                  v-model="newCompanySearch.name"
                  :label="$t('common.fields.name')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                md5
                lg5
              >
                <v-text-field
                  v-model="newCompanySearch.ico"
                  :label="$t('adm.companies.company_dialog.ico')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                md2
                lg2
                my-3
              >
                <v-btn
                  v-if="aresSearchLoading === false"
                  :color="$appColours.button.ok"
                  outlined
                  text
                  :loading="aresSearchLoading"
                  :disabled="aresSearchLoading"
                  @click="aresSearch"
                >
                  {{ $t('common.actions.search') }}
                </v-btn>
                <v-btn
                  v-else
                  :color="$appColours.button.ok"
                  outlined
                  text
                  disabled
                >
                  Searching...
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="newCompanyAlertText.length > 0"
                  type="error"
                  v-html="newCompanyAlertText"
                />
              </v-flex>
            </v-layout>

            <v-data-table
              v-if="aresSearchResults.length !== 0"
              :headers="aresSearchHeaders"
              hide-default-footer
              :items="aresSearchResults"
              :items-per-page="200"
              class="elevation-1"
            >
              <template
                slot="item.address_street"
                slot-scope="props"
              >
                {{ props.item.address_street }} {{ props.item.address_house_number }}, {{ props.item.address_city }}
              </template>
              <template
                slot="item.add_company"
                slot-scope="props"
              >
                <v-btn
                  v-if="props.item.openShortName === false"
                  color="success"
                  small
                  @click="$store.dispatch(&quot;clientsCompanies/openShortNameByICO&quot;, props.item.ic_number)"
                >
                  {{ $t('common.actions.add') }}
                </v-btn>
                <v-flex
                  v-if="props.item.openShortName === true"
                  row
                >
                  <v-flex xs12>
                    <v-text-field
                      v-model="props.item.short_name"
                      label="Short name"
                      :autocomplete="$randomString()"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    my-2
                    text-right
                  >
                    <v-btn
                      fab
                      :color="$appColours.button.ok"
                      dark
                      depressed
                      small
                      @click="createCompany(props.item)"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-flex>
                </v-flex>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            outlined
            :color="$appColours.button.close"
            @click="newCompanyDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editCompanyDialog"
      persistent
      max-width="800px"
      @keydown.esc="editCompanyDialog = false; clearEditCompanyDialog()"
      @keydown.meta.s="saveCompany"
      @keydown.meta.enter="saveCompany"
      @keydown.ctrl.s="saveCompany"
      @keydown.ctrl.enter="saveCompany"
      @keydown.enter="saveCompany"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.companies.company_dialog.title_edit') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="editCompany.fullname"
                  :label="$t('common.fields.full_name') + ' *'"
                  disabled
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="editCompany.ic_number"
                  :label="$t('adm.companies.company_dialog.ico')"
                  disabled
                />
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  v-model="editCompany.address_street"
                  :label="$t('adm.companies.company_dialog.street')"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-model="editCompany.address_house_number"
                  :label="$t('adm.companies.company_dialog.house_number')"
                />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  v-model="editCompany.address_city"
                  :label="$t('adm.companies.company_dialog.city')"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-model="editCompany.address_zip"
                  :label="$t('adm.companies.company_dialog.zip')"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="editCompany.short_name"
                  :label="$t('common.fields.short_name')"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editCompany.contact_phone"
                      :label="$t('common.fields.phone')"
                      required
                      :autocomplete="$randomString()"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="editCompany.contact_email"
                  :label="$t('common.fields.email')"
                  :autocomplete="$randomString()"
                />
              </v-flex>

              <v-flex xs6>
                <v-autocomplete
                  v-model="editCompany.case_assessment_assigned_to_id"
                  :autocomplete="$randomString()"
                  :items="assessmentAssignedTo()"
                  :label="$t('foreigners.filters.assigned_to')"
                  item-text="fullname"
                  item-value="id"
                />
              </v-flex>

              <v-flex xs6>
                <v-autocomplete
                  v-model="editCompany.case_assessment_permit_type_id"
                  :items="assessmentPermitType()"
                  :label="$t('foreigners.filters.case_assessment')"
                  :menu-props="{ maxHeight: '400' }"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>

              <v-flex xs12>
                <v-autocomplete
                  v-model="editCompany.case_assessment_survey_id"
                  :items="assessmentSurvey()"
                  :label="$t('quest.quest_type')"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>

              <v-flex
                xs12
                sm6
                md6
              >
                <v-switch
                  v-model="editCompany.is_active"
                  :label="$t('common.fields.active')"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-switch
                  v-model="editCompany.dropbox_enabled"
                  :label="$t('common.fields.dropbox_enabled')"
                />
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="editCompanyAlertText.length > 0"
                  type="error"
                  v-html="editCompanyAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            outlined
            :color="$appColours.button.close"
            @click="editCompanyDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.ok"
            outlined
            text
            @click="saveCompany"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteCompanyDialog"
      persistent
      max-width="800px"
      @keydown.esc="deleteCompanyDialog = false"
      @keydown.meta.s="deleteCompanyDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteCompanyDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteCompanyDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteCompanyDialogShortcutHandler($event)"
      @keydown.enter="deleteCompanyDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.companies.delete_company_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.companies.delete_company_dialog.confirm_text') }} <b>{{ deleteCompany.name }}</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteCompanyAlertText.length > 0"
                  type="error"
                  v-html="deleteCompanyAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            outlined
            :color="$appColours.button.close"
            @click="deleteCompanyDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            @click="deleteCompanyMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import ClientsCompaniesService from '../../services/administration/ClientsCompaniesService';
import helpers from '../../helpers';

export default {
  name: 'ClientsCompanies',
  data() {
    return {
      snackbar: false,
      snackbarText: '',

      headers: [
        {
          text: this.$t('common.filters.active'),
          align: 'left',
          sortable: true,
          value: 'is_active',
        },
        {
          text: this.$t('common.fields.full_name'),
          value: 'fullname',
          sortable: true,
        },
        {
          text: this.$t('common.fields.short_name'),
          value: 'short_name',
          sortable: true,
        },
        {
          text: this.$t('common.fields.contacts'),
          value: 'contacts',
          sortable: false,
        },
        {
          text: this.$t('adm.companies.company_dialog.city'),
          value: 'address_city',
          sortable: true,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 0,
      rowsOnPage: 10,
      sortBy: 'short_name',
      sortDesc: false,
      filters: {
        is_active: [
          {
            name: this.$t('common.fields.active'),
            id: true,
          },
          {
            name: this.$t('common.fields.not_active'),
            id: false,
          },
        ],
      },
      filterValues: {
        is_active: '',
      },

      newCompanyDialog: false,
      newCompanySearch: {
        name: '',
        ico: '',
      },
      newCompany: {},
      newCompanyAlertText: '',
      aresSearchLoading: false,
      aresSearchHeaders: [
        {
          text: this.$t('common.fields.full_name'),
          value: 'fullname',
        },
        {
          text: this.$t('adm.companies.company_dialog.ico'),
          value: 'ic_number',
        },
        {
          text: this.$t('common.fields.address'),
          value: 'address_street',
        },
        {
          text: 'Add company',
          value: 'add_company',
        },
      ],

      editCompanyDialog: false,
      editCompany: {
        id: '',
        fullname: '',
        is_active: '',
        short_name: '',
        contact_phone_code: '',
        contact_phone: '',
        contact_email: '',
        address_city: '',
        address_street: '',
        address_zip: '',
        address_house_number: '',
        dropbox_enabled: '',
        case_assessment_assigned_to_id: '',
        case_assessment_permit_type_id: '',
        case_assessment_survey_id: '',
      },
      editCompanyAlertText: '',

      editOriginalCompany: null,

      deleteCompanyDialog: false,
      deleteCompany: {
        id: 0,
        name: '',
      },
      deleteCompanyAlertText: '',
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'clientsCompanies/tableData',
      tableParams: 'clientsCompanies/tableParams',
      aresSearchResults: 'clientsCompanies/aresSearchResults',
      internalUserOptions: 'internalUsers/adminUsers',
      permitTypeOptions: 'permitTypes/selectCaseAssessmentOptions',
      questionnaireType: 'coreData/selectCaseAssessmentSurveyTypes',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    if (this.$store.getters['internalUsers/ifEmpty']) {
      this.$store.dispatch('internalUsers/retrieveUsers', {
        page: 1,
        rows: 100,
        order_by: 'fullname',
      });
    }

    if (this.$store.getters['permitTypes/ifEmpty']) {
      this.$store.dispatch('permitTypes/retrieveTypes', {
        page: 1,
        rows: 100,
        order_by: 'name',
      });
    }

    if (this.$store.getters['coreData/ifSurveyTypesEmpty']) {
      this.$store.dispatch('coreData/retrieveSurveyTypes');
    }

    let refresh = localStorage.getItem('itrack_refresh_admin_cli_comp');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
        this.filterValues.is_active = refresh.is_active;
      }
    }

    this.$store.dispatch('clientsCompanies/retrieveCompanies', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
      is_active: this.filterValues.is_active,
    });
  },
  methods: {
    editCompanyDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.saveCompany();
    },
    deleteCompanyDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deleteCompanyMethod();
    },
    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('clientsCompanies/retrieveCompanies', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('clientsCompanies/retrieveCompanies', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    refresh: function() {
      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        is_active: this.filterValues.is_active,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_cli_comp');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_cli_comp',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('clientsCompanies/retrieveCompanies', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        is_active: this.filterValues.is_active,
      });
    },
    sorting: function(value, sortable) {
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },
    clearNewTypeForm: function() {
      this.newCompanySearch.name = '';
      this.newCompanySearch.ico = '';
      this.$store.dispatch('clientsCompanies/clearAresSearchResults');
    },
    aresSearch: function() {
      this.newCompanyAlertText = '';
      if (
        this.newCompanySearch.name.length < 1 &&
        this.newCompanySearch.ico.length < 1
      ) {
        this.newCompanyAlertText = 'Enter at least one value for search';
        return;
      }
      this.aresSearchLoading = true;
      const that = this;

      this.$store
        .dispatch('clientsCompanies/aresSearch', {
          name: this.newCompanySearch.name,
          ico: this.newCompanySearch.ico,
        })
        .then((_) => {
          that.aresSearchLoading = false;
        });
    },
    createCompany: function(obj) {
      obj.dropbox_enabled = true;

      this.$store
        .dispatch('clientsCompanies/createCompany', obj)
        .then((data) => {
          this.$store.dispatch('clientsCompanies/retrieveCompanies', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newCompanyDialog = false;
          this.clearNewTypeForm();
        })
        .catch((e) => {
          this.newCompanyAlertText = helpers.createErrorHtml(e.response.data);
        });
    },
    editCompanyMethod: function(obj) {
      const that = this;
      this.editCompanyAlertText = '';
      this.$store
        .dispatch('clientsCompanies/getCompanyDetails', obj.id)
        .then((data) => {
          const obj = data.data.data;

          this.editCompany.id = obj.id;
          this.editCompany.fullname = obj.fullname;
          this.editCompany.short_name = obj.short_name;
          this.editCompany.is_active = obj.is_active;
          this.editCompany.contact_email = obj.contact_email;
          this.editCompany.ic_number = obj.ic_number;
          this.editCompany.dic_number = obj.dic_number;
          this.editCompany.address_city = obj.address_city;
          this.editCompany.address_street = obj.address_street;
          this.editCompany.address_zip = obj.address_zip;
          this.editCompany.address_house_number = obj.address_house_number;
          this.editCompany.dropbox_enabled = obj.dropbox_enabled;
          this.editCompany.case_assessment_assigned_to_id =
            obj.case_assessment_assigned_to_id === null
              ? -1
              : obj.case_assessment_assigned_to_id;
          this.editCompany.case_assessment_permit_type_id =
            obj.case_assessment_permit_type_id === null
              ? -1
              : obj.case_assessment_permit_type_id;
          this.editCompany.case_assessment_survey_id =
            obj.case_assessment_survey_id === null
              ? -1
              : obj.case_assessment_survey_id;

          const phoneRe = /\(\+(\d+)\) (.+)/;
          if (obj.contact_phone !== '') {
            const phone1 = obj.contact_phone;
            that.editCompany.contact_phone = phone1;
          }
          this.editOriginalCompany = JSON.parse(
            JSON.stringify(this.editCompany),
          );
          this.editCompanyDialog = true;
        });
    },
    clearEditCompanyDialog: function() {
      this.editCompany.fullname = '';
      this.editCompany.dropbox_enabled = '';
      this.editCompany.short_name = '';
      this.editCompany.contact_email = '';
      this.editCompany.contact_phone = '';
      this.editCompany.contact_phone_code = '';
      this.editCompanyAlertText = '';
      this.case_assessment_assigned_to_id = '';
      this.case_assessment_permit_type_id = '';
      this.case_assessment_survey_id = '';
    },
    validateEditCompany: function() {
      if (this.editCompany.short_name.length < 3) {
        this.editCompanyAlertText = 'Name is too short';
        return false;
      }

      if (this.editCompany.contact_email.length !== 0) {
        this.editCompany.contact_email =
          this.editCompany.contact_email.toLowerCase();
        const emailre =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (emailre.test(this.editCompany.contact_email) === false) {
          this.editCompanyAlertText = 'Email is not valid';
          return false;
        }
      }

      return true;
    },
    saveCompany: function() {
      this.editCompanyAlertText = '';
      if (this.validateEditCompany() !== true) return;

      const diffObj = {};
      for (const key in this.editOriginalCompany) {
        if (typeof this.editOriginalCompany[key] !== 'object') {
          if (this.editOriginalCompany[key] !== this.editCompany[key]) {
            diffObj[key] = this.editCompany[key];
          }
        } else {
          for (const key2 in this.editCompany[key]) {
            if (
              this.editOriginalCompany[key][key2] !==
              this.editCompany[key][key2]
            ) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = this.editCompany[key][key2];
            }
          }
        }
      }

      if (diffObj.case_assessment_assigned_to_id === -1)
        diffObj.case_assessment_assigned_to_id = null;

      if (diffObj.case_assessment_permit_type_id === -1)
        diffObj.case_assessment_permit_type_id = null;

      if (diffObj.case_assessment_survey_id === -1)
        diffObj.case_assessment_survey_id = null;

      this.$store
        .dispatch('clientsCompanies/editCompany', {
          id: this.editCompany.id,
          company: diffObj,
        })
        .then((data) => {
          this.$store.dispatch('clientsCompanies/retrieveCompanies', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.editCompanyDialog = false;
          this.clearEditCompanyDialog();
        })
        .catch((e) => {
          this.editCompanyAlertText = helpers.createErrorHtml(e.response.data);
        });
    },
    deleteCompanyOpenDialog: function(obj) {
      this.deleteCompanyAlertText = '';
      this.deleteCompany.name = obj.short_name;
      this.deleteCompany.id = obj.id;
      this.deleteCompanyDialog = true;
    },
    deleteCompanyMethod: function() {
      this.$store
        .dispatch('clientsCompanies/deleteCompany', this.deleteCompany.id)
        .then((data) => {
          this.$store.dispatch('clientsCompanies/retrieveCompanies', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteCompanyDialog = false;
        })
        .catch((e) => {
          this.deleteCompanyAlertText = helpers.createErrorHtml(
            e.response.data,
          );
        });
    },
    assessmentAssignedTo: function() {
      const opts = this.internalUserOptions;
      opts.unshift({ fullname: ' ', id: -1 });

      return opts;
    },
    assessmentPermitType: function() {
      const opts = this.permitTypeOptions;
      opts.unshift({ name: ' ', id: -1 });

      return opts;
    },
    assessmentSurvey: function() {
      const opts = this.questionnaireType;
      opts.unshift({ name: ' ', id: -1 });

      return opts;
    },
  },
};
</script>
