<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    persistent
    @keydown.esc="closeActivationMailDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('foreigners.basic_info.activation_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="activationMail"
                :autocomplete="$randomString()"
                :label="$t('common.fields.email') + ' *'"
              />
            </v-flex>
            <v-flex xs12>
              <v-alert
                v-if="error.length > 0"
                type="error"
                v-html="error"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :color="$appColours.button.close"
          outlined
          text
          @click="closeActivationMailDialog"
        >
          {{ $t('common.actions.close') }}
        </v-btn>
        <template v-if="showButton">
          <v-btn
            :loading="isLoading"
            color="green darken-1"
            outlined
            @click="sendActivationMail"
          >
            {{ $t('common.actions.save_changes') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from '@/helpers';
import AuthService from '../../../services/AuthService';

export default {
  name: 'ForeignersBasicInfoActivationMailDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    foreigner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activationMail: '',
      error: '',
      isLoading: false,
      showButton: true,
    };
  },

  methods: {
    sendActivationMail() {
      this.error = '';
      if (this.activationMail.length !== 0) {
        this.activationMail = this.activationMail.toLowerCase();
        const emailre = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (emailre.test(this.activationMail) === false) {
          this.error = 'Email is not valid';
          return false;
        }
      }
      this.isLoading = true;
      AuthService.sendActivationEmail(
        this.foreigner.id,
        this.activationMail,
      )
        .then(() => {
          this.isLoading = false;
          this.closeActivationMailDialog();
          this.showButton = false;
          this.$emit('refresh-foreigner', { foreigner: this.foreigner, tab: 0 });
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = helpers.createErrorHtml(err.response.data);
          this.snackbar = true;
        });
    },
    closeActivationMailDialog() {
      this.$emit('close');
      this.showButton = true;
      this.error = '';
      this.activationMail = '';
    },
  },
};
</script>
