import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard';
import Admin from './views/Admin';
import LoginUser from './views/LoginUser';
import PassRecoveryFirst from './views/PassRecoveryFirst';
import PassRecoverySecond from './views/PassRecoverySecond';
import PrivacyPolicy from './views/PrivacyPolicy';
import InternalUsers from './components/administration/InternalUsers';
import ClientsUsers from './components/administration/ClientsUsers';
import ClientsCompanies from './components/administration/ClientsCompanies';
import PermitDocuments from './components/administration/PermitDocuments';
import PermitStatuses from './components/administration/PermitStatuses';
import PermitActionDates from './components/administration/PermitActionDates';
import PermitTypes from './components/administration/PermitTypes';
import InternalPlaces from './components/administration/InternalPlaces';
import AuditLog from './components/administration/AuditLog';
import AgencyCompany from './components/administration/AgencyCompanies';
import AgencyUser from './components/administration/AgencyUser';
import ForeignersOverview from './components/foreigners/ForeignersOverview';
import ForeignerDetail from './components/foreigners/ForeignerDetail';
import UsersMe from './components/UsersMe';
import Notifications from './components/notifications/Notifications';
import ForeignersQuestionnaireFormGenerator from './components/foreigners/ForeignersQuestionnaireFormGenerator';
import form_generator from './components/foreigners/foreigner_view_components/form_generator';
import ResetLinkExpired from './views/ResetLinkExpired';
import ResetLinkAutorefresh from './views/ResetLinkAutorefresh';
import MobileLandingPage from './views/MobileLandingPage';
import FaqSections from './views/FaqSections';
import FaqSectionsDetail from './views/FaqSectionDetail';
import InternalFaq from '@/components/administration/InternalFaq.vue';

import store from './vuex/store';
import axios from 'axios';
import baseURL from '@/services/APIConfig';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  // Auth
  {
    path: '/reset_password/expired_link',
    name: 'linkExpired',
    component: ResetLinkExpired,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset_password/autorefresh_link',
    name: 'linkExpiredAutorefresh',
    component: ResetLinkAutorefresh,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset_password',
    name: 'PassRecoveryFirst',
    component: PassRecoveryFirst,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginUser,
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileLandingPage,
  },
  {
    path: '/reset_password/confirm/:uid',
    name: 'PassRecoverySecond',
    component: PassRecoverySecond,
  },
  // Admin -- Internal
  {
    path: '/administration/internal/users',
    name: 'internalUsers',
    component: InternalUsers,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/internal/places',
    name: 'internalPlaces',
    component: InternalPlaces,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/internal/faq',
    name: 'internalFaq',
    component: InternalFaq,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  // Admin -- Clients
  {
    path: '/administration/clients/users',
    name: 'clientsUsers',
    component: ClientsUsers,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/clients/companies',
    name: 'clientsCompanies',
    component: ClientsCompanies,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  // Admin -- Permit
  {
    path: '/administration/permit/documents',
    name: 'permitDocuments',
    component: PermitDocuments,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/permit/statuses',
    name: 'permitStatuses',
    component: PermitStatuses,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/permit/action_dates',
    name: 'permitActionDates',
    component: PermitActionDates,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/permit/types',
    name: 'permitTypes',
    component: PermitTypes,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  // Admin -- Agency
  {
    path: '/administration/agency/companies',
    name: 'agencyCompany',
    component: AgencyCompany,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  {
    path: '/administration/agency/users',
    name: 'agencyUser',
    component: AgencyUser,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  // Admin -- Audit
  {
    path: '/administration/audit_log',
    name: 'auditLog',
    component: AuditLog,
    meta: {
      requiresAuth: true,
      requiresAdminRole: true,
    },
  },
  // Foreigners
  {
    path: '/foreigners',
    name: 'foreignersOverview',
    component: ForeignersOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/info/notifications',
    props: true,
    name: 'foreignersInfoNotif',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/info',
    props: true,
    name: 'foreignersInfo',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/messaging',
    props: true,
    name: 'foreignersMessaging',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/messaging/:pid/:mid',
    props: true,
    name: 'foreignersMessaging',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/questionnaires',
    props: true,
    name: 'foreignersQs',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/permits/:pid',
    props: true,
    name: 'foreignersPermit',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/permits/:pid/documents',
    props: true,
    name: 'foreignersDocuments',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid',
    props: true,
    name: 'foreignersId',
    component: ForeignerDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/:fid/questionnaires/:qid',
    props: true,
    name: 'ForeignersQuestionnaireFormGenerator',
    component: ForeignersQuestionnaireFormGenerator,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/foreigners/questionnaires/:qid',
    props: true,
    name: 'form_generator',
    component: form_generator,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/permits/:pid',
    props: true,
    name: 'dashboardf',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/permits/:pid/documents',
    props: true,
    name: 'dashboardff',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/messaging',
    props: true,
    name: 'dashboardfff',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/family/:fid/messaging/:pid/:mid',
    props: true,
    name: 'dashboardfff',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/questionnaires',
    props: true,
    name: 'dashboardfffff',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/me',
    name: 'usersMe',
    component: UsersMe,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqSections,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faq/:id',
    name: 'faqDetail',
    component: FaqSectionsDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'dashboardd',
    component: Dashboard,
  },
  ],
});


// Router guard
router.beforeEach(async(to, from, next) => {
  const tmpArr = String(window.location).split('/');
  const tmpArrFor = to.fullPath.split('/');

  /* ROUTING FOR FOREIGNER VIEW*/
  /* [ "", "permits", "20" ] */
  if (tmpArrFor.length === 3 && tmpArrFor[1] === 'permits') {
    localStorage.setItem('itrack_for_forward_to_permit', tmpArrFor[2]);
  }
  /* [ "", "permits", "20", "documents" ] */
  if (tmpArrFor.length === 4 && tmpArrFor[1] === 'permits' && tmpArrFor[3] === 'documents') {
    localStorage.setItem('itrack_for_forward_to_permit_document', tmpArrFor[2]);
  }
  /* [ "", "messaging" ] */
  if (tmpArrFor.length === 2 && tmpArrFor[1] === 'messaging') {
    localStorage.setItem('itrack_for_forward_to_messaging', '0');
  }
  /* [ "", "foreigners", "questionnaires", "27" ]*/
  /* [  "", "foreigners", "questionnaires", "27" ]*/
  if (tmpArrFor.length === 4 && tmpArrFor[2] === 'questionnaires') {
    localStorage.setItem('itrack_for_forward_to_q', '0');
  }

  if (tmpArrFor.length === 6 && tmpArrFor[1] === 'family' && tmpArrFor[3] === 'messaging') {
    console.log(tmpArrFor);
    localStorage.setItem('itrack_for_forward_to_messaging', '0');
  }

  /* ROUTING FOR ADMIN VIEW*/
  /* [ "http:", "", "localhost:8080", "foreigners", "17" ] */
  if (tmpArr.length === 5 && tmpArr[3] === 'foreigners') {
    localStorage.setItem('itrack_adm_forward_to_foreigner', tmpArr[4]);
  }
  /* [ "http:", "", "localhost:8080", "foreigners", "17", "permits", "14" ] */
  if (tmpArr.length === 7 && tmpArr[3] === 'foreigners') {
    localStorage.setItem('itrack_adm_forward_to_foreigner', tmpArr[4]);
    localStorage.setItem('itrack_adm_forward_to_foreigner_permit', tmpArr[6]);
  }
  /* [ "http:", "", "localhost:8080", "foreigners", "17", "permits", "14", "documents" ] */
  if (tmpArr.length === 8 && tmpArr[3] === 'foreigners') {
    localStorage.setItem('itrack_adm_forward_to_foreigner', tmpArr[4]);
    localStorage.setItem('itrack_adm_forward_to_foreigner_permit', tmpArr[6]);
    localStorage.setItem('itrack_adm_forward_to_foreigner_permit_documents', '0');
  }
  /* [ "http:", "", "localhost:8080", "foreigners", "17", "messaging" ] */
  if (tmpArr.length === 6 && tmpArr[3] === 'foreigners' && tmpArr[5] === 'messaging') {
    localStorage.setItem('itrack_adm_forward_to_foreigner_messaging', tmpArr[4]);
  }
  /* [ "http:", "", "localhost:8080", "foreigners", "17", "questionnaires" ] */
  if (tmpArr.length === 6 && tmpArr[3] === 'foreigners' && tmpArr[5] === 'questionnaires') {
    localStorage.setItem('itrack_adm_forward_to_foreigner_questionnaires', tmpArr[4]);
  }
  /* [ "http:", "", "localhost:8080", "me" ] */
  if (tmpArr.length === 4 && tmpArr[3] === 'me') {
    localStorage.setItem('itrack_forward_to_me', '0');
  }

  const loggedIn = localStorage.getItem('token');
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next('login');
  } else {
    if (to.matched.some((record) => record.meta.requiresAdminRole)) {
      let isAdmin ;
      if (store.state.coreData.me.role == null) {
        /*
          This is workaround for the case, when non admin user try to access admin page.
          We don't use fetching /me with store, like await coreDataStore.dispatch('retrieveMe'),
          because at the moment it will be called, the axios interceptors in App.vue created hook
          are not set, so server will return 403. But, logically, if the above check (!loggedIn)
          will not pass the token is ready to use.
        */
        const token = window.localStorage.getItem('token');
        const me = await axios.get(`${baseURL}/user/me/`, { headers: {
          Authorization: `JWT ${token}`,
        } });
        isAdmin = me.data.data.role === 0 || me.data.data.role === 10;
      } else {
        isAdmin = store.state.coreData.me.role === 0 || store.state.coreData.me.role === 10;
      }

      if (!isAdmin) {
        next({
          path: '/',
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
