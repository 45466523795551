<script>
import { mapGetters } from 'vuex';
import helpers from '@/helpers';
import { nanoid } from 'nanoid';

export default {
  name: 'InternalFaq',
  data() {
    return {
      headers: [
        {
          text: this.$t('common.fields.short_name'),
          value: 'short_name',
          sortable: true,
        },
        {
          text: this.$t('common.fields.human_order'),
          value: 'human_order',
          sortable: true,
        },
        {
          text: this.$t('common.fields.active'),
          value: 'enabled',
          sortable: false,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],

      tablePage: 0,
      rowsOnPage: 10,
      orderBy: 'human_order',

      sortBy: 'short_name',
      sortDesc: false,

      newFaqDialog: false,
      isDialogFullScreen: false,

      action: 'new', // new or edit
      newFaq: {
        name_en: {
          value: '',
          error: '',
        },
        name_ru: {
          value: '',
          error: '',
        },
        short_name: {
          value: '',
          error: '',
        },
        human_order: {
          value: 1,
          error: '',
        },
        enabled: {
          value: false,
          error: '',
        },
        queries: [
          {
            id: nanoid(),
            name_en: {
              value: '',
              error: '',
            },
            name_ru: {
              value: '',
              error: '',
            },
            answer_en: {
              value: '',
              error: '',
            },
            answer_ru: {
              value: '',
              error: '',
            },
          },
        ],
      },
      newFaqAlertText: '',

      deleteFaqDialog: false,
      deleteFaq: {
        id: 0,
        full_address: '',
      },
      deleteFaqAlertText: '',
      disabledDeleteButton: false,

      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableParams: 'internalFaq/tableParams',
      tableData: 'internalFaq/tableData',
      me: 'coreData/me',
    }),
  },

  mounted() {
    this.$store.dispatch('internalFaq/retrieveFaqs', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
    });

    let refresh = localStorage.getItem('itrack_refresh_admin_int_faq');

    if (refresh == null) {
      return;
    }
    refresh = JSON.parse(refresh);
    if (String(this.me.id) in refresh) {
      refresh = refresh[String(this.me.id)];
      this.rowsOnPage = refresh.rows;
      if (refresh.order_by[0] === '-') {
        this.sortDesc = true;
        this.sortBy = refresh.order_by.slice(1);
      } else {
        this.sortDesc = false;
        this.sortBy = refresh.order_by;
      }
    }
  },

  methods: {
    nextPage() {
      this.$store.dispatch('internalFaq/retrieveFaqs', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },

    prevPage() {
      this.$store.dispatch('internalFaq/retrieveFaqs', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    closeNewFaqDialog() {
      this.newFaqDialog = false;
      this.clearNewFaqForm();
      this.isDialogFullScreen = false;
    },
    clearNewFaqForm() {
      this.newFaq.name_ru.value = '';
      this.newFaq.name_ru.error = '';
      this.newFaq.name_en.value = '';
      this.newFaq.name_en.error = '';
      this.newFaq.enabled.value = false;
      this.newFaq.enabled.error = '';
      this.newFaq.short_name.value = '';
      this.newFaq.short_name.error = '';
      this.newFaq.human_order.value = 1;
      this.newFaq.human_order.error = '';
      this.newFaq.queries = [
        {
          id: nanoid(),
          name_en: {
            value: '',
            error: '',
          },
          name_ru: {
            value: '',
            error: '',
          },
          answer_en: {
            value: '',
            error: '',
          },
          answer_ru: {
            value: '',
            error: '',
          },
        },
      ];
    },
    editFaqDialogShortcutHandler(event) {
      event.preventDefault();
      if (this.action === 'new') this.createFaq();
      else if (this.action === 'edit') this.editFaq();
    },
    editFaqMethod(obj) {
      this.action = 'edit';

      this.newFaq.name_ru.value = obj.name_ru;
      this.newFaq.name_en.value = obj.name_en;
      this.newFaq.short_name.value = obj.short_name;
      this.newFaq.enabled.value = obj.enabled;
      this.newFaq.queries = obj.queries.map((query) => ({
        id: query.id,
        name_en: {
          value: query.name_en,
          error: '',
        },
        name_ru: {
          value: query.name_ru,
          error: '',
        },
        answer_en: {
          value: query.answer_en,
          error: '',
        },
        answer_ru: {
          value: query.answer_ru,
          error: '',
        },
      }));
      this.newFaq.human_order.value = obj.human_order;
      this.newFaq.id = obj.id;

      this.newFaqDialog = true;
    },
    addQuestion() {
      this.newFaq.queries.push({
        id: nanoid(),
        name_en: {
          value: '',
          error: '',
        },
        name_ru: {
          value: '',
          error: '',
        },
        answer_en: {
          value: '',
          error: '',
        },
        answer_ru: {
          value: '',
          error: '',
        },
      });
    },
    onArrowUpClick(index) {
      if (index > 0) {
        this.swapItems(index, index - 1);
      }
    },
    onArrowDownClick(index) {
      if (index < this.newFaq.queries.length - 1) {
        this.swapItems(index, index + 1);
      }
    },
    swapItems(index1, index2) {
      const temp = this.newFaq.queries[index1];
      this.newFaq.queries.splice(index1, 1, this.newFaq.queries[index2]);
      this.newFaq.queries.splice(index2, 1, temp);
    },
    deleteQuestion(index) {
      this.newFaq.queries.splice(index, 1);
    },
    createFaqDTO() {
      return {
        name_en: this.newFaq.name_en.value,
        name_ru: this.newFaq.name_ru.value,
        short_name: this.newFaq.short_name.value,
        enabled: this.newFaq.enabled.value,
        human_order: this.newFaq.human_order.value,
        queries: this.newFaq.queries.map((q) => ({
          name_en: q.name_en.value,
          name_ru: q.name_ru.value,
          answer_en: q.answer_en.value,
          answer_ru: q.answer_ru.value,
        })),
      };
    },
    handleFaqValidationError(data) {
      const fieldErrorMap = {
        name_en: data.name_en ? data.name_en[0] : '',
        name_ru: data.name_ru ? data.name_ru[0] : '',
        short_name: data.short_name ? data.short_name[0] : '',
        human_order: data.human_order ? data.human_order[0] : '',
        enabled: data.enabled ? data.enabled[0] : '',
        queries: data.queries
          ? data.queries.map((query) => ({
            name_en: query.name_en ? query.name_en[0] : '',
            answer_en: query.answer_en ? query.answer_en[0] : '',
            name_ru: query.name_ru ? query.name_ru[0] : '',
            answer_ru: query.answer_ru ? query.answer_ru[0] : '',
          }))
          : [],
      };

      this.newFaq = {
        ...this.newFaq,
        name_en: {
          ...this.newFaq.name_en,
          error: fieldErrorMap.name_en,
        },
        name_ru: {
          ...this.newFaq.name_ru,
          error: fieldErrorMap.name_ru,
        },
        short_name: {
          ...this.newFaq.short_name,
          error: fieldErrorMap.short_name,
        },
        human_order: {
          ...this.newFaq.human_order,
          error: fieldErrorMap.human_order,
        },
        enabled: {
          ...this.newFaq.enabled,
          error: fieldErrorMap.enabled,
        },
        queries: this.newFaq.queries.map((query, index) => ({
          ...query,
          name_en: {
            ...query.name_en,
            error: fieldErrorMap.queries[index].name_en,
          },
          answer_en: {
            ...query.answer_en,
            error: fieldErrorMap.queries[index].answer_en,
          },
          name_ru: {
            ...query.name_ru,
            error: fieldErrorMap.queries[index].name_ru,
          },
          answer_ru: {
            ...query.answer_ru,
            error: fieldErrorMap.queries[index].answer_ru,
          },
        })),
      };
    },
    createFaq() {
      this.disabledSaveButton = true;
      this.newFaqAlertText = '';

      const obj = this.createFaqDTO();

      this.$store
        .dispatch('internalFaq/createFaq', obj)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalFaq/retrieveFaqs', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newFaqDialog = false;
          this.closeNewFaqDialog();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          if (e.response.data.message === 'validation error') {
            this.handleFaqValidationError(e.response.data.data);
          } else {
            this.newFaqAlertText = helpers.createErrorHtml(e.response.data);
          }
          this.disabledSaveButton = false;
        });
    },
    async editFaq() {
      this.disabledSaveButton = true;
      this.newFaqAlertText = '';

      const dto = this.createFaqDTO();

      try {
        const response = await this.$store
          .dispatch('internalFaq/editFaq', {
            faq: dto,
            id: this.newFaq.id,
          });
        this.snackbarText = response.data.message;
        this.snackbar = true;
        this.$store.dispatch('internalFaq/retrieveFaqs', {
          page: this.tableParams.page,
          rows: this.rowsOnPage,
          order_by: this.orderBy,
        });
        this.newFaqDialog = false;
        this.closeNewFaqDialog();
      } catch (e) {
        if (e.response.data.message === 'validation error') {
          this.handleFaqValidationError(e.response.data.data);
        } else {
          this.newFaqAlertText = helpers.createErrorHtml(e.response.data);
        }
      } finally {
        this.disabledSaveButton = false;
      }
    },
    refresh() {
      const currentFaqTableData = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      };
      let tableDataToSet = {};
      const localStorageFaqTableData = localStorage.getItem('itrack_refresh_admin_int_faq');
      if (localStorageFaqTableData != null) {
        tableDataToSet = JSON.parse(localStorageFaqTableData);
      }
      tableDataToSet[String(this.me.id)] = currentFaqTableData;

      localStorage.setItem(
        'itrack_refresh_admin_int_faq',
        JSON.stringify(tableDataToSet),
      );

      this.$store.dispatch('internalFaq/retrieveFaqs', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    deleteFaqOpenDialog(obj) {
      this.deleteFaqAlertText = '';
      this.deleteFaq.full_address = obj.full_address;
      this.deleteFaq.id = obj.id;
      this.deleteFaqDialog = true;
    },
    deleteFaqDialogShortcutHandler(event) {
      event.preventDefault();
      this.deleteFaqMethod();
    },
    deleteFaqMethod() {
      this.disabledDeleteButton = true;
      this.$store
        .dispatch('internalFaq/deleteFaq', this.deleteFaq.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalFaq/retrieveFaqs', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteFaqDialog = false;
          this.disabledDeleteButton = false;
        })
        .catch((e) => {
          this.deleteFaqAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledDeleteButton = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <v-flex row>
      <v-flex xs4>
        <div class="flex display-1 mx-2">
          {{ $t('adm.internal.faq.title') }}
        </div>
      </v-flex>
      <v-flex
        xs8
        class="text-right"
      >
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="newFaqDialog = true; action = 'new'"
          >
            {{ $t('adm.internal.faq.create_button') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      must-sort
      sort-by="human_order"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="item.enabled"
        slot-scope="props"
      >
        <v-icon
          v-if="props.item.enabled === true"
          :color="$appColours.status.others.active"
        >
          mdi-checkbox-blank-circle
        </v-icon>
        <v-icon
          v-else
          :color="$appColours.status.others.notactive"
        >
          mdi-checkbox-blank-circle
        </v-icon>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editFaqMethod(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteFaqOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model.lazy="newFaqDialog"
      persistent
      :fullscreen="isDialogFullScreen"
      :max-width="isDialogFullScreen ? 'auto' : '800px'"
      @keydown.esc="closeNewFaqDialog"
      @keydown.meta.s="editFaqDialogShortcutHandler($event)"
      @keydown.ctrl.s="editFaqDialogShortcutHandler($event)"
      @keydown.meta.enter="editFaqDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editFaqDialogShortcutHandler($event)"
      @keydown.enter="editFaqDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title :style="isDialogFullScreen ? 'padding: 16px 40px 10px' : 'padding: 16px 34px 10px'">
          <div
            class="d-flex justify-space-between"
            style="width: 100%"
          >
            <span
              v-if="action === 'new'"
              class="headline"
            >
              {{ $t('adm.internal.faq.faq_dialog.title_new') }}
            </span>
            <span
              v-else-if="action === 'edit'"
              class="headline"
            >
              {{ $t('adm.internal.faq.faq_dialog.title_edit') }}
            </span>
            <v-btn
              text
              icon
              small
              :title="$t('messaging.enter_fullscreen')"
              @click="isDialogFullScreen = !isDialogFullScreen"
            >
              <v-icon>mdi-dock-window</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container
            :fluid="isDialogFullScreen"
          >
            <v-layout
              wrap
            >
              <v-flex
                :xs12="!isDialogFullScreen"
                :xs6="isDialogFullScreen"
              >
                <v-text-field
                  v-model.lazy="newFaq.name_en.value"
                  :label="$t('common.fields.name_en') + ' *'"
                  required
                  :autocomplete="$randomString()"
                  :style="isDialogFullScreen ? 'margin: 0 4px;' : 'margin: 0;'"
                  :error-messages="newFaq.name_en.error"
                />
              </v-flex>
              <v-flex
                :xs12="!isDialogFullScreen"
                :xs6="isDialogFullScreen"
              >
                <v-text-field
                  v-model.lazy="newFaq.name_ru.value"
                  :label="$t('common.fields.name_ru') + ' *'"
                  required
                  :autocomplete="$randomString()"
                  :style="isDialogFullScreen ? 'margin: 0 4px;' : 'margin: 0;'"
                  :error-messages="newFaq.name_ru.error"
                />
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model.lazy="newFaq.short_name.value"
                  :label="$t('common.fields.short_name') + ' *'"
                  required
                  :autocomplete="$randomString()"
                  :style="isDialogFullScreen ? 'margin: 0 4px;' : 'margin: 0;'"
                  :error-messages="newFaq.short_name.error"
                />
              </v-flex>
              <v-flex
                xs2
                align-self-center
              >
                <v-text-field
                  v-model.lazy="newFaq.human_order.value"
                  :label="$t('common.fields.human_order') + ' *'"
                  required
                  type="number"
                  min="1"
                  :autocomplete="$randomString()"
                  :style="isDialogFullScreen ? 'margin: 0 4px;' : 'margin: 0 8px;'"
                  :error-messages="newFaq.human_order.error"
                />
              </v-flex>
              <v-flex
                xs2
                align-self-center
              >
                <v-switch
                  v-model="newFaq.enabled.value"
                  :label="$t('common.fields.active')"
                  :style="isDialogFullScreen ? 'margin: 0 4px;' : 'margin: 0;'"
                  :error-messages="newFaq.enabled.error"
                />
              </v-flex>
              <v-flex
                xs9
                class="headline"
              >
                {{ $t('adm.internal.faq.faq_dialog.subtitle_questions') }}
              </v-flex>
              <v-flex
                xs3
                align-self-center
              >
                <div class="d-flex justify-end">
                  <v-btn
                    :color="$appColours.button.ok"
                    dark
                    @click="addQuestion"
                  >
                    {{ $t('adm.internal.faq.faq_dialog.question_new') }}
                  </v-btn>
                </div>
              </v-flex>

              <div
                class="d-flex flex-column"
                style="gap: 16px; width: 100%; padding: 16px 12px 0 0; overflow-y: auto;"
                :style="isDialogFullScreen ? 'height: calc(100vh - 322px);' : 'height: 330px;'"
              >
                <div
                  v-for="(question, index) in newFaq.queries"
                  :key="question.id"
                  style="padding: 16px; width: 100%; border: 1px solid #ccc; border-radius: 12px;"
                >
                  <div class="d-flex align-stretch">
                    <div
                      class="d-flex"
                      style="width: 100%; margin-right: 24px"
                      :style="isDialogFullScreen ? 'flex-direction: row; gap: 8px;' : 'flex-direction: column; gap: 0;'"
                    >
                      <div style="flex: 1">
                        <v-text-field
                          v-model.lazy="question.name_en.value"
                          :label="$t('common.fields.question_en') + ' *'"
                          required
                          :autocomplete="$randomString()"
                          :error-messages="question.name_en.error"
                        />
                        <v-textarea
                          v-model.lazy="question.answer_en.value"
                          rows="1"
                          auto-grow
                          :label="$t('common.fields.answer_en') + ' *'"
                          required
                          :autocomplete="$randomString()"
                          :error-messages="question.answer_en.error"
                        />
                      </div>
                      <div style="flex: 1">
                        <v-text-field
                          v-model.lazy="question.name_ru.value"
                          :label="$t('common.fields.question_ru') + ' *'"
                          required
                          :autocomplete="$randomString()"
                          :error-messages="question.name_ru.error"
                        />
                        <v-textarea
                          v-model.lazy="question.answer_ru.value"
                          rows="1"
                          auto-grow
                          :label="$t('common.fields.answer_ru') + ' *'"
                          required
                          :autocomplete="$randomString()"
                          :error-messages="question.answer_ru.error"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column justify-space-between"
                      style="gap: 8px"
                      :style="isDialogFullScreen ? 'margin: 0;' : 'margin: 24px 0'"
                    >
                      <v-btn
                        :disabled="newFaq.queries.length === 1"
                        @click="() => onArrowUpClick(index)"
                      >
                        <v-icon>arrow_upward</v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="newFaq.queries.length === 1"
                        @click="deleteQuestion(index)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="newFaq.queries.length === 1"
                        @click="() => onArrowDownClick(index)"
                      >
                        <v-icon>arrow_downward</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions style="position: sticky; bottom: 0; background-color: white">
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="closeNewFaqDialog"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="createFaq"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="editFaq"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model.lazy="deleteFaqDialog"
      persistent
      max-width="800px"
      @keydown.esc="deleteFaqDialog = false"
      @keydown.meta.s="deleteFaqDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteFaqDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteFaqDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteFaqDialogShortcutHandler($event)"
      @keydown.enter="deleteFaqDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.internal.faq.delete_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div class="body-1">
                  {{ $t('adm.permits.faq.delete_dialog.confirm_text') }}
                  <v-flex text-center>
                    <b>{{ deleteFaq.short_name }}</b>
                  </v-flex>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteFaqAlertText.length > 0"
                  type="error"
                  v-html="deleteFaqAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteFaqDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            :loading="disabledDeleteButton"
            @click="deleteFaqMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

.dot {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}

>>> .v-dialog {
  overflow-y: hidden;
}

</style>
