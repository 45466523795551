import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import ForeignerService from '../../services/foreigners/ForeignersService';
import baseURL from '../../services/APIConfig';


Vue.use(Vuex);

export default ({
  namespaced: true,
  state: {
    foreigners: [],
    currentForeignerPermits: [],
    page: 1,
    next: null,
    prev: null,

    simpleForeigners: [],

    relationTypes: [],
    foreignerOptions: [],
    customNotifications: [],
    hireOriginOptions: [],
  },
  mutations: {
    setUsersAndParams(state, data) {
      state.foreigners = data.data;
      state.page = data['page'];
      state.prev = data['previous'];
      state.next = data['next'];
    },
    setSimpleUsers(state, data) {
      state.simpleForeigners = data;
    },
    setCurrentForeignerPermits(state, data) {
      let j = 1;
      for (let i = data.data.length - 1; i >= 0; i--) {
        data.data[i]['color'] = 'c' + j;
        j += 1;
      }
      state.currentForeignerPermits = data.data;
    },
    setForeignerAvatar(state, obj) {
      let foreigner = null;
      for (const f of state.foreigners) {
        if (f.id === obj.fid) {
          foreigner = f;
          break;
        }
      }
      if (!foreigner) return;
      foreigner.avatar_url = obj.avatar;
    },
    setCurrentForeignerPermitsStatusesDocuments(state, data) {
      for (const p of state.currentForeignerPermits) {
        if (p.id === data.pid) {
          for (const s of data.statuses.data) {
            for (const a of s.action_dates) {
              if (a.action_date) {
                const [day, month, year] = a.action_date.split('.');
                a.action_date_iso = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

                a.showToolTip = false;
              }
            }
            s.action_dates.sort((a,b) => (a.human_order > b.human_order) ? 1 : ((b.human_order > a.human_order) ? -1 : 0))
          }
          p.statuses = data.statuses.data;
          p.documents = data.documents.data;

          for (const d of p.documents)
            d.showToolTip = false;
          break;
        }
      }
    },
    clearCurrentForeignerPermits(state) {
      state.currentForeignerPermits = [];
    },

    setForeignerOptions(state, data) {
      for (const o of data.data)
        o.fullname = o.first_name + ' ' + o.last_name;
      state.foreignerOptions = data.data;
    },
    setRelationTypes(state, data) {
      state.relationTypes = data.data;
    },
    setHireOriginOptions(state, data) {
      state.hireOriginOptions = data.data;
    },
    setCustomNotifications(state, data) {
      state.customNotifications = data.data;
    },
  },
  actions: {
    retrieveForeigners(context, params) {
      ForeignerService.getForeigners(
        params.page,
        params.rows,
        params.order_by,
        params.client_company_id,
        params.assigned_to,
        params.status,
        params.main_permit_status,
        params.include_family,
        params.ids,
        params.search,
        params.permit_obtained,
        params.case_assessment,
      )
        .then((data) => {
          context.commit('setUsersAndParams', data.data);
        });
    },
    retrieveForeignersSimple(context, params) {
      let arr = [];
      let page = 1;
      let newData = null;

      async function getData(page) {
        return new Promise((resolve, reject) => {
          ForeignerService.getForeignersSimple(page, -1, 'fullname')
            .then((data) => {
              const resp = data.data;
              resolve(resp);
            });
        });
      }

      return new Promise((resolve, reject) => {
        ForeignerService.getForeignersSimple(page, -1, 'fullname')
          .then(async(data) => {
            const resp = data.data;
            arr = arr.concat(resp.data);
            let next = resp.next;
            while (next) {
              page++;
              newData = await getData(page);
              arr = arr.concat(newData.data);
              next = newData.next;
            }
            context.commit('setSimpleUsers', arr);
            resolve(arr);
          }).catch((err) => {
            reject(err);
          });
      });

    },
    createForeigner(context, obj) {
      return new Promise((resolve, reject) => {
        ForeignerService.createForeigner(obj).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    uploadAvatar(context, data) {
      return ForeignerService.editForeignerAvatar(data.id, data.avatar);
    },
    getForeignerDetails(context, id) {
      return new Promise((resolve, reject) => {
        Promise.all([ForeignerService.getForeignerDetails(id), ForeignerService.getForeignerFamily(id)]).then((values) => {
          const foreigner = values[0].data.data;
          const family = values[1].data.data;
          values[0].data.data.family = values[1].data.data;
          resolve(values[0]);
        });
      });
    },
    editForeigner(context, obj) {
      return new Promise((resolve, reject) => {
        ForeignerService.editForeigner(obj.foreigner, obj.id).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteForeigner(context, id) {
      return new Promise((resolve, reject) => {
        ForeignerService.deleteForeigner(id).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    // FAMILY means FAMILY_MEMBER
    createForeignerFamily(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.createForeignerFamily(params.id, params.family).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });

    },
    editForeignerFamily(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.editForeignerFamily(params.id, params.fid, params.change).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteForeignerFamily(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.deleteForeignerFamily(params.fid, params.rid).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });
    },


    // foreigner status
    editForeignerPermitStatus(context, params) {
      return new Promise((resolve, reject) => {
        const oldStatus = params.oldStatus;
        const editedStatus = params.editedStatus;
        let editedActionDates = [];

        const payload = {};

        const editableKeys = [
          'action_date',
          'action_time',
          'note',
          'share_with_foreigner',
          'share_with_client',
          'place_id',
          'assistance_id',
        ];

        editedStatus.action_dates.forEach(
          (newActionDate, index) => {
            if (newActionDate.assistance_id === -1) {
              newActionDate.assistance_id = null;
              newActionDate.without_assistance = true;
            }

            if (newActionDate.assistance_id === -2)
              newActionDate.without_assistance = true;

            if (newActionDate.place_id === -1)
              newActionDate.place_id = null;

            if (
              newActionDate.action_date !== undefined &&
              !newActionDate.action_date
            )
              newActionDate.action_date = null;

            if (
              newActionDate.action_time !== undefined &&
              !newActionDate.action_time
            )
              newActionDate.action_time = null;

            Object.keys(oldStatus.action_dates[index]).forEach(
              (key) => {
                if (
                  editableKeys.includes(key) &&
                  (
                    newActionDate[key] !== oldStatus.action_dates[index][key] ||
                    key === 'action_date' ||
                    key === 'action_time'
                  )
                ) {
                  if (editedActionDates[index] === undefined)
                    editedActionDates[index] = {};

                  editedActionDates[index][key] = newActionDate[key];
                }
              },
            );

            if (editedActionDates[index] !== undefined) {
              editedActionDates[index].id = newActionDate.id;
            }
          },
        );

        if (editedActionDates.length !== 0)
          editedActionDates = editedActionDates.filter((el) => el != null);

        if (editedActionDates.length > 0)
          payload.action_dates = editedActionDates;

        if (editedStatus.is_current !== oldStatus.is_current)
          payload.is_current = editedStatus.is_current;

        if (editedStatus.on_hold !== oldStatus.on_hold)
          payload.on_hold = editedStatus.on_hold;

        ForeignerService.editForeignerPermitStatus(
          params.fid, params.pid, params.sid, payload,
        ).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    // foreigner permits
    retrieveForeignerPermits(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.getForeignerPermits(params.fid).then((data) => {
          context.commit('setCurrentForeignerPermits', data.data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    addForeignerPermit(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.addForeignerPermit(params.id, params.permit).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteForeignerPermit(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.deleteForeignerPermit(params.fid, params.pid).then((data) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    retrieveForeignerPermitStatusesDocuments(context, params) {
      return new Promise((resolve, reject) => {
        const p2 = ForeignerService.getForeignerPermitsStatuses(params.fid, params.pid);
        const p3 = ForeignerService.getForeignerPermitsDocuments(params.fid, params.pid);
        Promise.all([p2, p3]).then((values) => {
          context.commit('setCurrentForeignerPermitsStatusesDocuments', {
            pid: params.pid,
            statuses: values[0].data,
            documents: values[1].data,
          });
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },

    //documents
    editForeignerPermitDocument(context, params) {
      return new Promise((resolve, reject) => {
        if (params.length === 0) resolve();
        ForeignerService.editForeignerPermitDocument(params).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    deleteDocument(context, params) {
      /*return ForeignerService.deleteForeignerPermitDocument(params.fid, params.pid, params.did);*/
      return new Promise((resolve, reject) => {
        ForeignerService.deleteForeignerPermitDocument(params.fid, params.pid, params.did).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    deleteDocumentRow(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.deleteForeignerPermitDocumentRow(params.fid, params.pid, params.did).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    deleteDocumentRowFromModel(context, params) {
      let permit = null;

      for (const p of context.state.currentForeignerPermits) {
        if (p.id === params.pid) {
          permit = p;
          break;
        }
      }

      for (const [i, doc] of permit.documents.entries()) {
        if (doc.id === params.did) {
          permit.documents.splice(i, 1);
          break;
        }
      }
    },
    addCustomDocuments(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.addCustomDocuments(params.fid, params.pid, params.documents).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    doPermitObtain(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.doPermitObtain(params.fid, params.pid, params.fd).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    doEditPermitObtain(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.doEditPermitObtain(params.fid, params.pid, params.obj).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    doEditPermitObtainDocument(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.doEditPermitObtainDocument(params.fid, params.pid, params.did, params.document).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    editForeignerPermit(context, params) {
      return new Promise((resolve, reject) => {
        ForeignerService.editForeignerPermit(params.fid, params.pid, params.obj).then((data) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveForeignerOptions(context) {
      return new Promise((resolve, reject) => {
        ForeignerService.getForeigners(1, 200, 'fullname').then((data) => {
          context.commit('setForeignerOptions', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveRelationTypes(context) {
      return new Promise((resolve, reject) => {
        ForeignerService.getRelationsTypes().then((data) => {
          context.commit('setRelationTypes', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveHireOriginOptions(context) {
      return new Promise((resolve, reject) => {
        ForeignerService.getHireOriginOptions().then((data) => {
          context.commit('setHireOriginOptions', data.data);
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    retrieveCustomNotifications() {
      return new Promise((resolve, reject) => {
        ForeignerService.getRelationsTypes()
          .then((data) => {
            context.commit('setCustomNotifications', data.data);
            resolve();
          }).catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    data: (state) => {
      const data = [];
      for (const u of state.foreigners) {
        let tmp1 = u.main_permit_expiration_date;
        let tmp2 = u.main_permit_renewal_date;
        if (tmp1)
          tmp1 = tmp1.split(' ')[0];
        if (tmp2)
          tmp2 = tmp2.split(' ')[0];

        data.push({
          id: u.id,
          registered: u.registered,
          avatar_url: u.avatar_url,
          first_name: u.first_name,
          last_name: u.last_name,
          fullname: u.first_name + ' ' + u.last_name,
          is_active: u.is_active,
          status: u.status,
          contact_email: u.email,
          phone_primary: u.phone_primary,
          client_company: u.client_company,
          date_modified: u.date_modified,
          modified_by_avatar_url: u.modified_by_avatar_url,
          main_permit_expiration_date: tmp1,
          main_permit_renewal_date: tmp2,
          profile: u.profile,
          main_permit_status: u.main_permit_status,
          main_permit_status_name: u.main_permit_status_name,
          main_permit_status_index: u.main_permit_status_index,
          main_permit_type_text: u.main_permit_type_text,
          main_permit_type: u.main_permit_type,
          family_members: u.family_members,
          reference_number: u.reference_number,
          survey_submitted: u.survey_submitted,
          interface_language: u.interface_language,
          family_profiles: u.family_profiles,
        });

      }
      return data;
    },
    dataSimple: (state) => {
      return state.simpleForeigners;
    },
    tableParams: (state) => {
      return {
        page: state.page,
        prev: state.prev,
        next: state.next,
      };
    },
    permits: (state) => {
      if (Object.entries(state.currentForeignerPermits).length === 0)
        state.currentForeignerPermits = [];
      return state.currentForeignerPermits;
    },

    ifEmpty: (state) => {
      return state.foreigners.length === 0;
    },

    relationTypes: (state) => {
      return state.relationTypes;
    },

    hireOriginOptions: (state) => {
      return state.hireOriginOptions;
    },

    relationTypeById: (state) => (id) => {
      const r = state.relationTypes.find((el) => el.id === id);
      if (!r)
        return '';
      return r.name;
    },
    foreignerOptions: (state) => {
      return state.foreignerOptions;
    },
    foreignerOptionById: (state) => (id) => {
      const f = state.foreignerOptions.find((el) => el.id === id);
      if (!f)
        return '';
      return f.first_name + ' ' + f.last_name;
    },
  },
});
