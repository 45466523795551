<template>
  <v-flex>
    <v-flex style="height: 95vh">
      <v-flex md12>
        <v-toolbar color="white accent-4">
          <v-flex xs1>
            <v-btn
              id="backBtn"
              color="grey lighten-1"
              @click="goBack"
            >
              <v-icon samll>
                arrow_back_ios
              </v-icon>
              {{ $t('common.actions.back') }}
            </v-btn>
          </v-flex>
        </v-toolbar>
      </v-flex>

      <v-flex
        id="rrr"
        md12
        ml-0
        mt-1
        row
        style="height: 96%"
      >
        <!-- <ForeignerDetailLeftPanel
          :is-expanded="leftPanelExpanded"
          :class="leftPanelExpanded ? 'flex1-5' : 'flex0-5'"
          @open="() => leftPanelExpanded = true"
          @close="() => leftPanelExpanded = false"
        /> -->

        <v-flex>
          <v-flex
            v-if="isLoading"
          >
            <v-container
              bg
              fill-height
              grid-list-md
              text-xs-center
            >
              <v-layout
                fill-height
                justify-center
                row
                wrap
              >
                <Loading
                  style="margin-top: 10vh"
                />
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex
            v-else
          >
            <v-card
              height="100%"
            >
              <v-container row>
                <v-flex
                  ml-2
                  xs1
                >
                  <v-img
                    v-if="Object.entries(newForeigner.avatar_url).length === 0"
                    :class="'pointerHover ' + ((avatarEditMode === 1) ? 'avatar-mask' : '')"
                    :title="$t('foreigners.change_avatar')"
                    src="../../assets/avatar_big.png"
                    style="width: 100%"
                    @click="$refs.avatarFile.click()"
                  />
                  <v-img
                    v-else
                    :class="'pointerHover ' + ((avatarEditMode === 1) ? 'avatar-mask' : '')"
                    :src="BASE_HOST + '/' + newForeigner.avatar_url['128']"
                    :title="$t('foreigners.change_avatar')"
                    @click="$refs.avatarFile.click()"
                  />
                  <input
                    ref="avatarFile"
                    accept="image/*"
                    style="display: none"
                    type="file"
                    @change="uploadAvatar($event.target.files[0])"
                  >
                </v-flex>
                <v-flex
                  ml-2
                  xs10
                >
                  <v-container
                    mt-2
                    row
                    class="pt-0 pb-0"
                  >
                    <v-flex xs9>
                      <span class="display-1">
                        {{ newForeigner.first_name }} {{ newForeigner.last_name }}
                      </span>
                    </v-flex>
                  </v-container>

                  <v-container
                    row
                    style="padding-top: 0"
                  >
                    <v-flex
                      mt-1
                      xs5
                    >
                      <v-icon
                        v-if="newForeigner.status === 20"
                        color="green"
                      >
                        fiber_manual_record
                      </v-icon>
                      <v-icon
                        v-else-if="newForeigner.status === 10"
                        color="orange"
                      >
                        fiber_manual_record
                      </v-icon>
                      <v-icon
                        v-else
                        color="grey lighten-1"
                      >
                        fiber_manual_record
                      </v-icon>
                      <template v-if="status">
                        {{ $capitalize(status.name) }}
                      </template>
                      <br>
                      <div
                        v-if="newForeigner.profile.assigned_to === null"
                        class="mt-1"
                      >
                        <img
                          src="../../assets/avatar_big.png"
                          style="width: 20px; height: 20px"
                        >
                        <span
                          class="ml-2"
                          style="color: red"
                        >{{ $t('common.values.not_assigned') }}</span>
                      </div>
                      <div
                        v-else
                        class="mt-1"
                      >
                        <img
                          v-if="Object.entries(newForeigner.profile.assigned_to_avatar_url).length === 0"
                          src="../../assets/avatar_big.png"
                          style="width: 20px; height: 20px"
                        >
                        <img
                          v-else
                          :src="BASE_HOST + newForeigner.profile.assigned_to_avatar_url['32']"
                          style="width: 20px; height: 20px"
                        >
                        <span class="ml-1">
                          &nbsp;{{ newForeigner.profile.assigned_to }}
                        </span>
                      </div>
                      <div class="mt-1">
                        <v-icon>store</v-icon>&nbsp;{{ newForeigner.profile.client_company }}
                      </div>
                    </v-flex>
                    <v-flex
                      mt-2
                      xs3
                    />
                    <v-flex
                      mt-2
                      xs5
                    />
                    <v-flex
                      mt-2
                      xs1
                    />
                  </v-container>
                </v-flex>
              </v-container>

              <v-tabs
                v-model="tab"
                fixed-tabs
              >
                <v-tab>
                  {{ $t('foreigners.tabs.basic_info') }}
                </v-tab>
                <v-tab>
                  {{ $t('foreigners.tabs.permits') }}
                </v-tab>
                <v-tab>
                  {{ $t('foreigners.tabs.questionnaires') }}
                </v-tab>
                <v-tab v-if="showMessagingTab()">
                  {{ $t('foreigners.tabs.messaging') }}
                </v-tab>
                <v-tab v-if="me.role !== $roles.HR && me.role !== $roles.AGENCY">
                  {{ $t('foreigners.tabs.log') }}
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-if="tab === 0"
                v-model="tab"
              >
                <v-tab-item>
                  <div class="mx-5 mt-3">
                    <ForeignerBasicInfo
                      :foreigner="newForeigner"
                      @refresh-foreigner="viewForeigner"
                      @set-avatareditmode="avatarEditMode = $event"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-if="tab === 1"
                v-model="tab"
              >
                <v-tab-item :value="1">
                  <div class="mx-5 mt-3">
                    <ForeignerPermits :foreigner="newForeigner" />
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-if="tab === 2"
                v-model="tab"
              >
                <v-tab-item :value="2">
                  <div class="mx-5 mt-3">
                    <ForeignerQuestionnaire :foreigner="newForeigner" />
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-if="tab === 3"
                v-model="tab"
              >
                <v-tab-item :value="3">
                  <div class="mx-5 mt-3">
                    <ForeignerMessaging :foreigner="newForeigner" />
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-if="tab === 4"
                v-model="tab"
              >
                <v-tab-item :value="4">
                  <div class="mx-5 mt-3">
                    <ForeignerAuditLog :foreigner="newForeigner" />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-flex>
      </v-flex>
    </v-flex>

    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      bottom
      right
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import ForeignersService from '../../services/foreigners/ForeignersService';
import ForeignerBasicInfo from './ForeignerBasicInfo/ForeignerBasicInfo';
import ForeignerPermits from './ForeignerPermits/ForeignerPermits';
import ForeignerAuditLog from './ForeignerAuditLog';
import ForeignerMessaging from './ForeignerMessaging';
import ForeignerQuestionnaire from './ForeignerQuestionnaire';
// import ForeignerDetailLeftPanel from './ForeignerDetailLeftPanel';
import Loading from '@/assets/loading.svg';

export default {
  name: 'ForeignerDetail',
  components: {
    ForeignerBasicInfo,
    ForeignerPermits,
    ForeignerAuditLog,
    ForeignerMessaging,
    ForeignerQuestionnaire,
    // ForeignerDetailLeftPanel,
    Loading,
  },
  props: {
    fid: {
      type: Number,
      default: undefined,
    },
    pid: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      leftPanelExpanded: false,
      tab: '',

      sortBy: 'fullname',
      sortDesc: false,

      isLoading: false,

      BASE_HOST: process.env.VUE_APP_HOSTURL,

      newForeigner: {
        first_name: '',
        last_name: '',
        email: '',
        profile: {
          client_company_id: '',
          assigned_to_id: '',
          citizenship_id: '',
        },
        gender: '',
        password: '',
        password_confirm: '',
        phone_primary_code: '',
        phone_primary: '',
        phone_secondary_code: '',
        phone_secondary: '',
      },

      avatarEditMode: 0,

      snackbar: false,
      snackbarText: '',
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
    }),
    orderBy() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
    status() {
      if (this.newForeigner && !isNaN(this.newForeigner.status) && this.$store.state.coreData.enums) {
        return this.$store.state.coreData.enums.user_statuses.find((status) => status.id === this.newForeigner.status);
      }

      return false;
    },
  },
  created() {
    this.isLoading = true;
    if (this.fid !== undefined) {
      const flagMessaging = String(location).split('/').includes('messaging');
      const flagInfo = String(location).split('/').includes('info');
      const flagNotifications = String(location).split('/').includes('notifications');
      const flagQuestionnaires = String(location).split('/').includes('questionnaires');
      const flagPermits = String(location).split('/').includes('permits');
      const flagDocuments = String(location).split('/').includes('documents');

      this.$store
        .dispatch('foreigners/getForeignerDetails', this.fid)
        .then((f) => {
          this.viewForeigner(f.data.data);
          if (flagMessaging) {
            this.tab = 3;
          }
          if (flagInfo) {
            if (flagNotifications) {
              window.localStorage.setItem('itrack_for_info_notifications', '1');
            }
            this.tab = 0;
          }
          if (flagQuestionnaires) {
            this.tab = 2;
          }
          if (flagPermits) {
            window.localStorage.setItem('itrack_adm_forward_to_foreigner_permit', this.pid);
          }
          if (flagDocuments) {
            window.localStorage.setItem('itrack_adm_forward_to_foreigner_permit_documents', true);
          }
        });
    }
  },
  mounted() {
    this.$store.dispatch('clientsCompanies/retrieveCompanies', {
      page: 1,
      rows: 200,
      order_by: 'short_name',
    });
    this.$store.dispatch('internalUsers/retrieveUsers', {
      page: 1,
      rows: 100,
      order_by: 'fullname',
    });
    this.$store.dispatch('clientsUsers/retrieveUsers', {
      page: 1,
      rows: 200,
      order_by: 'fullname',
    });
    this.$store.dispatch('permitStatuses/retrieveStatuses', {
      page: 1,
      rows: 100,
      order_by: this.orderBy,
    });
    this.$store.dispatch('coreData/retrieveEnums');
  },
  methods: {
    showMessagingTab() {
      if (this.me.role === this.$roles.HR || this.me.role === this.$roles.AGENCY) {
        return !this.me.features.hide_messaging_for_hr;
      }
      return true;
    },
    uploadAvatar(file) {
      this.$store
        .dispatch('foreigners/uploadAvatar', {
          id: this.fid,
          avatar: { file },
        })
        .then((response) => {
          ForeignersService.getForeignerDetails(this.fid);
          this.snackbarText = response.data.message;
          this.snackbar = true;
        })
        .catch((r) => {
          ForeignersService.getForeignerDetails(this.fid)
            .then((r) => {
              this.$store.commit('foreigners/setForeignerAvatar', {
                fid: this.fid,
                avatar: r.data.data.avatar_url,
              });
              this.newForeigner.avatar_url = r.data.data.avatar_url;
            });
          this.snackbarText = r.response.data.message;
          this.snackbar = true;
        });
    },
    goBack() {
      this.$router.push('/foreigners');
    },
    viewForeigner(obj, tab = 1) {
      this.isLoading = true;
      this.tab = tab;

      return this.$store
        .dispatch('foreigners/getForeignerDetails', obj.id)
        .then((data) => {
          let tmpObj = {};
          const obj = data.data.data;

          tmpObj = {
            id: obj.id,
            avatar_url: obj.avatar_url,
            first_name: obj.first_name,
            last_name: obj.last_name,
            gender: obj.gender,
            is_active: obj.is_active,
            status: obj.status,
            contact_email: obj.email,
            phone_primary: obj.phone_primary,
            profile: obj.profile,
            date_modified: obj.date_modified,
            modified_by: obj.modified_by,
            modified_by_avatar_url: obj.modified_by_avatar_url,
            main_permit_expiration_date: obj.main_permit_expiration_date,
            family: obj.family,
            reference_number: obj.reference_number,
            created_by: obj.created_by,
            registered: obj.registered,
            interface_language: obj.interface_language,
          };

          this.newForeigner = tmpObj;
          this.isLoading = false;
        });
    },
  },
};
</script>
