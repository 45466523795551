<template>
  <div>
    <v-flex row>
      <v-flex>
        <div class="flex display-1 mx-2">
          {{ $t('adm.internal.users.title') }}
        </div>
      </v-flex>
      <v-flex
        xs4
        row
        class="text-right mt-3 "
      >
        <v-flex
          xs8
          offset-xs2
        >
          <div>
            <v-autocomplete
              v-model="filterValues.is_active"
              :items="filters.is_active"
              :label="$t('common.filters.status')"
              item-text="name"
              item-value="id"
              style="display: inline"
              :autocomplete="$randomString()"
              @change="refresh"
            />
          </div>
        </v-flex>
        <div
          v-if="filterValues.is_active !== ''"
          class="pointerHover"
          @click="filterValues.is_active = '' ; refresh()"
        >
          <v-icon :color="$appColours.button.delete">
            mdi-close
          </v-icon>
        </div>
      </v-flex>
      <v-flex class="text-right my-3">
        <div>
          <span class="mx-2">
            <v-btn
              v-if="tableParams.prev !== null"
              text
              small
              icon
              color="black"
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="prevPage"
            >
              <v-icon>arrow_back_ios</v-icon>
            </v-btn>
            {{ tableParams.page }}
            <v-btn
              v-if="tableParams.next !== null"
              text
              small
              icon
              color="black"
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              small
              icon
              color="grey"
              disabled
              @click="nextPage"
            >
              <v-icon>arrow_forward_ios</v-icon>
            </v-btn>
          </span>
          <v-btn
            :color="$appColours.button.ok"
            dark
            @click="function(){
              newUserDialog = true;
              clearNewUserForm();

              action = 'new'}"
          >
            {{ $t('adm.agency.users.create_button') }}
          </v-btn>
        </div>
      </v-flex>
    </v-flex>

    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page.sync="rowsOnPage"
      class="elevation-1"
      :page.sync="tablePage"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'disable-pagination': true,
        'items-per-page-all-text': '',
        'show-current-page': false,
        firstIcon: 'null',
        lastIcon: 'null',
        prevIcon: 'null',
        nextIcon: 'null',
      }"
      @update:items-per-page="refresh"
    >
      <template
        slot="header"
      >
        <tr>
          <th
            v-for="(h, index) in headers"
            :key="index"
            :class="'header_sortable_'+h.sortable"
            @click="sorting(h.value, h.sortable)"
          >
            <v-icon
              v-if="sortBy === h.value && sortDesc === true"
              small
            >
              arrow_downward
            </v-icon>
            <v-icon
              v-if="sortBy === h.value && sortDesc === false"
              small
            >
              arrow_upward
            </v-icon>
            {{ $t(h.text) }}
          </th>
        </tr>
      </template>
      <template
        slot="item.avatar_url"
        slot-scope="props"
      >
        <img
          v-if="props.item.avatar_url != undefined"
          :src="BASE_HOST+props.item.avatar_url"
        >
        <v-icon v-else>
          person
        </v-icon>
      </template>
      <template
        slot="item.is_active"
        slot-scope="props"
      >
        <v-icon
          v-if="props.value === true"
          :color="$appColours.status.others.active"
        >
          mdi-checkbox-blank-circle
        </v-icon>
        <v-icon
          v-else
          :color="$appColours.status.others.notactive"
        >
          mdi-checkbox-blank-circle
        </v-icon>
      </template>
      <template
        slot="item.contacts"
        slot-scope="props"
      >
        <a :href="&quot;mailto:&quot;+props.item.email">{{ props.item.email }}</a><br>
        <a :href="&quot;tel:&quot;+props.item.phone_primary_replaced">{{ props.item.phone_primary }}</a>
      </template>
      <template
        slot="item.last_login"
        slot-scope="props"
      >
        <span v-if="props.value === null">
          {{ $t('common.values.no_records') }}
        </span>
        <span v-else>
          {{ props.value }}
        </span>
      </template>
      <template
        slot="item.actions"
        slot-scope="props"
      >
        <v-btn
          icon
          small
          :color="$appColours.button.edit"
          @click="editUser(props.item)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :color="$appColours.button.delete"
          @click="deleteUserOpenDialog(props.item)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="newUserDialog"
      persistent
      max-width="800px"
      @keydown.esc="newUserDialog = false; clearNewUserForm"
      @keydown.meta.s="editUserDialogShortcutHandler($event)"
      @keydown.ctrl.s="editUserDialogShortcutHandler($event)"
      @keydown.meta.enter="editUserDialogShortcutHandler($event)"
      @keydown.ctrl.enter="editUserDialogShortcutHandler($event)"
      @keydown.enter="editUserDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span
            v-if="action === 'new'"
            class="headline"
          >{{ $t('adm.agency.users.create_button') }}</span>
          <span
            v-else-if="action === 'edit'"
            class="headline"
          >{{ $t('adm.users.user_dialog.title_edit') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="newUser.first_name"
                  :label="$t('common.fields.first_name') + ' *' "
                  required
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="newUser.last_name"
                  :label="$t('common.fields.last_name') + ' *' "
                  required
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newUser.email"
                  :label="$t('common.fields.email') + ' *' "
                  required
                />
              </v-flex>
              <!--<v-flex xs12 sm6 md6>
								<v-text-field label="Password" type="password" required v-model="newUser.password"></v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md6>
								<v-text-field label="Password Confirm" type="password" required v-model="newUser.password_confirm"></v-text-field>
							</v-flex>-->
              <v-flex
                xs12
                sm6
                md6
              >
                <!--								<v-text-field :label="$t('common.fields.gender') + ' *' " required v-model="newUser.gender" disabled></v-text-field>-->
                <v-autocomplete
                  v-model="newUser.gender"
                  :items="userGenders"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('common.fields.gender') + ' *' "
                  item-text="name"
                  item-value="id"
                  :autocomplete="$randomString()"
                >
                  <template
                    slot="item"
                    slot-scope="props"
                  >
                    {{ $t(props.item.key) }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <!--								<v-text-field :label="$t('common.fields.role') + ' *' " required v-model="newUser.role" disabled></v-text-field>-->
                <v-autocomplete
                  v-model="newUser.role"
                  :items="internalUserRoles"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('common.fields.role') + ' *' "
                  item-text="name"
                  item-value="id"
                  :autocomplete="$randomString()"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-layout wrap>
                  <!--<v-flex xs4>
									<v-text-field v-model="newUser.phone_primary_code" label="Code 1" ></v-text-field>
								</v-flex>-->
                  <v-flex xs12>
                    <v-text-field
                      v-model="newUser.phone_primary"
                      :label="$t('common.fields.phone') + ' 1' "
                      required
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-layout wrap>
                  <!--<v-flex xs4>
										<v-text-field v-model="newUser.phone_secondary_code" label="Code 2" ></v-text-field>
									</v-flex>-->
                  <v-flex xs12>
                    <v-text-field
                      v-model="newUser.phone_secondary"
                      :label="$t('common.fields.phone') + ' 2' "
                      required
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!--<v-flex xs12 sm6 md6>
								<v-autocomplete v-model="newUser.time_zone"
								                :items="timezoneOptions"
								                :menu-props="{ maxHeight: '400' }"
								                label="Timezone*"
								                item-text="name"
								                item-value="name"
								></v-autocomplete>
							</v-flex>-->

              <!--			Edit actions				-->
              <v-flex
                v-if="action === 'edit' && newUser.avatar_url == undefined"
                xs12
              >
                <v-file-input
                  accept="image/*"
                  :label="$t('common.fields.avatar')"
                  prepend-icon="camera_alt"
                  @change="uploadAvatar"
                />
              </v-flex>
              <v-flex
                v-else-if="action === 'edit' && newUser.avatar_url != undefined"
                xs12
                row
              >
                <v-flex xs1>
                  <img
                    ref="avatarRef"
                    :src="BASE_HOST+newUser.avatar_url"
                    style="width: 100%;"
                    class="mx-2"
                  >
                </v-flex>
                <v-flex xs11>
                  <v-file-input
                    accept="image/*"
                    :label="$t('common.fields.avatar')"
                    prepend-icon="none"
                    @change="uploadAvatar"
                  />
                </v-flex>
              </v-flex>
              <v-flex
                v-if="action === 'edit'"
                xs12
                sm6
                md6
              >
                <v-switch
                  v-model="newUser.is_active"
                  :label="$t('common.fields.active')"
                />
              </v-flex>
              <v-flex
                v-if="action === 'edit'"
                xs12
                sm6
                md6
              >
                <v-btn
                  v-if="resettedPass === false"
                  color="warning"
                  outlined
                  text
                  class="my-3"
                  @click="resetPassword"
                >
                  {{ $t('adm.users.user_dialog.reset_password') }}
                </v-btn>
                <v-btn
                  v-else
                  disabled
                  color="warning"
                  outlined
                  text
                  class="my-3"
                >
                  {{ $t('adm.users.user_dialog.reset_password_email_sent') }}
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="newUserAlertText.length > 0"
                  type="error"
                  v-html="newUserAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="newUserDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
          <v-btn
            v-if="action === 'new'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="createNewUser"
          >
            {{ $t('common.actions.save') }}
          </v-btn>
          <v-btn
            v-else-if="action === 'edit'"
            :color="$appColours.button.ok"
            outlined
            text
            :loading="disabledSaveButton"
            @click="saveUser"
          >
            {{ $t('common.actions.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteUserDialog"
      persistent
      max-width="800px"
      @keydown.esc="deleteUserDialog = false"
      @keydown.meta.s="deleteUserDialogShortcutHandler($event)"
      @keydown.ctrl.s="deleteUserDialogShortcutHandler($event)"
      @keydown.meta.enter="deleteUserDialogShortcutHandler($event)"
      @keydown.ctrl.enter="deleteUserDialogShortcutHandler($event)"
      @keydown.enter="deleteUserDialogShortcutHandler($event)"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t('adm.users.delete_user_dialog.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                text-center
              >
                <div
                  v-if="deleteUserAlertText.length === 0"
                  class="body-1"
                >
                  {{ $t('adm.users.delete_user_dialog.confirm_text') }} <b>{{ deleteUser.name }}</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-alert
                  v-if="deleteUserAlertText.length > 0"
                  type="error"
                  v-html="deleteUserAlertText"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="deleteUserAlertText.length === 0">
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteUserDialog = false"
          >
            {{ $t('common.actions.cancel') }}
          </v-btn>
          <v-btn
            :color="$appColours.button.delete"
            outlined
            dark
            :loading="disabledDeleteButton"
            @click="deleteUserMethod"
          >
            {{ $t('common.actions.delete') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer />
          <v-btn
            :color="$appColours.button.close"
            text
            outlined
            @click="deleteUserDialog = false"
          >
            {{ $t('common.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthService from '../../services/AuthService';
import baseURL from '../../services/APIConfig';
import helpers from '../../helpers';

export default {
  name: 'InternalUsers',
  data() {
    return {
      statusFilterItems: ['Any status', 'Active', 'Non-active'],
      statusFilter: 'Any status',

      snackbar: false,
      snackbarText: '',

      sortBy: 'username',
      sortDesc: false,
      filters: {
        is_active: [
          {
            name: this.$t('common.fields.active'),
            id: 10,
          },
          {
            name: this.$t('common.fields.not_active'),
            id: 20,
          },
        ],
      },
      filterValues: {
        is_active: '',
      },

      headers: [
        {
          text: this.$t('common.fields.avatar'),
          align: 'left',
          sortable: false,
          value: 'avatar_url',
        },
        {
          text: this.$t('common.filters.active'),
          align: 'left',
          sortable: true,
          value: 'is_active',
        },
        {
          text: this.$t('common.fields.name'),
          value: 'fullname',
          sortable: true,
        },
        {
          text: this.$t('common.fields.contacts'),
          value: 'contacts',
          sortable: false,
        },
        {
          text: this.$t('adm.users.table_header.last_login'),
          value: 'last_login',
          sortable: true,
        },
        {
          text: this.$t('common.fields.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      tablePage: 0,
      rowsOnPage: 10,
      //orderBy: 'id',

      BASE_HOST: process.env.VUE_APP_HOSTURL,
      action: 'new', //new or edit
      newUserDialog: false,
      newUser: {
        first_name: '',
        last_name: '',
        email: '',
        role: 10,
        gender: 10,
        password: '',
        password_confirm: '',
        phone_primary_code: '',
        phone_primary: '',
        phone_secondary_code: '',
        phone_secondary: '',
        //time_zone: "",
      },
      newUserAlertText: '',
      resettedPass: false,
      disabledSaveButton: false,

      editOriginalUser: null,

      deleteUserDialog: false,
      deleteUser: {
        id: 0,
        name: '',
      },
      deleteUserAlertText: '',
      disabledDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'internalUsers/tableData',
      tableParams: 'internalUsers/tableParams',
      internalUserRoles: 'coreData/internalUserRoles',
      userGenders: 'coreData/userGenders',
      dialCodes: 'coreData/dialCodes',
      timezoneOptions: 'coreData/timezones',
      me: 'coreData/me',
    }),
    orderBy: function() {
      return `${this.sortDesc ? '-' : ''}` + this.sortBy;
    },
  },
  mounted() {
    let refresh = localStorage.getItem('itrack_refresh_admin_int_user');
    if (refresh != null) {
      refresh = JSON.parse(refresh);
      if (String(this.me.id) in refresh) {
        refresh = refresh[String(this.me.id)];
        this.rowsOnPage = refresh.rows;
        if (refresh.order_by[0] === '-') {
          this.sortDesc = true;
          this.sortBy = refresh.order_by.slice(1);
        } else {
          this.sortDesc = false;
          this.sortBy = refresh.order_by;
        }
        this.filterValues.is_active = refresh.is_active;
      }
    }

    this.$store.dispatch('internalUsers/retrieveUsers', {
      page: 1,
      rows: this.rowsOnPage,
      order_by: this.orderBy,
      is_active: this.filterValues.is_active,
    });
  },
  methods: {
    editUserDialogShortcutHandler: function(event) {
      event.preventDefault();
      if (this.action === 'new') this.createNewUser();
      else if (this.action === 'edit') this.saveUser();
    },
    deleteUserDialogShortcutHandler: function(event) {
      event.preventDefault();
      this.deleteUserMethod();
    },

    nextPage: function() {
      const obj = this.rowsOnPage + 1;
      this.$store.dispatch('internalUsers/retrieveUsers', {
        page: this.tableParams.page + 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    prevPage: function() {
      const tmp = this.rowsOnPage - 1;
      this.$store.dispatch('internalUsers/retrieveUsers', {
        page: this.tableParams.page - 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
      });
    },
    refresh: function() {
      //localStorage.setItem('itrack_rpp_admin_int_user', this.rowsOnPage);

      const obj = {
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        is_active: this.filterValues.is_active,
      };
      let obj1 = null;
      const r = localStorage.getItem('itrack_refresh_admin_int_user');
      if (r != null) {
        obj1 = JSON.parse(r);
        obj1[String(this.me.id)] = obj;
      } else {
        obj1 = {};
        obj1[String(this.me.id)] = obj;
      }
      localStorage.setItem(
        'itrack_refresh_admin_int_user',
        JSON.stringify(obj1),
      );

      this.$store.dispatch('internalUsers/retrieveUsers', {
        page: 1,
        rows: this.rowsOnPage,
        order_by: this.orderBy,
        is_active: this.filterValues.is_active,
      });
    },
    sorting: function(value, sortable) {
      // //console.log("sorting", value, sortable);
      if (sortable === false) return;

      const old = this.sortBy;
      if (old === value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = false;
        this.sortBy = value;
      }
      this.refresh();
    },

    validateNewUserForm: function() {
      if (this.newUser.first_name.length < 2) {
        this.newUserAlertText = 'First name is too short';
        return false;
      }
      if (this.newUser.last_name.length < 2) {
        this.newUserAlertText = 'Last name is too short';
        return false;
      }
      /*if (this.newUser.time_zone.length < 1) {
					this.newUserAlertText = "Timezone is not selected";
					return false;
				}*/
      if (this.newUser.password) {
        if (this.newUser.password.length > 0) {
          if (this.newUser.password !== this.newUser.password_confirm) {
            this.newUserAlertText = 'Passwords are not the same';
            return false;
          }
        }
      }

      this.newUser.email = this.newUser.email.toLowerCase();
      const emailre =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (emailre.test(this.newUser.email) === false) {
        this.newUserAlertText = 'Email is not valid';
        return false;
      }

      /*if ((this.newUser.phone_primary.length > 0 && this.newUser.phone_primary_code.length === 0) ||
					(this.newUser.phone_primary.length === 0 && this.newUser.phone_primary_code.length > 0)){
					this.newUserAlertText = "Phone 1 is in bad format";
					return false;
				}
				if ((this.newUser.phone_secondary.length > 0 && this.newUser.phone_secondary_code.length === 0) ||
					(this.newUser.phone_secondary.length === 0 && this.newUser.phone_secondary_code.length > 0)){
					this.newUserAlertText = "Phone 2 is in bad format";
					return false;
				}

				if (this.newUser.phone_primary_code.length > 0 ) {
					this.newUser.phone_primary_code.replace(/[+() ]/g,'')
					if (this.$store.getters['coreData/checkDialCode', this.newUser.phone_primary_code] === false){
						this.newUserAlertText = "Code 1 do not exist";
						return false;
					}
				}
				if (this.newUser.phone_secondary_code.length > 0 ) {
					this.newUser.phone_secondary_code.replace(/[+() ]/g,'')
					if (this.$store.getters['coreData/checkDialCode', this.newUser.phone_secondary_code] === false){
						this.newUserAlertText = "Code 2 do not exist";
						return false;
					}
				}*/

      return true;
    },
    clearNewUserForm: function() {
      this.newUser.first_name = '';
      this.newUser.last_name = '';
      this.newUser.email = '';
      this.newUser.password = '';
      this.newUser.password_confirm = '';
      this.newUser.phone_primary_code = '';
      this.newUser.phone_primary = '';
      this.newUser.phone_secondary_code = '';
      this.newUser.phone_secondary = '';
      //this.newUser.time_zone = "";
      this.newUser.gender = '';
      this.newUser.role = '';
      this.newUserAlertText = '';
    },
    createNewUser: function() {
      this.disabledSaveButton = true;
      this.newUserAlertText = '';
      if (this.validateNewUserForm() !== true) {
        this.disabledSaveButton = false;
        return;
      }

      const obj = {};
      for (const k in this.newUser) obj[k] = this.newUser[k];

      obj.profile = {};
      if (obj.phone_primary === '') delete obj.phone_primary;
      if (obj.phone_primary_code === '') delete obj.phone_primary_code;
      if (obj.phone_secondary === '') delete obj.phone_secondary;
      if (obj.phone_secondary_code === '') delete obj.phone_secondary_code;
      if (obj.password.length === 0) {
        delete obj.password;
        delete obj.password_confirm;
      }

      // //console.log("Will send", obj)

      // //console.log("Create user", obj);
      this.$store
        .dispatch('internalUsers/createUser', obj)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalUsers/retrieveUsers', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.newUserDialog = false;
          this.clearNewUserForm();
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newUserAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newUserAlertText = `Error occured`;
					}*/
          this.newUserAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    uploadAvatar: function(file) {
      const that = this;
      if (file === null) {
        this.newUserAlertText = 'Picture is null';
        return;
      }
      this.newUserAlertText = '';

      this.$store
        .dispatch('internalUsers/uploadAvatar', {
          id: this.newUser.id,
          avatar: file,
        })
        .then((data) => {
          that.editUser(that.newUser).then((_) => {
            const t = parseInt(Date.now());
            // //console.log("REF img", that.$refs.avatarRef, that.$refs.avatarRef.src);
            const old = that.$refs.avatarRef.src;
            that.$refs.avatarRef.src = '';
            that.$refs.avatarRef.src = old + '?t=' + t;
          });
          that.$store.dispatch('internalUsers/retrieveUsers', {
            page: 1,
            rows: 10,
            order_by: this.orderBy,
          });
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newUserAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newUserAlertText = `Error occured`;
					}*/
          this.newUserAlertText = helpers.createErrorHtml(e.response.data);
        });
    },

    editUser: function(obj) {
      // //console.log("EDITING", obj);
      this.action = 'edit';
      this.newUserAlertText = '';
      const that = this;
      return this.$store
        .dispatch('internalUsers/getUserDetails', obj.id)
        .then((data) => {
          // //console.log("Will edit user", data.data.data);
          const tmpObj = {};
          const obj = data.data.data;
          tmpObj.first_name = obj.first_name;
          tmpObj.last_name = obj.last_name;
          tmpObj.email = obj.email;
          tmpObj.is_active = obj.is_active;
          tmpObj.id = obj.id;
          //tmpObj.time_zone = obj.time_zone;
          tmpObj.profile = obj.profile;
          tmpObj.role = obj.role;
          tmpObj.gender = obj.gender;
          tmpObj.avatar_url = obj.avatar_url['128'];

          const phoneRe = /\(\+(\d+)\) (.+)/g;
          if (obj.phone_primary !== '') {
            const phone1 = obj.phone_primary;
            //let phone1match = phoneRe.exec(phone1);
            // let phone1match = phone1.match(phoneRe)
            // tmpObj.phone_primary_code = phone1match[1];
            tmpObj.phone_primary = phone1;
          } else {
            tmpObj.phone_primary = '';
          }
          if (obj.phone_secondary !== '') {
            const phone2 = obj.phone_secondary;
            // let phone2match = phoneRe.exec(phone2);
            // tmpObj.phone_secondary_code = phone2match[1];
            tmpObj.phone_secondary = phone2;
          } else {
            tmpObj.phone_secondary = '';
          }
          that.newUser = tmpObj;
          that.editOriginalUser = JSON.parse(JSON.stringify(tmpObj));
          this.newUserDialog = true;
        });
    },
    saveUser: function() {
      // //console.log("SEVE EDITTED", this.newUser);
      this.disabledSaveButton = true;
      this.newUserAlertText = '';
      if (this.validateNewUserForm() !== true) {
        this.disabledSaveButton = false;
        return;
      }

      /*let obj = {};
				for (let k in this.newUser)
					obj[k] = this.newUser[k];*/

      /*delete obj.profile;


				if (!obj.password || obj.password.length === 0){
					delete obj.password;
					delete obj.password_confirm;
				}*/

      const diffObj = {};
      for (const key in this.editOriginalUser) {
        if (typeof this.editOriginalUser[key] !== 'object') {
          if (this.editOriginalUser[key] !== this.newUser[key]) {
            diffObj[key] = this.newUser[key];
          }
        } else {
          for (const key2 in this.newUser[key]) {
            if (this.editOriginalUser[key][key2] !== this.newUser[key][key2]) {
              if (!diffObj[key]) diffObj[key] = {};
              diffObj[key][key2] = this.newUser[key][key2];
            }
          }
        }
      }
      // //console.log("DIFF:", diffObj)

      // //console.log("save editted User", obj);
      this.$store
        .dispatch('internalUsers/editUser', {
          user: diffObj,
          id: this.newUser.id,
        })
        .then((data) => {
          /*this.snackbarText = data.data.message
					this.snackbar = true;*/
          this.$store.dispatch('internalUsers/retrieveUsers', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.clearNewUserForm();
          this.newUserDialog = false;
          this.disabledSaveButton = false;
        })
        .catch((e) => {
          /*let errorMsg = "";
					try {
						for (let m in e.response.data.data) {
							errorMsg += m + ': ' + e.response.data.data[m].join(" ")
						}
						this.newUserAlertText = `${e.response.status} ${e.response.statusText}: ${e.response.data.message}
					<br>
					${errorMsg}`
					}
					catch (e){
						// //console.log("CATCHED AN ERROR ", e);
						this.newUserAlertText = `Error occured`;
					}*/
          this.newUserAlertText = helpers.createErrorHtml(e.response.data);
          this.disabledSaveButton = false;
        });
    },

    resetPassword: function() {
      AuthService.resetUserPassword(this.newUser.username).then((data) => {
        this.resettedPass = true;
      });
    },

    deleteUserOpenDialog: function(obj) {
      this.deleteUserAlertText = '';
      this.deleteUser.name = obj.name;
      this.deleteUser.id = obj.id;
      this.deleteUserDialog = true;
    },
    deleteUserMethod: function() {
      this.$store
        .dispatch('internalUsers/deleteUser', this.deleteUser.id)
        .then((data) => {
          this.snackbarText = data.data.message;
          this.snackbar = true;
          this.$store.dispatch('internalUsers/retrieveUsers', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteUserDialog = false;
        })
        .catch((e) => {
          this.$store.dispatch('internalUsers/retrieveUsers', {
            page: this.tableParams.page,
            rows: this.rowsOnPage,
            order_by: this.orderBy,
          });
          this.deleteUserAlertText = helpers.createErrorHtml(e.response.data);
        });
    },
  },
};
</script>

<style scoped>
</style>
