<template>
  <v-container
    id="containerLogin"
    fluid
    fill-height
    style="margin-top: 8vh; margin-left: 10vw"
  >
    <v-flex row>
      <v-layout align-center>
        <v-flex md4>
          <v-card
            class="elevation-5"
            @keyup.enter="login"
          >
            <v-card-text>
              <v-form autocomplete="off">
                <v-text-field
                  id="usernameInp"
                  v-model="username"
                  autocomplete="off"
                  :class="{ 'input-group--dirty': dirtyEmail }"
                  placeholder=" "
                  prepend-icon="person"
                  name="fmvlkjfnvui"
                  :label="$t('common.fields.login')"
                  type="text"
                />
                <v-text-field
                  id="passwordInp"
                  v-model="password"
                  name="fakepasswordremembered"
                  :class="{ 'input-group--dirty': dirtyPwd }"
                  placeholder=" "
                  autocomplete="off"
                  prepend-icon="lock"
                  :label="$t('common.fields.password')"
                  type="password"
                />
              </v-form>
            </v-card-text>

            <v-alert
              v-if="errors && errors.message.length > 0"
              class="mx-2"
              :dense="true"
              type="error"
            >
              {{ errors.message }}
            </v-alert>
            <v-card-actions>
              <v-btn
                outlined
                :color="$appColours.button.action"
                @click="forgetPass"
              >
                {{ $t('main.login.forgot_pass') }}
              </v-btn>
              <v-spacer />
              <v-btn
                dark
                :color="$appColours.button.action"
                :loading="loading"
                @click="login"
              >
                {{ $t('common.actions.login') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex
          md1
          row
          offset-md1
          justify-end
        >
          <Logo
            style="width: 64px"
          />
        </v-flex>
        <v-flex
          md4
          ml-6
        >
          <h1 style="display: inline">
            {{ $t('main.login.welcome') }}
            <span
              style="color: #b6265b; display: inline"
            >i</span>Track
          </h1>
          <br>
          <h4
            style="color: #b6265b"
            class="ml-1"
          >
            {{ $t('main.login.subtitle') }}
          </h4>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      row
      mt-10
    />
    <v-flex
      row
      mt-10
    >
      <v-layout align-center>
        <v-flex md10>
          <h4 style="color: #b6265b">
            {{ $t('main.login.q1') }}
          </h4>
          {{ $t('main.login.a1') }}
          <br><br>
          <h4 style="color: #b6265b">
            {{ $t('main.login.q2') }}
          </h4>
          {{ $t('main.login.a2') }}
          <a href="mailto:info@czechrelocate.cz">info@czechrelocate.cz</a>
          <br><br>
          <h4 style="color: #b6265b">
            {{ $t('main.login.q3') }}
          </h4>
          <div v-html="prepareTmplResetLink($t('main.login.a3'))" />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
import isMobile from 'ismobilejs';
import { mapGetters } from 'vuex';
import Logo from '@/assets/logo.svg';
import CoreDataService from '@/services/CoreDataService';

export default {
  name: 'LoginUser',
  components: {
    Logo,
  },
  data() {
    return {
      username: '',
      password: '',
      errors: null,
      loading: false,
      recaptchaRequired: false,

      dirtyEmail: null,
      dirtyPwd: null,

      BASE_HOST: process.env.VUE_APP_HOSTURL,
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      interfaceLanguagesOptions: 'coreData/interfaceLanguages',
    }),
  },
  mounted() {
    this.username = '';
    this.password = '';

    let times = 0;
    const interval = setInterval(() => {
      times += 1;
      if ((this.dirtyEmail && this.dirtyPwd) || times === 20) {
        clearInterval(interval);
      }
    }, 100);

    if (!this.$route.query.noMobileRedirect && (isMobile().apple.device || isMobile().android.device)) {
      this.$router.push('/mobile');
    }
  },
  methods: {
    async loginSuccessful() {
      const that = this;
      await Promise.all([
        this.$store.dispatch('coreData/retrieveInternalUserRoles'),
        this.$store.dispatch('coreData/retrieveClientUserRoles'),
        this.$store.dispatch('coreData/retrieveCountries'),
        this.$store.dispatch('coreData/retrieveTimezones'),
        this.$store.dispatch('coreData/retrieveMe'),
      ]);

      await this.$store .dispatch('coreData/retrieveInterfaceLanguages');

      CoreDataService.getCurrentLanguage().then((data) => {
        const code = this.interfaceLanguagesOptions.find(
          (el) =>
            el.id ===
                  that.$store.getters['coreData/me']['interface_language'],
        ).name_text;

        localStorage.setItem('locale_code', code);
        const langData = data.data.data;
        for (const k in langData) {
          if (k !== 'main.login.a3') {
            langData[k] = that.$sanitize(langData[k]);
          }
        }
        localStorage.setItem('locale_data', JSON.stringify(langData));
        this.$i18n.setLocaleMessage(
          code,
          JSON.parse(localStorage.getItem('locale_data')),
        );
        this.$i18n.locale = code;

        const f = localStorage.getItem(
          'itrack_adm_forward_to_foreigner',
        );
        const ff = localStorage.getItem(
          'itrack_adm_forward_to_foreigner_messaging',
        );
        const fff = localStorage.getItem(
          'itrack_adm_forward_to_foreigner_questionnaires',
        );
        const ffff = localStorage.getItem('itrack_forward_to_me');

        if (f != null) {
          localStorage.removeItem('itrack_adm_forward_to_foreigner');
          if (
            this.me.role !== this.$roles.FOREIGNER &&
                  this.me.role !== this.$roles.FAMILY
          )
            location.replace('/foreigners/' + f);
          else {
            location.replace('/');
          }
        } else if (ff != null) {
          localStorage.removeItem(
            'itrack_adm_forward_to_foreigner_messaging',
          );
          if (
            this.me.role !== this.$roles.FOREIGNER &&
                  this.me.role !== this.$roles.FAMILY
          )
            location.replace('/foreigners/' + ff + '/messaging');
          else {
            location.replace('/');
          }
        } else if (fff != null) {
          localStorage.removeItem(
            'itrack_adm_forward_to_foreigner_questionnaires',
          );
          if (
            this.me.role !== this.$roles.FOREIGNER &&
                  this.me.role !== this.$roles.FAMILY
          )
            location.replace(
              '/foreigners/' + fff + '/questionnaires',
            );
          else {
            location.replace('/');
          }
        } else if (ffff != null) {
          localStorage.removeItem('itrack_forward_to_me');
          location.replace('/me');
        } else {
          location.replace('/');
        }
      });
    },
    async login() {
      let token = undefined;
      if (this.recaptchaRequired){
        await this.$recaptchaLoaded();
        token = await this.$recaptcha('login');
      }

      this.errors = null;
      this.loading = true;
      this.$store
        .dispatch('auth/login', {
          username: this.username,
          password: this.password,
          g_recaptcha_response: token,
        })
        .then(() => {
          this.loginSuccessful();
        })
        .catch((err) => {
          this.errors = err.response.data;
          this.loading = false;

          if (this.errors && this.errors.data && this.errors.data.recaptcha_requested) {
            this.recaptchaRequired = true;
          }
        });
    },
    forgetPass() {
      this.$router.push({ name: 'PassRecoveryFirst' });
    },
    prepareTmplResetLink(str) {
      return str.replace(
        ':href=\'BASE_HOST\' + \'reset_password\'',
        'href="' + this.BASE_HOST + 'reset_password"',
      );
    },
  },
};
</script>

<style scoped>
#containerLogin {
}
</style>
