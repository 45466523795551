<template>
  <div>
    <v-autocomplete
      id="mentionAutocomplete"
      v-model="selectedMention"
      attach="#msgFlex"
      label="Components"
      style="position: absolute; z-index: -905; left: 3vw"
      :menu-props="{ top: true, offsetY: true }"
      :items="possibleMentions"
      item-text="fullname"
      item-value="id"
      :autocomplete="$randomString()"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.fullname }} <v-icon color="grey">
                mdi-alpha-{{ data.item.roleLetter }}-box-outline
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <v-card
      class="elevation-1 mt-3"
      color="white accent-4"
      dense
    >
      <v-flex row>
        <v-flex
          id="msgFlex"
          xs9
          ml-6
        >
          <v-textarea
            id="msgText"
            v-model="text"
            outlined
            :label="$t('messaging.message')"
            rows="2"
            :autocomplete="$randomString()"
            @keydown="keyHandler"
            @input="$emit('update-text', text)"
          />
        </v-flex>
        <v-flex
          class="flex1-5"
          ml-5
          text-center
          style="margin-top: -10px"
        >
          <v-autocomplete
            id="permits"
            v-model="permitId"
            :label="$t('messaging.related_with')"
            :items="permitsWithNone"
            item-text="permit_type_name"
            item-value="id"
            :autocomplete="$randomString()"
            @change="$emit('update-permit-id', permitId)"
          >
            <template v-slot:item="data">
              <template v-if="data.item.id !== -1">
                <v-icon :color="$msgColours[data.item.color]">
                  mdi-square
                </v-icon>
              </template>

              {{ data.item.permit_type_name }}
              <template v-if="data.item.id !== -1">
                ({{ data.item.reference_number }})
              </template>
            </template>
          </v-autocomplete>
          <v-btn
            id="sendBtn"
            text
            large
            style="margin-top: -15px"
            @click="sendMessage"
          >
            <v-icon :disabled="(!permitId)">
              mdi-send
            </v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      bottom
      right
      :timeout="5000"
    >
      {{ snackbarText }}
      <v-btn
        color="blue"
        text
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    fid: {
      type: Number,
      required: true,
    },
    messagingStore: {
      type: String,
      required: true,
    },
    permitsWithNone: {
      type: Array,
      required: true,
    },
    possibleMentions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      text: '',
      permitId: null,
      mentions: [],

      snackbar: false,
      snackbarText: '',

      selectedMention: 0,
    };
  },

  watch: {
    permitId() {
      for (const el of Object.keys(localStorage).filter((el) => el.includes(`itrack_msg_${this.fid}`))) {
        window.localStorage.removeItem(el);
      }
      if (this.text.length > 0) {
        window.localStorage.setItem(`itrack_msg_${this.fid}_${this.permitId}`, this.text);
      }
    },
    text() {
      // store text in LS until msg is sent
      if (this.text.length > 0) {
        window.localStorage.setItem(`itrack_msg_${this.fid}_${this.permitId}`, this.text);
      } else {
        window.localStorage.removeItem(`itrack_msg_${this.fid}_${this.permitId}`);
      }

      if (this.text[this.text.length - 1] === '@') {
        document.querySelector('#mentionAutocomplete').click();
      }
    },
    selectedMention() {
      if (this.selectedMention > 0) {
        const mention = this.$store.getters[`${this.messagingStore}/mentionById`](this.selectedMention);
        this.text = `${this.text}[${mention}]`;
        document.querySelector('#msgText').click();

        this.selectedMention = 0;
      } else if (this.selectedMention === -2) {
        const tmp = this.possibleMentions.filter((el) => el.roleLetter === 'c' && el.id !== -2);
        this.text += tmp
          .map((el) => {
            const mention = this.$store.getters[`${this.messagingStore}/mentionById`](el.id);
            return `@[${mention}]`;
          })
          .join(', ');
        this.text = this.text.slice(1);

        document.querySelector('#msgText').click();
        this.selectedMention = 0;
      }
    },
  },

  mounted() {
    const lsSavedKeys = Object.keys(window.localStorage).filter((el) => el.includes(`itrack_msg_${this.fid}`));
    if (lsSavedKeys.length > 0) {
      const permId = lsSavedKeys[0].split('_')[3];
      if (permId) {
        this.permitId = parseInt(permId);
      }
      this.text = window.localStorage.getItem(lsSavedKeys[0]);
    }
  },

  methods: {
    keyHandler(event) {
      if (event.key === 'Enter' && event.shiftKey) {
      } else if (event.key === 'Enter') {
        event.preventDefault();
        this.sendMessage();
      }
    },

    sendMessage() {
      if (this.text.length === 0) return;
      if (!this.permitId) {
        for (const elem of document.querySelectorAll('label[for=permits]')) {
          const oldColor = elem.style.color;
          elem.style['color'] = 'red';
          setTimeout(function() {
            elem.style.color = oldColor;
          }, 300);
          setTimeout(function() {
            elem.style.color = 'red';
          }, 600);
          setTimeout(function() {
            elem.style.color = oldColor;
          }, 900);
        }

        return;
      }

      // prepare mentions
      const regexp = /@\[(.*?)\]/gm;
      let matches = '';
      try {
        while ((matches = regexp.exec(this.text.toString())) !== null) {
          this.mentions.push(
            this.$store.getters[`${this.messagingStore}/mentionByName`](
              matches[0].slice(2, -1),
            ),
          );
        }
      } catch (e) {
        this.snackbarText = 'Mentioned user not found';
        this.snackbar = true;
        return;
      }

      this.mentions.push(this.fid);

      const obj = {
        fid: this.fid,
        text: this.text.replace(/@\[(.*?)\]/g, (a, b) => b),
        recipients: Array.from(new Set(this.mentions)),
      };

      if (this.permitId !== null && this.permitId !== -1) {
        obj.permit_id = this.permitId;
      }

      this.$store.dispatch(`${this.messagingStore}/sendMessage`, obj).then((_) => {
        this.text = '';
        setTimeout((_) => {
          this.$emit('scroll-to-bottom');
        }, 200);
      });
    },
  },
};
</script>
