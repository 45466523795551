<script>
import { mapGetters } from 'vuex';

export default ({
  data: () => ({
    faqSectionDetail: null,
  }),
  computed: {
    ...mapGetters({
      faqSections: 'faq/faqSections',
      noFaqSections: 'faq/ifEmpty',
      me: 'coreData/me',
    }),
  },
  watch: {
    faqSections() {
      this.faqSectionDetail = this.faqSections.find((item) => item.id.toString() === this.$route.params.id);
    },
  },
  mounted() {
    this.$store.dispatch('faq/retrieveFaqSections');
  },
  methods: {
    backToFaq() {
      this.$router.push({ name: 'faq' });
    },
  },
});
</script>

<template>
  <div
    :class="me.role && me.role === $roles.FOREIGNER ? 'container--foreigner' : ''"
  >
    <div
      class="d-flex justify-space-between"
      style="width: 100%; margin-bottom: 24px"
    >
      <v-btn
        color="primary"
        @click="backToFaq"
      >
        {{ $t('common.faq.detail.back') }}
      </v-btn>
      <h1>
        {{ me.interface_language === 2 ? faqSectionDetail.name_ru : faqSectionDetail.name_en }}
      </h1>
      <div style="width: 132px" />
    </div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(faqItem,i) in faqSectionDetail.queries"
        :key="i"
      >
        <v-expansion-panel-header style="white-space: pre">
          <p
            style="text-overflow: ellipsis; overflow: hidden; white-space: pre-line; margin-bottom: 0; line-height: normal"
          >
            {{ me.interface_language === 2 ? faqItem.name_ru : faqItem.name_en }}
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="white-space: pre">
          <p
            style="text-overflow: ellipsis; overflow: hidden; white-space: pre-line; margin-bottom: 0"
          >
            {{ me.interface_language === 2 ? faqItem.answer_ru : faqItem.answer_en }}
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style scoped>
.container--foreigner {
  width: 90%;
  margin-left: 5%;
  margin-top: 24px;
}

>>> .v-expansion-panel-header {
  font-weight: bold;
}
</style>
