<template>
  <v-container
    id="containerLogin"
    fluid
    fill-height
    style="margin-top: 8vh; margin-left: 10vw"
  >
    <v-flex
      row
      mt-10
    >
      <v-layout align-center>
        <v-flex md10>
          <h4 style="color: #b6265b">
            Privacy Policy
          </h4>
          This Privacy Policy describes the information collected by CzRelo SH s.r.o.
          (CzechRelocate) as data controller, through our mobile applications, websites,
          and related CzechRelocate accounts, and how we use that information.
          Personal data is collected only with your knowledge and only for the means of
          improving our services. You can send us a request at any time to delete your
          personal data and we will do so. However, your e-mail address is connected to
          your account. If you ask us to delete your e-mail address, it will be
          necessary to delete your account as well.
          We never sell or rent personal information, and we ensure none of our partners
          do either.
          The analytic tools we use are to improve our service. These tools are in
          compliance with our Privacy Policy guidelines.
          <br><br>
          <h4 style="color: #b6265b">
            Collection and Use of Personal Information
          </h4>
          If you would like to view information collected through the account, please
          contact us at: <a href="mailto:help@app.itrack.cz">help@app.itrack.cz</a>.
          You can request to close your account by contacting us at: <a href="mailto:help@app.itrack.cz">help@app.itrack.cz</a>. Closing an account
          must be requested by the email provided at the time of registration in order
          to protect your privacy. If you decide to close your account, all stored data
          will be erased as soon as possible.
          The following is a description of the personal information that CzechRelocate
          may collect from you and how we use that information:
          <br><br>
          <h4 style="color: #b6265b">
            Your email address
          </h4>
          By creating a account (and in so doing, providing your email address) you
          acknowledge that you may receive communications related to your account, the
          service, and our other products and services, including without limitation:
          <ul>
            <li>
              We may send you an email confirming your account creation or otherwise
              related to your account.
            </li>
            <li>
              We may send you an email giving you notice of our information collection
              practices.
            </li>
            <li>
              We may send emails containing information about the account such as
              tips, availability of new features in app, or another promotions.
            </li>
            <li>
              We may alert you if there is an issue with your account or any other
              circumstances that could result in a disruption of service.
            </li>
          </ul>
          <br><br>
          <h4 style="color: #b6265b">
            Sharing of Your Personal Information
          </h4>
          No personal information will be disclosed, shared, or provided to third
          parties and will not be used to advertise products to you.
          <br><br>
          <h4 style="color: #b6265b">
            Third Party Service Providers
          </h4>
          We use technologies like cookies (small files stored by your browser), web
          beacons, or unique device identifiers to anonymously identify your computer or
          device so we can deliver a better experience. Our systems also log information
          like your browser, operating system, and IP address, but are not designed to
          associate personal information with your activities (such as pages you view or
          things you click on or search for).
          <br><br>
          <h4 style="color: #b6265b">
            Google Analytics
          </h4>
          Google Analytics is a web analytics tool that helps website owners understand
          how visitors engage with their website. Google Analytics customers can view a
          variety of reports about how visitors interact with their website so they can
          improve it. Google Analytics collects information anonymously. It reports
          website trends without identifying individual visitors,
          <br><br>
          <h4 style="color: #b6265b">
            No Ads in Application
          </h4>
          We do not use any advertising services inside of our application.
          <br><br>
          <h4 style="color: #b6265b">
            Final Words
          </h4>
          This privacy policy was last updated on 24.09.2021 and may change or be
          updated. If we make any material changes to our policies, we will place a
          prominent notice on our website or in the application. If the change
          materially affects registered users, we will notify you more prominently, such
          as via email communication or other forms of communication, if permissible. If
          material changes will impact our handling of personal information collected
          from your account, we will first notify and obtain verifiable consent.
          <br><br>
          If you have more questions about Privacy Policy please contact us at: <a href="mailto:help@app.itrack.cz">help@app.itrack.cz</a>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {};
  },
  methods: {},
};
</script>
