import axios from 'axios';

export default {
  getQuestionnaires() {
    const url = `${process.env.VUE_APP_FORURL}/survey/`;
    return axios.get(url);
  },
  getQuestionnaire(qid) {
    const url = `${process.env.VUE_APP_FORURL}/survey/${qid}/`;
    return axios.get(url);
  },
  sendDataForQuestionnaire(qid, survey_json, completed) {
    const url = `${process.env.VUE_APP_FORURL}/survey/${qid}/`;
    return axios.patch(url, survey_json);
  },
};
