<template>
  <div>
    <v-card>
      <v-tabs
        v-model="tab"
        color="blue"
        grow
        @change="debug"
      >
        <v-tab
          v-for="dialog in dialogs"
          :key="dialog.foreigner_id"
        >
          {{ dialog.foreigner_fullname }}
          <template v-if="dialog.message_count > 0">
            <v-badge
              color="red"
              class="ml-3"
            >
              <template v-slot:badge>
                {{ dialog.message_count }}
              </template>
            </v-badge>
          </template>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="dialog in dialogs"
          :key="dialog.foreigner_id"
        >
          <v-card>
            <v-card-text>
              <messaging_dialogue
                :ref="'md'+dialog.foreigner_id"
                :fid="dialog.foreigner_id"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messaging_dialogue from './messaging_dialogue';

export default {
  name: 'Messaging',
  components: { messaging_dialogue },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapGetters({
      me: 'coreData/me',
      dialogs: 'messaging/dialogs',
    }),
  },
  mounted() {
    this.$store.dispatch('messaging/retrieveDialogs');
  },
  methods: {
    debug: function(data) {
      const that = this;
      this.$store.commit('messaging/setMessagesAndParams', []);
      this.$store.dispatch('messaging/retrieveMessages', {
        fid: this.dialogs[data].foreigner_id,
        page: 1,
        rows: 10,
      });
      setTimeout(function() {
        that.$refs[
          String('md' + that.dialogs[data].foreigner_id)
        ][0].scrollToBottom();
      }, 500);
    },
  },
};
</script>

<style scoped>
</style>
